import { useMemo } from 'react';
import { StylingProfile } from '../../../../dataTypes';
import { questionnaireToDict } from './helpers';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function useDefaultValues(stylingProfile: StylingProfile) {
  return useMemo(() => {
    return {
      questionnaire: questionnaireToDict(stylingProfile.questionnaire),
      dateOfBirth: stylingProfile.dateOfBirth,
      questionnaireComments: stylingProfile.questionnaireComments,
    };
  }, [
    stylingProfile.questionnaire,
    stylingProfile.questionnaireComments,
    stylingProfile.dateOfBirth,
  ]);
}

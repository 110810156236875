import { FC, ReactElement } from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { CustomerProfile, StylingFor } from '../../../../../dataTypes';
import { TextInput } from '../../../../../components';
import { NAME_MAX_LENGTH, NAME_MIN_LENGTH } from '../constants';
import { FormState, useStoreOperations } from './useStoreOperations';
import { useSubscribeOnFormChanges } from '../../../../../utils/form-helpers';
import { useCanAutofocus } from '../../../../../hooks/useCanAutofocus';
import { RadioButton } from 'components/RadioButton';
import { genderKidsAnswerOptions } from 'stores';
import styles from './StylingProfile.module.scss';

interface Props {
  stylingFor: StylingFor;
  customerProfile?: CustomerProfile;
  isFirstProfileBeingCreated: boolean;
  onNext(): void;
  renderFooter(form: UseFormReturn<FormState>): ReactElement;
}

export const StylingProfile: FC<Props> = (props) => {
  const { stylingFor, customerProfile, isFirstProfileBeingCreated, onNext, renderFooter } = props;

  const { defaultValues, patchStore } = useStoreOperations(
    isFirstProfileBeingCreated,
    customerProfile,
  );

  const form = useForm<FormState>({
    mode: 'all',
    defaultValues,
  });

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
  } = form;

  useSubscribeOnFormChanges(watch, patchStore);

  const canAutofocus = useCanAutofocus();

  const stylingForKids = stylingFor === StylingFor.Kids;

  const placeholder = `Of ${stylingForKids ? 'kid' : 'person'} to style for`;

  return (
    <>
      <form
        data-testid="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit((formState) => {
          patchStore(formState);
          return onNext();
        })}
      >
        {renderFooter(form)}
        <div className={styles.container}>
          <TextInput
            label="First Name"
            placeholder={placeholder}
            maxLength={NAME_MAX_LENGTH}
            autoFocus={canAutofocus}
            id="sp-firstName"
            hasError={!!errors.firstName}
            {...register('firstName', {
              required: true,
              minLength: NAME_MIN_LENGTH,
              maxLength: NAME_MAX_LENGTH,
            })}
          />
          <TextInput
            label="Last Name"
            placeholder={placeholder}
            maxLength={NAME_MAX_LENGTH}
            id="sp-lastName"
            hasError={!!errors.lastName}
            {...register('lastName', {
              required: true,
              minLength: NAME_MIN_LENGTH,
              maxLength: NAME_MAX_LENGTH,
            })}
          />
          {stylingForKids && (
            <Controller<FormState>
              control={control}
              name="gender"
              rules={{
                required: true,
              }}
              render={({ field: { name, value, onChange } }) => (
                <RadioButton
                  label="Gender"
                  items={genderKidsAnswerOptions.map((option) => {
                    return {
                      value: option.title,
                      label: option.title,
                    };
                  })}
                  value={value}
                  name={name}
                  onChange={onChange}
                />
              )}
            />
          )}
        </div>
      </form>
    </>
  );
};

import { forwardRef, useMemo } from 'react';
import classNames from 'classnames';
import styles from './MultilineTextInput.module.scss';
import { Textarea } from '../Input';
import { getColumnGrid, GridOptions } from '../InputGrid/styles';

type NativeTextAreaProps = JSX.IntrinsicElements['textarea'];

interface AdditionalProps {
  title?: string;
  getGridStyles?(): GridOptions;
  inputClassName?: string;
  titleClassName?: string;
}

type MultilineTextInputProps = AdditionalProps & NativeTextAreaProps;

export const MultilineTextInput = forwardRef<HTMLTextAreaElement, MultilineTextInputProps>(
  function MultilineTextInput(props, ref) {
    const {
      title,
      className,
      getGridStyles = getColumnGrid,
      inputClassName,
      titleClassName,
      ...textAreaProps
    } = props;

    const gridClasses = useMemo(() => getGridStyles(), [getGridStyles]);

    return (
      <div className={classNames(gridClasses.container, className)}>
        <label className={styles.label} htmlFor={textAreaProps.id}>
          {title}
        </label>
        <Textarea
          {...textAreaProps}
          ref={ref}
          rows={3}
          className={classNames(gridClasses.input, inputClassName, styles.textArea)}
        />
      </div>
    );
  },
);

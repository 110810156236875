import React, { FC } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { RegistrationFlow } from './pages/RegistrationFlow';
import { ScrollToTop } from './components/ScrollToTop/ScrollToTop';
import * as urls from './routePaths';
import './App.scss';
import './Login.css';
import './normalize.scss';
import { Page404 } from './pages/Page404';
import { withMainLayout } from './components/MainLayout/withMainLayout';
import { Help } from './pages/Help';
import { TermsAndConditions } from './pages/TermsAndConditions';
import LandingPage from './pages/LandingPage';
import { Privacy } from './pages/Privacy';
import { longSiteTitle } from './constants';
import { ChangePasswordRedirect } from './pages/ChangePasswordRedirect';
import { Login } from './pages/Login';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ProtectedArea from 'components/ProtectedArea';
import { ProfileLayout } from 'components/ProfileLayout';
import { ProfileSubMenu } from 'components/ProfileSubMenu';
import { Profile } from 'pages/Profile';
import { Orders } from 'pages/Orders';
import { PaymentMethods } from 'pages/PaymentMethods';
import { CustomerDetails } from 'pages/CustomerDetails';
import { Addresses } from 'pages/Addresses';
import { firstStepUrl } from 'pages/RegistrationFlow/registrationFlowSteps';
import About from 'pages/About';
import ZipPay from 'pages/ZipPay';

export const App: FC = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{longSiteTitle}</title>
        </Helmet>
        <ScrollToTop />
        <Switch>
          <Route exact path={urls.LOGIN_URL} component={Login} />
          <Route exact path="/" component={LandingPage} />
          <Route component={RegistrationFlow} path={urls.ORDER_FLOW_PATH} />
          <Redirect from={urls.ORDER_FLOW_START_PATH} to={firstStepUrl} />

          <Route exact path={urls.HELP_URL} component={withMainLayout(Help)} />
          <Route exact path={urls.ABOUT_URL} component={withMainLayout(About)} />
          <Route exact path={urls.CHANGE_PASSWORD_REDIRECT} component={ChangePasswordRedirect} />
          <Route
            exact
            path={urls.TERMS_AND_CONDITIONS_URL}
            component={withMainLayout(TermsAndConditions)}
          />
          <Route exact path={urls.PRIVACY_URL} component={Privacy} />
          <Route exact path={urls.ZIP_PAY_URL} component={withMainLayout(ZipPay)} />

          <ProtectedArea>
            <ProfileLayout subMenu={<ProfileSubMenu />}>
              <Route exact path={urls.PROFILE_URL} component={Profile} />
              <Route exact path={urls.ORDER_VIEW_URL} component={Orders} />
              <Route path={urls.PAYMENT_METHODS_URL} component={PaymentMethods} />
              <Route path={urls.CUSTOMER_DETAILS_URL} component={CustomerDetails} />
              <Route path={urls.ADDRESS_LIST_URL} component={Addresses} />
            </ProfileLayout>
          </ProtectedArea>

          <Route component={Page404} />
        </Switch>
      </HelmetProvider>
    </>
  );
};

import { FC, useEffect, useState } from 'react';
import styles from './LoadingScreen.module.scss';
import { Loading } from '../Loading';

export const LoadingScreen: FC = () => {
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (!showLoading) {
      setTimeout(() => {
        setShowLoading(true);
      }, 3000);
    }
  }, [showLoading]);

  return (
    <>
      {showLoading && (
        <div className={styles.container}>
          <Loading />
        </div>
      )}
    </>
  );
};

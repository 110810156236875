import { forwardRef } from 'react';
import classNames from 'classnames';

import styles from './Label.module.scss';

type Props = JSX.IntrinsicElements['label'];

export const Label = forwardRef<HTMLLabelElement, Props>(function Label(props, ref) {
  const { className, ...otherProps } = props;
  return <label {...otherProps} className={classNames(className, styles.label)} ref={ref} />;
});

import { FC, useCallback } from 'react';
import styles from './OrderListItem.module.scss';
import classNames from 'classnames';
import { SubmittedOrder } from 'dataTypes';
import { generatePath, useHistory } from 'react-router-dom';
import { ORDER_VIEW_URL } from 'routePaths';
import { dateToShortString } from 'utils';
import { OrderStatusBadge } from 'pages/Orders/components/OrderStatusBadge';

interface OrderListItemProps {
  order: SubmittedOrder;
}

const OrderListItem: FC<OrderListItemProps> = (props: OrderListItemProps) => {
  const { order } = props;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,@typescript-eslint/restrict-template-expressions
  const title = `${order.stylingFor}, ${order.stylingProfile.firstName} ${order.stylingProfile
    .lastName!}`;

  const history = useHistory();

  const onClick = useCallback(() => {
    history.push(generatePath(ORDER_VIEW_URL, { orderId: order.id }));
  }, [history, order]);

  return (
    <div className={classNames(styles.item, styles.grid)} onClick={onClick} data-testid={'div'}>
      <div className={styles.col}>
        <div className={styles.box}>
          <div className={styles.title}>Summary</div>
          <div className={styles.text}>{title}</div>
        </div>
        <div className={styles.box}>
          <div className={styles.title}>Order number</div>
          <div className={styles.text}>{order.id}</div>
        </div>
      </div>

      <div className={classNames(styles.col, styles.hiddenMobile)}>
        <div
          className={classNames({ [styles.box]: true, [styles.hidden]: order.items.length <= 0 })}
        >
          <div className={styles.title}>Items</div>
          <div className={styles.text}>{order.items.length}</div>
        </div>
        <div
          className={classNames({
            [styles.box]: true,
            [styles.hidden]: !order.purchase,
          })}
        >
          <div className={styles.title}>Agreed price</div>
          <div className={styles.text}>£ {order.purchase?.totals.sellPrice}</div>
        </div>
      </div>

      <div className={classNames(styles.col, styles.hiddenMobile)}>
        <div className={styles.box}>
          <div className={styles.title}>{dateToShortString(order.createdDate)}</div>
        </div>
      </div>

      <div className={styles.col}>
        <OrderStatusBadge status={order.status} />
      </div>
    </div>
  );
};

export default OrderListItem;

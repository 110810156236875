import { FC, ReactNode } from 'react';
import { SavedAddress } from '../../dataTypes';
import { HomeIcon } from '../Icons';
import styles from './AddressPane.module.scss';

interface Props {
  address: SavedAddress;
  rightActions?: ReactNode;
  actions?: ReactNode;
}

export const AddressPane: FC<Props> = ({ address, rightActions, actions }) => {
  return (
    <div className={styles.root}>
      <div className={styles.leftIconContainer}>
        <HomeIcon width={23} height={27} />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.contentTitle}>Home address</div>
        <div className={styles.contentName}>{address.name}</div>
        <div className={styles.contentAddress}>
          <div>{address.house}</div>
          <div>{address.street}</div>
          <div>{address.city}</div>
          <div>{address.county}</div>
          <div>{address.postcode}</div>
        </div>
      </div>
      <div className={styles.rightActionsContainer}>{rightActions}</div>
      <div className={styles.actionsContainer}>{actions}</div>
    </div>
  );
};

import { QuestionBase } from './baseTypes';

export class TextQuestion implements QuestionBase<string> {
  constructor(
    public readonly label: string,
    public readonly placeholder: string,
    public readonly validate: (value: string | undefined) => string | undefined = () => undefined,
  ) {}

  public maxLength: number | undefined = undefined;

  withMaxLength(maxLength: number | undefined): this {
    this.maxLength = maxLength;
    return this;
  }
}

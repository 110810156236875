import { ComponentProps, forwardRef } from 'react';
import { useApplyStyles, StylingProps } from './styling';

type NativeButtonProps = ComponentProps<'a'>;

type Props = StylingProps & NativeButtonProps;

export const LinkButton = forwardRef<HTMLAnchorElement, Props>(function LinkButton(props, ref) {
  const [className, children, buttonProps] = useApplyStyles(props);

  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return (
    <a {...buttonProps} className={className} ref={ref}>
      {children}
    </a>
  );
});

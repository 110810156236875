import { FC } from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { ORDER_FLOW_START_PATH } from '../../routePaths';

import styles from './Header.module.scss';
import { RightMenu } from './components/RightMenu';

interface UrlParams {
  stylingFor: string;
}

interface HeaderProps {
  showNavButtons: boolean;
  headerRef?: React.RefObject<HTMLDivElement>;
}

const Header: FC<HeaderProps> = ({ showNavButtons, headerRef }) => {
  const { stylingFor } = useParams<UrlParams>();

  return (
    <header
      className={classNames({
        [styles.navigation]: true,
      })}
      ref={headerRef}
    >
      <div className={styles.linkBtnsBox}>
        <Link to="/" className={styles.logoLink}>
          <div className={styles.logo} />
        </Link>
        <div className={styles.logoText}>
          <div className={styles.logoText_item}>The World’s First Ever</div>
          <div className={styles.logoText_item}>Pay What U Want</div>
        </div>
      </div>
      <nav
        className={classNames(styles.navigationItems, {
          [styles.menuShow]: showNavButtons,
        })}
      >
        <Link
          to={generatePath(ORDER_FLOW_START_PATH, { stylingFor: 'men' })}
          className={classNames(styles.navigationItem, {
            [styles.navigationItem_selected]: stylingFor === 'men',
          })}
        >
          MEN
        </Link>
        <Link
          to={generatePath(ORDER_FLOW_START_PATH, { stylingFor: 'kids' })}
          className={classNames(styles.navigationItem, {
            [styles.navigationItem_selected]: stylingFor === 'kids',
          })}
          data-testid="kids-link"
        >
          KIDS
        </Link>
      </nav>
      <div className={styles.rightMenu}>
        <RightMenu />
      </div>
    </header>
  );
};

export default Header;

import { FC } from 'react';

export const Pencil: FC = () => {
  return (
    <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3196 1.17663C13.003 0.493208 14.1111 0.493208 14.7945 1.17663L19.0371 5.41927C19.7205 6.10269 19.7205 7.21072 19.0371 7.89414L7.72338 19.2079C7.60298 19.3283 7.44518 19.4042 7.27588 19.423L0.911924 20.1301C0.685484 20.1552 0.459874 20.0761 0.298774 19.915C0.137664 19.7539 0.0585339 19.5283 0.0836939 19.3018L0.790794 12.9379C0.809604 12.7686 0.885454 12.6108 1.00588 12.4904L12.3196 1.17663ZM13.7338 2.23729C13.6362 2.13966 13.4779 2.13966 13.3803 2.23729L2.25257 13.365L1.67804 18.5357L6.84878 17.9612L17.9765 6.83348C18.0741 6.73585 18.0741 6.57756 17.9765 6.47993L13.7338 2.23729Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6728 8.60116L5.24816 16.0259L4.1875 14.9652L11.6121 7.54053L12.6728 8.60116Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8551 10.0154L10.1982 4.35851L11.2589 3.29785L16.9158 8.95465L15.8551 10.0154Z"
        fill="black"
      />
    </svg>
  );
};

import BoxSamplesTiles from 'components/BoxSamplesTiles';
import { FC } from 'react';
import menSamples from 'components/BoxSamplesTiles/samples/menSamples';
import kidsSamples from 'components/BoxSamplesTiles/samples/kidsSamples';
import styles from './BoxSamplesSlider.module.scss';
import { BoxSample } from 'dataTypes';

export const firstScreenTitle = 'Personal styling for Men & Kids';

const BoxSamplesSlider: FC = () => {
  const mergeBoxSamples = (firstSamples: BoxSample[], secondSamples: BoxSample[]): BoxSample[] => {
    const shortestArrayLength = Math.min(firstSamples.length, secondSamples.length);
    const mergedArray = [
      ...firstSamples.slice(0, shortestArrayLength),
      ...secondSamples.slice(0, shortestArrayLength),
    ];

    const resultArr = [];
    for (let i = 0; i < shortestArrayLength; ++i) {
      resultArr.push(mergedArray[i + shortestArrayLength]);
      resultArr.push(mergedArray[i]);
    }

    return resultArr;
  };

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <h1 className={styles.title}>What our customers recently bought</h1>
        <h1 className={styles.subtitle}>Remember, pay what you want- it’s your Choixce!</h1>
        <BoxSamplesTiles
          boxSamples={mergeBoxSamples(menSamples, kidsSamples)}
          showBadges
          autoplay
        />
      </div>
    </section>
  );
};

export default BoxSamplesSlider;

import { FC } from 'react';
import { generatePath } from 'react-router-dom';
import { PAYMENT_METHODS_URL } from '../../../../../../../routePaths';
import styles from './NewCardPayment.module.scss';

export const NewCardPayment: FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.flexBox}>
        <p className={styles.title}>
          You have no payment methods registered in the system. Please{' '}
          <a
            className={styles.small_link}
            rel={'noreferrer'}
            href={generatePath(PAYMENT_METHODS_URL)}
            target={'_blank'}
          >
            click here
          </a>{' '}
          to add one.
        </p>
      </div>
    </div>
  );
};

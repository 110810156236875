/* istanbul ignore file */
import { QuestionnaireItem } from '../../../../dataTypes';
import { toDictionary } from '../../../../utils/toDictionary';
import { arrayHasItems } from '../../../../utils';

export function questionnaireToDict(items: QuestionnaireItem[]): Record<string, string> {
  return toDictionary(
    items,
    (item) => item.question,
    (item) => (arrayHasItems(item.answers) ? item.answers[0] : ''),
  );
}

export function dictToQuestionnaire(dict: Record<string, string>): QuestionnaireItem[] {
  return Object.entries(dict).map(
    ([question, answer]) =>
      ({
        question,
        answers: [answer],
      } as QuestionnaireItem),
  );
}

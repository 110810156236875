import { FC, useEffect, useState } from 'react';
import dropin, { Dropin, PaymentMethodPayload } from 'braintree-web-drop-in';
import './NewBraintreeCardForm.css';
import { Button } from 'components/Button';

import styles from './NewBraintreeCardForm.module.scss';

const BUTTON_TEXT = 'ADD';

interface Props {
  show: boolean;
  buttonText?: string;
  clientToken: string;
  checkout(nonce: string): void;
}

export const NewBraintreeCardForm: FC<Props> = ({
  show,
  buttonText,
  clientToken,
  checkout,
}: Props) => {
  const [braintreeInstance, setBraintreeInstance] = useState<Dropin | undefined>();
  const [showButton, setShowButton] = useState(true);

  const loadBraintree = (): void => {
    const config = {
      authorization: clientToken,
      container: '#braintree-drop-in-div',
      translations: { payWithCard: 'Add new card' },
    };

    if (process.env.REACT_APP_MODE !== 'development') {
      Object.assign(config, {
        threeDSecure: true,
      });
    }

    const callback = (error: object | null, instance: Dropin | undefined): void => {
      if (error) console.log(error);
      else setBraintreeInstance(instance);
    };

    const initializeBraintree = (): void => dropin.create(config, callback);

    if (braintreeInstance) {
      braintreeInstance
        .teardown()
        .then(() => {
          initializeBraintree();
          setShowButton(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      initializeBraintree();
    }
  };

  useEffect(
    () => {
      if (show) loadBraintree();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [show],
  );

  const requestPaymentMethod = (): void => {
    const callback = (error: object | null, payload: PaymentMethodPayload): void => {
      if (error) {
        console.error(error);
        return;
      }

      setShowButton(false);
      const paymentMethodNonce = payload.nonce;
      checkout(paymentMethodNonce);
    };

    braintreeInstance &&
      braintreeInstance.requestPaymentMethod(
        {
          threeDSecure: {
            amount: '1',
            challengeRequested: true,
          },
        },
        callback,
      );
  };

  return (
    <div className={styles.container}>
      <div id={'braintree-drop-in-div'} />
      {braintreeInstance && showButton && (
        <Button
          color="black"
          onClick={requestPaymentMethod}
          className={styles.button}
          data-testid="add-card"
        >
          {buttonText ?? BUTTON_TEXT}
        </Button>
      )}
    </div>
  );
};

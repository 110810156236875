import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { RegistrationFlowStepSwitch } from './RegistrationFlowStepSwitch';
import { useStore } from '../../providers/useStore';
import { useStylingFor } from './hooks/useStylingFor';
import { useStartFromFirstStep } from './hooks/useStartFromFirstStep';
import { useReturnToLastVisitedStep } from './hooks/useReturnToLastVisitedStep';
import { useSaveLastVisitedStep } from './hooks/useSaveLastVisitedStep';
import { useSyncEffect } from '../../hooks/useSyncEffect';
import { withReloadOnClearStorage } from './hocs/withReloadOnClearStorage';
import { useCurrentStep } from './hooks/useCurrentStep';
import RegistrationFlowLayout from 'components/RegistrationFlowLayout';
import { RegistrationFlowContextProvider } from 'providers/RegistrationFlowContext';
import { stepsCount } from './registrationFlowSteps';

export const RegistrationFlow: FC = withReloadOnClearStorage(
  observer(() => {
    const { currentStep, currentStepIndex } = useCurrentStep();

    useStartFromFirstStep(currentStep);
    useReturnToLastVisitedStep(currentStep);
    useSaveLastVisitedStep(currentStep);

    const { registrationFlowStore } = useStore();
    const stylingFor = useStylingFor();

    useSyncEffect(() => {
      registrationFlowStore.init(stylingFor);
    }, [registrationFlowStore, stylingFor]);

    return (
      <RegistrationFlowContextProvider>
        <RegistrationFlowLayout stepsCount={stepsCount} currentStepIndex={currentStepIndex}>
          <RegistrationFlowStepSwitch
            currentStep={currentStep}
            currentStepIndex={currentStepIndex}
          />
        </RegistrationFlowLayout>
      </RegistrationFlowContextProvider>
    );
  }),
);

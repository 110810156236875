import { ComponentProps, forwardRef } from 'react';
import { useApplyStyles, StylingProps } from './styling';

type NativeButtonProps = ComponentProps<'button'>;

type Props = StylingProps & NativeButtonProps;

export const Button = forwardRef<HTMLButtonElement, Props>(function Button(props, ref) {
  const [className, children, buttonProps] = useApplyStyles(props);

  return (
    <button {...buttonProps} className={className} ref={ref}>
      {children}
    </button>
  );
});

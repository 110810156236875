import { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';

export interface IMediaQueries {
  isPhone: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isDesktop: boolean;
}

const matchMediaQueries = (width = window.innerWidth): IMediaQueries => {
  return {
    isPhone: width <= 767,
    isTablet: width <= 1023,
    isLaptop: width <= 1279,
    isDesktop: width > 1279,
  };
};

export const useMediaQueries = (): IMediaQueries => {
  const [mediaQueries, setMediaQueries] = useState(matchMediaQueries());

  const listener = (): void => {
    const matches = matchMediaQueries();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (!isEqual(matches, mediaQueries)) setMediaQueries(matches);
  };

  useEffect(() => {
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  });

  return mediaQueries;
};

export default useMediaQueries;

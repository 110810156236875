import { useMutation, UseMutationResult } from 'react-query';
import { useService } from '../../../../providers/useService';

/* istanbul ignore file */

interface BookChatParams {
  stylingProfileId: string;
  comments: string;
}

export function useBookChatForOrder(): UseMutationResult<void, unknown, BookChatParams> {
  const { apiService } = useService();
  return useMutation(async ({ stylingProfileId, comments }) => {
    await apiService.bookChatForOrder(stylingProfileId, comments);
  });
}

import { FC } from 'react';

/* istanbul ignore next */

interface OrderIconProps {
  className?: string;
  width: number;
  height: number;
}

export const OrderIcon: FC<OrderIconProps> = (props: OrderIconProps) => {
  return (
    <div className={props.className} style={{ width: props.width, height: props.height }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.9214 4.00854C25.9037 3.98453 25.8892 3.95839 25.8781 3.93074C25.827 3.84358 25.7627 3.76484 25.6874 3.69734C25.5947 3.60853 25.4859 3.53813 25.3668 3.48987L13.234 0.0321051C13.0809 -0.0107017 12.9191 -0.0107017 12.766 0.0321051L0.63323 3.48987C0.534741 3.51884 0.441234 3.56256 0.355909 3.61954C0.280646 3.68705 0.21633 3.76578 0.165251 3.85294C0.168113 3.87879 0.168113 3.90489 0.165251 3.93074C0.0771638 4.05675 0.0206947 4.20201 0.000592077 4.35432V21.6432C-0.00659975 21.8372 0.0518991 22.028 0.166664 22.1848C0.281429 22.3416 0.445777 22.4553 0.63323 22.5076L12.766 25.9654C12.9186 26.0115 13.0814 26.0115 13.234 25.9654L25.3668 22.5076C25.5542 22.4553 25.7186 22.3416 25.8333 22.1848C25.9481 22.028 26.0066 21.8372 25.9994 21.6432V4.35432C25.9952 4.23523 25.9687 4.11797 25.9214 4.00854ZM13 1.76099L21.9783 4.35432L13 6.94764L4.02174 4.35432L13 1.76099ZM1.73385 5.50402L3.4671 5.99676V12.1343C3.46133 12.3318 3.52355 12.5252 3.64338 12.6825C3.76322 12.8398 3.93345 12.9514 4.12574 12.9987L7.59225 13.8632C7.66132 13.8715 7.73116 13.8715 7.80024 13.8632C8.03008 13.8632 8.25051 13.7721 8.41303 13.61C8.57556 13.4479 8.66686 13.228 8.66686 12.9987V7.47495L12.1334 8.46042V23.9512L1.73385 20.9948V5.50402ZM6.93361 6.94764V11.8577L5.20036 11.4255V6.48949L6.93361 6.94764ZM24.2662 20.9948L13.8666 23.9512V8.46042L24.2662 5.50402V20.9948Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

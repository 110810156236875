import React, { FC } from 'react';
import { Title } from '../../../../components/Title';
import { RouterLinkButton } from '../../../../components/Button';
import { useAddressList } from '../../../../serverCache/address';
import { AddressListItem } from '../../components/AddressListItem';
import styles from './Main.module.scss';
import { Loading } from '../../../../components/Loading';
import { ADDRESS_NEW_URL, PROFILE_URL } from '../../../../routePaths';
import { arrayHasItems } from '../../../../utils';
import { ProfileBack } from '../../../../components/ProfileBack';

export const Main: FC = () => {
  const { data, isLoading } = useAddressList();

  return (
    <>
      <ProfileBack to={PROFILE_URL} name="profile" />
      <Title backgroundTitle="My Profile" title="My Addresses" align="center" />
      {isLoading && (
        <div className={styles.loadingWrapper}>
          <Loading isBlack />
        </div>
      )}
      {!isLoading && (
        <>
          <div className={styles.addNewButtonContainer}>
            <RouterLinkButton className={styles.addNewButton} color="white" to={ADDRESS_NEW_URL}>
              Add New address
            </RouterLinkButton>
          </div>
          {arrayHasItems(data) && (
            <div className={styles.content}>
              {data.map((address) => (
                <AddressListItem key={address.id} address={address} />
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

/* istanbul ignore file */

import { ChangeEvent, forwardRef, useMemo } from 'react';
import classNames from 'classnames';

import styles from './TextInput.module.scss';
import { Label } from '../Label';
import { Input } from '../Input';
import { getRowGrid } from '../InputGrid/styles';

function identity<T>(value: T): T {
  return value;
}

type NativeInputProps = JSX.IntrinsicElements['input'];

type ModifyOnChange = (e: ChangeEvent<HTMLInputElement>) => ChangeEvent<HTMLInputElement>;

interface AdditionalProps {
  label?: string;
  hasError?: boolean;
  inputClassName?: string;
  titleClassName?: string;
  modifyOnChange?: ModifyOnChange;
}

type TextInputProps = AdditionalProps & Omit<NativeInputProps, 'type'>;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(function TextInput(
  props,
  inputRef,
) {
  const {
    label,
    className,
    inputClassName,
    titleClassName,
    hasError,
    modifyOnChange = identity,
    ...inputProps
  } = props;

  const gridStyles = useMemo(getRowGrid, []);

  return (
    <div className={classNames(gridStyles.container, styles.container, className)}>
      {label && (
        <Label
          className={classNames(gridStyles.label, styles.title, titleClassName)}
          htmlFor={inputProps.id}
        >
          {label}
        </Label>
      )}
      <Input
        className={classNames(gridStyles.input, inputClassName)}
        {...inputProps}
        onChange={(e) => inputProps.onChange?.(modifyOnChange(e))}
        error={hasError}
        type="text"
        ref={inputRef}
      />
    </div>
  );
});

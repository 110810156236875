import { FC } from 'react';
import styles from './HowItWorks.module.scss';
import classNames from 'classnames';
import TrustedShopsRating from 'components/TrustedShopsRating';

const HowItWorks: FC = () => {
  return (
    <section className={styles.section}>
      <div className={styles.TrustedShopsWrapper}>
        <TrustedShopsRating />
      </div>

      <h2 className={styles.mainTitle}>How It Works</h2>
      <div className={styles.container}>
        <div className={styles.row}>
          <div>
            <div className={classNames(styles.image, styles.complete)}></div>
            <div className={styles.cardTitle}>
              <span className={styles.cardTitleNumber}>1.</span>
              <span>Complete Your Style Profile</span>
            </div>
            <div className={styles.cardText}>So we understand your fit, style and need</div>
          </div>

          <div>
            <div className={classNames(styles.image, styles.box)}></div>
            <div className={styles.cardTitle}>
              <span className={styles.cardTitleNumber}>2.</span>
              <span>Book Your Box</span>
            </div>
            <div className={styles.cardText}>
              Your stylist will handpick items for your box - for a £20 styling deposit, which will
              be credited against anything you decide to keep
            </div>
          </div>

          <div>
            <div className={classNames(styles.image, styles.clothes)}></div>
            <div className={styles.cardTitle}>
              <span className={styles.cardTitleNumber}>3.</span>
              <span>Try Everything On in the Comfort of Your Own Home</span>
            </div>
            <div className={styles.cardText}>Free UK shipping, with delivery within 3 days.</div>
          </div>

          <div>
            <div className={classNames(styles.image, styles.creditCardIcon)}></div>
            <div className={styles.cardTitle}>
              <span className={styles.cardTitleNumber}>4.</span>
              <span>Payment</span>
            </div>
            <div className={styles.cardText}>
              Decide what you want to keep and pay up to 70% Off RRP.
            </div>
          </div>

          <div>
            <div className={classNames(styles.image, styles.returnIcon)}></div>
            <div className={styles.cardTitle}>
              <span className={styles.cardTitleNumber}>5.</span>
              <span>Return For Free</span>
            </div>
            <div className={styles.cardText}>
              Paperless returns from your home or drop off location
            </div>
          </div>

          <div>
            <div className={classNames(styles.image, styles.clickIcon)}></div>
            <div className={styles.cardTitle}>
              <span className={styles.cardTitleNumber}>6.</span>
              <span>Reorder in One Click</span>
            </div>
            <div className={styles.cardText}>
              To be one step closer to &quot;pay what you want&quot; Gold Membership
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;

import { FC } from 'react';
import styles from './PaymentMethodListItem.module.scss';
import { Badge } from '../../../../components/Badge';
import { CardBrandIcon } from '../../../../components/CardBrandIcon';
import { PaymentMethod } from '../../../../dataTypes';
import { useCardBrand } from '../../../../hooks/useCardBrand';
import { useCardLastDigits } from '../../../../hooks/useCardLastDigits';
import {
  useDeletePaymentMethod,
  useMakePaymentMethodDefault,
} from '../../../../serverCache/paymentMethods';
import { IconButton } from '../../../../components/IconButton';
import { Trash } from '../../../../components/Icons/Trash';

interface Props {
  method: PaymentMethod;
}

export const PaymentMethodListItem: FC<Props> = ({ method }) => {
  const { card, isDefault, id } = method;
  const { cardNumber, expiryYear, expiryMonth } = card;

  const lastCardNumberDigits = useCardLastDigits(cardNumber);
  const cardBrand = useCardBrand(cardNumber);

  const { mutate: onDelete, isLoading: isDeleting } = useDeletePaymentMethod(id);
  const { mutate: onMakeDefault, isLoading: isMakingDefault } = useMakePaymentMethodDefault(id);

  return (
    <div className={styles.host}>
      <div className={styles.top}>
        <CardBrandIcon cardBrand={cardBrand} />
        <span className={styles.brandText}>Ending in {lastCardNumberDigits}</span>
        <IconButton disabled={isDeleting} onClick={onDelete}>
          <Trash />
        </IconButton>
      </div>
      <div className={styles.bottom}>
        Valid until {expiryMonth} / {expiryYear}
        <span className={styles.makeDefault}>
          {isDefault ? (
            <Badge>default</Badge>
          ) : (
            <button className={styles.makeDefaultButton} onClick={onMakeDefault}>
              {isMakingDefault ? 'Loading...' : 'Make default'}
            </button>
          )}
        </span>
      </div>
    </div>
  );
};

import { FC } from 'react';
import styles from './ChooseDiscountSlider.module.scss';
import Slider from 'rc-slider';
import { handleRender } from 'components/TooltipSlider/TooltipSlider';

interface Props {
  minDiscount: number; //int
  maxDiscount: number;
  discountPercent: number;
  onSelectDiscount(discount: number): void;
}

const ChooseDiscountSlider: FC<Props> = ({
  onSelectDiscount,
  discountPercent,
  minDiscount,
  maxDiscount,
}) => {
  const isPayWhatYouWant = maxDiscount === 100 ? true : false;
  const railGrayStyle = { backgroundColor: 'var(--gray-500)' };
  const railColoredStyle = {
    background:
      'linear-gradient(90deg, #db0707, #0d0d0d 20%, rgba(251, 186, 20, 0) 35%, #fbba14 40%)',
  };
  const railStyle = isPayWhatYouWant ? railColoredStyle : railGrayStyle;

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Choose your discount</div>

      <div className={styles.sliderTop}>
        {!isPayWhatYouWant && (
          <div className={styles.info}>
            Want more discount? Become a gold member by completing 4 boxes and unlock the “pay what
            you want” sliding scale!
          </div>
        )}

        {discountPercent >= 95 && isPayWhatYouWant && (
          <div className={styles.info}>
            Please bear in mind the time we’ve taken to listen to your requirements, pick, pack the
            order and dispatch…remember you can send it all back for free because at this price we
            may not be around very long and be able to send you future orders.
          </div>
        )}
        {discountPercent >= 90 && discountPercent < 95 && isPayWhatYouWant && (
          <div className={styles.info}>
            Please tell us if you are disappointed with your order so that we can get it right for
            you, because at this price, we will have to prioritise fairer paying customers over your
            future orders. We are only have a limited amount of spaces in our club, at this price
            your are in danger of being in the lowest 10% and so having your membership revoked.
          </div>
        )}

        {discountPercent >= 71 && discountPercent < 82 && isPayWhatYouWant && (
          <div className={styles.info}>
            Thank you! Your stylist is starting to get some commission 😊
          </div>
        )}

        {discountPercent >= 61 && discountPercent <= 70 && isPayWhatYouWant && (
          <div className={styles.info}>
            Wow! Thank you – at this price you are close to gaining access to our exclusive luxury
            brands, getting first dibs on our new stock and we can start giving back with our
            charity partner – nudge the scale up a bit and you can 😊
          </div>
        )}

        {discountPercent <= 60 && isPayWhatYouWant && (
          <div className={styles.info}>
            Congratulations, you have full access to our exclusive brands, priority of place in your
            stylist’s diary and first dibs on new stock!
          </div>
        )}
      </div>

      <Slider
        min={minDiscount}
        max={maxDiscount}
        reverse
        defaultValue={discountPercent}
        onChange={(value: number | number[]) => onSelectDiscount((value as number) / 100)}
        railStyle={railStyle}
        handleRender={handleRender}
      />
    </div>
  );
};

export default ChooseDiscountSlider;

import { AxiosError, AxiosInstance } from 'axios';
import { generatePath } from 'react-router';
import {
  makePostDirectPaymentUrl,
  makeGetOrderPaymentStatusUrl,
  PAYMENT_METHODS_DELETE_URL,
  PAYMENT_METHODS_SET_DEFAULT_URL,
  PAYMENT_METHODS_URL,
  makePostPayDesiredAmount,
  makeGetZipLinkDesiredAmount,
  makeGetZipLink,
  makePayWithZipLink,
  makeGenerateBraintreeTokenUrl,
  makeBraintreeCheckouUrl,
} from '../consts';
import { PaymentMethod, PaymentResult, ZipLinkResult } from '../dataTypes';

export const paymentsPath = 'payments';

export class PaymentApiService {
  constructor(private readonly axiosInstance: AxiosInstance) {}

  async deletePaymentMethod(id: string): Promise<void> {
    const url = generatePath(PAYMENT_METHODS_DELETE_URL, { id });

    await this.axiosInstance.delete(url);
  }

  public async makePaymentMethodDefault(id: string): Promise<void> {
    const url = generatePath(PAYMENT_METHODS_SET_DEFAULT_URL, { id });

    await this.axiosInstance.post(url);
  }

  async getPaymentMethods(): Promise<PaymentMethod[]> {
    const url = generatePath(PAYMENT_METHODS_URL);

    const response = await this.axiosInstance.get<PaymentMethod[]>(url);

    return response.data;
  }

  async directPayForOrder(orderId: string, paymentMethodId: string): Promise<PaymentResult | null> {
    const url = makePostDirectPaymentUrl(orderId);

    try {
      const result = await this.axiosInstance.post<PaymentResult>(url, {
        paymentMethodId,
      });

      return result.data;
    } catch (e) {
      const axiosError = e as AxiosError<PaymentResult>;

      if (axiosError.response?.status === 412) {
        return axiosError.response.data;
      }

      throw e;
    }
  }

  async payDesiredAmount(
    orderId: string,
    paymentMethodId: string,
    sellPrice: number,
    purchasedItemIds: string[],
  ): Promise<PaymentResult | null> {
    const url = makePostPayDesiredAmount(orderId);

    try {
      const result = await this.axiosInstance.post<PaymentResult>(url, {
        paymentMethodId,
        sellPrice,
        purchasedItemIds,
      });

      return result.data;
    } catch (e) {
      const axiosError = e as AxiosError<PaymentResult>;

      if (axiosError.response?.status === 412) {
        return axiosError.response.data;
      }

      throw e;
    }
  }

  async getPaymentStatus(orderId: string): Promise<PaymentResult | null> {
    const url = makeGetOrderPaymentStatusUrl(orderId);

    const response = await this.axiosInstance.get<PaymentResult | null>(url);

    return response.data;
  }

  async getZipCheckoutLink(
    orderId: string,
    sellPrice?: number,
    purchasedItemIds?: string[],
  ): Promise<ZipLinkResult> {
    const url =
      sellPrice && purchasedItemIds
        ? makeGetZipLinkDesiredAmount(orderId)
        : makeGetZipLink(orderId);
    const response = await this.axiosInstance.post<ZipLinkResult>(url, {
      sellPrice,
      purchasedItemIds,
    });
    return response.data;
  }

  async payWithZip(orderId: string, checkoutId: string): Promise<PaymentResult | null> {
    const url = makePayWithZipLink(orderId, checkoutId);
    const response = await this.axiosInstance.post<PaymentResult>(url);
    return response.data;
  }

  async generateBraintreeToken(): Promise<string> {
    const response = await this.axiosInstance.get(makeGenerateBraintreeTokenUrl());
    const { token } = response.data as { token: string };

    return token;
  }

  async checkoutWithBraintree(paymentMethodNonce: string): Promise<unknown> {
    const response = await this.axiosInstance.post(makeBraintreeCheckouUrl(), {
      paymentMethodNonce,
    });
    return response.data as unknown;
  }
}

import { FC, useState } from 'react';
import { useBookChatForOrder } from './useBookChatForOrder';
import { ReorderTile } from '../ReorderTile';
import { MultilineTextInput } from '../../../../components';
import styles from './SomethingSpecificBox.module.scss';
import { SomethingBoxIcon } from '../../../../components/Icons';

export const SomethingSpecificBox: FC = () => {
  const [comments, setComments] = useState('');
  const { status, mutate } = useBookChatForOrder();

  const mutateWithComments = (stylingProfileId: string): void => {
    mutate({ stylingProfileId, comments });
  };

  const idleContent = (
    <>
      <p>Book a chat with your stylist or let us know what you need.</p>
      <MultilineTextInput
        data-testid="comments"
        className={styles.multilineTextInput}
        placeholder="Type here what else would you like us to know"
        onChange={(e) => setComments(e.target.value)}
      />
    </>
  );

  return (
    <ReorderTile
      icon={<SomethingBoxIcon />}
      title="Something Specific Box"
      buttonTitle="Book a chat"
      idleContent={idleContent}
      successContent={<p>Thank you. We&apos;ll be in touch shortly.</p>}
      errorContent={
        <p>
          Oops! We were unable to book a chat for you. Please try again a bit later or contact your
          stylist on the phone if problem persists.
        </p>
      }
      status={status}
      mutate={mutateWithComments}
    />
  );
};

import React, { FC, useMemo } from 'react';
import { StepComponentProps } from '../../StepComponentProps';
import { useAddressList, useCreateAddress } from 'serverCache/address';
import { DeliveryAddressStepRouter } from './DeliveryAddressStepRouter';
import { Loading } from '../../../../components/Loading';
import { useSetHeader } from 'pages/RegistrationFlow/hooks/useSetHeader';

import styles from './DeliveryAddressStep.module.scss';
import { useCustomerProfile } from 'serverCache/customerProfile';

export const deliveryAddressStepTitle = 'Please enter your shipping address below';
export const subTitle = 'So that we know where to send your box';

export const DeliveryAddressStep: FC<StepComponentProps> = ({
  orderDraft,
  stepsCount,
  currentStepIndex,
  onChange,
  onStepBack,
  onStepForward,
}: StepComponentProps) => {
  useSetHeader({ title: deliveryAddressStepTitle, subTitle: subTitle });

  const { data: addresses, isLoading: isAddressListLoading, isSuccess } = useAddressList();
  const defaultAddress = useMemo(() => {
    let defaultAddress = addresses?.find((address) => address.isDefault);
    if (!defaultAddress && addresses?.length) {
      defaultAddress = addresses[0];
    }

    return defaultAddress;
  }, [addresses]);

  const { data: customerProfile, isLoading: isCustomerProfileLoading } = useCustomerProfile();
  const customerName = customerProfile
    ? `${customerProfile.firstName} ${customerProfile.lastName}`
    : '';

  const { mutateAsync: createAddress, isLoading: isCreateAddressLoading } = useCreateAddress();

  return (
    <>
      {(isAddressListLoading || isCustomerProfileLoading || isCreateAddressLoading) && (
        <div className={styles.loadingWrapper}>
          <Loading isBlack />
        </div>
      )}
      {isSuccess && (
        <DeliveryAddressStepRouter
          defaultAddress={orderDraft.address || defaultAddress}
          addressList={
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            addresses ?? []
          }
          customerName={customerName}
          stepsCount={stepsCount}
          currentStepIndex={currentStepIndex}
          onChange={(address) => onChange({ address })}
          onCreate={createAddress}
          onStepBack={onStepBack}
          onStepForward={onStepForward}
        />
      )}
    </>
  );
};

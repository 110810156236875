import { FC, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useAnalytics } from './AnalyticsContext';

/* istanbul ignore file */

export const PageViewListener: FC = () => {
  const { pathname } = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    analytics.gtag('config', analytics.gaAccount, {
      page_path: pathname,
    });

    analytics.ymHit();
  }, [pathname, analytics]);

  return null;
};

import { useMutation, UseMutationResult } from 'react-query';
import { useService } from '../../../../providers/useService';

/* istanbul ignore file */

export function useCreateMysteryOrder(): UseMutationResult<void, unknown, string> {
  const { apiService } = useService();
  return useMutation(async (stylingProfileid: string) => {
    await apiService.createMysteryOrder(stylingProfileid);
  });
}

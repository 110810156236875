import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import { AuthStore } from '../stores/AuthStore';
import { CreateAuth0Lock } from '../services/CreateAuth0Lock';

export const AuthContext = createContext({} as AuthStore);

export const AuthProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const store = useMemo(() => {
    return new AuthStore(CreateAuth0Lock());
  }, []);
  return (
    /* istanbul ignore next */
    <AuthContext.Provider value={store}>{children}</AuthContext.Provider>
  );
};

export const useAuth = (): AuthStore => {
  return useContext(AuthContext);
};

import { FieldValues, UnpackNestedValue, UseFormWatch } from 'react-hook-form';
import { useEffect, useRef } from 'react';

export function useSubscribeOnFormChanges<T extends FieldValues>(
  watch: UseFormWatch<T>,
  callback: (values: UnpackNestedValue<T>) => void,
): void {
  const callbackRef = useRef(callback);
  if (callback !== callbackRef.current) {
    callbackRef.current = callback;
  }

  useEffect(() => {
    const subs = watch((values) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      callbackRef.current(values);
    });

    return () => subs.unsubscribe();
  }, [watch]);
}

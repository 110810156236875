import { FC, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Loading } from 'components/Loading';
import { PaymentMethod, PaymentResultStatus, PurchaseTotals, SubmittedOrder } from 'dataTypes';
import { Payment } from '../payment';
import { OrderPaymentFailure } from '../OrderPaymentFailure';

import styles from './OrderPay.module.scss';
import { useStore } from 'providers/useStore';
import { useProcessZipPaymentAfterRedirect } from './useProcessZipPaymentAfterRedirect';

interface Props {
  order: SubmittedOrder;
  totals: PurchaseTotals;
  purchasedItemIds?: string[];
}

enum OrderPayMode {
  Loading = 'Loading',
  Processing = 'Processing',
  ReadyToPay = 'ReadyToPay',
}

export const OrderPay: FC<Props> = observer(({ order, totals, purchasedItemIds }) => {
  const { paymentStore } = useStore();

  useEffect(() => {
    paymentStore.loadLastPaymentStatus(order.id);
    return () => {
      paymentStore.reset();
    };
  }, [order.id, paymentStore]);

  useProcessZipPaymentAfterRedirect(order.id);

  const mode = useMemo(() => {
    if (!paymentStore.lastPaymentStatusLoaded) {
      return OrderPayMode.Loading;
    }

    if (
      paymentStore.paymentResult?.status === PaymentResultStatus.Completed ||
      paymentStore.paymentResult?.status === PaymentResultStatus.Processing
    ) {
      return OrderPayMode.Processing;
    }

    return OrderPayMode.ReadyToPay;
  }, [paymentStore.paymentResult, paymentStore.lastPaymentStatusLoaded]);

  const handlePay = (type: string, paymentMethod?: PaymentMethod): void => {
    if (type === 'zip') {
      void paymentStore.createZipCheckout(order.id, totals.sellPrice, purchasedItemIds);
      return;
    }

    paymentMethod && paymentStore.pay(order.id, paymentMethod, totals.sellPrice, purchasedItemIds);
  };

  return (
    <>
      {paymentStore.paymentResult?.status === PaymentResultStatus.Failed && (
        <div className={styles.wrapper}>
          <div className={styles.flexBox}>
            <OrderPaymentFailure errorMessage={paymentStore.paymentResult.errorMessage} />
          </div>
        </div>
      )}
      {mode === OrderPayMode.ReadyToPay && (
        <Payment orderId={order.id} totals={totals} onPay={handlePay} />
      )}
      {(mode === OrderPayMode.Loading || mode === OrderPayMode.Processing) && (
        <div className={styles.loadingWrapper}>
          <Loading isBlack />
        </div>
      )}
    </>
  );
});

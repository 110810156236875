import { useAsync, UseAsyncResult } from 'hooks/useAsync';
import { OrderReturn } from 'dataTypes';
import { useService } from 'providers/useService';

export function useGetOrderReturn(orderId: string): UseAsyncResult<OrderReturn> {
  const { apiService } = useService();

  return useAsync(async () => {
    const orderReturn = await apiService.getOrderReturn(orderId);
    if (!orderReturn.link) {
      orderReturn.link = await apiService.createOrderReturnLink(orderId);
    }
    return orderReturn;
  }, [orderId]);
}

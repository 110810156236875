import { FC } from 'react';
import classNames from 'classnames';
import styles from './SelectableImage.module.scss';

export interface SelectableImageProps {
  imageUrl: string;
  imageUrlHover: string;
  imageBadge?: string;
  title: string;
  isSelected: boolean;
  buttonClassName?: string;

  onSelect(title: string, isSelected: boolean): void;
}

export const SelectableImage: FC<SelectableImageProps> = (props: SelectableImageProps) => {
  const { imageUrl, imageUrlHover, imageBadge, title, isSelected, buttonClassName, onSelect } =
    props;

  return (
    <div className={buttonClassName}>
      <input
        id={title}
        type="checkbox"
        checked={isSelected || false}
        className={styles.checkboxInput}
        onChange={() => onSelect(title, !isSelected)}
      />
      <label htmlFor={title} className={styles.label} data-testid="label">
        <div className={styles.imageContainer}>
          <img className={styles.image} src={imageUrl} alt={title} draggable={false} />
          <img src={imageUrlHover} alt={title} className={styles.imageHover} />
          <div
            className={classNames(styles.imageOverlay, {
              [styles.imageOverlayActive]: isSelected,
            })}
          >
            <img src={imageBadge} alt="" className={styles.badge} />
          </div>
        </div>

        <div className={classNames(styles.title, { [styles.titleActive]: isSelected })}>
          {title}
        </div>
      </label>
    </div>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useContext } from 'react';
import { SavedAddress } from '../../../../dataTypes';
import { AddressForm } from 'components/forms/AddressForm';
import { Button } from 'components/Button';
import { AddressPane } from 'components/AddressPane';
import { IconButton } from 'components/IconButton';
import { Pencil } from 'components/Icons/Pencil';
import StepFooter from 'pages/RegistrationFlow/components/StepFooter';

import styles from './DeliveryAddressStepRouter.module.scss';
import StylistMessage from 'components/StylistMessage/StylistMessage';
import RegistrationFlowContext from 'providers/RegistrationFlowContext';

type Mode = 'show' | 'select' | 'new';

interface Props {
  defaultAddress?: SavedAddress;
  addressList: SavedAddress[];
  customerName?: string;
  stepsCount: number;
  currentStepIndex: number;
  imageUrl?: string;
  name?: string;
  onChange(address?: Partial<SavedAddress>): void;
  onCreate(address: SavedAddress): Promise<SavedAddress>;
  onStepBack(): void;
  onStepForward(): void;
}

export const DeliveryAddressStepRouter: FC<Props> = (props) => {
  const {
    defaultAddress,
    addressList,
    customerName,
    stepsCount,
    currentStepIndex,
    imageUrl,
    name,
    onChange,
    onCreate,
    onStepBack,
    onStepForward,
  } = props;

  const customerHasAddress = !!defaultAddress;

  const [mode, setMode] = useState<Mode>(() => {
    return customerHasAddress ? 'show' : 'new';
  });
  const [address, setAddress] = useState<Partial<SavedAddress> | undefined>(defaultAddress);
  const [isNextEnabled, setIsNextEnabled] = useState(customerHasAddress);

  const switchMode = (mode: Mode, isNextEnabled: boolean): void => {
    setMode(mode);
    setIsNextEnabled(isNextEnabled);
  };

  const switchToShowMode = (): void => switchMode('show', true);
  const switchToSelectMode = (): void => switchMode('select', false);
  const switchToNewMode = (): void => switchMode('new', false);

  const { header } = useContext(RegistrationFlowContext).values;
  const showProgressBar = currentStepIndex + 1 <= stepsCount;
  const showStylistMessage = showProgressBar && !header.isHidden;

  const onBack = (): void => {
    if (mode === 'new') {
      setAddress(defaultAddress);
      customerHasAddress ? switchToSelectMode() : onStepBack();
    }

    if (mode === 'show') {
      onStepBack();
    }

    if (mode === 'select') {
      switchToShowMode();
    }
  };

  const onNext = async (): Promise<void> => {
    if (mode === 'new') {
      const createdAddress = await onCreate(address as SavedAddress);
      onChange(createdAddress);
      onStepForward();
    }

    if (mode === 'show') {
      onChange(address);
      onStepForward();
    }
  };

  return (
    <>
      <StepFooter
        onBack={onBack}
        onNext={onNext}
        disableNext={!isNextEnabled}
        stepsCount={stepsCount}
        currentStepIndex={currentStepIndex}
      />

      {showStylistMessage && (
        <StylistMessage
          imageUrl={imageUrl}
          name={name || 'Lorna'}
          title={header.title || ''}
          message={header.subTitle || ''}
        />
      )}

      {mode === 'show' && address && (
        <div className={styles.wrapper}>
          <AddressPane
            address={address as SavedAddress}
            rightActions={
              <IconButton onClick={switchToSelectMode} data-testid="change">
                <Pencil />
              </IconButton>
            }
          />
          <div className={styles.deliveryTimeText}>Estimated delivery time 2-4 business days</div>
        </div>
      )}
      {mode === 'select' && (
        <>
          <div className={styles.addNewButtonContainer}>
            <Button onClick={switchToNewMode}>Add new</Button>
          </div>
          <div className={styles.items}>
            {addressList.map((addressElement) => (
              <AddressPane
                key={addressElement.id}
                address={addressElement}
                actions={
                  <Button
                    color="white"
                    onClick={() => {
                      setAddress(addressElement);
                      switchToShowMode();
                    }}
                  >
                    Select
                  </Button>
                }
              />
            ))}
          </div>
        </>
      )}
      {mode === 'new' && (
        <div className={styles.wrapper}>
          <AddressForm
            defaultValues={{ name: customerName }}
            onChange={(formState) => setAddress({ isDefault: false, ...formState })}
            onValidationStateChanged={setIsNextEnabled}
          />
        </div>
      )}
      {/* <StepFooter onBack={onBack} onNext={onNext} disableNext={!isNextEnabled} /> */}
    </>
  );
};

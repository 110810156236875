import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Switch } from 'react-router-dom';

import OrderList from './components/OrderList';
import { EmptyComponent } from './components/EmptyComponent';
import { OrderLoading } from './components/OrderLoading';
import { useStore } from '../../providers/useStore';
import { ORDER_LIST_URL, ORDER_VIEW_URL } from 'routePaths';
import OrderView from './components/OrderView';

export interface OrdersUrlParams {
  orderId: string;
}

export const Orders: FC = observer(() => {
  const { orderListStore } = useStore();

  useEffect(() => {
    orderListStore.startLoadingOrderList();
  }, [orderListStore]);

  if (!orderListStore.listHasLoadedOnce) {
    return <OrderLoading />;
  }

  if (orderListStore.isOrderListEmpty) {
    return <EmptyComponent />;
  }

  return (
    <Switch>
      <Route exact path={ORDER_LIST_URL} component={OrderList} />
      <Route path={ORDER_VIEW_URL} component={OrderView} />
    </Switch>
  );
});

import { FC } from 'react';

export const SomethingBoxIcon: FC = () => {
  return (
    <svg width="77" height="51" viewBox="0 0 77 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8">
        <path
          d="M50.25 14C50.25 6.95837 55.9584 1.25 63 1.25C70.0416 1.25 75.75 6.95837 75.75 14C75.75 21.0416 70.0416 26.75 63 26.75H50.25V14Z"
          stroke="#0D0D0D"
          strokeWidth="2.5"
        />
        <path
          d="M39.8791 17.167C39.8519 17.13 39.8295 17.0898 39.8124 17.0473C39.7339 16.9132 39.6349 16.7921 39.5191 16.6882C39.3764 16.5516 39.209 16.4433 39.0258 16.369L20.36 11.0494C20.1245 10.9835 19.8755 10.9835 19.64 11.0494L0.9742 16.369C0.822678 16.4136 0.678822 16.4809 0.547553 16.5685C0.431764 16.6724 0.332816 16.7935 0.254233 16.9276C0.258636 16.9674 0.258636 17.0075 0.254233 17.0473C0.118713 17.2412 0.031838 17.4646 0.000910888 17.6989V44.2972C-0.0101535 44.5957 0.0798448 44.8892 0.256406 45.1304C0.432967 45.3717 0.685811 45.5466 0.9742 45.6271L19.64 50.9467C19.8747 51.0178 20.1253 51.0178 20.36 50.9467L39.0258 45.6271C39.3142 45.5466 39.567 45.3717 39.7436 45.1304C39.9202 44.8892 40.0102 44.5957 39.9991 44.2972V17.6989C39.9926 17.5157 39.9519 17.3353 39.8791 17.167ZM20 13.7092L33.8127 17.6989L20 21.6887L6.1873 17.6989L20 13.7092ZM2.66746 19.4677L5.334 20.2258V29.6681C5.32513 29.9719 5.42084 30.2696 5.60521 30.5116C5.78957 30.7536 6.05147 30.9252 6.34729 30.9981L11.6804 32.328C11.7867 32.3408 11.8941 32.3408 12.0004 32.328C12.354 32.328 12.6931 32.1879 12.9431 31.9385C13.1932 31.689 13.3336 31.3508 13.3336 30.9981V22.4999L18.6667 24.016V47.848L2.66746 43.2997V19.4677ZM10.6671 21.6887V29.2426L8.00055 28.5776V20.9838L10.6671 21.6887ZM37.3325 43.2997L21.3333 47.848V24.016L37.3325 19.4677V43.2997Z"
          fill="black"
        />
        <path
          d="M57 12H69"
          stroke="black"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57 17H64"
          stroke="black"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

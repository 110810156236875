import ChevronLeftIcon from 'components/Icons/ChevronLeftIcon/ChevronLeftIcon';
import { SubmittedOrder } from 'dataTypes';
import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { ORDER_LIST_URL } from 'routePaths';
import styles from './Layout.module.scss';

/* istanbul ignore file */

type Props = PropsWithChildren<{
  order: SubmittedOrder;
}>;

export const Layout: FC<Props> = ({ order, children }: Props) => {
  return (
    <div className={styles.orderView}>
      <div className={styles.header}>
        <Link to={ORDER_LIST_URL} className={styles.back}>
          <ChevronLeftIcon />
          <span className={styles.backText}>Back to orders</span>
        </Link>

        <div className={styles.title}>Order number {order.id}</div>
      </div>
      {children}
    </div>
  );
};

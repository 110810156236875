/* eslint-disable @typescript-eslint/no-misused-promises */
import { FC, useMemo } from 'react';
import { shortSiteTitle } from '../../constants';
import { usePath } from './hooks/usePath';
import { useGo } from './hooks/useGo';
import { useGoWithSubmit } from './hooks/useGoWithSubmit';
import { useGoWithSave } from './hooks/useGoWithSave';
import { useStore } from 'providers/useStore';
import { Helmet } from 'react-helmet-async';
import { withAuthenticationRequired } from 'auth/withAuthenticationRequired';
import registrationFlowSteps, { stepsCount } from './registrationFlowSteps';

interface Props {
  currentStep: string;
  currentStepIndex: number;
}

export const RegistrationFlowStepSwitch: FC<Props> = ({ currentStep, currentStepIndex }) => {
  const { registrationFlowStore } = useStore();

  const { Component, title, stepBackUrl, additionalAction, authenticationRequired } =
    registrationFlowSteps[currentStepIndex];

  const pathFn = usePath();

  const prevStepPath =
    currentStepIndex === 0
      ? '/'
      : pathFn(stepBackUrl || registrationFlowSteps[currentStepIndex - 1].path);
  const onStepBack = useGo(prevStepPath);

  const nextStepPath =
    currentStepIndex >= stepsCount ? '/' : pathFn(registrationFlowSteps[currentStepIndex + 1].path);
  const onStepForward = useGo(nextStepPath);
  const onStepForwardWithSave = useGoWithSave(nextStepPath);
  const onStepForwardWithSubmit = useGoWithSubmit(nextStepPath);

  let stepForwardHandler = onStepForward;
  if (additionalAction === 'save') {
    stepForwardHandler = onStepForwardWithSave;
  } else if (additionalAction === 'submit') {
    stepForwardHandler = onStepForwardWithSubmit;
  }

  return useMemo(
    () => {
      const ComponentWithPageTitle = (
        <>
          <Helmet>
            <title>
              {shortSiteTitle} - {title}
            </title>
          </Helmet>
          <Component
            orderDraft={registrationFlowStore.order}
            onChange={registrationFlowStore.patch}
            onStepBack={onStepBack}
            onStepForward={stepForwardHandler}
            stepsCount={stepsCount}
            currentStepIndex={currentStepIndex}
          />
        </>
      );

      if (!authenticationRequired) {
        return ComponentWithPageTitle;
      }

      const ProtectedComponent = withAuthenticationRequired(() => ComponentWithPageTitle);
      return <ProtectedComponent />;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStep],
  );
};

import React, { FC, useCallback, useEffect } from 'react';
import styles from './HeightInput.module.scss';
import classNames from 'classnames';
import {
  heightAsImperial,
  heightAsMetric,
  heightToString,
  imperialHeightToString,
  stringToHeight,
} from './conversionFunctions';
import { Label } from '../Label';
import { Input } from '../Input';
import { SystemOfMeasure } from 'dataTypes';

interface AdditionalProps {
  systemOfMeasure: SystemOfMeasure;
  label: string;
  name?: string;
  value?: string;
  errorMessage?: string;
  onChange?(value: string): void;
  onBlur?: JSX.IntrinsicElements['input']['onBlur'];
}

type Props = AdditionalProps;

export const HeightInput: FC<Props> = (props) => {
  const { systemOfMeasure, label, errorMessage, name, value, onChange, onBlur } = props;

  const height = stringToHeight(value);
  const metricHeight = heightAsMetric(height);
  const imperialHeight = heightAsImperial(height);

  useEffect(() => {
    if (height && systemOfMeasure !== height.systemOfMeasure) {
      onChange?.(heightToString(height, systemOfMeasure));
    }
  }, [height, systemOfMeasure, onChange]);

  const handleChangeCm = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const cmValue = e.target.value;
      if (cmValue.length > 3) {
        return;
      }

      onChange?.(cmValue);
    },
    [onChange],
  );

  const handleChangeFeet = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const feet = e.target.value;
      if (feet.length > 1) {
        return;
      }

      onChange?.(imperialHeightToString(feet, imperialHeight?.inches ?? ''));
    },
    [imperialHeight, onChange],
  );

  const handleChangeInch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const inches = e.target.value;
      if (inches.length > 4) {
        return;
      }

      onChange?.(imperialHeightToString(imperialHeight?.feet ?? '', inches));
    },
    [imperialHeight, onChange],
  );

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <Label className={styles.label}>{label}</Label>
        <div className={styles.inputBlock}>
          {systemOfMeasure === 'Metric' && (
            <Input
              type="number"
              name={name}
              placeholder="cm"
              data-testid="cm-input"
              error={!!errorMessage}
              className={classNames(styles.input)}
              value={metricHeight?.cm ?? ''}
              onChange={handleChangeCm}
              onBlur={onBlur}
            />
          )}
          {systemOfMeasure === 'Imperial' && (
            <>
              <Input
                type="number"
                name={name}
                placeholder="ft"
                data-testid="ft-input"
                error={!!errorMessage}
                className={classNames(styles.input)}
                value={imperialHeight?.feet ?? ''}
                onChange={handleChangeFeet}
                onBlur={onBlur}
              />
              <Input
                type="number"
                name={name}
                placeholder="in"
                data-testid="in-input"
                step={0.5}
                error={!!errorMessage}
                className={classNames(styles.input)}
                value={imperialHeight?.inches ?? ''}
                onChange={handleChangeInch}
                onBlur={onBlur}
              />
            </>
          )}
        </div>
      </div>
      {errorMessage && (
        <div data-testid="error-message">
          <div className={classNames(styles.inputBlock, styles.errorMessageContainer)}>
            {errorMessage}
          </div>
        </div>
      )}
    </div>
  );
};

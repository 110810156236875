import styles from './CardDetails.module.scss';
import classNames from 'classnames';

export interface CardDetialsProps {
  image?: JSX.Element;
  title: string;
  info: string | JSX.Element;
  alignStart?: boolean;
}

export const CardDetials: (props: CardDetialsProps) => JSX.Element = (props: CardDetialsProps) => {
  const { image, title, info, alignStart } = props;
  return (
    <div
      className={classNames({
        [styles.card]: true,
        [styles.flexStart]: alignStart,
      })}
    >
      <div className={styles.title}>{title}</div>
      <div className={styles.infoWrapper}>
        <div className={styles.image}>{image}</div>
        <div className={styles.info}>{info}</div>
      </div>
    </div>
  );
};

import { ComponentProps, FC } from 'react';
import { CommunicationMethod } from '../../dataTypes';
import { RadioInput } from '../RadioInput';

export const communicationMethods = [
  {
    label: 'Phone call',
    value: 'call',
  },
  {
    label: 'WhatsApp (text)',
    value: 'whatsapp',
  },
  {
    label: 'E-Mail',
    value: 'email',
  },
];

type ProxyProps = Omit<ComponentProps<typeof RadioInput>, 'value' | 'items' | 'outlined'>;

interface AdditionalProps {
  value: CommunicationMethod;
  className?: string;
}

type Props = ProxyProps & AdditionalProps;

export const CommunicationMethodSwitch: FC<Props> = (props) => {
  return (
    <RadioInput {...props} outlined items={communicationMethods} className={props.className} />
  );
};

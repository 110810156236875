import { FC } from 'react';

export const Email: FC = () => {
  return (
    <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 1H3C1.89543 1 1 1.89543 1 3V15C1 16.1046 1.89543 17 3 17H22C23.1046 17 24 16.1046 24 15V3C24 1.89543 23.1046 1 22 1Z"
        stroke="#2c292c"
        strokeWidth="1.9"
      />
      <path d="M2 2L12.5 9.5L23 2" stroke="#2c292c" strokeWidth="1.9" />
    </svg>
  );
};

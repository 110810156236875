import { OrderItem, PurchaseTotals, SubmittedOrder } from 'dataTypes';
import ChooseDiscountSlider from 'pages/Orders/components/ChooseDiscountSlider';
import { ItemListSelector } from 'pages/Orders/components/ItemListSelector';
import { FC, useState } from 'react';
import { useAvailableCredits } from 'serverCache/credits';
import {
  arrayHasItems,
  calculateAmountSaved,
  calculateCredit,
  calculateItemsPrice,
  calculatePriceAfterDiscount,
} from 'utils';
import { OrderPay } from '../../../../OrderPay';
import Total from '../../../../Total';

interface Props {
  order: SubmittedOrder;
}

interface Totals extends PurchaseTotals {
  fullPrice: number;
}

const calculateTotals = (items: OrderItem[], discount: number): Totals => {
  const fullPrice = calculateItemsPrice(items);
  const sellPrice = calculatePriceAfterDiscount(fullPrice, discount);
  const savings = calculateAmountSaved(fullPrice, discount);

  return {
    fullPrice,
    discount,
    sellPrice,
    savings,
    numberOfPurchasedItems: items.length,
  };
};

const OfferSlider: FC<Props> = ({ order }) => {
  const minDiscount = order.minDiscount ? order.minDiscount * 100 : 0;
  const maxDiscount = order.maxDiscount ? order.maxDiscount * 100 : 100;
  const defaultDiscount = (maxDiscount * 0.72) / 100;

  const [purchasedItems, setPurchasedItems] = useState(order.items);
  const [totals, setTotals] = useState(calculateTotals(order.items, defaultDiscount));
  const purchasedItemsIds = purchasedItems.map((i) => i.id);

  const { data: credits } = useAvailableCredits(order.id);

  const creditAmount = calculateCredit(credits);

  const onItemsChanged = (items: OrderItem[]): void => {
    setPurchasedItems(items);
    setTotals(calculateTotals(items, totals.discount));
  };

  const onSelectDiscount = (discount: number): void => {
    setTotals(calculateTotals(purchasedItems, discount));
  };

  const discountPrice = totals.fullPrice - totals.sellPrice;

  const discountPercent = Math.floor(totals.discount * 100);

  return (
    <>
      {arrayHasItems(order.items) && (
        <ItemListSelector title="My items" items={order.items} onItemsChanged={onItemsChanged} />
      )}
      <ChooseDiscountSlider
        minDiscount={minDiscount}
        maxDiscount={maxDiscount}
        discountPercent={discountPercent}
        onSelectDiscount={onSelectDiscount}
      />
      <Total
        discountPercent={discountPercent}
        fullPrice={totals.fullPrice}
        discountPrice={discountPrice}
        sellPrice={totals.sellPrice - creditAmount}
        deposit={creditAmount}
      />
      <OrderPay order={order} totals={totals} purchasedItemIds={purchasedItemsIds} />
    </>
  );
};

export default OfferSlider;

import { FC } from 'react';
import styles from './TwoColumnLayout.module.scss';

interface Props {
  firstColumn: JSX.Element;
  secondColumn: JSX.Element;
}

const TwoColumnLayout: FC<Props> = (props: Props) => {
  return (
    <div className={styles.row}>
      <div className={styles.firstColumn}>{props.firstColumn}</div>
      <div className={styles.secondColumn}>{props.secondColumn}</div>
    </div>
  );
};

export default TwoColumnLayout;

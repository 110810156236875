import { Dayjs } from 'dayjs';

export enum StylingFor {
  Men = 'Men',
  Kids = 'Kids',
}

export declare type Gender = 'Male' | 'Boy' | 'Girl';

export interface Page<T> {
  items: T[];
  total: number;
}

export interface UrlParams {
  stylingFor: StylingFor;
  stylingStep: string;
}

export enum QuestionType {
  Select = 'Select',
  NumberInput = 'Number Input',
  HeightInput = 'Height Input',
}

export interface Question {
  type: QuestionType;
  title: string;
  placeholder?: string;
  answerValue?: string;
  answerOptions: AnswerOption[];
}

export interface AnswerOption {
  title: string;
  isSelected: boolean;
}

export interface ImageAnswerOption extends AnswerOption {
  text?: string;
  imageUrl: string;
  imageUrlHover: string;
  imageBadge?: string;
}

export interface TextAnswerOption extends AnswerOption {
  placeholder: string;
  value: string;
}

export interface QuestionnaireItem {
  question: string;
  answers: string[];
}

export interface StylingProfile {
  id?: string;
  firstName: string;
  lastName: string;
  dateOfBirth?: Date;
  gender: Gender;
  questionnaire: QuestionnaireItem[];
  questionnaireComments?: string;
  systemOfMeasure: SystemOfMeasure;
}

export interface SubmittedStylingProfile {
  name?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth: Date;
  gender: Gender;
  questionnaire: { question: string; answers: string[] }[];
  questionnaireComments?: string;
}

export type CommunicationMethod = 'call' | 'whatsapp' | 'email';

export interface CommunicationDateTime {
  date?: string;
  time?: string;
}

export interface OrderItem {
  id: string;
  sku: string;
  division: string;
  category: string;
  websiteCategory?: string;
  brand: string;
  itemName: string;
  colour: string;
  fabricComposition: string;
  size: string;
  quantity: number;
  choixcePrice: number;
}

export interface OrderDraft {
  id?: string;
  stylingFor: StylingFor;
  intention: string;
  clothingCategories: string[];
  stylingProfile: StylingProfile;
  address?: SavedAddress;
  status?: OrderStatus;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  communicationMethod: CommunicationMethod;
  communicationDateTime: CommunicationDateTime;
  customerComments?: string;
}

export type SystemOfMeasure = 'Imperial' | 'Metric';

export interface SavedOrderDraft extends OrderDraft {
  id: string;
}

export interface PurchaseInfo {
  purchasedItems: OrderItem[];
  returnedItems: OrderItem[];
  totals: PurchaseTotals;
}

export interface PurchaseTotals {
  numberOfPurchasedItems: number;
  sellPrice: number;
  discount: number;
  savings: number;
}

export interface SubmittedOrder {
  id: string;
  stylingFor: StylingFor;
  intention: string;
  clothingCategories: string[];
  stylingProfile: SubmittedStylingProfile;
  address: Address;
  items: OrderItem[];
  status: OrderStatus;
  communicationMethod: CommunicationMethod;
  communicationDateTime?: CommunicationDateTime;
  purchase?: PurchaseInfo;
  createdDate: Dayjs;
  minDiscount: number;
  maxDiscount: number;
}

export enum OrderStatus {
  Submitted = 'Submitted',
  ReadyForPicking = 'Picking & Packing',
  Dispatched = 'Dispatched',
  Delivered = 'Delivered',
  DeliveryOverdue = 'Delivery Overdue',
  PendingPayment = 'Pending Payment',
  PaymentOverdue = 'Payment Overdue',
  PendingReturn = 'Pending Return',
  ReturnOverdue = 'Return Overdue',
  Cancelled = 'Cancelled',
  FullyReturned = 'Fully Returned',
  Completed = 'Completed',
}

export interface OrderComments {
  comments: string;
}

export interface CustomerProfile {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  communicationMethod: CommunicationMethod;
}

export interface PatchCustomerProfilePayload {
  firstName?: string;
  lastName?: string;
  phone?: string;
  communicationMethod?: CommunicationMethod;
}

export interface Address {
  name: string;
  phone: string;
  house?: string;
  street?: string;
  city?: string;
  county?: string;
  postcode?: string;
}

export interface SavedAddress {
  id: string;
  isDefault: boolean;
  name: string;
  phone: string;
  house: string;
  street: string;
  city: string;
  county?: string;
  postcode: string;
}

export type CreateAddressPayload = Partial<Omit<SavedAddress, 'id'>>;

export interface UserProfile {
  email: string;
}

export interface Card {
  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
}

export interface PaymentMethod {
  id: string;
  isDefault: boolean;
  card: Card;
}

export enum PaymentResultStatus {
  Processing = 'Processing',
  Completed = 'Completed',
  Failed = 'Failed',
}

export type PaymentResult =
  | { status: PaymentResultStatus.Processing }
  | { status: PaymentResultStatus.Completed }
  | { status: PaymentResultStatus.Failed; errorMessage: string };

export interface ZipLinkResult {
  checkoutId: string;
  redirectUrl: string;
}

export interface ShipmentInfoModel {
  trackingLink: string;
}

export interface OrderReturnLink {
  link: string;
}

export enum ReturnStatus {
  Requested = 'Requested',
  Delivered = 'Delivered',
}

export enum ReturnType {
  Partial = 'Partial',
  Full = 'Full',
}

export interface ReturnItem {
  id: string;
  sku: string;
  division: string;
  category: string;
  websiteCategory?: string;
  brand: string;
  itemName: string;
  colour: string;
  fabricComposition: string;
  size: string;
  quantity: number;
  choixcePrice: number;
  returnReason: string;
}

export interface OrderReturn {
  id: string;
  type: ReturnType;
  status: ReturnStatus;
  link: string;
  items: ReturnItem[];
}

export interface BoxSample {
  name: string;
  imageUrl: string;
  originalPrice: number;
  customerPrice: number;
  type: StylingFor;
}

export interface MakeOfferPayload {
  sellPrice: number;
  purchasedItemIds: string[];
}

export interface ReviewLink {
  link: string;
}

export interface ReviewList {
  averageRating: number;
  reviews: Review[];
}

export interface Review {
  comment: string;
  rating: number;
}

// prettier-ignore
// eslint-disable-next-line @typescript-eslint/ban-types
export type DeepPartial<T> = T extends Function ? T : T extends object
  ? T extends unknown[]
    ? DeepPartial<T[number]>[]
    : { [P in keyof T]?: DeepPartial<T[P]> }
  : T;

export interface Credit {
  amount: number;
}

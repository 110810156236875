import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

export const useAnchor = /* istanbul ignore next */ (): void => {
  const { hash } = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        history.replace({
          hash: undefined,
        });
        setTimeout(() => {
          element.scrollIntoView(true);
        });
      }
    }
  }, [hash, history]);
};

import { createContext, FC, PropsWithChildren } from 'react';
import { useCreateContext } from './useCreateContext';
import { AppContext as Context } from './AppContext';

export const AppContext = createContext(null as unknown as Context);

export const AppContextProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const context = useCreateContext();
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

import { BoxSample, StylingFor } from 'dataTypes';

const MenSamples: BoxSample[] = [
  {
    name: 'Sarah',
    imageUrl: '/images/boxSamples/men/men-sample-1.webp',
    originalPrice: 1320,
    customerPrice: 500,
    type: StylingFor.Men,
  },
  {
    name: 'Maria',
    imageUrl: '/images/boxSamples/men/men-sample-2.webp',
    originalPrice: 250,
    customerPrice: 120,
    type: StylingFor.Men,
  },
  {
    name: 'David',
    imageUrl: '/images/boxSamples/men/men-sample-3.webp',
    originalPrice: 360,
    customerPrice: 160,
    type: StylingFor.Men,
  },
  {
    name: 'Paul',
    imageUrl: '/images/boxSamples/men/men-sample-4.webp',
    originalPrice: 665,
    customerPrice: 300,
    type: StylingFor.Men,
  },
  {
    name: 'James',
    imageUrl: '/images/boxSamples/men/men-sample-5.webp',
    originalPrice: 145,
    customerPrice: 80,
    type: StylingFor.Men,
  },
  {
    name: 'Joseph',
    imageUrl: '/images/boxSamples/men/men-sample-6.webp',
    originalPrice: 1065,
    customerPrice: 400,
    type: StylingFor.Men,
  },
  {
    name: 'Max',
    imageUrl: '/images/boxSamples/men/men-sample-7.webp',
    originalPrice: 300,
    customerPrice: 120,
    type: StylingFor.Men,
  },
  {
    name: 'Toby',
    imageUrl: '/images/boxSamples/men/men-sample-8.webp',
    originalPrice: 1090,
    customerPrice: 450,
    type: StylingFor.Men,
  },
  {
    name: 'Sophie',
    imageUrl: '/images/boxSamples/men/men-sample-9.webp',
    originalPrice: 465,
    customerPrice: 280,
    type: StylingFor.Men,
  },
  {
    name: 'Jacob',
    imageUrl: '/images/boxSamples/men/men-sample-10.webp',
    originalPrice: 775,
    customerPrice: 230,
    type: StylingFor.Men,
  },
  {
    name: 'Christian',
    imageUrl: '/images/boxSamples/men/men-sample-11.webp',
    originalPrice: 1090,
    customerPrice: 330,
    type: StylingFor.Men,
  },
  {
    name: 'Tyler',
    imageUrl: '/images/boxSamples/men/men-sample-12.webp',
    originalPrice: 1345,
    customerPrice: 400,
    type: StylingFor.Men,
  },
  {
    name: 'Bryan',
    imageUrl: '/images/boxSamples/men/men-sample-13.webp',
    originalPrice: 590,
    customerPrice: 180,
    type: StylingFor.Men,
  },
];

export default MenSamples;

import React, { ChangeEvent, FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import MaskedTextInput from 'react-text-mask';
import dayjs, { Dayjs } from 'dayjs';
import styles from './DatePicker.module.scss';
import { Label } from '../Label';
import { Input } from '../Input';
import { getRowGrid } from '../InputGrid/styles';

type ProxyInputProps = Omit<JSX.IntrinsicElements['input'], 'type' | 'value' | 'onChange' | 'ref'>;

interface AdditionalProps {
  label: string;
  hasError?: boolean;
  value?: string | Date;
  onChange?(value: string | Date, e: React.ChangeEvent): void;
}

type Props = ProxyInputProps & AdditionalProps;

export const dateOfBirthFormat = 'DD / MM / YYYY';

const fromValueToString = (value: unknown): string => {
  if (value === undefined) {
    return '';
  }

  let dayjsDate: Dayjs;
  if (typeof value === 'string') {
    dayjsDate = dayjs(value, 'YYYY-DD-MM');
    if (!dayjsDate.isValid()) {
      return value;
    }
  } else {
    dayjsDate = dayjs(value as Date);
  }

  return dayjsDate.utc(true).format(dateOfBirthFormat);
};

export const DatePicker: FC<Props> = (props) => {
  const { label, hasError, value: rawValue, onChange, ...inputProps } = props;

  const value = fromValueToString(rawValue);

  const onRealChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const date = dayjs(value, dateOfBirthFormat).utc(true);
      onChange?.(date.isValid() ? date.toDate() : value, e);
    },
    [onChange],
  );

  const gridStyles = useMemo(getRowGrid, []);

  return (
    <div className={classNames(gridStyles.container, styles.row)}>
      <Label htmlFor={inputProps.id} className={classNames(gridStyles.label, styles.label)}>
        {label}
      </Label>
      <MaskedTextInput
        {...inputProps}
        type="text"
        mask={[/\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, /\d/, /\d/]}
        className={classNames(gridStyles.input, styles.input)}
        onChange={onRealChange}
        value={value}
        render={
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          (ref, props) => <Input {...props} error={hasError} ref={ref} />
        }
      />
    </div>
  );
};

export const PROTECTED_URL = '/my';

export const ORDER_FLOW_START_PATH = `/styling/:stylingFor`;
export const ORDER_FLOW_PATH = `${ORDER_FLOW_START_PATH}/:stylingStep`;
export const ORDER_LIST_URL = `${PROTECTED_URL}/orders`;
export const ORDER_VIEW_URL = `${ORDER_LIST_URL}/:orderId?`;

export const CUSTOMER_DETAILS_URL = `${PROTECTED_URL}/details`;
export const CUSTOMER_DETAILS_EDIT_URL = `${CUSTOMER_DETAILS_URL}/edit`;

export const ADDRESS_LIST_URL = `${PROTECTED_URL}/addresses`;
export const ADDRESS_NEW_URL = `${ADDRESS_LIST_URL}/new`;

export const PAYMENT_METHODS_URL = `${PROTECTED_URL}/payment-methods`;
export const PAYMENT_METHODS_NEW_URL = `${PAYMENT_METHODS_URL}/new`;

export const HELP_URL = `/help`;
export const COMING_SOON_URL = `/comingsoon`;
export const LANDING_REGISTER_HASH = `#register`;
export const PRIVACY_POLICY_URL = `/privacy-policy`;
export const TERMS_AND_CONDITIONS_URL = `/terms-and-conditions`;
export const PROFILE_URL = `${PROTECTED_URL}/profile`;
export const CHANGE_PASSWORD_REDIRECT = `/change-password-redirect`;
export const LOGIN_URL = `/login`;
export const PRIVACY_URL = `/privacy`;
export const ABOUT_URL = `/about`;
export const ZIP_PAY_URL = `/zip-pay`;

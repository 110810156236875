const reviews = [
  {
    comment:
      'My stylist Lorna is always friendly and helpful and picks out pieces exactly to my taste and style. The service is a perfect cross over between online and conventional shopping as you get to try before you buy in the comfort of your own home. It also take the hassle and time out of having to go and find new clothes, Would highly recommend to anyone !',
  },
  {
    comment:
      "I'm absolutely amazed by the service! No charge up front, very quick delivery, and you choose what you want to pay! I was very skeptical at first, but I've just completed my payment without any issues and I have one very happy little girl. The only thing I would add is a function to make an offer on the website, as currently you have to go through your stylist if you can not pay the suggested discount amount.",
  },
  {
    comment:
      'I had a great experience with Choixce. Great brands, great quality and I really did get to pay what I wanted. Highly recommend!',
  },
  {
    comment:
      "Lorna is brilliant and listened to what I needed for my son as he can only have 100% material. The stuff is fantastic that I've had so far, and I will carry on using you for him as it takes all the hassle of me going into the shops looking through the labels to see what material it is. He even likes his stuff, and he's not easy to please.",
  },
  {
    comment:
      'It was really good the stylish my little girls loves her new clothes and would recommend to anyone who has children',
  },
  {
    comment:
      "Was very unsure at first anyway my first box came within 2 days items was not something I would pick out but my daughter looked so beautiful wearing them I'm so amazed the quality and communication was amazing will deff be ordering again and also letting other people know thank you so much",
  },
  {
    comment:
      'So easy , goods delivered to my house tried them on , like nearly all and paid what I wanted to pay - happy shopper',
  },
  {
    comment:
      "Brilliant service never used before definitely use again clothes are gorgeous fit my little girl perfect didn't wait long for delivery either came really quick xx",
  },
  {
    comment:
      'Love the concept of pay what I want. Feel like I’m getting good quality clothes, some brands I love, at unbelievable prices! Really happy with the purchase!',
  },
  {
    comment:
      'We found the one to one personal advice , suggestions and thoughtfulness from the stylist above and beyond . The delivery was extremely fast considering the items are hand selected with thought and consideration',
  },
  {
    comment:
      "The brands inside.. I had some great brands in my recent box which I'm very surprised about - particularly Belstaff! Which has now been added to my wardrobe! Again, can't fault the service.. they really seem to know what they're doing! Thanks again.",
  },
  {
    comment:
      "Top service! I received some great clothes in my parcel, and kept a few. Delivery of my box was very quick and everything was packed super nicely. The returns process was very simple, too. I quite enjoy shopping online and in store but there's something really satisfying about receiving a box full of clothes to try, for free, and then paying what I want for what I keep. Will be recommending to friends and definitely be a returning customer. Thanks again!",
  },
  {
    comment:
      'It was a pleasant surprise to receive such good quality shirts and trousers but what really surprised me was actually what good value it was. I am hoping that shortly you will replicate this with New World Wines :)',
  },
  {
    comment:
      'Easy to use app and a great personal touch and input from my designer. I will definitely be a repeat customer!',
  },
  {
    comment:
      'I was sceptical at first but the entire experience was great from start to finish and I got some great deals for the kids and I!',
  },
  {
    comment:
      'What an awesome way to shop! I ordered 2 boxes to update my boys winter wardrobes and we loved what we received. The stylist listened carefully to the boys likes and dislikes and got sent amazing branded clothes. The boys loved the surprise of opening their boxes and I got to ‘pay what I wanted’ for the clothes…win win. We will definitely be ordering more.',
  },
  {
    comment:
      'This is a great service and gives you a chance to try something new both in style and colour, - I found the quality of the items better than the high street. Returns free and easy. Will definitely be back for all the family.',
  },
  {
    comment:
      'Have to be honest I was a little hesitant to try this, appeared far too good to be true. However I can happily say that this has been a fantastic experience throughout. Lorna got my styling down to a tee and I got some incredible brands (Hackett, Belstaff and Paul Smith). The discount I got was just surreal, so what started out as curiosity has allowed me to find my new go-to for clothes. Have to say I can’t see why I’d shop elsewhere, it was just so easy!',
  },
  {
    comment:
      "Such a great idea - my stylist was extremely helpful and nailed the brief! Can't believe I can pay what I want, so excited to shop with Choixce again.",
  },
  {
    comment:
      'Absolutely brilliant service, Lorna was great. Quick delivery and my partner loved the clothes. The price negotiation was smooth and reasonable. We got a fabulous deal.',
  },
  {
    comment:
      'The concept of pay what you want is great and the brands I was sent were really high end. Will definitely be ordering again and I highly recommend.',
  },
  {
    comment:
      'Awesome quality of clothes with great customer service, will definitely be ordering another box in the future',
  },
  {
    comment:
      'Loved the clothes Stylist got my brief and love the pay what you want aspect. Very happy shopper 👍',
  },
];

export default reviews;

import './index.css';
import 'reflect-metadata';
import * as serviceWorker from './serviceWorker';
import ReactDOM from 'react-dom';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import { AlertTemplate } from './components/AlertTemplate';
import { App } from './App';
import { AuthProvider } from './providers/AuthProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as AlertProvider, positions } from 'react-alert';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AppContextProvider } from './providers/AppContextProvider';
import { StrictMode } from 'react';
import { initLocalGtag, AnalyticsContext, PageViewListener } from './analytics';
import { HistorySetter } from './auth/HistorySetter';
import { getCookieConsentValue } from 'react-cookie-consent';

import * as consentConstants from './consentConstants';

const GA_ACCOUNT = process.env.REACT_APP_GA_ACCOUNT ?? '';
const FB_PIXEL_ID = process.env.REACT_APP_FB_PIXEL_ID ?? '';
const YM_COUNTER_ID = process.env.REACT_APP_YM_COUNTER_ID ?? '';

initLocalGtag(GA_ACCOUNT);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <StrictMode>
    <AnalyticsContext
      gaAccount={GA_ACCOUNT}
      fbPixelId={FB_PIXEL_ID}
      ymCounterId={YM_COUNTER_ID}
      loadScriptOnInit={
        getCookieConsentValue(consentConstants.COOKIE_NAME) === consentConstants.ACCEPTED_VALUE
      }
    >
      <AuthProvider>
        <AppContextProvider>
          <Router>
            <HistorySetter />
            <PageViewListener />
            <QueryClientProvider client={queryClient}>
              <AlertProvider
                template={AlertTemplate}
                position={positions.TOP_CENTER}
                timeout={5000}
              >
                <App />
              </AlertProvider>
            </QueryClientProvider>
          </Router>
        </AppContextProvider>
      </AuthProvider>
    </AnalyticsContext>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

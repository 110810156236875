/* eslint-disable react/no-unescaped-entities */
import AccordionContent from 'components/AccordionContent';
import AccordionItem from 'components/AccordionItem';
import { TitleMain } from 'components/TitleMain';
import { FC } from 'react';
import styles from './ZipPay.module.scss';

const ZipPay: FC = () => {
  return (
    <section className={styles.zipPay}>
      <TitleMain
        title="Zip Pay"
        description="A new way to pay that’s available online and lets you own it now and pay for it later!"
      />

      <AccordionContent>
        <AccordionItem
          title="Am I eligible to pay using Zip?"
          content={
            <>
              <p>To shop using Zip, you need:</p>
              <ul>
                <li>To be over the age of 18</li>
                <li>To have a UK issued debit card</li>
                <li>To be living in the UK</li>
                <li>To have a good credit history</li>
              </ul>
            </>
          }
        />

        <AccordionItem
          title="Will Zip affect my credit score?"
          content={
            <p>
              Zip performs a soft credit check with credit reference agencies to determine if you
              are eligible. This does not impact your credit score.
            </p>
          }
        />

        <AccordionItem
          title="How do I pay with Zip?"
          content={
            <>
              <p>When you get to the payment page, select Zip as a payment method.</p>
              <p>
                If it’s your first time shopping with Zip, you’ll need to enter some personal
                details such as name, date of birth, mobile phone number and home address. Take care
                when entering your personal details as errors may prevent Zip from being able to
                verify your account or send you payment notifications.{' '}
              </p>
              <p>
                Once approved, and you are happy with the instalment schedule, Zip will take the
                first instalment from the UK card you supplied when signing up.
              </p>
              <p>
                Once accepted, your goods will be sent to you as they would with any other payment
                method.
              </p>
            </>
          }
        />

        <AccordionItem
          title="Is my payment information safe?"
          content={
            <p>
              Payment information is processed securely by our payment gateway provider. All
              transactions take place via secured connections that adhere to the latest industry
              standards and security protocols.
            </p>
          }
        />

        <AccordionItem
          title="What happens if I cancel or return my order?"
          content={
            <>
              <p>
                Once the merchant has notified us of the cancellation/return and we’ve accepted it,
                Zip will cancel any future scheduled payments as well as refund any amounts due.
              </p>
              <p>
                As soon as we have registered your cancellation or return, Zip will process your
                refund within 10-15 business days.
              </p>
              <p>Any partial refunds will be applied to outstanding instalments first. </p>
            </>
          }
        />

        <AccordionItem
          title="What happens if I don’t pay for my order?"
          content={
            <>
              <p>
                Zip will automatically attempt to collect payment for your Pay In 4 purchase from
                the card you entered when you signed up to Zip.
              </p>
              <p>
                If you miss a scheduled payment, Zip will give you 2 full days to process the
                payment yourself. On the third day after the payment was missed we will retry this
                payment again, if this retry is unsuccessful we will apply a £6 late fee to the
                instalment. A payment can be made by you at any time by logging into your Zip
                customer portal. Please make sure you have money in your account in advance of your
                payments coming out.
              </p>
              <p>
                Zip will contact you directly by email and SMS in advance every time as a reminder.
              </p>
            </>
          }
        />

        <AccordionItem
          title="I have asked for a refund. How will I be refunded?"
          content={<p>Refunds from Zip will be issued back to the card supplied to Zip.</p>}
        />

        <AccordionItem
          title="Is there really no interest applied and no extra fees when payments are made on time?"
          content={
            <p>
              Nope, Zip are not like some traditional finance companies. As long as you pay your
              scheduled payments on time you will only pay the price advertised.
            </p>
          }
        />

        <AccordionItem
          title="When will my payments be due?"
          content={
            <>
              <p>Payments are automatically scheduled to be taken every 2 weeks.</p>
              <p>
                You’ll pay 25% of your full purchase upfront, then 25% every 2 weeks for the next 6
                weeks.
              </p>
              <p>
                Zip will try to take payment during the day and try again if unsuccessful. If this
                fails, or if you'd like to make a payment earlier, you can log into your Zip
                customer portal and manually make a payment.
              </p>
              <p>
                For more information, visit our Help pages at{' '}
                <a
                  href="https://zip.co/uk"
                  className={styles.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://zip.co/uk
                </a>
              </p>
            </>
          }
        />

        <AccordionItem
          title="I've received a statement from Zip, but I've not yet received my goods."
          content={
            <p>
              Zip is the finance provider and will not have information regarding your order status.
              Please always contact the retailer directly about your purchases.
            </p>
          }
        />
      </AccordionContent>
    </section>
  );
};

export default ZipPay;

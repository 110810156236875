import { FC } from 'react';
import { CardBrands } from '../../utils';
import styles from './CardBrandIcon.module.scss';

import electron from './images/electron.svg';
import maestro from './images/maestro.svg';
import dankort from './images/dankort.svg';
import interpayment from './images/interpayment.svg';
import unionpay from './images/unionpay.svg';
import visa from './images/visa.svg';
import mastercard from './images/mastercard.svg';
import amex from './images/amex.svg';
import diners from './images/diners.svg';
import discover from './images/discover.svg';
import jcb from './images/jcb.svg';
import carteblanche from './images/carteblanche.svg';

const cardImages: Record<CardBrands, string> = {
  electron: electron,
  maestro: maestro,
  dankort: dankort,
  interpayment: interpayment,
  unionpay: unionpay,
  visa: visa,
  mastercard: mastercard,
  amex: amex,
  diners: diners,
  discover: discover,
  jcb: jcb,
  carteblanche: carteblanche,
};

interface Props {
  cardBrand?: CardBrands;
}

export const CardBrandIcon: FC<Props> = ({ cardBrand }: Props) => {
  /* istanbul ignore next */
  if (!cardBrand || !(cardBrand in cardImages)) {
    console.error('Unknown card brand');
    return null;
  }

  return (
    <div className={styles.logo}>
      <img src={cardImages[cardBrand]} alt="" />
    </div>
  );
};

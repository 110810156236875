import { FC, useMemo, useRef, useState, useContext } from 'react';
import { StepComponentProps } from '../../StepComponentProps';
import { StylingFor } from 'dataTypes';
import {
  answerOptionExists,
  intentionKidsAnswerOptions,
  intentionMenImageAnswerOptions,
} from 'stores';
import StepFooter from 'pages/RegistrationFlow/components/StepFooter';
import { useSetHeader } from 'pages/RegistrationFlow/hooks/useSetHeader';

import styles from './IntentionStep.module.scss';
import SelectableCard from 'pages/RegistrationFlow/components/SelectableCard';
import { ArrowIcon } from 'components/Icons';
import classNames from 'classnames';
// import Header from 'components/Header';
// import ProgressBar from 'pages/RegistrationFlow/components/ProgressBar';
import StylistMessage from 'components/StylistMessage/StylistMessage';
import RegistrationFlowContext from 'providers/RegistrationFlowContext';

export const intentionStepTitle = 'Please choose from one of the three options below:';
export const subTitle = 'Select what you’re looking for and click next';

const CUSTOM_INTENTION_MAX_LENGTH = 250;

export const IntentionStep: FC<StepComponentProps> = ({
  orderDraft,
  imageUrl,
  name,
  stepsCount,
  currentStepIndex,
  onChange,
  onStepForward,
  onStepBack,
}: StepComponentProps) => {
  useSetHeader({
    title: intentionStepTitle,
    subTitle: subTitle,
  });

  const answerOptions = useMemo(
    () =>
      orderDraft.stylingFor === StylingFor.Men
        ? intentionMenImageAnswerOptions
        : intentionKidsAnswerOptions,
    [orderDraft.stylingFor],
  );

  const hasCustomValue =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    orderDraft.intention !== '' && !answerOptionExists(answerOptions, orderDraft.intention);

  const [intention, setIntention] = useState(orderDraft.intention);
  const [isCustomIntentionUsed, setIsCustomIntentionUsed] = useState(hasCustomValue);
  const intentionFieldRef = useRef<HTMLTextAreaElement>(null);

  const { header } = useContext(RegistrationFlowContext).values;
  const showProgressBar = currentStepIndex + 1 <= stepsCount;
  const showStylistMessage = showProgressBar && !header.isHidden;

  const changeIntention = (intention: string, isCustomIntentionUsed: boolean): void => {
    setIntention(intention);
    setIsCustomIntentionUsed(isCustomIntentionUsed);
  };

  const onNext = (): void => {
    onChange({
      intention,
    });

    onStepForward();
  };

  const handleAnythingElseClick = (): void => {
    if (isCustomIntentionUsed) {
      changeIntention('', false);
    } else {
      changeIntention(intentionFieldRef.current?.value || '', true);
    }
  };

  return (
    <>
      <StepFooter
        onBack={onStepBack}
        onNext={onNext}
        disableNext={!intention}
        selectMessage
        stepsCount={stepsCount}
        currentStepIndex={currentStepIndex}
      />

      {showStylistMessage && (
        <StylistMessage
          imageUrl={imageUrl}
          name={name || 'Lorna'}
          title={intentionStepTitle}
          message={subTitle}
        />
      )}

      <section className={styles.imagesContainer}>
        {answerOptions.map((answer) => {
          return (
            <SelectableCard
              key={answer.title}
              title={answer.title}
              text={answer.text}
              imageUrl={answer.imageUrl}
              imageUrlHover={answer.imageUrlHover}
              isSelected={answer.title === intention}
              onSelect={(value) => changeIntention(value, false)}
            />
          );
        })}
      </section>
      <section className={styles.textOptionContainer}>
        <div className={styles.box}>
          <div className={styles.text}>Anything else?</div>
          <div className={styles.linkWrapper} onClick={handleAnythingElseClick}>
            <div className={styles.link}>Let us know</div>
            <ArrowIcon
              className={classNames({
                [styles.arrowActive]: isCustomIntentionUsed,
              })}
            />
          </div>
        </div>
        <textarea
          id={'Others'}
          className={classNames(styles.textarea, {
            [styles.textareaActive]: isCustomIntentionUsed,
          })}
          placeholder="Please specify here"
          maxLength={CUSTOM_INTENTION_MAX_LENGTH}
          onChange={(e) => changeIntention(e.currentTarget.value, true)}
          ref={intentionFieldRef}
          defaultValue={isCustomIntentionUsed ? intention : ''}
        ></textarea>
      </section>
    </>
  );
};

import { FC } from 'react';
import { ItemList } from '../../../../ItemList';
import { StatusViewProps } from '../../../propTypes';
import { OrderPay } from '../../OrderPay';
import { arrayHasItems, calculateCredit } from '../../../../../../../utils';
import { DeliveryDetailsInfo } from '../../DeliveryDetailsInfo';
import { OrderMessage } from '../../OrderMessage';
import TwoColumnLayout from 'components/TwoColumnLayout';
import Total from '../../Total';
import { useAvailableCredits } from 'serverCache/credits';

export const PendingPaymentStatusView: FC<StatusViewProps> = (props: StatusViewProps) => {
  const { order } = props;
  const { data: credits } = useAvailableCredits(order.id);

  const creditAmount = calculateCredit(credits);

  const purchasedItems = order.purchase?.purchasedItems;
  const returnedItems = order.purchase?.returnedItems;

  const sellPrice = order.purchase?.totals.sellPrice || 0;
  const sellPriceWithDeposit = sellPrice - creditAmount;
  const discount = order.purchase?.totals.savings || 0;
  const fullPrice = sellPrice + discount;

  return (
    <TwoColumnLayout
      firstColumn={<DeliveryDetailsInfo order={order} hideOnMobile />}
      secondColumn={
        <>
          {!order.purchase && (
            <OrderMessage messageText="Please contact your stylist regarding payment for this order." />
          )}
          {order.purchase && <OrderPay order={order} totals={order.purchase.totals} />}
          {arrayHasItems(purchasedItems) && (
            <ItemList
              title="My items"
              description={`You kept ${purchasedItems.length} items`}
              showDetails
              items={purchasedItems}
            />
          )}
          {arrayHasItems(returnedItems) && (
            <ItemList
              description={`${returnedItems.length} due to be returned`}
              items={returnedItems}
            />
          )}
          <Total
            fullPrice={fullPrice}
            discountPrice={discount}
            sellPrice={sellPriceWithDeposit}
            deposit={creditAmount}
          />
        </>
      }
    />
  );
};

import { useContext, useEffect } from 'react';
import RegistrationFlowContext, { HeaderSetup } from 'providers/RegistrationFlowContext';

export interface HeaderCommands {
  setHeader(header: HeaderSetup): void;
  showHeader(): void;
  hideHeader(): void;
}

export function useSetHeader(header: HeaderSetup): HeaderCommands {
  const { setValues } = useContext(RegistrationFlowContext);

  const setHeader = (header: HeaderSetup): void =>
    setValues({
      header: {
        ...header,
        subTitle: header.subTitle ?? '',
        isHidden: header.isHidden === undefined ? false : header.isHidden,
      },
    });

  const showHeader = (): void => setValues({ header: { ...header, isHidden: false } });
  const hideHeader = (): void => setValues({ header: { ...header, isHidden: true } });

  useEffect(
    () => {
      setHeader(header);
      return () => {
        hideHeader();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { setHeader, showHeader, hideHeader };
}

/* istanbul ignore file */
import { changePasswordRedirectKey } from '../../constants';
import { PROFILE_URL } from '../../routePaths';

export function setChangePasswordRedirectUrl(): void {
  window.localStorage.setItem(changePasswordRedirectKey, window.location.pathname);
}

export function getChangePasswordRedirectUrl(): string {
  return window.localStorage.getItem(changePasswordRedirectKey) || PROFILE_URL;
}

/* eslint-disable react/no-unescaped-entities */
import { FC, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import styles from './AdvantagesSlider.module.scss';
import image1 from '../../assets/images/advantages/1.webp';
import image2 from '../../assets/images/advantages/2.webp';
import image3 from '../../assets/images/advantages/3.webp';
import image4 from '../../assets/images/advantages/4.webp';

SwiperCore.use([Navigation, Pagination]);

const AdvantagesSlider: FC = () => {
  const prevRef: React.RefObject<HTMLDivElement> = useRef(null);
  const nextRef: React.RefObject<HTMLDivElement> = useRef(null);

  return (
    <section className={styles.advantages}>
      <div className={styles.sliderWrapper}>
        <Swiper
          slidesPerView={1}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          loop={true}
          pagination={{
            el: '.pagination',
            clickable: true,
          }}
        >
          <SwiperSlide>
            <div className={styles.slider}>
              <div className={styles.image}>
                <img src={image1} alt="" />
              </div>

              <div className={styles.textWrapper}>
                <h4 className={styles.title}>Guaranteed Value</h4>
                <div className={styles.text}>
                  <p className={styles.paragraph}>
                    We will send you a guaranteed value of between <strong>£200 to £500</strong> of
                    RRP in the box. This will be handpicked by your personal stylist and will match
                    your requirements. <strong>All delivery and returns are free!</strong>
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.slider}>
              <div className={styles.image}>
                <img src={image2} alt="" />
              </div>

              <div className={styles.textWrapper}>
                <h4 className={styles.title}>Discover</h4>
                <div className={styles.text}>
                  <p className={styles.paragraph}>
                    We have all of your favourite high street and luxury brands waiting to be picked{' '}
                    <strong>especially for you</strong> by your stylist.
                  </p>
                  <p className={styles.paragraph}>
                    There's always something new to discover, but{' '}
                    <strong>once gone, it's gone</strong> - so get in quick!
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.slider}>
              <div className={styles.image}>
                <img src={image3} alt="" />
              </div>
              <div className={styles.textWrapper}>
                <h4 className={styles.title}>Exclusivity</h4>
                <div className={styles.text}>
                  Brand new <strong>unmissable</strong> products are added to our studio everyday,
                  register early so you <strong>don't miss out!</strong>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.slider}>
              <div className={styles.image}>
                <img src={image4} alt="" />
              </div>
              <div className={styles.textWrapper}>
                <h4 className={styles.title}>Spend & Reward</h4>
                <div className={styles.text}>
                  <p>We reward you the more you spend, shop and recommend us to your friends.</p>
                  <p>
                    Benefits include: <strong>early access</strong> to exclusive products and access
                    to <strong>gold-level brands</strong> as our way of saying thank you for your
                    loyalty.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <div className={styles.buttonsWrapper}>
          <div className={styles.sliderButtonPrev} ref={prevRef} />
          <div className="paginationWrapper">
            <div className="pagination"></div>
          </div>
          <div className={styles.sliderButtonNext} ref={nextRef} />
        </div>
      </div>
    </section>
  );
};

export default AdvantagesSlider;

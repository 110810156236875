import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { UrlParams } from '../../../dataTypes';
import { getStepIndex } from '../registrationFlowSteps';

export const useCurrentStep = (): { currentStep: string; currentStepIndex: number } => {
  const { stylingStep } = useParams<UrlParams>();
  const currentStepIndex = useMemo(() => getStepIndex(stylingStep), [stylingStep]);

  return { currentStep: stylingStep, currentStepIndex };
};

import { useMutation, UseMutationResult } from 'react-query';
import { useService } from '../providers/useService';
import { useAlert } from 'react-alert';
import { MakeOfferPayload } from 'dataTypes';

/* istanbul ignore file */

export function useMakeOffer(orderId: string): UseMutationResult<void, unknown, MakeOfferPayload> {
  const { offerApiService } = useService();
  const { error } = useAlert();

  return useMutation(
    async (payload: MakeOfferPayload) => {
      return await offerApiService.makeOffer(orderId, payload);
    },
    {
      onError: () => {
        error(
          'Oops! We were unable to send this offer. Please try again a bit later or contact us if problem persists.',
        );
      },
    },
  );
}

import React, { ChangeEvent, FC, useEffect } from 'react';
import { DefaultValues, useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { TextInput } from '../../TextInput';
import { noAngleBracketsRegex, phoneRegex, postcodeRegex } from '../../../constants';
import styles from './AddressForm.module.scss';
import { useSubscribeOnFormChanges } from '../../../utils/form-helpers';
import { useCanAutofocus } from 'hooks/useCanAutofocus';

function toUppercase(e: ChangeEvent<HTMLInputElement>): ChangeEvent<HTMLInputElement> {
  e.target.value = e.target.value.toUpperCase();
  return e;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/explicit-function-return-type
function noop() {}

const NAME_MIN_LENGTH = 1;
const NAME_MAX_LENGTH = 35;
const PHONE_MIN_LENGTH = 8;
const PHONE_MAX_LENGTH = 20;
const HOUSE_MAX_LENGTH = 35;
const STREET_MAX_LENGTH = 35;
const CITY_MIN_LENGTH = 2;
const CITY_MAX_LENGTH = 35;
const COUNTY_MAX_LENGTH = 32;
const POSTCODE_MIN_LENGTH = 5;
const POSTCODE_MAX_LENGTH = 8;

export interface FormState {
  name: string;
  phone: string;
  house: string;
  street: string;
  city: string;
  county: string;
  postcode: string;
}

interface Props {
  defaultValues?: DefaultValues<FormState>;
  onSubmit?: SubmitHandler<FormState>;
  onChange?(state: FormState): void;
  onValidationStateChanged(isValid: boolean): void;
  className?: string;
  id?: string;
}

export const AddressForm: FC<Props> = (props) => {
  const { defaultValues, onSubmit, onChange, onValidationStateChanged, className, id } = props;

  const form = useForm<FormState>({
    mode: 'all',
    defaultValues,
  });

  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState,
    formState: { isValid, errors },
  } = form;

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset(defaultValues);
    }
  }, [formState, reset, defaultValues]);

  useEffect(
    () => {
      onValidationStateChanged(isValid);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isValid],
  );

  useSubscribeOnFormChanges(watch, onChange || noop);

  const canAutofocus = useCanAutofocus();

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form id={id} className={className} onSubmit={handleSubmit(onSubmit || noop)}>
      <TextInput
        label="Name"
        autoComplete="name"
        placeholder="Person to deliver to"
        id="address-name"
        className={styles.textInput}
        maxLength={NAME_MAX_LENGTH}
        autoFocus={canAutofocus}
        hasError={!!errors.name}
        {...register('name', {
          required: true,
          minLength: NAME_MIN_LENGTH,
          maxLength: NAME_MAX_LENGTH,
          pattern: noAngleBracketsRegex,
        })}
      />
      <TextInput
        label="Phone"
        autoComplete="tel"
        placeholder="International or local"
        id="address-phone"
        className={styles.textInput}
        minLength={PHONE_MIN_LENGTH}
        maxLength={PHONE_MAX_LENGTH}
        hasError={!!errors.phone}
        {...register('phone', {
          required: true,
          pattern: phoneRegex,
        })}
      />
      <TextInput
        label="House name / No"
        placeholder="e.g. 15"
        autoComplete="address-line2"
        id="address-address-line2"
        className={styles.textInput}
        maxLength={HOUSE_MAX_LENGTH}
        hasError={!!errors.house}
        {...register('house', {
          required: true,
          maxLength: HOUSE_MAX_LENGTH,
          pattern: noAngleBracketsRegex,
        })}
      />
      <TextInput
        label="Street name"
        autoComplete="address-line1"
        placeholder="e.g. Victoria Road"
        id="address-address-line1"
        className={styles.textInput}
        maxLength={STREET_MAX_LENGTH}
        hasError={!!errors.street}
        {...register('street', {
          required: true,
          maxLength: STREET_MAX_LENGTH,
          pattern: noAngleBracketsRegex,
        })}
      />
      <TextInput
        label="Town / City"
        autoComplete={'address-level2'}
        placeholder="e.g. London"
        id="address-address-level2"
        className={styles.textInput}
        maxLength={CITY_MAX_LENGTH}
        hasError={!!errors.city}
        {...register('city', {
          required: true,
          minLength: CITY_MIN_LENGTH,
          maxLength: CITY_MAX_LENGTH,
          pattern: noAngleBracketsRegex,
        })}
      />
      <TextInput
        label="County"
        autoComplete={'address-level1'}
        placeholder="e.g. Hampshire"
        id="address-address-level1"
        className={styles.textInput}
        maxLength={COUNTY_MAX_LENGTH}
        hasError={!!errors.county}
        {...register('county', {
          maxLength: COUNTY_MAX_LENGTH,
          pattern: noAngleBracketsRegex,
        })}
      />
      <TextInput
        label="Postcode"
        autoComplete={'postal-code'}
        placeholder="e.g. EC3R 4DR"
        id="address-postal-code"
        className={styles.textInput}
        modifyOnChange={toUppercase}
        minLength={POSTCODE_MIN_LENGTH}
        maxLength={POSTCODE_MAX_LENGTH}
        hasError={!!errors.postcode}
        {...register('postcode', {
          required: true,
          pattern: postcodeRegex,
        })}
      />
      <div className={styles.deliveryTimeContainer}>
        <div className={styles.deliveryTimeLabel} />
        <div className={styles.deliveryTimeText}>Estimated delivery time 2-4 business days</div>
      </div>
    </form>
  );
};

import classNames from 'classnames';
import styles from './Input.module.scss';

export interface StylingProps {
  error?: boolean;
  className?: string;
}

export function combineStyles<T extends StylingProps>(
  props: T,
): [string, Omit<T, keyof StylingProps>] {
  const { error, className: forwardedClassName, ...otherProps } = props;

  const className = classNames(
    styles.base,
    {
      [styles.error]: error,
    },
    forwardedClassName,
  );

  return [className, otherProps];
}

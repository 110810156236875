import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Profile.module.scss';
import { Title } from '../../components/Title';
import { Loading } from '../../components/Loading';
import { PastFavouritesBox } from './components/PastFavouritesBox';
import { MysterySurpriseBox } from './components/MysterySurpriseBox';
import { SomethingSpecificBox } from './components/SomethingSpecificBox';
import { useCanRequestAnotherBox } from './useCanRequestAnotherBox';
import { useCustomerProfile } from '../../serverCache/customerProfile';
import classNames from 'classnames';
import { useActiveOrderList } from './useActiveOrderList';
import { Logout } from './components/Logout';
import { ProfileSubMenu } from '../../components/ProfileSubMenu';
import OrderListItem from 'components/OrderListItem';
import { EmptyComponent } from 'pages/Orders/components/EmptyComponent';

export const Profile: FC = observer(() => {
  const { data: canRequestAnotherBox, isLoading: isCanRequestAnotherBoxLoading } =
    useCanRequestAnotherBox();
  const { data: customerProfile } = useCustomerProfile();
  const { data: activeOrders, isLoading: isActiveOrderLoading } = useActiveOrderList({
    pageNumber: 0,
    pageSize: 3,
  });

  const firstName = customerProfile?.firstName ? `Hello ${customerProfile.firstName}!` : 'Hello!';

  return (
    <>
      <Title
        align={'center'}
        title={firstName}
        backgroundTitle="my profile"
        subtitle="Welcome to your profile. Here you can manage your orders, returns and other account information."
      />
      <ProfileSubMenu className={styles.mobileSubmenu} />
      {(isCanRequestAnotherBoxLoading || isActiveOrderLoading) && (
        <>
          <div className={styles.loadingBox}>
            <Loading isBlack />
          </div>
        </>
      )}
      {activeOrders && activeOrders.total > 0 && (
        <>
          <h2 className={classNames(styles.subtitle, styles.hiddenSm)}>Latest updates</h2>
          {activeOrders.items.map((order) => (
            <OrderListItem key={order.id} order={order} />
          ))}
        </>
      )}
      {activeOrders && activeOrders.total === 0 && (
        <div className={styles.emptyWrapper}>
          <EmptyComponent />
        </div>
      )}
      {canRequestAnotherBox && (
        <>
          <h2 className={styles.subtitle}>Order more in one click</h2>
          <section className={styles.wrapper}>
            <div className={styles.box}>
              <PastFavouritesBox />
              <MysterySurpriseBox />
              <SomethingSpecificBox />
            </div>
          </section>
        </>
      )}
      <Logout className={styles.logout} />
    </>
  );
});

export default function getYmCounterScript(counterId: string): string {
  return `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
    m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
    ym(${counterId}, "init", {
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor:true
    });`;
}

export function getYmCounterNoScript(counterId: string): Node {
  const div = document.createElement('div');

  const img = document.createElement('img');
  img.src = `https://mc.yandex.ru/watch/${counterId}`;
  img.style.cssText = 'position:absolute; left:-9999px;';
  img.alt = '';

  div.appendChild(img);
  return div;
}

export function ymCounterHit(counterId: string): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  ym(counterId, 'hit', window.location.href);
}

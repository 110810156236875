import { QuestionBase } from './baseTypes';

export class SelectQuestion implements QuestionBase<string> {
  constructor(
    public readonly label: string,
    public readonly options: { value: string; label: string }[],
  ) {}

  validate(value: string | undefined): string | undefined {
    return undefined;
  }
}

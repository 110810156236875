import { FC } from 'react';
import styles from './Title.module.scss';
import classNames from 'classnames';

/* istanbul ignore file */

export interface TitleLeftProps {
  backgroundTitle?: string;
  title: string;
  subtitle?: string;
  align?: 'left' | 'center' | 'right';
  whiteSpace?: string;
}

export const Title: FC<TitleLeftProps> = ({
  title,
  backgroundTitle,
  subtitle,
  align = 'left',
  whiteSpace = 'nowrap',
}: TitleLeftProps) => {
  return (
    <section className={styles.host}>
      <div
        className={classNames({
          [styles.titleBackground]: true,
          [styles[align]]: true,
        })}
      >
        {backgroundTitle ?? title}
        <h1
          className={classNames({
            [styles.title]: true,
            [styles[align]]: true,
            [styles[whiteSpace]]: true,
          })}
          title={title}
        >
          {title}
        </h1>
      </div>
      <div
        className={classNames({
          [styles.subtitle]: true,
          [styles[align]]: true,
        })}
        title={subtitle}
      >
        {subtitle}
      </div>
    </section>
  );
};

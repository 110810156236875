import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { shortSiteTitle } from '../../constants';
import { Main } from './pages/Main';
import { Edit } from './pages/Edit';
import { CUSTOMER_DETAILS_EDIT_URL, CUSTOMER_DETAILS_URL } from '../../routePaths';

export const CustomerDetails: FC = () => {
  return (
    <>
      <Helmet>
        <title>{shortSiteTitle} - My details</title>
      </Helmet>
      <Switch>
        <Route exact path={CUSTOMER_DETAILS_URL} component={Main} />
        <Route path={CUSTOMER_DETAILS_EDIT_URL} component={Edit} />
      </Switch>
    </>
  );
};

import { QuestionBase } from './baseTypes';

const imperialHeightRegex = /^(\d)'((\d|(10)|(11))(\.5)?")?$/;
const metricHeightRegex = /^\d+$/;

export class HeightQuestion implements QuestionBase<string> {
  constructor(public readonly label: string) {}

  validate = (value: string | undefined): string | undefined => {
    if (value && !imperialHeightRegex.test(value) && !metricHeightRegex.test(value)) {
      return 'Please enter correct height (digits only)';
    }

    return undefined;
  };
}

import { FC, ReactElement, useCallback } from 'react';
import { StylingProfile, UrlParams } from 'dataTypes';
import { RadioCard } from 'components/RadioCard';
import { Button } from 'components/Button';
import { PlusIcon } from 'components/Icons/PlusIcon';
import { useLogout } from 'providers/useLogout';
import { generatePath } from 'react-router';
import { ORDER_FLOW_START_PATH } from 'routePaths';
import { useParams } from 'react-router-dom';
import styles from './StylingProfileList.module.scss';
import { useStoreOperations } from './useStoreOperations';

interface Props {
  stylingProfiles: StylingProfile[];
  firstName?: string;
  lastName?: string;
  onNext(): void;
  renderFooter(onClickNext: () => void): ReactElement;
  onCreateStylingProfile(): void;
}

export const StylingProfileList: FC<Props> = (props) => {
  const { stylingProfiles, firstName, lastName, renderFooter, onNext, onCreateStylingProfile } =
    props;

  const { defaultValue, patchStore, clearStore } = useStoreOperations(stylingProfiles);
  const logout = useLogout();
  const { stylingFor } = useParams<UrlParams>();
  const logoutUrl = window.location.origin + generatePath(ORDER_FLOW_START_PATH, { stylingFor });

  const createStylingProfileLabel = `I'm styling for another ${
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    stylingFor === 'men' ? 'person' : 'kid'
  }`;

  const fullName = firstName && lastName && `${firstName} ${lastName}`;

  const handleCreateStylingProfile = useCallback(() => {
    clearStore();
    onCreateStylingProfile();
  }, [clearStore, onCreateStylingProfile]);

  const handleNextClick = useCallback(
    () => {
      patchStore(defaultValue);
      onNext();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultValue],
  );

  return (
    <div className={styles.wrapper}>
      {renderFooter(handleNextClick)}
      <div className={styles.container}>
        <div className={styles.radioCardWrapper}>
          <RadioCard items={stylingProfiles} onSelect={patchStore} selectedItem={defaultValue} />
        </div>
        <Button
          className={styles.createNewStylingProfileButton}
          color="white"
          width="full"
          startIcon={<PlusIcon />}
          onClick={handleCreateStylingProfile}
        >
          {createStylingProfileLabel}
        </Button>
        {fullName && (
          <div className={styles.logoutText}>
            Not {`${fullName}? `}
            <span className={styles.logoutLink} onClick={() => logout(logoutUrl)}>
              Logout
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

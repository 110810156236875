import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Title } from '../../../../components/Title';
import { useCreateAddress } from '../../../../serverCache/address';
import { AddressForm } from '../../../../components/forms/AddressForm';
import { ADDRESS_LIST_URL } from '../../../../routePaths';
import styles from './New.module.scss';
import { Button, RouterLinkButton } from '../../../../components/Button';
import { ProfileBack } from '../../../../components/ProfileBack';

export const New: FC = () => {
  const { mutateAsync: createAddress } = useCreateAddress();
  const history = useHistory();

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const onValidationStateChanged = (isValid: boolean): void => {
    setIsSaveEnabled(isValid);
  };

  return (
    <>
      <ProfileBack to={ADDRESS_LIST_URL} name="addresses" />
      <Title backgroundTitle="My Addresses" title="Add new address" align="center" />
      <AddressForm
        id="addressForm"
        className={styles.form}
        onSubmit={async (formState) => {
          const result = await createAddress(formState);
          history.push(ADDRESS_LIST_URL);
          return result;
        }}
        onValidationStateChanged={onValidationStateChanged}
      />
      <div className={styles.buttonContainer}>
        <RouterLinkButton color="white" to={ADDRESS_LIST_URL}>
          Cancel
        </RouterLinkButton>
        <Button type="submit" form="addressForm" disabled={!isSaveEnabled}>
          Save
        </Button>
      </div>
    </>
  );
};

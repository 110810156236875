import { FC } from 'react';
import useMediaQueries from 'hooks/useMediaQueries';

const MediaQueries: FC = ({ children }) => {
  const matches = useMediaQueries();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  if (typeof children === 'function') return children(matches);
  return children;
};

export default MediaQueries;

import { FC, PropsWithChildren } from 'react';
import styles from './BorderBox.module.scss';
import classNames from 'classnames';

interface Props {
  className?: string;
}

export const BorderBox: FC<PropsWithChildren<Props>> = ({ children, className = '' }) => {
  return (
    <div
      className={classNames({
        [styles.host]: true,
        [className]: true,
      })}
    >
      {children}
    </div>
  );
};

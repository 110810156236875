import { FC, useCallback } from 'react';
import { Title } from '../../../../components/Title';
import { PAYMENT_METHODS_URL } from '../../../../routePaths';
import { ProfileBack } from '../../../../components/ProfileBack';
import { NewBraintreePaymentMethod } from 'pages/PaymentMethods/components/NewBraintreePaymentMethod';
import { useRefreshPaymentMethods } from 'serverCache/paymentMethods';
import { useHistory } from 'react-router-dom';

export const New: FC = () => {
  const refreshPaymentMethods = useRefreshPaymentMethods();
  const { push } = useHistory();

  const onSuccess = useCallback(() => {
    refreshPaymentMethods();
    push(PAYMENT_METHODS_URL);
  }, [refreshPaymentMethods, push]);

  return (
    <>
      <ProfileBack to={PAYMENT_METHODS_URL} name="payments" />
      <Title
        backgroundTitle="My Payment Methods"
        title="New Payment Method"
        align="center"
        whiteSpace="normal"
      />
      <NewBraintreePaymentMethod onSuccess={onSuccess} />
    </>
  );
};

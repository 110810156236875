/* istanbul ignore file */
import { FC } from 'react';

export interface TickButtonIconProps {
  className?: string;
}

export const TickButtonIcon: FC<TickButtonIconProps> = (props: TickButtonIconProps) => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="48" height="48" rx="24" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.2241 39.6621C33.5575 37.0212 30.8533 34.3418 28.191 31.7057C29.5644 31.0257 30.6866 29.9315 31.3897 28.5854C34.0228 31.1899 36.721 33.8589 39.424 36.5321C38.3766 37.5564 37.2806 38.6287 36.2241 39.6621M36.2891 11.2347C37.3262 12.2547 38.4034 13.3161 39.4234 14.3197C36.7654 16.9303 34.0915 19.5566 31.4396 22.1617C30.7711 20.8303 29.7012 19.73 28.3783 19.0281C30.9852 16.4601 33.6353 13.8495 36.2891 11.2347M13.8326 39.686C12.7547 38.6204 11.6508 37.5286 10.5766 36.4666C13.2115 33.87 15.8835 31.2369 18.5276 28.6312C19.2563 30.0028 20.4229 31.1068 21.8443 31.7728C19.1839 34.401 16.4924 37.059 13.8326 39.686M13.8568 11.2659C16.406 13.7871 19.0269 16.3794 21.6435 18.9681C20.2622 19.6676 19.1418 20.791 18.4533 22.1651C15.8427 19.5891 13.209 16.9901 10.6197 14.4343C11.6726 13.4039 12.7808 12.3194 13.8568 11.2659M25 0C11.1935 0 0 11.1923 0 25.0006C0 38.8071 11.1935 50 25 50C38.8071 50 50 38.8071 50 25.0006C50 11.1923 38.8071 0 25 0"
        fill="white"
      />
    </svg>
  );
};

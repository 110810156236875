import { FC } from 'react';
import styles from './ProfileSubMenu.module.scss';
import { generatePath, NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../../providers/AuthProvider';
import {
  CUSTOMER_DETAILS_URL,
  ADDRESS_LIST_URL,
  ORDER_LIST_URL,
  PAYMENT_METHODS_URL,
  PROFILE_URL,
} from '../../routePaths';
import { OrderIcon, DetailsIcon, HomeIcon, CreditCardIcon, ProfileIcon } from '../Icons';
import { Logout } from '../../pages/Profile/components/Logout';
import classNames from 'classnames';

interface ProfileSubMenuProps {
  className?: string;
}

export const ProfileSubMenu: FC<ProfileSubMenuProps> = observer((props: ProfileSubMenuProps) => {
  const { isAuthenticated } = useAuth();

  return (
    <nav className={classNames(styles.nav, props.className)}>
      <NavLink
        to={generatePath(PROFILE_URL)}
        activeClassName={styles.active}
        className={styles.item}
      >
        <ProfileIcon className={classNames(styles.icon, styles.invert)} width={26} height={20} />
        My Profile
      </NavLink>
      {isAuthenticated && (
        <NavLink
          to={generatePath(ORDER_LIST_URL)}
          data-testid={'orders'}
          activeClassName={styles.active}
          className={styles.item}
        >
          <OrderIcon className={styles.icon} width={26} height={26} />
          My Orders
        </NavLink>
      )}

      {isAuthenticated && (
        <NavLink
          to={generatePath(CUSTOMER_DETAILS_URL)}
          data-testid={'customer-details'}
          activeClassName={styles.active}
          className={styles.item}
        >
          <DetailsIcon className={styles.icon} width={26} height={24} />
          My Details
        </NavLink>
      )}

      {isAuthenticated && (
        <NavLink
          to={generatePath(ADDRESS_LIST_URL)}
          data-testid={'addresses'}
          activeClassName={styles.active}
          className={styles.item}
        >
          <HomeIcon className={styles.icon} width={26} height={24} />
          My Addresses
        </NavLink>
      )}

      {isAuthenticated && (
        <NavLink
          to={generatePath(PAYMENT_METHODS_URL)}
          data-testid={'payment-methods'}
          activeClassName={styles.active}
          className={styles.item}
        >
          <CreditCardIcon className={styles.icon} width={26} height={26} />
          My Payment Methods
        </NavLink>
      )}

      <Logout className={styles.logout} />
    </nav>
  );
});

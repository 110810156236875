/* istanbul ignore file */
/* Ignored because it is trivial */

export function omit<T, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> {
  const result: Partial<T> = {
    ...obj,
  };

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    delete result[key];
  }

  return result as Omit<T, K>;
}

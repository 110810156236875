import React, { FC } from 'react';
import { Title } from '../../../../components/Title';
import { usePaymentMethods } from '../../../../serverCache/paymentMethods';
import { PaymentMethodListItem } from '../../components/PaymentMethodsListItem/PaymentMethodListItem';
import styles from './Main.module.scss';
import { RouterLinkButton } from '../../../../components/Button';
import { PAYMENT_METHODS_NEW_URL, PROFILE_URL } from '../../../../routePaths';
import { ProfileBack } from '../../../../components/ProfileBack';

export const Main: FC = () => {
  const { data: paymentMethods } = usePaymentMethods();

  return (
    <>
      <ProfileBack to={PROFILE_URL} name="profile" />
      <Title
        backgroundTitle="My Profile"
        title="My Payment Methods"
        align="center"
        whiteSpace="normal"
      />
      <div className={styles.addNewButtonContainer}>
        <RouterLinkButton
          to={PAYMENT_METHODS_NEW_URL}
          color="white"
          className={styles.addNewButton}
        >
          Add new payment method
        </RouterLinkButton>
      </div>
      <div className={styles.content}>
        {(paymentMethods ?? []).map((i) => (
          <PaymentMethodListItem method={i} key={i.id} />
        ))}
      </div>
    </>
  );
};

import { FC, useRef } from 'react';
import styles from './TrustedShopsReviews.module.scss';

import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import reviews from './reviews';
import classNames from 'classnames';
import { useReviews } from 'serverCache/reviews';

SwiperCore.use([Navigation, Autoplay]);

const TrustedShopsReviews: FC = () => {
  const prevRef: React.RefObject<HTMLDivElement> = useRef(null);
  const nextRef: React.RefObject<HTMLDivElement> = useRef(null);

  const linkProps = {
    href: 'https://www.trustedshops.co.uk/buyerrating/info_X96D612D17BFCBE26062D4B66579A231D.html',
    target: '_blank',
    className: styles.link,
  };

  const { data: reviewList } = useReviews();

  const reviewsToShow = reviewList?.reviews || reviews;
  const averageRating = reviewList?.averageRating;

  return (
    <section className={styles.section} id="TrustedShopsReviews">
      <div className={styles.review}>
        <div className={styles.rating}>
          <div className={styles.image}></div>
          <div>
            <div className={styles.ratingBox}>
              <div className={styles.ratingNumber}>{averageRating}</div>
              <div className={styles.ratingText}>Excellent</div>
            </div>
            <div className={styles.ratingLink}>
              <div className={styles.starWrapper}>
                <div className={styles.star} />
                <div className={styles.star} />
                <div className={styles.star} />
                <div className={styles.star} />
                <div className={styles.star} />
              </div>
              <a {...linkProps}>View all reviews</a>
            </div>
          </div>
        </div>
        <div className={styles.itemWrapper}>
          <div className={styles.sliderButtonPrev} ref={prevRef} />
          <div className={styles.sliderButtonNext} ref={nextRef} />
          <Swiper
            slidesPerView={1}
            loop={true}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
          >
            {reviewsToShow.map((item) => (
              <SwiperSlide key={item.comment}>
                <div className={styles.item}>
                  <div className={styles.itemText} data-testid="trusted-shops-comment">
                    {item.comment}
                  </div>
                  <div className={styles.itemVerified}>
                    <div className={styles.verified} />
                    <span>Verified review</span>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.infoItem}>
          <div className={classNames(styles.infoIcon, styles.shopping)} />
          <div className={styles.infoBox}>
            <div className={styles.infoTitle}>Shopping is secure here</div>
            <div className={styles.infoText}>
              This shop meets the Trusted Shops quality criteria
            </div>
          </div>
        </div>

        <div className={styles.infoItem}>
          <div className={classNames(styles.infoIcon, styles.independent)} />
          <div className={styles.infoBox}>
            <div className={styles.infoTitle}>Independent guarantee</div>
            <div className={styles.infoText}>
              Your purchase is protected up to £ 2,500 however you choose to pay
            </div>
          </div>
        </div>

        <div className={styles.infoItem}>
          <div className={classNames(styles.infoIcon, styles.rating)} />
          <div className={styles.infoBox}>
            <div className={styles.infoTitle}>Rating score: Excellent</div>
            <div className={styles.infoText}>
              Buy better with authentic reviews. Read all reviews for choixce.com
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrustedShopsReviews;

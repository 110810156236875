/* istanbul ignore file */
/* Ignored because it is trivial */

export function pick<T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
  const result: Partial<T> = {};

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    result[key] = obj[key];
  }

  return result as Pick<T, K>;
}

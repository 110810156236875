import { FC } from 'react';

const ChevronLeftIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5684 16.743L13.2169 18.2173L7.32379 12.7371C6.89142 12.3408 6.89142 11.6591 7.32379 11.2628L13.2169 5.81372L14.5684 7.28803L9.4794 11.9999L14.5684 16.743Z"
        fill="#7c7c7c"
      />
    </svg>
  );
};

export default ChevronLeftIcon;

import { useParams } from 'react-router-dom';
import { generatePath } from 'react-router';
import { UrlParams } from '../../../dataTypes';
import { ORDER_FLOW_PATH } from '../../../routePaths';

type UsePathType = () => (stylingStep: string) => string;

export const usePath: UsePathType = () => {
  const { stylingFor } = useParams<UrlParams>();

  return (stylingStep: string) => {
    return generatePath(ORDER_FLOW_PATH, { stylingFor, stylingStep });
  };
};

import dayjs from 'dayjs';
import { makeAutoObservable, runInAction } from 'mobx';
import { OrderStatus, SubmittedOrder } from '../dataTypes';
import { OrderApiService } from '../services/OrderApiService';
import { LoadingState } from './dataTypes';

export class OrderListStore {
  public orderList: SubmittedOrder[] = [];

  public listLoadingState = LoadingState.Init;
  public listHasLoadedOnce = false;

  constructor(private apiService: OrderApiService) {
    makeAutoObservable(this);
  }

  public get isOrderListEmpty(): boolean {
    return this.orderList.length === 0;
  }

  startLoadingOrderList(): void {
    this.listLoadingState = LoadingState.Loading;
    void this.apiService.getOrderList().then((value = []) => {
      runInAction(() => {
        this.listLoadingState = LoadingState.Success;
        this.listHasLoadedOnce = true;
        this.orderList = value.map(this.prepareOrderForStore);
      });
    });
  }

  private prepareOrderForStore = (order: SubmittedOrder): SubmittedOrder => ({
    ...order,
    createdDate: dayjs(order.createdDate),
    status: OrderStatus[order.status as keyof typeof OrderStatus],
  });

  getOrder(id: string): SubmittedOrder | undefined {
    if (!this.listHasLoadedOnce) {
      this.startLoadingOrderList();
    }

    const order = this.orderList.find((order) => {
      return order.id === id;
    });

    return order;
  }

  replaceOrder(newOrder: SubmittedOrder): void {
    const orderIndex = this.orderList.findIndex((order) => order.id === newOrder.id);
    if (orderIndex >= 0) {
      const newOrderList = [...this.orderList];
      newOrderList[orderIndex] = newOrder;
      this.orderList = newOrderList;
    }
  }

  reloadOrder(id: string): void {
    this.listLoadingState = LoadingState.Loading;
    void this.apiService.getOrder(id).then((changedOrder) => {
      runInAction(() => {
        this.replaceOrder(this.prepareOrderForStore(changedOrder));
        this.listLoadingState = LoadingState.Success;
      });
    });
  }
}

import React, { FC } from 'react';
import { Title } from '../../../../components/Title';
import { CardDetials } from '../../../../components/CardDetials';
import { ProfileIcon, PhoneIcon } from '../../../../components/Icons';
import { Email } from '../../../../components/Icons/Email';
import { RouterLinkButton } from '../../../../components/Button';
import { Chat } from '../../../../components/Icons/Chat';
import { useCustomerProfile } from '../../../../serverCache/customerProfile';
import styles from './Main.module.scss';
import { Loading } from '../../../../components/Loading';
import { CUSTOMER_DETAILS_EDIT_URL, PROFILE_URL } from '../../../../routePaths';
import { ProfileBack } from '../../../../components/ProfileBack';

export const Main: FC = () => {
  const { data, isLoading } = useCustomerProfile();

  if (isLoading) {
    return (
      <div className={styles.loadingWrapper}>
        <Loading isBlack />
      </div>
    );
  }

  if (data) {
    return (
      <>
        <ProfileBack to={PROFILE_URL} name="profile" />
        <Title backgroundTitle="My Profile" title="My Details" align="center" />
        <div className={styles.content}>
          <CardDetials
            image={<ProfileIcon width={20} height={20} className={styles.invert} />}
            title="First and last name"
            info={`${data.firstName || ''} ${data.lastName || ''}`}
          />
          <CardDetials image={<Email />} title="E-mail" info={data.email} />
          <CardDetials image={<PhoneIcon />} title="Phone number" info={data.phone} />
          <CardDetials
            image={<Chat />}
            title="Preferred contact method"
            alignStart
            info={data.communicationMethod}
          />
        </div>
        <RouterLinkButton color="white" to={CUSTOMER_DETAILS_EDIT_URL} className={styles.button}>
          edit
        </RouterLinkButton>
      </>
    );
  } else {
    return <div>Robot monks erased all your data.</div>;
  }
};

import { useEffect, useState } from 'react';

export const useCanAutofocus = (): boolean => {
  const [canAutofocus, setCanAutofocus] = useState(() => window.innerWidth > 767);
  useEffect(() => {
    const handleResize = (): void => {
      setCanAutofocus(window.innerWidth > 767);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return canAutofocus;
};

import classNames from 'classnames';
import { SystemOfMeasure } from 'dataTypes';
import { FC } from 'react';
import styles from './SystemOfMeasureSwitch.module.scss';

interface SystemOfMeasureSwitchProps {
  value: SystemOfMeasure;
  onChange(systemOfMeasure: SystemOfMeasure): void;
}

export const SystemOfMeasureSwitch: FC<SystemOfMeasureSwitchProps> = ({ value, onChange }) => {
  const handleChange = (value: SystemOfMeasure): void => {
    onChange(value);
  };

  return (
    <div className={styles.container}>
      <div className={classNames(styles.radioBtn, styles.outlined)}>
        <input
          id="imperial-button"
          type="radio"
          value="Imperial"
          checked={value === 'Imperial'}
          onChange={(e) => handleChange(e.target.value as SystemOfMeasure)}
        />
        <label htmlFor="imperial-button">Imperial</label>
      </div>

      <div className={classNames(styles.radioBtn, styles.outlined)}>
        <input
          id="metric-button"
          type="radio"
          value="Metric"
          checked={value === 'Metric'}
          onChange={(e) => handleChange(e.target.value as SystemOfMeasure)}
        />
        <label htmlFor="metric-button">Metric</label>
      </div>
    </div>
  );
};

import React, { ChangeEvent, FC } from 'react';
import styles from './Select.module.scss';

type NativeInputProps = React.InputHTMLAttributes<HTMLInputElement>;

export interface Option {
  value: string;
  label: string;
}

interface AdditionalProps {
  name: string;
  label?: string;
  onChange(e: ChangeEvent<HTMLSelectElement>): void;
  items: Option[];
}

type SelectProps = AdditionalProps & Pick<NativeInputProps, 'value' | 'className'>;

export const Select: FC<SelectProps> = (props) => {
  const { name, items, label, onChange, value, className } = props;

  return (
    <div className={className}>
      {label && <div className={styles.title}>{label}</div>}
      <select
        name={name}
        className={styles.select}
        onChange={onChange}
        value={value || ''}
        data-testid={label || name}
      >
        {items.map((item) => (
          <option value={item.value} key={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};

import { useEffect } from 'react';
import { useStore } from '../../../providers/useStore';
import { useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import { ORDER_FLOW_PATH } from '../../../routePaths';
import { useStylingFor } from './useStylingFor';

export interface Cache {
  lastVisitedStep: string;
}

const isValidStep = (currentStep: string, lastVisitedStep: string): boolean => {
  return currentStep === lastVisitedStep;
};

export const useReturnToLastVisitedStep = (currentStep: string): void => {
  const { registrationFlowStore } = useStore();
  const stylingFor = useStylingFor().toLowerCase();
  const history = useHistory();
  useEffect(
    () => {
      const { lastVisitedStep } = registrationFlowStore.getCache<Cache>();

      if (isValidStep(currentStep, lastVisitedStep)) {
        return;
      }
      history.push(generatePath(ORDER_FLOW_PATH, { stylingFor, stylingStep: lastVisitedStep }));
    },
    /*eslint-disable-next-line react-hooks/exhaustive-deps */
    [history, registrationFlowStore, stylingFor],
  );
};

import { AuthStore } from '../stores/AuthStore';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import * as urls from '../routePaths';
import { useHistory } from 'react-router-dom';

export class AxiosProvider {
  public axiosInstance: AxiosInstance;

  private _history!: ReturnType<typeof useHistory>;

  set history(value: ReturnType<typeof useHistory>) {
    this._history = value;
  }

  constructor(private authStore: AuthStore) {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_PATH,
    });
    this.setUpAxios();
  }

  private setUpAxios(): void {
    this.axiosInstance.interceptors.response.use(function (response) {
      return response;
    }, this.onReject);
    this.axiosInstance.interceptors.request.use(this.addAuthHeaders);
  }

  private onReject = (error?: AxiosError): Promise<never> => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (401 === error?.response?.status) {
      this.authStore.invalidateAuth();
      this.redirectToLogin();
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  };

  private redirectToLogin(): void {
    this._history.push(urls.LOGIN_URL);
  }

  private addAuthHeaders = (config: AxiosRequestConfig): AxiosRequestConfig => {
    if (this.authStore.isAuthenticated) {
      (config.headers as Record<string, string>)[
        'IYC-Auth'
      ] = `Bearer ${this.authResult.accessToken}`;

      (config.headers as Record<string, string>)['IYC-AuthId'] = `token ${this.authResult.idToken}`;
    }
    return config;
  };

  authResult!: AuthResult;

  setAuthResult = (result: AuthResult): void => {
    /* istanbul ignore next */
    this.authResult = result;
  };
}

import { FC, useCallback, useEffect, useState, useContext } from 'react';
import {
  CustomerProfile as TCustomerProfile,
  StylingFor,
  StylingProfile as TStylingProfile,
} from 'dataTypes';
import { StepComponentProps } from '../../../StepComponentProps';
import { CustomerProfile } from '../CustomerProfile/CustomerProfile';
import StepFooter from 'pages/RegistrationFlow/components/StepFooter';
import { StylingProfile } from '../StylingProfile/StylingProfile';
import { StylingProfileList } from '../StylingProfileList';
import { useStoreOperations } from '../StylingProfile/useStoreOperations';
import { useSetHeader } from 'pages/RegistrationFlow/hooks/useSetHeader';
// import styles from './PersonalDetailsRouter.module.scss';
import StylistMessage from 'components/StylistMessage/StylistMessage';
import RegistrationFlowContext from 'providers/RegistrationFlowContext';

export type step = 'customerProfile' | 'stylingProfile' | 'stylingProfileList';

export const personalDetailsStepDefaultTitle = 'Please introduce yourself';
export const subTitleDefault =
  'Fill out your details below to begin creating your account and click next';
export const subTitleStylingProfile =
  'You can add additional profiles at any time by logging into your account';

interface PersonalDetailsStepProps extends StepComponentProps {
  isAuthenticated: boolean;
  stylingFor: StylingFor;
  customerProfile?: TCustomerProfile;
  stylingProfiles?: TStylingProfile[];
  firstStep: step;
}

export const checkCustomerProfileHasAllData = (
  customerProfile: TCustomerProfile | undefined,
): customerProfile is TCustomerProfile => {
  if (customerProfile) {
    return !!(customerProfile.firstName && customerProfile.lastName && customerProfile.email);
  }
  return false;
};

export const PersonalDetailsRouter: FC<PersonalDetailsStepProps> = ({
  isAuthenticated,
  stylingFor,
  imageUrl,
  name,
  stepsCount,
  currentStepIndex,
  onStepBack,
  onStepForward,
  customerProfile,
  stylingProfiles,
  firstStep,
}) => {
  const [currentStep, setCurrentStep] = useState<step>(firstStep);

  const isFirstProfileBeingCreated = !stylingProfiles?.length;

  const { customerFirstName } = useStoreOperations(isFirstProfileBeingCreated, customerProfile);

  const greeting = customerFirstName ? `Hi ${customerFirstName}, ` : '';
  const greetingTitle = `${greeting}who are we styling for today?`;

  // const stylistIntroSubTitle = (
  //   <>
  //     <span className={styles.messageParagraph}>
  //       My name is Lorna and I&apos;ve been a fashion stylist for more than 7 years. I&apos;m
  //       passionate about personal shopping and will be happy to help you.
  //     </span>
  //     <span className={styles.messageLink}>
  //       And you can check{' '}
  //       <a href="#BoxSamplesTiles" className={styles.link}>
  //         box examples
  //       </a>{' '}
  //       I&apos;ve packed for other clients.
  //     </span>
  //   </>
  // );

  const { setHeader } = useSetHeader({
    title: currentStep === 'customerProfile' ? personalDetailsStepDefaultTitle : greetingTitle,
    subTitle: currentStep === 'customerProfile' ? subTitleDefault : subTitleStylingProfile,
  });

  useEffect(() => {
    setCurrentStep(firstStep);
  }, [firstStep]);

  const handleStylingProfileGoBack = useCallback(() => {
    let goBackStep: step = 'stylingProfileList';

    if (isFirstProfileBeingCreated) {
      if (!checkCustomerProfileHasAllData(customerProfile)) {
        goBackStep = 'customerProfile';
      } else {
        onStepBack();
        return;
      }
    }

    setCurrentStep(goBackStep);
  }, [isFirstProfileBeingCreated, customerProfile, onStepBack]);

  const goToStylingProfile = useCallback(() => {
    setCurrentStep('stylingProfile');
    setHeader({
      title: greetingTitle,
      subTitle: subTitleDefault,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { header } = useContext(RegistrationFlowContext).values;
  const showProgressBar = currentStepIndex + 1 <= stepsCount;
  const showStylistMessage = showProgressBar && !header.isHidden;
  const title = currentStep === 'customerProfile' ? personalDetailsStepDefaultTitle : greetingTitle;

  return (
    <>
      {currentStep === 'customerProfile' && (
        <CustomerProfile
          onStepForward={goToStylingProfile}
          showLoginLink={!isAuthenticated}
          customerProfile={customerProfile}
          renderFooter={({ formState: { isValid, isSubmitting } }) => (
            <>
              <StepFooter
                onBack={onStepBack}
                disableNext={!isValid}
                isSubmitting={isSubmitting}
                hideLogin
                stepsCount={stepsCount}
                currentStepIndex={currentStepIndex}
              />

              {showStylistMessage && (
                <StylistMessage
                  imageUrl={imageUrl}
                  name={name || 'Lorna'}
                  title={title}
                  message={subTitleDefault}
                />
              )}
            </>
            // <StepFooter
            //   onBack={onStepBack}
            //   disableNext={!isValid}
            //   isSubmitting={isSubmitting}
            //   hideLogin
            // />
          )}
        />
      )}
      {currentStep === 'stylingProfileList' && (
        <StylingProfileList
          stylingProfiles={stylingProfiles || []}
          firstName={customerProfile?.firstName}
          lastName={customerProfile?.lastName}
          onNext={onStepForward}
          onCreateStylingProfile={() => setCurrentStep('stylingProfile')}
          renderFooter={(onClickNext: () => void) => (
            <>
              <StepFooter
                onBack={onStepBack}
                onNext={onClickNext}
                stepsCount={stepsCount}
                currentStepIndex={currentStepIndex}
              />

              {showStylistMessage && (
                <StylistMessage
                  imageUrl={imageUrl}
                  name={name || 'Lorna'}
                  title={title}
                  message={subTitleStylingProfile}
                />
              )}
            </>
            // <StepFooter onBack={onStepBack} onNext={onClickNext} />
          )}
        />
      )}
      {currentStep === 'stylingProfile' && (
        <StylingProfile
          stylingFor={stylingFor}
          customerProfile={customerProfile}
          isFirstProfileBeingCreated={isFirstProfileBeingCreated}
          onNext={onStepForward}
          renderFooter={({ formState: { isValid, isSubmitting } }) => (
            <>
              <StepFooter
                onBack={handleStylingProfileGoBack}
                disableNext={!isValid}
                isSubmitting={isSubmitting}
                hideLogin
                stepsCount={stepsCount}
                currentStepIndex={currentStepIndex}
              />

              {showStylistMessage && (
                <StylistMessage
                  imageUrl={imageUrl}
                  name={name || 'Lorna'}
                  title={title}
                  message={subTitleStylingProfile}
                />
              )}
            </>
            // <StepFooter
            //   onBack={handleStylingProfileGoBack}
            //   disableNext={!isValid}
            //   isSubmitting={isSubmitting}
            //   hideLogin
            // />
          )}
        />
      )}
    </>
  );
};

import { FC } from 'react';
import styles from './ProgressBar.module.scss';
// import logo from '../../../../assets/images/logos/logo-mini.svg';

export interface ProgressBarProps {
  stepsCount: number;
  currentStepIndex: number;
}

const ProgressBar: FC<ProgressBarProps> = (props: ProgressBarProps) => {
  const { stepsCount, currentStepIndex } = props;

  const stepNumber = currentStepIndex + 1;
  const progressBarWidth = (100 / stepsCount) * stepNumber;

  return (
    <section className={styles.container}>
      <div className={styles.progressBar}>
        <div
          className={styles.progressLine}
          style={{ transform: `scaleX(${progressBarWidth / 100})` }}
        ></div>
        {/* <div className={styles.logo} style={{ transform: `translateX(100%)` }}>
          <img src={logo} alt="" />
        </div> */}
      </div>
      <div className={styles.stepNumber}>
        Page {stepNumber} of {stepsCount}
      </div>
    </section>
  );
};

export default ProgressBar;

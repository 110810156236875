import { ComponentType, FC, useEffect, useState } from 'react';
import { useStore } from '../../../../providers/useStore';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function withReloadOnClearStorage<TProps>(Component: ComponentType<TProps>) {
  const Wrapped: FC<TProps> = (props) => {
    const [mounted, setMounted] = useState(true);
    const { registrationFlowStore } = useStore();

    useEffect(() => {
      if (!mounted) {
        setMounted(true);
      }
    }, [mounted]);

    useEffect(() => {
      const handler = function (event: StorageEvent): void {
        if (event.key === null && event.newValue === null) {
          registrationFlowStore.reset();
          setMounted(false);
        }
      };

      window.addEventListener('storage', handler);

      return () => {
        window.removeEventListener('storage', handler);
      };
    }, [registrationFlowStore]);
    return <>{mounted && <Component {...props} />}</>;
  };

  return Wrapped;
}

import { AxiosInstance } from 'axios';
import { CreateAddressPayload, SavedAddress } from '../dataTypes';
import {
  makeDeleteAddressUrl,
  makeGetAddressListUrl,
  makePostAddressUrl,
  makeSetDefaultAddressUrl,
} from '../consts';

/* istanbul ignore file */

export class AddressApiService {
  constructor(private readonly axiosInstance: AxiosInstance) {}

  async getList(): Promise<SavedAddress[]> {
    const url = makeGetAddressListUrl();
    const response = await this.axiosInstance.get<SavedAddress[]>(url);
    return response.data;
  }

  async create(payload: CreateAddressPayload): Promise<SavedAddress> {
    const url = makePostAddressUrl();
    const response = await this.axiosInstance.post<SavedAddress>(url, payload);
    return response.data;
  }

  async setDefault(id: string): Promise<void> {
    const url = makeSetDefaultAddressUrl(id);
    await this.axiosInstance.post(url);
  }

  async delete(id: string): Promise<void> {
    const url = makeDeleteAddressUrl(id);
    await this.axiosInstance.delete(url);
  }
}

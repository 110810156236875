import { FC, useEffect, useRef, useState } from 'react';
import { useAnchor } from './useAnchor';
import styles from './LandingPage.module.scss';
import FirstScreen from './components/FirstScreen';
import Header from '../../components/Header';
import HowItWorks from './components/HowItWorks/HowItWorks';
import VideoBanner from './components/VideoBanner';
import { CookieConsent } from 'components/CookieConsent';
import { useAnalytics } from 'analytics';
import * as consentConstants from '../../consentConstants';
import Footer from 'components/Footer';
import BoxSamplesSlider from './components/BoxSamplesSlider';
import TrustedShopsReviews from './components/TrustedShopsReviews';
import AdvantagesSlider from 'components/AdvantagesSlider';
import BarometerSlider from './components/BarometerSlider';

const LandingPage: FC = /* istanbul ignore next */ () => {
  useAnchor();
  const stylingButtonsRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const { loadScripts } = useAnalytics();

  const [showNavButtons, setShowNavButtons] = useState(false);

  useEffect(() => {
    const handleScroll = (): void => {
      if (!stylingButtonsRef.current || !headerRef.current) return;

      const { top, height: buttonsHeight } = stylingButtonsRef.current.getBoundingClientRect();
      const { height: headerHeight } = headerRef.current.getBoundingClientRect();

      if (top <= headerHeight - buttonsHeight) {
        setShowNavButtons(true);
      } else {
        setShowNavButtons(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className={styles.header}>
        <Header showNavButtons={showNavButtons} headerRef={headerRef} />
      </div>
      <main className={styles.main}>
        <FirstScreen stylingButtonsRef={stylingButtonsRef} />
        <HowItWorks />
        <AdvantagesSlider />
        <BarometerSlider />
        <VideoBanner />
        <TrustedShopsReviews />
        <BoxSamplesSlider />
        <Footer />
      </main>
      <CookieConsent
        cookieName={consentConstants.COOKIE_NAME}
        cookieValue={consentConstants.ACCEPTED_VALUE}
        onAccept={loadScripts}
      />
    </>
  );
};

export default LandingPage;

import { FC } from 'react';
import { TitleMain } from '../../components/TitleMain';
import styles from './About.module.scss';
import image1 from '../../assets/images/about/image-1.webp';
import image2 from '../../assets/images/about/image-2.webp';

const About: FC = () => {
  return (
    <>
      <TitleMain title="about us" />
      <div className={styles.content}>
        <div className={styles.imagesContainer}>
          <img src={image1} alt="" className={styles.image} />
          <img src={image2} alt="" className={styles.image} />
        </div>
        <div className={styles.text}>
          <h2>At Choixce, we believe in trust</h2>
          <p>
            We know that you will love our service and we truly believe ‘what goes around comes
            around’. So it’s through this strong faith in our loyal customers that we trust you to
            make a fair choixce at checkout.
          </p>
          <p>
            We believe that ‘fast fashion’ should be a thing of the past and we only ever want to
            operate Choixce fairly and sustainably, so the fairer the price, the more support that
            we can offer our charity partners.
          </p>
          <h2>We want to change the world of online fashion</h2>
          <p>
            We understand that the “Try-before-you-buy” aspect of shopping in store has never been
            truly re-created online. We wanted you to have that experience and take it one step
            further by letting you, our loyal customer, pay what you want.
          </p>
          <p>
            By combining the convenience of technology with the experience of our expert stylists,
            we are able to curate a style that is truly personalised to you.
          </p>
          <p>And the price? Trust us, it’s your Choixce.</p>
        </div>
      </div>
    </>
  );
};

export default About;

import { FC } from 'react';

export interface WhiteLogoProps {
  className?: string;
}

export const WhiteLogo: FC<WhiteLogoProps> = (props: WhiteLogoProps) => {
  const { className } = props;
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.214 16.6582C14.0941 15.549 12.9583 14.4236 11.8402 13.3165C12.417 13.0309 12.8883 12.5713 13.1836 12.0059C14.2895 13.0998 15.4228 14.2208 16.558 15.3436C16.1181 15.7738 15.6578 16.2241 15.214 16.6582ZM15.2414 4.71864C15.6769 5.14705 16.1294 5.59283 16.5578 6.01434C15.4414 7.11079 14.3184 8.21387 13.2046 9.30801C12.9238 8.74881 12.4745 8.28668 11.9188 7.99187C13.0137 6.91331 14.1268 5.81687 15.2414 4.71864ZM5.80972 16.6681C5.35704 16.2206 4.89338 15.762 4.44223 15.316C5.54889 14.2254 6.67114 13.1195 7.78163 12.0251C8.08767 12.6012 8.57765 13.0648 9.17466 13.3446C8.05727 14.4484 6.92685 15.5648 5.80972 16.6681ZM5.81994 4.73167C6.89058 5.79056 7.99136 6.87934 9.09036 7.96658C8.51021 8.26036 8.03965 8.7322 7.75046 9.30929C6.65402 8.22741 5.54787 7.13582 4.46037 6.06237C4.90257 5.62962 5.36802 5.17413 5.81994 4.73167ZM10.5 0C4.70127 0 0 4.70076 0 10.5003C0 16.299 4.70127 21 10.5 21C16.299 21 21 16.299 21 10.5003C21 4.70076 16.299 0 10.5 0Z"
        fill="#FEFEFE"
      />
    </svg>
  );
};

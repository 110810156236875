import { FC, useState } from 'react';
import { PaymentMethod, PurchaseTotals } from 'dataTypes';
import { getCurrencyString } from 'utils';
import { OrderPaymentMethod } from '../../OrderPaymentMethod';
import styles from './DirectPayment.module.scss';
import { Button } from 'components/Button';
import { WhiteLogo } from 'components/Icons';
import visaLogo from 'assets/images/icons/visa.svg';
import mastercardLogo from 'assets/images/icons/mastercard.svg';

interface Props {
  paymentMethods: PaymentMethod[];
  purchaseTotals: PurchaseTotals;
  creditAmount: number;
  onPay(type: string, paymentMethod?: PaymentMethod): void;
}

const getPayNowText = (sellPrice: number): string => {
  const formattedSellPrice = getCurrencyString(Math.abs(sellPrice));

  return sellPrice >= 0
    ? `PAY NOW FOR ${formattedSellPrice}`
    : `GET YOUR ${formattedSellPrice} REFUND`;
};

export const DirectPayment: FC<Props> = (props: Props) => {
  const { paymentMethods, purchaseTotals, creditAmount, onPay } = props;

  const [selectedPaymentMethod, selectPaymentMethod] = useState(() => {
    const result = paymentMethods.find((i) => i.isDefault);
    return result ?? paymentMethods[0];
  });

  const payNowButtonText = getPayNowText(purchaseTotals.sellPrice - creditAmount);
  const formattedCreditAmount = creditAmount ? getCurrencyString(creditAmount) : undefined;

  return (
    <>
      <OrderPaymentMethod
        paymentMethods={paymentMethods}
        selectedPaymentMethod={selectedPaymentMethod}
        onSelectPaymentMethod={selectPaymentMethod}
      />
      <div className={styles.wrapper}>
        <div className={styles.buttonsWrapper}>
          <Button
            className={styles.button}
            size="small"
            onClick={() => onPay('card', selectedPaymentMethod)}
            data-testid="pay-button"
            disabled={purchaseTotals.sellPrice <= 0}
          >
            <WhiteLogo className={styles.button_image} />
            {payNowButtonText}
          </Button>
        </div>

        <div className={styles.infoWrapper}>
          <div className={styles.box}>
            <div className={styles.icons}>
              <img src={visaLogo} alt="visa" className={styles.icon} />
              <img src={mastercardLogo} alt="mastercard" className={styles.icon} />
            </div>
          </div>
        </div>

        {formattedCreditAmount && (
          <>
            <strong>Your deposit of {formattedCreditAmount}</strong> has been deducted from the
            payment amount.
          </>
        )}
      </div>
    </>
  );
};

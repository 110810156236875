import { FC, useMemo, useState, useContext } from 'react';
import { SelectableImage } from '../../components';
import StepFooter from '../../components/StepFooter';
import styles from './ClothingCategoriesStep.module.scss';
import { StepComponentProps } from '../../StepComponentProps';
import { StylingFor } from '../../../../dataTypes';
import {
  categoriesKidsImageAnswerOptions,
  categoriesMenImageAnswerOptions,
} from '../../../../stores';
import { useSetHeader } from 'pages/RegistrationFlow/hooks/useSetHeader';
import { Checkbox } from 'components';
import StylistMessage from 'components/StylistMessage/StylistMessage';
import RegistrationFlowContext from 'providers/RegistrationFlowContext';

export const clothingCategoriesStepTitle = 'Please select the items you’re looking for';
export const subTitle = 'Select one or more and press next to continue';

export const ClothingCategoriesStep: FC<StepComponentProps> = ({
  imageUrl,
  name,
  orderDraft,
  stepsCount,
  currentStepIndex,
  onChange,
  onStepBack,
  onStepForward,
}: StepComponentProps) => {
  useSetHeader({
    title: clothingCategoriesStepTitle,
    subTitle: subTitle,
  });

  const clothingCategoriesAnswers = useMemo(
    () =>
      orderDraft.stylingFor === StylingFor.Men
        ? categoriesMenImageAnswerOptions
        : categoriesKidsImageAnswerOptions,
    [orderDraft.stylingFor],
  );

  const [selectedClothingCategories, setSelectedClothingCategories] = useState<string[]>(
    orderDraft.clothingCategories,
  );

  const selectClothingCategory = (title: string, isSelected: boolean): void => {
    let newSelectedClothingCategories = [...selectedClothingCategories];

    if (isSelected) {
      newSelectedClothingCategories.push(title);
    } else {
      newSelectedClothingCategories = newSelectedClothingCategories.filter(
        (category) => category !== title,
      );
    }

    setSelectedClothingCategories(newSelectedClothingCategories);
  };

  const selectAllClothingCategories = (isSelected: boolean): void => {
    const newSelectedClothingCategories = isSelected
      ? clothingCategoriesAnswers.map((answer) => answer.title)
      : [];

    setSelectedClothingCategories(newSelectedClothingCategories);
  };

  const isAllCategoriesSelected = clothingCategoriesAnswers.every((answer) =>
    selectedClothingCategories.includes(answer.title),
  );

  const { header } = useContext(RegistrationFlowContext).values;
  const showProgressBar = currentStepIndex + 1 <= stepsCount;
  const showStylistMessage = showProgressBar && !header.isHidden;

  const onNext = (): void => {
    onChange({
      clothingCategories: selectedClothingCategories,
    });

    onStepForward();
  };

  return (
    <>
      <StepFooter
        onBack={onStepBack}
        onNext={onNext}
        disableNext={!selectedClothingCategories.length}
        selectMessage
        stepsCount={stepsCount}
        currentStepIndex={currentStepIndex}
      />

      {showStylistMessage && (
        <StylistMessage
          imageUrl={imageUrl}
          name={name || 'Lorna'}
          title={clothingCategoriesStepTitle}
          message={subTitle}
        />
      )}

      <div className={styles.container}>
        <div className={styles.selectWrapper}>
          <Checkbox
            checked={isAllCategoriesSelected}
            label="I want it all"
            data-testid="i-want-it-all"
            onChange={(e) => selectAllClothingCategories(e.target.checked)}
          />
        </div>

        <section className={styles.list}>
          {clothingCategoriesAnswers.map((category) => {
            return (
              <SelectableImage
                key={category.title}
                title={category.title}
                imageUrl={category.imageUrlHover}
                imageUrlHover={category.imageUrl}
                imageBadge={category.imageBadge}
                isSelected={selectedClothingCategories.includes(category.title)}
                buttonClassName={styles.item}
                onSelect={selectClothingCategory}
                data-testid="selectable-image"
              />
            );
          })}
        </section>
      </div>

      {/* <StepFooter
        onBack={onStepBack}
        onNext={onNext}
        disableNext={!selectedClothingCategories.length}
      /> */}
    </>
  );
};

import { useEffect } from 'react';
import { useStore } from '../../../providers/useStore';
import { Cache } from './useReturnToLastVisitedStep';
import { getStepIndex, stepsCount } from '../registrationFlowSteps';

export const useSaveLastVisitedStep = (currentStep: string): void => {
  const { registrationFlowStore } = useStore();
  useEffect(() => {
    // don't save the last step
    if (getStepIndex(currentStep) === stepsCount) {
      return;
    }
    registrationFlowStore.setCache<Cache>({
      lastVisitedStep: currentStep,
    });
  }, [currentStep, registrationFlowStore]);
};

import { useCallback, useMemo } from 'react';
import { pick } from '../../../../../utils/pick';
import { useStore } from '../../../../../providers/useStore';
import { CustomerProfile } from '../../../../../dataTypes';

export type FormState = Pick<CustomerProfile, 'firstName' | 'lastName' | 'email'>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function useStoreOperations(customerProfileFromApi?: CustomerProfile) {
  const {
    registrationFlowStore,
    registrationFlowStore: { order },
  } = useStore();

  const defaultValues = useMemo<FormState>(() => {
    const anyFieldsSpecified =
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      order && (order.firstName || order.lastName || order.email);

    if (anyFieldsSpecified) {
      return pick(order, ['firstName', 'lastName', 'email']) as FormState;
    }

    if (customerProfileFromApi) {
      return pick(customerProfileFromApi, ['firstName', 'lastName', 'email']) as FormState;
    }

    return {} as FormState;
  }, [order, customerProfileFromApi]);

  const patchStore = useCallback(
    (formState: FormState): void => {
      registrationFlowStore.patch(formState);
    },
    [registrationFlowStore],
  );

  const submitFormState = useCallback(
    (formState: FormState) => {
      patchStore(formState);
    },
    [patchStore],
  );

  return {
    defaultValues,
    patchStore,
    submitFormState,
  };
}

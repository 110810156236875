import { AxiosInstance } from 'axios';
import dayjs from 'dayjs';
import {
  makeGetOrderListUrl,
  makeGetOrderUrl,
  makeGetOrderShipmentUrl,
  makePostOrderCommentUrl,
  makePostOrderReturnLinkUrl,
  makeGetOrderReturnUrl,
  makePostLastCompletedOrderUrl,
  makePostMysteryOrder,
  makePostBookChatForOrder,
  makePostOrderUrl,
  makeGetPaginatedOrderListUrl,
  makeGetReviewLinkUrl,
  makeGetCreditListUrl,
} from '../consts';
import {
  Credit,
  OrderComments,
  OrderReturn,
  OrderReturnLink,
  OrderStatus,
  Page,
  ReviewLink,
  ShipmentInfoModel,
  SubmittedOrder,
} from '../dataTypes';

interface SubmittedOrderListResponse {
  orders: SubmittedOrder[];
}

export class OrderApiService {
  constructor(private readonly axiosInstance: AxiosInstance) {}

  async createOrder(id: string): Promise<SubmittedOrder> {
    const url = makePostOrderUrl();
    const response = await this.axiosInstance.post<SubmittedOrder>(url, { orderDraftId: id });
    return response.data;
  }

  async createOrderComments(id: string, orderComments: OrderComments): Promise<boolean> {
    const url = makePostOrderCommentUrl(id);
    const response = await this.axiosInstance.post(url, orderComments);

    return response.status === 200;
  }

  async repeatLastCompletedOrder(stylingProfileId: string): Promise<void> {
    const url = makePostLastCompletedOrderUrl();
    await this.axiosInstance.post(url, { stylingProfileId });
  }

  async createMysteryOrder(stylingProfileId: string): Promise<void> {
    const url = makePostMysteryOrder();
    await this.axiosInstance.post(url, { stylingProfileId });
  }

  async bookChatForOrder(stylingProfileId: string, comments: string): Promise<void> {
    const url = makePostBookChatForOrder();
    await this.axiosInstance.post(url, { stylingProfileId, customerComments: comments });
  }

  async getOrderList(): Promise<SubmittedOrder[]> {
    const url = makeGetOrderListUrl();
    const response = await this.axiosInstance.get<SubmittedOrderListResponse>(url);
    return response.data.orders;
  }

  async getPaginatedOrderList(
    offset: number,
    size: number,
    statuses: string[],
  ): Promise<Page<SubmittedOrder>> {
    const url = makeGetPaginatedOrderListUrl();

    const response = await this.axiosInstance.get<Page<SubmittedOrder>>(url, {
      params: {
        offset,
        size,
        statuses,
      },
    });

    return {
      items: response.data.items.map((order) => this.prepareOrder(order)),
      total: response.data.total,
    };
  }

  private prepareOrder = (order: SubmittedOrder): SubmittedOrder => ({
    ...order,
    createdDate: dayjs(order.createdDate),
    status: OrderStatus[order.status as keyof typeof OrderStatus],
  });

  async getOrder(id: string): Promise<SubmittedOrder> {
    const url = makeGetOrderUrl(id);
    const response = await this.axiosInstance.get<SubmittedOrder>(url);
    return response.data;
  }

  async getOrderShipment(id: string): Promise<ShipmentInfoModel> {
    const url = makeGetOrderShipmentUrl(id);
    const response = await this.axiosInstance.get<ShipmentInfoModel>(url);
    return response.data;
  }

  async getOrderReturn(orderId: string): Promise<OrderReturn> {
    const url = makeGetOrderReturnUrl(orderId);
    const response = await this.axiosInstance.get<OrderReturn>(url);
    return response.data;
  }

  async createOrderReturnLink(id: string): Promise<string> {
    const url = makePostOrderReturnLinkUrl(id);
    const response = await this.axiosInstance.post<OrderReturnLink>(url);
    return response.data.link;
  }

  async getReviewLink(id: string): Promise<string> {
    const url = makeGetReviewLinkUrl(id);
    const response = await this.axiosInstance.get<ReviewLink>(url);
    return response.data.link;
  }

  async getCreditList(id: string): Promise<Credit[]> {
    const url = makeGetCreditListUrl(id);
    const response = await this.axiosInstance.get<Credit[]>(url);
    return response.data;
  }
}

import { FC } from 'react';
import { Loading } from '../../../../components/Loading';
import styles from './OrderLoading.module.scss';

export const OrderLoading: FC = () => {
  return (
    <div className={styles.orderLoading}>
      <Loading isBlack={true} />
    </div>
  );
};

import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import dayjs from 'dayjs';
import styles from './CongratulationsStep.module.scss';
import { TitleMain } from '../../../../components/TitleMain';
import { CardStep } from '../../../../components/CardStep';
import { ORDER_VIEW_URL } from '../../../../routePaths';
import { useOrder } from '../../../../serverCache/orders';
import { Loading } from '../../../../components/Loading';
import { useCreatedOrderId } from './useCreatedOrderId';
import { generatePath } from 'react-router';
import { SubmittedOrder } from '../../../../dataTypes';
import { useAnalytics } from '../../../../analytics';
import { useSetHeader } from 'pages/RegistrationFlow/hooks/useSetHeader';

export const congratulationsStepTitle = 'Congratulations!';

function formatDayRelativeToNow(date: string): string {
  const now = dayjs().startOf('day');
  const destDate = dayjs(date, 'DD/MM/YYYY');
  const diffInDays = destDate.diff(now, 'days');

  if (diffInDays === 0) {
    return 'today';
  } else if (diffInDays === 1) {
    return 'tomorrow';
  } else {
    return `on ${destDate.format('dddd')}`;
  }
}

function makeContactDescription(order: SubmittedOrder): string {
  let communicationTime = 'soon';

  const { communicationMethod, communicationDateTime } = order;

  if (
    communicationMethod !== 'email' &&
    communicationDateTime &&
    !!communicationDateTime.date &&
    !!communicationDateTime.time
  ) {
    const formattedDay = formatDayRelativeToNow(communicationDateTime.date);
    communicationTime = `${formattedDay} at ${communicationDateTime.time}`;
  }

  return `Our stylist will ${communicationMethod} you ${communicationTime} to confirm your order and clarify the details.`;
}

export const CongratulationsStep: FC = () => {
  useSetHeader({ title: congratulationsStepTitle });

  const { scriptsLoaded, fbTrack } = useAnalytics();

  useEffect(() => {
    scriptsLoaded && fbTrack('CompleteRegistration');
  }, [scriptsLoaded, fbTrack]);

  const orderId = useCreatedOrderId();

  const { data: order, isLoading } = useOrder(orderId);
  const description = order ? makeContactDescription(order) : ' ';

  return (
    <div className={styles.container}>
      <TitleMain title={congratulationsStepTitle} description={description} />
      <div className={styles.cardStepContainer}>
        <CardStep
          number={1}
          image={<div className={classNames(styles.icon, styles.complete)} />}
          title="Complete Your Style Profile"
          visited
        />
        <CardStep
          number={2}
          image={<div className={classNames(styles.icon, styles.chat)} />}
          title="Chat To Your Stylist"
        />
        <CardStep
          number={3}
          image={<div className={classNames(styles.icon, styles.clothes)} />}
          title="Try Everything On"
        />
        <CardStep
          number={4}
          image={<div className={classNames(styles.icon, styles.credit)} />}
          title="Pay What You Want"
        />
        <CardStep
          number={5}
          image={<div className={classNames(styles.icon, styles.return)} />}
          title="Return For Free"
        />
        <CardStep
          number={6}
          image={<div className={classNames(styles.icon, styles.order)} />}
          title="Order Next Box in One Click"
        />
      </div>

      {isLoading && (
        <div className={styles.loadingContainer}>
          <Loading isBlack />
        </div>
      )}
      {!isLoading && order && (
        <div className={styles.summary}>
          <ul className={styles.box}>
            <li>Order summary</li>
          </ul>
          <ul className={classNames(styles.box, styles.fontMedium)}>
            <li>
              Styling for {order.stylingProfile.firstName} {order.stylingProfile.lastName}
            </li>
            <li>{order.intention}</li>
            <li>{order.clothingCategories.join(', ')}</li>
          </ul>
        </div>
      )}

      <div className={styles.thanks} data-testid={'thank-you'}>
        Track the status of your order in
        <Link to={generatePath(ORDER_VIEW_URL, { orderId })} className={styles.link}>
          your profile
        </Link>
      </div>
    </div>
  );
};

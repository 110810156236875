import { FC } from 'react';

interface ArrowIconProps {
  className?: string;
}

export const ArrowIcon: FC<ArrowIconProps> = (props: ArrowIconProps) => {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M1 1L6.5 7L12 1" stroke="#0D0D0D" />
    </svg>
  );
};

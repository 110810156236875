import { OrderDraft, StylingFor } from '../../dataTypes';

export const defaultMenRegistrationData: OrderDraft = {
  stylingFor: StylingFor.Men,
  intention: '',
  clothingCategories: [],
  stylingProfile: {
    dateOfBirth: undefined,
    gender: 'Male',
    firstName: '',
    lastName: '',
    questionnaire: [],
    questionnaireComments: '',
    systemOfMeasure: 'Imperial',
  },
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  communicationMethod: 'call',
  communicationDateTime: {},
};

import { FC } from 'react';

interface PlayProps {
  className?: string;
}

export const Play: FC<PlayProps> = (props: PlayProps) => {
  return (
    <div className={props.className}>
      <svg
        width="10"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.5 6L0.5 12L0.500001 -3.93402e-07L9.5 6Z" fill="white" />
      </svg>
    </div>
  );
};

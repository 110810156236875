import { useService } from '../../providers/useService';
import { useQuery, UseQueryResult } from 'react-query';
import { OrderStatus, Page, SubmittedOrder } from '../../dataTypes';
import { getEnumKeyByValue } from '../../utils';

/* istanbul ignore file */
const activeStatuses = [
  getEnumKeyByValue(OrderStatus, OrderStatus.Submitted),
  getEnumKeyByValue(OrderStatus, OrderStatus.ReadyForPicking),
  getEnumKeyByValue(OrderStatus, OrderStatus.Dispatched),
  getEnumKeyByValue(OrderStatus, OrderStatus.Delivered),
  getEnumKeyByValue(OrderStatus, OrderStatus.DeliveryOverdue),
  getEnumKeyByValue(OrderStatus, OrderStatus.PendingPayment),
  getEnumKeyByValue(OrderStatus, OrderStatus.PendingReturn),
];

interface PageOptions {
  pageNumber: number;
  pageSize: number;
}

export function useActiveOrderList(pageOptions: PageOptions): UseQueryResult<Page<SubmittedOrder>> {
  const { apiService } = useService();

  return useQuery(['activeOrderList', pageOptions], async () => {
    const { pageNumber, pageSize } = pageOptions;

    const ordersResponse = await apiService.getPaginatedOrderList(
      (pageNumber - 1) * pageSize,
      pageSize,
      activeStatuses,
    );

    return ordersResponse;
  });
}

import { FC } from 'react';
import styles from './OrderList.module.scss';
import { Title } from 'components/Title';
import { useStore } from 'providers/useStore';
import { ProfileBack } from 'components/ProfileBack';
import { PROFILE_URL } from 'routePaths';
import OrderListItem from 'components/OrderListItem';

const OrderList: FC = () => {
  const { orderListStore } = useStore();

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!orderListStore.orderList) {
    return <></>;
  }

  return (
    <div className={styles.orderList}>
      <ProfileBack to={PROFILE_URL} name="profile" />
      <Title
        title="My orders"
        subtitle="Monitor your order transactions here."
        align="center"
        backgroundTitle="My profile"
      />
      {orderListStore.orderList.map((order) => (
        <OrderListItem key={order.id} order={order} />
      ))}
    </div>
  );
};

export default OrderList;

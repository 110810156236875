import { FC, useCallback, useState } from 'react';
import { OrderMessage } from '../../../../OrderMessage';
import styles from './ReturnInfo.module.scss';
import { Option } from 'components/Select';
import { LinkButton } from 'components/Button';
import { OrderReturn, ReturnItem, ReturnType } from 'dataTypes';
import DetailedItem from 'pages/Orders/components/DetailedItem';
import { useSetItemReturnReason } from './useSetItemReturnReason';

interface Props {
  orderReturn: OrderReturn | null;
}

const errorMessage =
  'Oops! We were unable to connect with our partner to perform a return. ' +
  'Please try again a bit later or contact your stylist if problem persists.';

export const partialReturnMessage =
  'Please select reason, click the button and follow the instructions.';

export const fullReturnMessage =
  "We are sorry that we couldn't fulfill your needs this time. " +
  'Please let us know if we can be of any assistance for a future order. ' +
  'Click the button below to return your items.';

export const getPartialReturnMessage = (itemsCount: number): string => {
  const itemText = itemsCount === 1 ? 'item' : 'items';
  return `You have ${itemsCount} ${itemText} to return. Please select reason, click the button and follow the instructions.`;
};

export const returnReasons = [
  'Too big',
  'Too small',
  'Put off by RRP',
  "Don't need it anymore",
  "Hate it / Doesn't Suit me",
  'Poor Quality / Faulty',
  'Arrived damaged',
];

const getReturnReasonOptions = (): Option[] => {
  const reasons = returnReasons.map((reason) => {
    return { value: reason, label: reason };
  });

  reasons.unshift({ value: '', label: 'Select reason' });

  return reasons;
};

export const ReturnInfo: FC<Props> = ({ orderReturn }) => {
  const [returnItems, setReturnItems] = useState<ReturnItem[]>(orderReturn?.items || []);
  const { mutateAsync: setItemReturnReason } = useSetItemReturnReason(orderReturn?.id || '');

  const onReasonChange = useCallback(
    async (itemId: string, returnReason: string) => {
      await setItemReturnReason({ itemId, returnReason });
      const newItems = returnItems.map((item) => {
        if (itemId === item.id) {
          item.returnReason = returnReason;
        }

        return item;
      });

      setReturnItems(newItems);
    },
    [returnItems, setItemReturnReason],
  );

  return (
    <>
      {!orderReturn && <OrderMessage messageText={errorMessage} />}
      {orderReturn && (
        <OrderMessage
          messageText={
            <>
              {orderReturn.type === ReturnType.Full
                ? fullReturnMessage
                : getPartialReturnMessage(orderReturn.items.length)}
            </>
          }
          data-testid="message"
        >
          <div className={styles.itemsWrapper}>
            {orderReturn.items.map((item) => (
              <DetailedItem
                key={item.id}
                type="return"
                category={item.websiteCategory || item.category}
                brand={item.brand}
                size={item.size}
                price={item.choixcePrice}
                selectedReason={item.returnReason}
                returnReasons={getReturnReasonOptions()}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onReasonChange={(reason: string) => onReasonChange(item.id, reason)}
              />
            ))}
          </div>
          <LinkButton
            className={styles.button}
            href={orderReturn.link}
            target="_blank"
            rel="noreferrer"
          >
            Return
          </LinkButton>
        </OrderMessage>
      )}
    </>
  );
};

import classNames from 'classnames';
import { Option, Select } from 'components/Select';
import { FC } from 'react';
import { getIconPath } from 'utils/itemIcons';
import styles from './DetailedItem.module.scss';

interface Props {
  type: 'base' | 'selectable' | 'return';
  category: string;
  brand: string;
  size: string;
  price: number;
  isSelected?: boolean;
  selectedReason?: string;
  returnReasons?: Option[];
  onSelect?(): void;
  onReasonChange?(reason: string): void;
}

const DetailedItem: FC<Props> = ({
  type,
  category,
  brand,
  size,
  price,
  isSelected,
  selectedReason,
  returnReasons,
  onSelect,
  onReasonChange,
}) => {
  return (
    <div
      className={classNames({
        [styles.item]: true,
        [styles.itemActive]: isSelected,
        [styles.selectable]: type === 'selectable',
        [styles.return]: type === 'return',
      })}
      onClick={onSelect}
    >
      <img src={getIconPath(category)} alt={category} className={styles.image} />
      <div className={styles.box}>
        <div className={styles.info}>
          <div className={styles.title}>{brand}</div>
          <div className={styles.text}>
            {category}, Size: {size}
          </div>
        </div>
      </div>
      {type !== 'return' && <div className={styles.price}>£{price}</div>}
      {type === 'selectable' && <div className={styles.checkbox}></div>}
      {type === 'return' && (
        <Select
          name="returnReasons"
          className={styles.select}
          onChange={(e) => onReasonChange?.(e.target.value)}
          value={selectedReason}
          items={returnReasons || []}
        />
      )}
    </div>
  );
};

export default DetailedItem;

import { ComponentType, FC } from 'react';
import { MainLayout } from './MainLayout';

export const withMainLayout = <P extends Record<string, unknown>>(
  Component: ComponentType<P>,
  Submenu?: JSX.Element,
): FC<P> => {
  return function WithMainLayout(props: P): JSX.Element {
    return (
      <MainLayout subMenu={Submenu}>
        <Component {...props} />
      </MainLayout>
    );
  };
};

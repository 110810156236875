import { ComponentProps, forwardRef } from 'react';
import styles from './IconButton.module.scss';
import classNames from 'classnames';

type Props = ComponentProps<'button'>;

export const IconButton = forwardRef<HTMLButtonElement, Props>(function IconButton(props, ref) {
  const { className, ...forwardedProps } = props;
  return <button {...forwardedProps} className={classNames(styles.base, className)} ref={ref} />;
});

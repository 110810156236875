import { FC } from 'react';
import styles from './StylistMessage.module.scss';
import stylistImage from '../../assets/images/stylists/stylist.webp';
import classNames from 'classnames';

interface StylistMessageProps {
  imageUrl?: string;
  name?: string;
  title: string;
  message?: JSX.Element | string;
  noPaddingBottom?: boolean;
}

const StylistMessage: FC<StylistMessageProps> = ({
  imageUrl,
  name,
  title,
  message,
  noPaddingBottom,
}: StylistMessageProps) => {
  return (
    <div
      className={classNames({
        [styles.wrapper]: !noPaddingBottom,
        [styles.stylistWithoutMessage]: !message,
      })}
    >
      <div className={styles.container}>
        <div className={styles.box}>
          <div className={styles.stylist}>
            <div className={styles.stylistImageWrapper}>
              <img src={imageUrl || stylistImage} alt={name} className={styles.stylistImage} />
            </div>
            <div className={styles.stylistText}>
              <div className={styles.stylistName}>{name}</div>
              <div className={styles.stylistFashion}>Fashion Stylist</div>
            </div>
          </div>
          <div className={styles.info}>
            <div>
              {title && <h1 className={styles.title}>{title}</h1>}
              {message && (
                <div className={styles.message} data-testid="stylist-message">
                  {message}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StylistMessage;

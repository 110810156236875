import { AxiosInstance } from 'axios';
import { makePostOrderDraftUrl, makePutOrderDraftUrl } from '../consts';
import { OrderDraft, SavedOrderDraft } from '../dataTypes';

export class OrderDraftApiService {
  constructor(private readonly axiosInstance: AxiosInstance) {}

  async createOrderDraft(order: OrderDraft): Promise<SavedOrderDraft> {
    const url = makePostOrderDraftUrl();
    const response = await this.axiosInstance.post(url, order);

    return response.data as SavedOrderDraft;
  }

  async updateOrderDraft(id: string, order: OrderDraft): Promise<SavedOrderDraft> {
    const url = makePutOrderDraftUrl(id);
    const response = await this.axiosInstance.put(url, order);
    return response.data as SavedOrderDraft;
  }
}

import { useStore } from 'providers/useStore';
import { generatePath, useHistory } from 'react-router-dom';
import { ORDER_FLOW_PATH } from 'routePaths';
import { firstStep } from '../registrationFlowSteps';
import { useStylingFor } from './useStylingFor';

export const useStartFromFirstStep = (currentStep: string): void => {
  const { registrationFlowStore } = useStore();
  const stylingFor = useStylingFor().toLowerCase();
  const history = useHistory();

  if (!registrationFlowStore.isStarted && currentStep !== firstStep) {
    registrationFlowStore.setStarted();
    history.push(generatePath(ORDER_FLOW_PATH, { stylingFor, stylingStep: firstStep }));
  }
};

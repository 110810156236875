import { FC } from 'react';
import styles from './ItemList.module.scss';
import { OrderItem } from '../../../../dataTypes';
import DetailedItem from '../DetailedItem';
import { BriefItem } from '../BriefItem';

interface Props {
  title?: string;
  description?: string;
  showDetails?: boolean;
  items?: OrderItem[];
}

export const ItemList: FC<Props> = ({ showDetails, items, title, description }) => {
  if (!items) {
    return <></>;
  }

  return (
    <div>
      {title && <div className={styles.subtitle}>{title}</div>}
      {description && <div className={styles.description}>{description}</div>}
      {showDetails && (
        <>
          <div className={styles.itemsHeader}>Сhoixce price</div>
          <div>
            {items.map((item) => (
              <DetailedItem
                category={item.websiteCategory || item.category}
                brand={item.brand}
                size={item.size}
                price={item.choixcePrice}
                key={item.id}
                type={'base'}
              />
            ))}
          </div>
        </>
      )}
      {!showDetails && (
        <div>
          {items.map((item) => (
            <BriefItem item={item} key={item.id} />
          ))}
        </div>
      )}
    </div>
  );
};

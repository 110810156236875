import { AxiosError, AxiosInstance } from 'axios';
import {
  makeGetCustomerProfileUrl,
  makePatchCustomerProfileUrl,
  makePostCustomerProfileUrl,
} from '../consts';
import { CustomerProfile, PatchCustomerProfilePayload } from '../dataTypes';

export class CustomerProfileApiService {
  constructor(private readonly axiosInstance: AxiosInstance) {}

  async getCustomerProfile(): Promise<CustomerProfile | null> {
    try {
      const url = makeGetCustomerProfileUrl();
      const response = await this.axiosInstance.get<CustomerProfile>(url);
      return response.data;
    } catch (e) {
      const axiosError = e as AxiosError;

      if (axiosError.response?.status === 404) {
        return null;
      }

      throw e;
    }
  }

  async createCustomerProfile(payload: CustomerProfile): Promise<CustomerProfile> {
    const url = makePostCustomerProfileUrl();
    const response = await this.axiosInstance.post(url, payload);
    return response.data as CustomerProfile;
  }

  async patchCustomerProfile(
    payload: PatchCustomerProfilePayload,
  ): Promise<CustomerProfile | null> {
    try {
      const url = makePatchCustomerProfileUrl();
      const response = await this.axiosInstance.patch(url, payload);
      return response.data as CustomerProfile;
    } catch (e) {
      const axiosError = e as AxiosError;

      if (axiosError.response?.status === 404) {
        return null;
      }

      throw e;
    }
  }
}

import { FC } from 'react';
import { useLoadShipmentInfo } from './useLoadShipmentInfo';
import styles from './ShipmentInfo.module.scss';
import { OrderMessage } from '../../../../OrderMessage';
import { LinkButton } from '../../../../../../../../../components/Button';

interface Props {
  orderId: string;
}

export const ShipmentInfo: FC<Props> = (props: Props) => {
  const { orderId } = props;
  const { data, error } = useLoadShipmentInfo(orderId);

  return (
    <>
      {error && (
        <OrderMessage
          messageText={
            <>
              Oops! We were unable to retrieve your shipment tracking information. Please try again
              a bit later or contact your stylist if problem persists.
            </>
          }
        />
      )}
      {!error && (
        <OrderMessage
          messageText={
            <>
              Great news! You order is on its way.
              <br />
              To track its progress click the button below.
            </>
          }
        >
          <LinkButton
            href={data?.trackingLink}
            rel="noreferrer"
            target="_blank"
            className={styles.button}
            data-testid="tracking-link"
          >
            Track
          </LinkButton>
        </OrderMessage>
      )}
    </>
  );
};

import { SystemOfMeasure } from 'dataTypes';

interface MetricWeight {
  readonly systemOfMeasure: 'Metric';
  readonly kg: string;
}

interface ImperialWeight {
  readonly systemOfMeasure: 'Imperial';
  readonly stones: string;
  readonly pounds: string;
}

export type Weight = MetricWeight | ImperialWeight;

export function imperialWeightToString(stones: string, pounds: string): string {
  return `${stones ? stones + ' st' : ''}${pounds ? ', ' + pounds + ' lb' : ''}`; // e.g. `5 st, 4 lb`
}

export function stringToWeight(value?: string): Weight | undefined {
  if (!value) {
    return undefined;
  }

  const imperialRegexp = /^(?<stones>\d+)? st(, (?<pounds>\d+(\.\d+)?) lb)?$/; // parses `5 st, 4 lb` to { stones: '5', pounds: '4' }
  const imperialResult = imperialRegexp.exec(value);

  if (imperialResult) {
    return {
      systemOfMeasure: 'Imperial',
      stones: imperialResult.groups?.stones ?? '',
      pounds: imperialResult.groups?.pounds ?? '',
    };
  }

  const metricRegexp = /^(?<kg>\d+)$/; // parses `45` to { kg: '45' }
  const metricResult = metricRegexp.exec(value);

  if (metricResult && metricResult.groups) {
    return {
      systemOfMeasure: 'Metric',
      kg: metricResult.groups.kg,
    };
  }

  return undefined;
}

export function weightToString(
  weight: Weight | undefined,
  systemOfMeasure: SystemOfMeasure,
): string {
  if (!weight) {
    return '';
  }

  if (systemOfMeasure === 'Imperial') {
    const imperialWeight = weightAsImperial(weight);
    return imperialWeight
      ? imperialWeightToString(imperialWeight.stones, imperialWeight.pounds)
      : '';
  } else {
    const metricWeight = weightAsMetric(weight);
    return metricWeight ? metricWeight.kg : '';
  }
}

const KG_IN_POUNDS = 0.453592;
// 2.205 other way

export function weightAsMetric(weight: Weight | undefined): MetricWeight | undefined {
  if (weight?.systemOfMeasure === 'Metric') {
    return weight;
  } else if (weight?.systemOfMeasure === 'Imperial') {
    if (!weight.stones && !weight.pounds) {
      return undefined;
    }

    const stones = weight.stones ? Number.parseInt(weight.stones) : 0;
    const pounds = weight.pounds ? Number.parseFloat(weight.pounds) : 0;

    return {
      systemOfMeasure: 'Metric',
      kg: `${Math.round((stones * 14 + pounds) * KG_IN_POUNDS)}`,
    };
  }
}

export function weightAsImperial(weight: Weight | undefined): ImperialWeight | undefined {
  if (weight?.systemOfMeasure === 'Imperial') {
    return weight;
  } else if (weight?.systemOfMeasure === 'Metric') {
    if (!weight.kg) {
      return undefined;
    }

    const totalPounds = Number.parseFloat(weight.kg) / KG_IN_POUNDS;
    let stones = Math.floor(totalPounds / 14);
    let pounds = Math.round(totalPounds - stones * 14);
    if (pounds === 14) {
      stones++;
      pounds = 0;
    }

    return {
      systemOfMeasure: 'Imperial',
      stones: `${stones}`,
      pounds: pounds === 0 ? '' : `${pounds}`,
    };
  }
}

import { forwardRef } from 'react';
import classNames from 'classnames';
import styles from './Checkbox.module.scss';

/* istanbul ignore file */

type NativeInputProps = JSX.IntrinsicElements['input'];

interface AdditionalProps {
  label?: string;
}

type CheckboxProps = AdditionalProps & Omit<NativeInputProps, 'type'>;

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(props, ref) {
  const { label, className, id = 'checkbox', checked, ...inputProps } = props;

  return (
    <div className={styles.wrapper}>
      <input
        {...inputProps}
        checked={checked || false}
        id={id}
        className={classNames(styles.checkbox, className)}
        type="checkbox"
        ref={ref}
      />
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
});

import { FC } from 'react';

export const Check: FC = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="17" height="17" fill="white" />
      <path
        d="M8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16Z"
        stroke="#7c7c7c"
        strokeWidth="1.5"
      />
      <path
        d="M4.5 8.5L7.5 11.5L12.5 5.5"
        stroke="#7c7c7c"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

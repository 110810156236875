import { useHistory } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';
import { useEffect } from 'react';
import { CreateAuth0Lock } from '../services/CreateAuth0Lock';
import { getRedirectUrl } from './getRedirectUrl';
import { setChangePasswordRedirectUrl } from '../utils/changePasswordRedirect';
import { useCreateCustomerProfile, useCustomerProfile } from 'serverCache/customerProfile';
import { CommunicationMethod } from 'dataTypes';

export const useOnAuth = (allowSignUp: boolean): void => {
  const history = useHistory();
  const { setAuthResult } = useAuth();
  const { refetch: getCustomerProfile } = useCustomerProfile({ enabled: false });
  const { mutateAsync: createCustomerProfile } = useCreateCustomerProfile();

  useEffect(() => {
    const lock = CreateAuth0Lock({ allowSignUp });
    const url = getRedirectUrl();

    const authenticate = async (authResult: AuthResult): Promise<void> => {
      setAuthResult(authResult);

      // this will always return customer profile now, need to be changed in api
      const { data: customerProfile } = await getCustomerProfile();

      if (!customerProfile && authResult.idTokenPayload.email) {
        const payload = {
          email: authResult.idTokenPayload.email,
          firstName: '',
          lastName: '',
          phone: '',
          communicationMethod: 'call' as CommunicationMethod,
        };

        await createCustomerProfile(payload);
      }

      history.replace(url);
      lock.hide();
    };

    lock.show();
    lock.on('authenticated', (authResult) => void authenticate(authResult));
    lock.on('forgot_password submit', setChangePasswordRedirectUrl);
    return () => {
      lock.hide();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

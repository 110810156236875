import { ComponentProps, forwardRef } from 'react';
import { combineStyles, StylingProps } from './styling';

type NativeProps = ComponentProps<'textarea'>;

type Props = NativeProps & StylingProps;

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(function Textarea(props, ref) {
  const [className, inputProps] = combineStyles(props);

  return <textarea {...inputProps} className={className} ref={ref} />;
});

import { FC } from 'react';

export const MysteryBoxIcon: FC = () => {
  return (
    <svg width="72" height="60" viewBox="0 0 72 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8">
        <path
          d="M54.8791 25.167C54.8519 25.13 54.8295 25.0898 54.8124 25.0473C54.7339 24.9132 54.6349 24.7921 54.5191 24.6882C54.3764 24.5516 54.209 24.4433 54.0258 24.369L35.36 19.0494C35.1245 18.9835 34.8755 18.9835 34.64 19.0494L15.9742 24.369C15.8227 24.4136 15.6788 24.4809 15.5476 24.5685C15.4318 24.6724 15.3328 24.7935 15.2542 24.9276C15.2586 24.9674 15.2586 25.0075 15.2542 25.0473C15.1187 25.2412 15.0318 25.4646 15.0009 25.6989V52.2972C14.9898 52.5957 15.0798 52.8892 15.2564 53.1304C15.433 53.3717 15.6858 53.5466 15.9742 53.6271L34.64 58.9467C34.8747 59.0178 35.1253 59.0178 35.36 58.9467L54.0258 53.6271C54.3142 53.5466 54.567 53.3717 54.7436 53.1304C54.9202 52.8892 55.0102 52.5957 54.9991 52.2972V25.6989C54.9926 25.5157 54.9519 25.3353 54.8791 25.167ZM35 21.7092L48.8127 25.6989L35 29.6887L21.1873 25.6989L35 21.7092ZM17.6675 27.4677C23.4765 29.1191 26.0578 29.853 33.6667 32.016V55.848L17.6675 51.2997V27.4677ZM52.3325 51.2997L36.3333 55.848V32.016L52.3325 27.4677V51.2997Z"
          fill="black"
        />
        <path
          d="M35 1L35 4.4"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35 9.6001L35 13.0001"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41 7L37.6 7"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.3999 7L28.9999 7"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M68.4851 21.8284L67.3537 22.9597"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M63.9597 26.354L62.8283 27.4854"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M68.4851 27.4854L67.3537 26.354"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M63.9597 22.9597L62.8283 21.8283"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.48535 21.8284L7.35398 22.9597"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.95984 26.354L2.82847 27.4854"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.48535 27.4854L7.35398 26.354"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.95984 22.9597L2.82847 21.8283"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.284 44.616C25.188 44.616 25.104 44.58 25.032 44.508C24.96 44.436 24.924 44.352 24.924 44.256V43.68C24.984 43.116 25.158 42.624 25.446 42.204C25.746 41.772 26.166 41.28 26.706 40.728C27.102 40.344 27.402 40.014 27.606 39.738C27.81 39.462 27.93 39.18 27.966 38.892C28.014 38.4 27.852 38.01 27.48 37.722C27.108 37.422 26.658 37.272 26.13 37.272C24.954 37.272 24.246 37.854 24.006 39.018C23.922 39.294 23.748 39.432 23.484 39.432H21.954C21.834 39.432 21.738 39.396 21.666 39.324C21.594 39.24 21.558 39.132 21.558 39C21.582 38.34 21.78 37.722 22.152 37.146C22.524 36.57 23.064 36.108 23.772 35.76C24.48 35.4 25.308 35.22 26.256 35.22C27.252 35.22 28.068 35.382 28.704 35.706C29.352 36.018 29.82 36.42 30.108 36.912C30.408 37.404 30.558 37.914 30.558 38.442C30.558 39.054 30.42 39.582 30.144 40.026C29.88 40.458 29.478 40.962 28.938 41.538C28.554 41.958 28.254 42.318 28.038 42.618C27.834 42.906 27.696 43.218 27.624 43.554C27.576 43.854 27.546 44.058 27.534 44.166C27.438 44.466 27.276 44.616 27.048 44.616H25.284ZM25.23 48C25.11 48 25.008 47.958 24.924 47.874C24.84 47.79 24.798 47.688 24.798 47.568V46.002C24.798 45.882 24.84 45.78 24.924 45.696C25.008 45.6 25.11 45.552 25.23 45.552H26.976C27.108 45.552 27.216 45.594 27.3 45.678C27.384 45.762 27.426 45.87 27.426 46.002V47.568C27.426 47.688 27.384 47.79 27.3 47.874C27.216 47.958 27.108 48 26.976 48H25.23Z"
          fill="black"
        />
        <path
          d="M6 51L6 52.6"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 57.3999L6 58.9999"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 55L8.4 55"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.59998 55L1.99998 55"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M63 51L63 52.6"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M63 57.3999L63 58.9999"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M67 55L65.4 55"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M60.6001 55L59.0001 55"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

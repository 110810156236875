import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
  UseMutationResult,
} from 'react-query';
import { PaymentMethod } from '../dataTypes';
import { useAlert } from 'react-alert';
import { useService } from '../providers/useService';

export const PAYMENT_METHODS_KEY = 'paymentMethods';

export function usePaymentMethods(): UseQueryResult<PaymentMethod[]> {
  const { paymentApiService } = useService();

  return useQuery(PAYMENT_METHODS_KEY, async () => {
    return await paymentApiService.getPaymentMethods();
  });
}

export function useDeletePaymentMethod(id: string): UseMutationResult {
  const { paymentApiService } = useService();
  const client = useQueryClient();
  const { error } = useAlert();

  return useMutation(
    async () => {
      const confirmResult = window.confirm('Are you sure you want to delete this payment method?');
      if (!confirmResult) {
        return;
      }
      await paymentApiService.deletePaymentMethod(id);
    },
    {
      onSuccess: () => client.invalidateQueries(PAYMENT_METHODS_KEY),
      onError: () => {
        error(
          'Oops! We were unable to delete this payment method. Please try again a bit later or contact us if problem persists.',
        );
      },
    },
  );
}

export function useMakePaymentMethodDefault(id: string): UseMutationResult {
  const { paymentApiService } = useService();
  const client = useQueryClient();
  const { error } = useAlert();

  return useMutation(
    async () => {
      await paymentApiService.makePaymentMethodDefault(id);
    },
    {
      onSuccess: () => client.invalidateQueries(PAYMENT_METHODS_KEY),
      onError: () => {
        error(
          'Oops! We were unable to set this payment method as default one. Please try again a bit later or contact us if problem persists.',
        );
      },
    },
  );
}

export function useRefreshPaymentMethods(): () => void {
  const client = useQueryClient();

  const { mutateAsync } = useMutation(() => Promise.resolve(), {
    onSuccess: () => client.invalidateQueries(PAYMENT_METHODS_KEY),
  });

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  return mutateAsync;
}

import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './FirstScreen.module.scss';
import { generatePath, Link } from 'react-router-dom';
import { ORDER_FLOW_START_PATH } from '../../../../routePaths';
import classNames from 'classnames';
import poster from '../../../../assets/images/video/poster.webp';

interface FirstScreenProps {
  stylingButtonsRef?: React.RefObject<HTMLDivElement>;
}

export const landingTitle = 'Personal styling for Men & kids';

const FirstScreen: FC<FirstScreenProps> = observer(({ stylingButtonsRef }) => {
  /* istanbul ignore next */
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <p className={styles.text}>{landingTitle}</p>
        <p className={styles.text}>Designer brands</p>
        <h1 className={styles.title}>UP TO 70% OFF</h1>
        <h1 className={styles.actionPrompt}>Get Started</h1>
        <p className={styles.text}>complete your style profile below</p>
        <div className={styles.buttonWrapper} ref={stylingButtonsRef}>
          <Link
            to={generatePath(ORDER_FLOW_START_PATH, { stylingFor: 'men' })}
            className={classNames(styles.button, styles.buttonBlack)}
            data-testid="men-button"
          >
            style for men
          </Link>
          <Link
            to={generatePath(ORDER_FLOW_START_PATH, { stylingFor: 'kids' })}
            className={classNames(styles.button, styles.buttonBlack)}
          >
            style for kids
          </Link>
        </div>
      </div>

      <div className={styles.videoDesktop}>
        <video
          preload="none"
          loop
          autoPlay
          playsInline
          muted
          poster={poster}
          webkit-playsinline="webkit-playsinline"
          x5-playsinline="x5-playsinline"
        >
          <source src="https://choixce.azureedge.net/video/main.webm" type="video/webm" />
          <source src="https://choixce.azureedge.net/video/main.mp4" type="video/mp4" />
        </video>
      </div>

      <div className={styles.videoMobile}>
        <video
          preload="none"
          loop
          autoPlay
          playsInline
          muted
          poster={poster}
          webkit-playsinline="webkit-playsinline"
          x5-playsinline="x5-playsinline"
        >
          <source src="https://choixce.azureedge.net/video/mainm2.webm" type="video/webm" />
          <source src="https://choixce.azureedge.net/video/mainm2.mp4" type="video/mp4" />
        </video>
      </div>
    </section>
  );
});

export default FirstScreen;

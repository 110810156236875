import { FC } from 'react';
import styles from './BarometerSlider.module.scss';
import logo from '../../../../assets/images/logos/logo-mini.svg';
import classNames from 'classnames';

const BarometerSlider: FC = () => {
  return (
    <section className={styles.barometer}>
      <div className={styles.container}>
        <h2 className={styles.mainTitle}>How to Pay What You Want</h2>

        <div className={styles.text}>
          <p>
            Choixce.com is the first fashion retailer in the world to offer &quot;pay what you
            want&quot; as a payment option and we are extremely proud of this!!
          </p>
          <p>
            To gain access to this exclusive gold members only payment method, all you have to do is
            order and complete 4 boxes with us. This will then unlock the full sliding payment scale
            on your account, where you can select the price you pay
          </p>
        </div>

        <div className={styles.barometerInfo}>
          <div className={styles.info}>
            These are our lowest paying members, who paid what they wanted but may have their
            membership revoked.
          </div>
          <div className={styles.info}>
            This is what our members are paying on average this month
          </div>
          <div className={styles.info}>
            <span className={styles.important}>Gold member status</span>{' '}
            <span>
              Our gold members get access to exclusive luxury brands and first dibs on our new
              stock.
            </span>
          </div>
        </div>

        <div className={styles.barometerInfoMobile}>
          <div className={classNames(styles.info, styles.infoFirst)}>
            These are our lowest paying customers, who paid what they wanted but will have their
            memberships revoked.
          </div>
          <div className={styles.infoWrapper}>
            <div className={classNames(styles.info, styles.infoCenter)}>
              This is what our members are paying on average this month
            </div>
            <div className={styles.infoBox}>
              <div className={styles.info}>
                <span className={styles.important}>Gold member status</span>{' '}
                <span>
                  Our gold members get access to exclusive luxury brands and first dibs on our new
                  stock.
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.barometerItem}>
          <div className={styles.barometerText}>Danger Zone</div>
          <div className={styles.barometerText}>DISCOUNT 60% or less</div>
          <div className={styles.logo}>
            <img src={logo} alt="" />
          </div>
        </div>

        <div className={styles.barometerItemMobile}>
          <div className={styles.textMobile}>
            <span className={styles.block}>Danger</span> Zone
          </div>
          <div className={styles.textMobile}>
            <span className={styles.block}>Average</span> 72% Discount
          </div>
          <div className={styles.textMobile}>
            <span className={styles.block}>Discount</span> 60% or less
          </div>
        </div>
      </div>
    </section>
  );
};

export default BarometerSlider;

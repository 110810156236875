import { useCallback, useMemo } from 'react';
import { pick } from '../../../../../utils/pick';
import { useStore } from '../../../../../providers/useStore';
import { CustomerProfile, StylingFor, StylingProfile } from '../../../../../dataTypes';

export type FormState = Pick<StylingProfile, 'firstName' | 'lastName' | 'gender'>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function useStoreOperations(
  isFirstProfileBeingCreated: boolean,
  customerProfileFromApi?: CustomerProfile,
) {
  const {
    registrationFlowStore,
    registrationFlowStore: { order },
  } = useStore();

  const defaultValues = useMemo<FormState>(
    () => {
      const anyFieldsSpecified = order.stylingProfile.firstName || order.stylingProfile.lastName;

      if (anyFieldsSpecified) {
        return pick(order.stylingProfile, ['firstName', 'lastName', 'gender']) as FormState;
      } else if (isFirstProfileBeingCreated && order.stylingFor === 'Men') {
        return {
          firstName: customerProfileFromApi?.firstName || order.firstName,
          lastName: customerProfileFromApi?.lastName || order.lastName,
          gender: 'Male',
        } as FormState;
      }

      return {
        firstName: '',
        lastName: '',
        gender: order.stylingFor === StylingFor.Men ? 'Male' : 'Boy',
      } as FormState;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order.stylingProfile],
  );

  const patchStore = useCallback(
    (formState: FormState): void => {
      registrationFlowStore.patch({
        stylingProfile: formState,
      });
    },
    [registrationFlowStore],
  );

  return {
    defaultValues,
    customerFirstName: order.firstName,
    patchStore,
  };
}

import { FC } from 'react';
import styles from './Badge.module.scss';
import classNames from 'classnames';

interface Props {
  className?: string;
}

export const Badge: FC<Props> = ({ children, className }) => {
  return <div className={classNames(styles.host, className)}>{children}</div>;
};

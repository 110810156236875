/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { communicationMethods } from 'components/CommunicationMethodSwitch';
import { useMemo } from 'react';
import { OrderDraft } from '../../../../dataTypes';

export const DEFAULT_COMMUNICATION_METHOD = communicationMethods[0].value;

export function useDefaultValues(orderDraft: OrderDraft) {
  return useMemo(() => {
    const result = {
      communicationDateTime: orderDraft.communicationDateTime,
      customerComments: orderDraft.customerComments,
      phone: orderDraft.phone || orderDraft.address?.phone,
      communicationMethod: orderDraft.communicationMethod || DEFAULT_COMMUNICATION_METHOD,
    };
    return result;
  }, [orderDraft]);
}

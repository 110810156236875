import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './StylingProfileCard.module.scss';
import { StylingProfile } from 'dataTypes';
import { getAgeByDateOfBirth } from 'utils';

export interface StylingProfileCardProps {
  selected: boolean;
  profile: StylingProfile;
  onClick(profile: StylingProfile): void;
}

export const StylingProfileCard: FC<StylingProfileCardProps> = (props: StylingProfileCardProps) => {
  const { profile, selected, onClick } = props;

  return (
    <div
      className={classNames(styles.card, { [styles.cardSelected]: selected })}
      onClick={() => onClick(profile)}
    >
      <div className={styles.info}>
        <div className={styles.name}>
          {profile.firstName} {profile.lastName}
        </div>
        {profile.dateOfBirth && (
          <div className={styles.age}>
            {getAgeByDateOfBirth(new Date(profile.dateOfBirth))} YEARS
          </div>
        )}
      </div>
      <div className={classNames(styles.checkbox, { [styles.checkboxSelected]: selected })} />
    </div>
  );
};

import { FC, PropsWithChildren } from 'react';
import styles from './MainLayout.module.scss';
import classNames from 'classnames';
import { CookieConsent } from '../CookieConsent';
import * as consentConstants from './../../consentConstants';
import { useAnalytics } from '../../analytics';
import Footer from 'components/Footer';
import Header from 'components/Header';

interface Props {
  subMenu?: JSX.Element;
  banner?: JSX.Element;
  fullScreenWidth?: boolean;
}

export const MainLayout: FC<PropsWithChildren<Props>> = ({
  children,
  subMenu,
  banner,
  fullScreenWidth,
}) => {
  const { loadScripts } = useAnalytics();

  return (
    <div className={styles.wrapper}>
      <main
        className={classNames({
          [styles.main]: true,
        })}
      >
        <Header showNavButtons={true} />
        {subMenu}
        <div>{banner}</div>
        <div
          className={classNames({
            [styles.container]: true,
            [styles.container_fullScreenWidth]: fullScreenWidth,
          })}
        >
          {children}
        </div>
        <Footer />
      </main>
      <CookieConsent
        cookieName={consentConstants.COOKIE_NAME}
        cookieValue={consentConstants.ACCEPTED_VALUE}
        onAccept={loadScripts}
      />
    </div>
  );
};

import { FC } from 'react';
import { StatusViewProps } from '../../../propTypes';
import { ItemList } from '../../../../ItemList';
import { arrayHasItems } from '../../../../../../../utils';
import { DeliveryDetailsInfo } from '../../DeliveryDetailsInfo';
import TwoColumnLayout from 'components/TwoColumnLayout';

export const FullyReturnedStatusView: FC<StatusViewProps> = (props) => {
  const { order } = props;
  return (
    <>
      <TwoColumnLayout
        firstColumn={<DeliveryDetailsInfo order={order} hideOnMobile />}
        secondColumn={
          <>
            {arrayHasItems(order.items) && <ItemList title="Returned items" items={order.items} />}
          </>
        }
      />
    </>
  );
};

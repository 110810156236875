import { FC, PropsWithChildren } from 'react';
import * as React from 'react';
import styles from './OrderMessage.module.scss';

/* istanbul ignore file */

interface Props {
  messageText?: React.ReactNode;
}

export const OrderMessage: FC<PropsWithChildren<Props>> = (props) => {
  const { children, messageText } = props;

  return (
    <div className={styles.wrapper}>
      {messageText && <div className={styles.title}>{messageText}</div>}
      {children}
    </div>
  );
};

import { FC } from 'react';
import { StatusViewProps } from '../../../propTypes';
import { arrayHasItems } from '../../../../../../../utils';
import { ItemList } from '../../../../ItemList';
import { ReturnInfo } from './components/ReturnInfo';
import { DeliveryDetailsInfo } from '../../DeliveryDetailsInfo';
import TwoColumnLayout from 'components/TwoColumnLayout';
import { OrderPaid } from '../../OrderPaid';
import { useGetOrderReturn } from './useGetOrderReturn';
import { ReturnType } from 'dataTypes';
import { Loading } from 'components/Loading';
import styles from './PendingReturnStatusView.module.scss';

interface Props extends StatusViewProps {
  showReviewRequest: boolean;
}

export const PendingReturnStatusView: FC<Props> = ({ order, showReviewRequest }) => {
  const { data: orderReturn, loading } = useGetOrderReturn(order.id);

  const isFullReturn = orderReturn?.type === ReturnType.Full;
  const purchasedItems = order.purchase?.purchasedItems;

  return (
    <TwoColumnLayout
      firstColumn={<DeliveryDetailsInfo order={order} hideOnMobile />}
      secondColumn={
        <>
          {!isFullReturn && (
            <OrderPaid
              orderId={order.id}
              purchaseTotals={order.purchase?.totals}
              showReviewRequest={showReviewRequest}
            />
          )}
          {loading && (
            <div className={styles.loadingBox}>
              <Loading isBlack />
            </div>
          )}
          {!loading && <ReturnInfo orderReturn={orderReturn} />}
          {arrayHasItems(purchasedItems) && (
            <ItemList
              title="My items"
              description={`You kept ${purchasedItems.length} items`}
              showDetails
              items={purchasedItems}
            />
          )}
        </>
      }
    />
  );
};

import { OrderListStore } from '../stores/OrderListStore';
import { PaymentStore } from '../stores/PaymentStore';
import { RegistrationFlowStore } from '../stores/RegistrationFlowStore';
import { makeAutoObservable } from 'mobx';

export class Stores {
  constructor(
    public readonly orderListStore: OrderListStore,
    public readonly paymentStore: PaymentStore,
    public readonly registrationFlowStore: RegistrationFlowStore,
  ) {
    makeAutoObservable(this);
  }
}

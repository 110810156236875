import { AxiosInstance } from 'axios';
import { makeGetReviewListUrl } from 'consts';
import { ReviewList } from 'dataTypes';

export class ReviewApiService {
  constructor(private readonly axiosInstance: AxiosInstance) {}

  async getAllReviews(): Promise<ReviewList | null> {
    try {
      const url = makeGetReviewListUrl();
      const response = await this.axiosInstance.get<ReviewList>(url);

      return response.data;
    } catch {
      return null;
    }
  }
}

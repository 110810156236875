import { FC, useEffect, useContext } from 'react';
import { Loading } from '../../../../components/Loading';
import styles from './PaymentDetailsStep.module.scss';
import { useSetHeader } from 'pages/RegistrationFlow/hooks/useSetHeader';
import StepFooter from 'pages/RegistrationFlow/components/StepFooter';
import { StepComponentProps } from 'pages/RegistrationFlow/StepComponentProps';
import { usePaymentMethods } from 'serverCache/paymentMethods';
import StylistMessage from 'components/StylistMessage/StylistMessage';
import RegistrationFlowContext from 'providers/RegistrationFlowContext';
import visaIcon from '../../../../assets/images/payment/visa.svg';
import mastercardIcon from '../../../../assets/images/payment/mastercard.svg';
import amexIcon from '../../../../assets/images/payment/amex.svg';
import { NewBraintreePaymentMethod } from 'pages/PaymentMethods/components/NewBraintreePaymentMethod';

export const paymentDetailsStepTitle = 'Last bit - Card verification';

export const createErrorMessage = (error: string): string =>
  `Oops! An error has occured while trying to process your payment method: ${error}. Please try again.`;

const PAY_BUTTON_TEXT = 'Verify';

export const PaymentDetailsStep: FC<StepComponentProps> = ({
  imageUrl,
  name,
  stepsCount,
  currentStepIndex,
  onStepBack,
  onStepForward,
}: StepComponentProps) => {
  const message = (
    <>
      <span className={styles.messageParagraph}>
        At Choixce you can pay what you want, but we need to verify your card details to protect us
        against fraud.
      </span>
      <span className={styles.messageParagraph}>
        You may notice a £1 charge in your account which is refunded straight away.
      </span>
    </>
  );

  const { showHeader } = useSetHeader({
    title: paymentDetailsStepTitle,
    subTitle: message,
    isHidden: true,
  });

  const { data: paymentMethods, isLoading: isPaymentMethodsLoading } = usePaymentMethods();
  useEffect(
    () => {
      if (paymentMethods?.length) {
        onStepForward();
      } else if (!isPaymentMethodsLoading) {
        showHeader();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentMethods],
  );

  const { header } = useContext(RegistrationFlowContext).values;
  const showProgressBar = currentStepIndex + 1 <= stepsCount;
  const showStylistMessage = showProgressBar && !header.isHidden;

  return (
    <>
      {isPaymentMethodsLoading || paymentMethods?.length ? (
        <div className={styles.loadingContainer}>
          <Loading isBlack />
        </div>
      ) : (
        <>
          <StepFooter
            onBack={onStepBack}
            hideNextButton={true}
            stepsCount={stepsCount}
            currentStepIndex={currentStepIndex}
          />

          {showStylistMessage && (
            <StylistMessage
              imageUrl={imageUrl}
              name={name || 'Lorna'}
              title={paymentDetailsStepTitle}
              message={message}
              noPaddingBottom={true}
            />
          )}

          <div className={styles.container}>
            <NewBraintreePaymentMethod buttonText={PAY_BUTTON_TEXT} onSuccess={onStepForward} />
          </div>

          <div className={styles.paymentList}>
            <img src={visaIcon} alt="" className={styles.icon} />
            <img src={mastercardIcon} alt="" className={styles.icon} />
            <img src={amexIcon} alt="" className={styles.icon} />
          </div>

          {/* <StepFooter onBack={onStepBack} hideNextButton={true} /> */}
        </>
      )}
    </>
  );
};

import { FC, useContext } from 'react';
import { StepComponentProps } from '../../StepComponentProps';
// import { ContentContainer } from '../../components/ContentContainer';
import { Controller, useForm } from 'react-hook-form';
import { FormState } from './FormState';
import { useSubscribeOnFormChanges } from 'utils/form-helpers';
import { CommunicationMethodSwitch } from 'components/CommunicationMethodSwitch';
import { MultilineTextInput, TextInput } from 'components';
import { CommunicationDateAndTimeFields } from './CommunicationDateAndTimeFields';
import { getRowGrid } from 'components/InputGrid/styles';
import { useDefaultValues } from './useDefaultValues';
import { getAvailableCommunicationDays, getAvailableCommunicationTime } from './dateTimeHelpers';
import { phoneRegex } from '../../../../constants';
import { useSetHeader } from 'pages/RegistrationFlow/hooks/useSetHeader';
import StepFooter from 'pages/RegistrationFlow/components/StepFooter';
import { usePatchCustomerProfile } from 'serverCache/customerProfile';
import StylistMessage from 'components/StylistMessage/StylistMessage';
import RegistrationFlowContext from 'providers/RegistrationFlowContext';
import styles from './ContactDetailsStep.module.scss';

const PHONE_MAX_LENGTH = 35;

export const contactDetailsStepTitle = 'How would you like to be contacted?';
export const subTitle =
  'We recommend a quick call with your stylist, a brief 5-minute chat really helps us curate the perfect box';

export const ContactDetailsStep: FC<StepComponentProps> = ({
  orderDraft,
  imageUrl,
  name,
  stepsCount,
  currentStepIndex,
  onChange,
  onStepBack,
  onStepForward,
}) => {
  useSetHeader({ title: contactDetailsStepTitle, subTitle: subTitle });

  const defaultValues = useDefaultValues(orderDraft);
  const form = useForm<FormState>({
    mode: 'onChange',
    defaultValues,
  });

  const { mutateAsync: patchCustomerProfile } = usePatchCustomerProfile();

  const {
    register,
    control,
    watch,
    formState: { errors, isValid },
  } = form;

  useSubscribeOnFormChanges(watch, onChange);

  const communicationMethod = watch('communicationMethod');
  const phone = watch('phone');

  const canPickDateAndTime = communicationMethod !== 'email';

  const handleStepForward = async (): Promise<void> => {
    await patchCustomerProfile({ phone, communicationMethod });
    onStepForward();
  };

  const { header } = useContext(RegistrationFlowContext).values;
  const showProgressBar = currentStepIndex + 1 <= stepsCount;
  const showStylistMessage = showProgressBar && !header.isHidden;

  return (
    <>
      <StepFooter
        onBack={onStepBack}
        onNext={handleStepForward}
        disableNext={!isValid}
        stepsCount={stepsCount}
        currentStepIndex={currentStepIndex}
      />

      {showStylistMessage && (
        <StylistMessage
          imageUrl={imageUrl}
          name={name || 'Lorna'}
          title={contactDetailsStepTitle}
          message={subTitle}
        />
      )}

      <div className={styles.container}>
        <form data-testid="form">
          <Controller
            control={control}
            name="communicationMethod"
            rules={{
              required: true,
            }}
            render={({ field: { ref, ...field } }) => (
              <CommunicationMethodSwitch label="" {...field} />
            )}
          />
          <TextInput
            label="Phone"
            autoComplete="tel"
            placeholder="International or local"
            id="cd-phone"
            maxLength={PHONE_MAX_LENGTH}
            hasError={!!errors.phone}
            {...register('phone', {
              required: true,
              pattern: phoneRegex,
            })}
          />
          {canPickDateAndTime && (
            <CommunicationDateAndTimeFields
              form={form}
              communicationDayValues={getAvailableCommunicationDays(18)}
              communicationTimeValues={getAvailableCommunicationTime(9, 19, 2)}
            />
          )}
          <MultilineTextInput
            title="Your comment"
            getGridStyles={getRowGrid}
            placeholder="What else would you like us to know?"
            id="cd-communicationComment"
            {...register('customerComments', {})}
          />
        </form>
      </div>

      {/* <StepFooter onBack={onStepBack} onNext={handleStepForward} disableNext={!isValid} /> */}
    </>
  );
};

import { FC } from 'react';
import { SavedAddress } from '../../../../dataTypes';
import styles from './AddressListItem.module.scss';
import { useDeleteAddress, useSetDefaultAddress } from '../../../../serverCache/address';
import { Badge } from '../../../../components/Badge';
import { Button } from '../../../../components/Button';
import { AddressPane } from '../../../../components/AddressPane';
import { IconButton } from '../../../../components/IconButton';
import { Trash } from '../../../../components/Icons/Trash';

interface Props {
  address: SavedAddress;
}

export const AddressListItem: FC<Props> = (props) => {
  const { address } = props;

  const { mutate: deleteAddress, isLoading: isDeleting } = useDeleteAddress(address.id);
  const { mutate: setDefault, isLoading: isSettingDefault } = useSetDefaultAddress(address.id);

  return (
    <AddressPane
      address={address}
      rightActions={
        <IconButton disabled={isDeleting} onClick={deleteAddress} data-testid="delete">
          <Trash />
        </IconButton>
      }
      actions={
        address.isDefault ? (
          <Badge className={styles.badge}>Default</Badge>
        ) : (
          <Button
            color="white"
            className={styles.makeDefaultButton}
            onClick={setDefault}
            data-testid="setDefault"
          >
            {isSettingDefault ? 'Loading...' : 'Set default'}
          </Button>
        )
      }
    />
  );
};

import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ArrowIcon } from '../Icons';
import classNames from 'classnames';
import styles from './ProfileBack.module.scss';

interface ProfileBackProps {
  className?: string;
  to: string;
  name: string;
}

export const ProfileBack: FC<ProfileBackProps> = (props: ProfileBackProps) => {
  return (
    <Link to={props.to} className={classNames(styles.back, styles.className)}>
      <ArrowIcon className={styles.arrow} /> Back to your {props.name}
    </Link>
  );
};

import React, { FC, useCallback, useEffect } from 'react';
import styles from './WeightInput.module.scss';
import classNames from 'classnames';
import {
  imperialWeightToString,
  stringToWeight,
  weightAsImperial,
  weightAsMetric,
  weightToString,
} from './conversionFunctions';
import { Input } from '../Input';
import { SystemOfMeasure } from 'dataTypes';

interface AdditionalProps {
  systemOfMeasure: SystemOfMeasure;
  label: string;
  name?: string;
  value?: string;
  errorMessage?: string;
  onChange?(value: string): void;
  onBlur?: JSX.IntrinsicElements['input']['onBlur'];
}

type Props = AdditionalProps;

export const WeightInput: FC<Props> = (props) => {
  const { systemOfMeasure, label, errorMessage, name, value, onChange, onBlur } = props;

  const weight = stringToWeight(value);

  useEffect(() => {
    if (weight && systemOfMeasure !== weight.systemOfMeasure) {
      onChange?.(weightToString(weight, systemOfMeasure));
    }
  }, [weight, systemOfMeasure, onChange]);

  const metricValue = weightAsMetric(weight);
  const imperialValue = weightAsImperial(weight);

  const handleChangeKg = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const kgValue = e.target.value;
      if (kgValue.length > 3) {
        return;
      }
      onChange?.(kgValue);
    },
    [onChange],
  );

  const handleChangeStones = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const stones = e.target.value;
      if (stones.length > 2) {
        return;
      }

      onChange?.(imperialWeightToString(stones, imperialValue?.pounds ?? ''));
    },
    [imperialValue, onChange],
  );

  const handleChangePounds = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const pounds = e.target.value;
      if (pounds.length > 4) {
        return;
      }

      onChange?.(imperialWeightToString(imperialValue?.stones ?? '', pounds));
    },
    [imperialValue, onChange],
  );

  return (
    <div className={styles.root}>
      <label className={styles.label}>{label}</label>
      <div className={styles.inputBlock}>
        {systemOfMeasure === 'Metric' && (
          <Input
            type="number"
            name={name}
            placeholder="kg"
            data-testid="kg-input"
            error={!!errorMessage}
            className={classNames(styles.input)}
            value={metricValue?.kg ?? ''}
            onChange={handleChangeKg}
            onBlur={onBlur}
          />
        )}
        {systemOfMeasure === 'Imperial' && (
          <>
            <Input
              type="number"
              name={name}
              placeholder="st"
              data-testid="stones-input"
              error={!!errorMessage}
              className={classNames(styles.input)}
              value={imperialValue?.stones ?? ''}
              onChange={handleChangeStones}
              onBlur={onBlur}
            />
            <Input
              type="number"
              name={name}
              placeholder="lb"
              data-testid="pounds-input"
              error={!!errorMessage}
              className={classNames(styles.input)}
              value={imperialValue?.pounds ?? ''}
              onChange={handleChangePounds}
              onBlur={onBlur}
            />
          </>
        )}
      </div>
      {errorMessage && (
        <div className={styles.errorContainer}>
          <div data-testid="error-message">
            <div className={styles.label} />
            <div className={classNames(styles.inputBlock, styles.errorMessageContainer)}>
              {errorMessage}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

import { BoxSample, StylingFor } from 'dataTypes';

const KidsSamples: BoxSample[] = [
  {
    name: 'Michael',
    imageUrl: '/images/boxSamples/kids/kids-sample-1.webp',
    originalPrice: 40,
    customerPrice: 25,
    type: StylingFor.Kids,
  },
  {
    name: 'Sarah',
    imageUrl: '/images/boxSamples/kids/kids-sample-2.webp',
    originalPrice: 50,
    customerPrice: 25,
    type: StylingFor.Kids,
  },
  {
    name: 'Eleanor',
    imageUrl: '/images/boxSamples/kids/kids-sample-3.webp',
    originalPrice: 120,
    customerPrice: 60,
    type: StylingFor.Kids,
  },
  {
    name: 'Joan',
    imageUrl: '/images/boxSamples/kids/kids-sample-4.webp',
    originalPrice: 205,
    customerPrice: 80,
    type: StylingFor.Kids,
  },
  {
    name: 'Robert',
    imageUrl: '/images/boxSamples/kids/kids-sample-5.webp',
    originalPrice: 100,
    customerPrice: 50,
    type: StylingFor.Kids,
  },
  {
    name: 'Maria',
    imageUrl: '/images/boxSamples/kids/kids-sample-6.webp',
    originalPrice: 175,
    customerPrice: 100,
    type: StylingFor.Kids,
  },
  {
    name: 'Kate',
    imageUrl: '/images/boxSamples/kids/kids-sample-7.webp',
    originalPrice: 240,
    customerPrice: 120,
    type: StylingFor.Kids,
  },
  {
    name: 'Rachel',
    imageUrl: '/images/boxSamples/kids/kids-sample-8.webp',
    originalPrice: 240,
    customerPrice: 100,
    type: StylingFor.Kids,
  },
  {
    name: 'Helen',
    imageUrl: '/images/boxSamples/kids/kids-sample-9.webp',
    originalPrice: 200,
    customerPrice: 85,
    type: StylingFor.Kids,
  },
  {
    name: 'Hayden',
    imageUrl: '/images/boxSamples/kids/kids-sample-10.webp',
    originalPrice: 300,
    customerPrice: 90,
    type: StylingFor.Kids,
  },
  {
    name: 'Emily',
    imageUrl: '/images/boxSamples/kids/kids-sample-11.webp',
    originalPrice: 285,
    customerPrice: 85,
    type: StylingFor.Kids,
  },
  {
    name: 'Alice',
    imageUrl: '/images/boxSamples/kids/kids-sample-12.webp',
    originalPrice: 150,
    customerPrice: 45,
    type: StylingFor.Kids,
  },
  {
    name: 'Colin',
    imageUrl: '/images/boxSamples/kids/kids-sample-13.webp',
    originalPrice: 210,
    customerPrice: 65,
    type: StylingFor.Kids,
  },
];

export default KidsSamples;

import { FC } from 'react';
import { StatusViewProps } from '../../../propTypes';
import { OrderMessage } from '../../OrderMessage';
import { DeliveryDetailsInfo } from '../../DeliveryDetailsInfo';
import TwoColumnLayout from 'components/TwoColumnLayout';

export const ReadyForPickingStatusView: FC<StatusViewProps> = ({ order }: StatusViewProps) => {
  return (
    <TwoColumnLayout
      firstColumn={<DeliveryDetailsInfo order={order} />}
      secondColumn={
        <OrderMessage messageText="Your order is being picked and packed, and will be dispatched to you soon." />
      }
    />
  );
};

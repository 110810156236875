import { FC } from 'react';
import styles from './OrderPaymentFailure.module.scss';

interface Props {
  errorMessage: string;
}

export const OrderPaymentFailure: FC<Props> = (props: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.error}>Payment declined</div>
      <div className={styles.reason}>Reason: {props.errorMessage}</div>
      <div className={styles.label}>Please try again or choose a different payment method.</div>
    </div>
  );
};

import { FC } from 'react';
import styles from './Loading.module.scss';
import classNames from 'classnames';

interface Props {
  isBlack?: boolean;
  className?: string;
  size?: LoadingSizes;
}

export enum LoadingSizes {
  small,
  big,
}

export const Loading: FC<Props> = ({
  isBlack = false,
  className = '',
  size = LoadingSizes.big,
}) => {
  return (
    <div
      className={classNames({
        [styles.loading]: true,
        [styles.isBlack]: isBlack,
        [styles.small]: size === LoadingSizes.small,
        [className]: true,
      })}
    />
  );
};

import Auth0Lock from 'auth0-lock';
import { languageDictionary } from './languageDictionary';

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CONFIGURATION_BASE_URL,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_AUDIENCE,
} = process.env;

export const defaultAuth0LockConstructorOptions: Auth0LockConstructorOptions = {
  autoclose: true,
  avatar: null,
  rememberLastLogin: true,
  oidcConformant: true,
  closable: false,
  configurationBaseUrl: REACT_APP_AUTH0_CONFIGURATION_BASE_URL,
  auth: {
    responseType: 'token id_token',
    audience: REACT_APP_AUTH0_AUDIENCE,
    redirect: false,
    redirectUrl: window.location.origin,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    scope: 'openid',
    connectionScopes: {
      facebook: ['public_profile', 'email'],
    },
  },
  theme: {
    logo: '/images/logo-short-black.svg',
    primaryColor: 'black',
    hideMainScreenTitle: true,
  },
  mustAcceptTerms: true,
  languageDictionary: languageDictionary,
};

export function CreateAuth0Lock(
  auth0LockConstructorOptions: Partial<Auth0LockConstructorOptions> = {},
  id = REACT_APP_AUTH0_CLIENT_ID as string,
  domain = REACT_APP_AUTH0_DOMAIN as string,
): typeof Auth0Lock {
  const options = { ...defaultAuth0LockConstructorOptions, ...auth0LockConstructorOptions };

  return new Auth0Lock(id, domain, options);
}

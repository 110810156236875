import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { OrderStatus } from '../../../../dataTypes';
import {
  CompletedStatusView,
  DeliveredStatusView,
  DeliveryOverdueStatusView,
  DispatchedStatusView,
  PaymentOverdueStatusView,
  PendingPaymentStatusView,
  PendingReturnStatusView,
  ReturnOverdueStatusView,
  FullyReturnedStatusView,
  SubmittedStatusView,
  UnknownStatusView,
} from './components/statusViews';
import { Layout } from './components/Layout';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useStore } from 'providers/useStore';
import { OrdersUrlParams } from 'pages/Orders';
import { ReadyForPickingStatusView } from './components/statusViews/ReadyForPickingStatusView';

const OrderView: FC = observer(() => {
  const { orderId } = useParams<OrdersUrlParams>();
  const { orderListStore } = useStore();

  const [showReviewRequest, setShowReviewRequest] = useState(false);

  const order = useMemo(
    () => {
      if (!orderListStore.listHasLoadedOnce) {
        orderListStore.startLoadingOrderList();
        return null;
      }

      return orderListStore.getOrder(orderId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderListStore.listLoadingState, orderId],
  );

  useEffect(() => {
    if (order?.status === OrderStatus.Delivered || order?.status === OrderStatus.PendingPayment) {
      setShowReviewRequest(true);
    }
  }, [order]);

  if (!order) {
    return null;
  }

  const statusView = (): ReactElement => {
    switch (order.status) {
      case OrderStatus.Submitted:
        return <SubmittedStatusView order={order} />;
      case OrderStatus.ReadyForPicking:
        return <ReadyForPickingStatusView order={order} />;
      case OrderStatus.Dispatched:
        return <DispatchedStatusView order={order} />;
      case OrderStatus.Delivered:
        return <DeliveredStatusView order={order} />;
      case OrderStatus.DeliveryOverdue:
        return <DeliveryOverdueStatusView order={order} />;
      case OrderStatus.PendingPayment:
        return <PendingPaymentStatusView order={order} />;
      case OrderStatus.PaymentOverdue:
        return <PaymentOverdueStatusView order={order} />;
      case OrderStatus.PendingReturn:
        return <PendingReturnStatusView order={order} showReviewRequest={showReviewRequest} />;
      case OrderStatus.ReturnOverdue:
        return <ReturnOverdueStatusView order={order} />;
      case OrderStatus.FullyReturned:
        return <FullyReturnedStatusView order={order} />;
      case OrderStatus.Completed:
        return <CompletedStatusView order={order} showReviewRequest={showReviewRequest} />;
      default: {
        return <UnknownStatusView order={order} />;
      }
    }
  };

  return <Layout order={order}>{statusView()}</Layout>;
});

export default OrderView;

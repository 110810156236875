import { Credit, OrderItem } from 'dataTypes';
import { Dayjs } from 'dayjs';

export const anyNonWordSymbols = /[^\w]/gi;

export const dateToUtc = (date: Date): Date => {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

export const dateToLongString = (date: Dayjs): string => {
  return date
    .toDate()
    .toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
};

export const dateToShortString = (date: Dayjs): string => {
  return date
    .toDate()
    .toLocaleDateString('en-GB', { year: 'numeric', month: 'numeric', day: 'numeric' });
};

export const getAgeByDateOfBirth = (dateOfBirth: Date): number => {
  const ageDifMs = Date.now() - dateOfBirth.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export function getPercentageString(percentage: number): string {
  return new Intl.NumberFormat('en-GB', { style: 'percent', maximumFractionDigits: 2 }).format(
    percentage,
  );
}

export function getCurrencyString(amount: number): string {
  return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(amount);
}

// See https://stackoverflow.com/a/19138852
const cardBrandRegexes = {
  electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
  maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
  dankort: /^(5019)\d+$/,
  interpayment: /^(636)\d+$/,
  unionpay: /^(62|88)\d+$/,
  visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
  mastercard: /^5[1-5][0-9]{14}$/,
  amex: /^3[47][0-9]{13}$/,
  diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
  discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
  jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
  carteblanche: /^389[0-9]{11}$/,
};

export type CardBrands = keyof typeof cardBrandRegexes;

export function getCardBrand(cardNumber: string): CardBrands | undefined {
  for (const [cardBrand, regex] of Object.entries(cardBrandRegexes)) {
    if (regex.test(cardNumber)) {
      return cardBrand as CardBrands;
    }
  }
}

export function getLastCardNumberDigits(cardNumber: string): string {
  const maskEndIndex = cardNumber.includes('X')
    ? cardNumber.lastIndexOf('X')
    : cardNumber.lastIndexOf('*');

  if (maskEndIndex >= 0) {
    return cardNumber.substr(maskEndIndex + 1);
  }

  if (cardNumber.length >= 4) {
    return cardNumber.substr(cardNumber.length - 4);
  }

  return cardNumber;
}

export function arrayHasItems<T>(items: T[] | null | undefined): items is T[] {
  return (items?.length ?? 0) > 0;
}

export function removeSpecialSymbols(text: string): string {
  return text.replace(anyNonWordSymbols, '');
}

export function getEnumKeyByValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string,
): keyof T {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);

  if (keys.length > 0) {
    return keys[0];
  }

  throw Error('Enum member cannot be found by value');
}

export function calculateItemsPrice(items: OrderItem[]): number {
  return items.map((item) => item.choixcePrice).reduce((prev, curr) => prev + curr, 0);
}

export function calculatePriceAfterDiscount(fullPrice: number, discount: number): number {
  return Math.floor(fullPrice * (1 - discount));
}

export function calculateAmountSaved(fullPrice: number, discount: number): number {
  return fullPrice - calculatePriceAfterDiscount(fullPrice, discount);
}

export function calculateCredit(credits?: Credit[]): number {
  const creditAmount =
    credits && credits.length > 0
      ? credits.map((el) => el.amount).reduce((sum, el) => sum + el)
      : 0;

  return creditAmount;
}

import { FC } from 'react';
import styles from './TitleMain.module.scss';
import classNames from 'classnames';

/* istanbul ignore file */

export interface TitleMainProps {
  number?: number;
  title: string;
  description?: string;
  titleLarge?: boolean;
  invertСolor?: boolean;
  className?: string;
}

export const TitleMain: FC<TitleMainProps> = (props: TitleMainProps) => {
  const { number, title, description, titleLarge, invertСolor, className } = props;
  return (
    <section
      className={classNames(
        {
          [styles.container]: true,
          [styles.minHeight]: description && !number,
          [styles.dark]: invertСolor,
        },
        className,
      )}
    >
      <div className={styles.number}>{number}</div>
      <div
        className={classNames({
          [styles.titleBackground]: true,
          [styles.active]: !number,
          [styles.titleTranslate]: description || titleLarge,
        })}
      >
        {title}
      </div>
      <h1
        className={classNames({
          [styles.title]: true,
          [styles.titleLarge]: titleLarge,
        })}
      >
        {title}
      </h1>
      <h2 className={styles.description}>{description}</h2>
    </section>
  );
};

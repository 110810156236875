import { useLocation } from 'react-router-dom';

interface LocationState {
  orderId: string;
}

export function useCreatedOrderId(): string {
  const {
    state: { orderId },
  } = useLocation<LocationState>();

  return orderId;
}

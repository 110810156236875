import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import styles from './RadioButton.module.scss';
import { Label } from '../Label';
import { getRowGrid } from '../InputGrid/styles';

type NativeInputProps = React.InputHTMLAttributes<HTMLInputElement>;

interface RadioItem {
  value: string;
  label: string;
}

interface AdditionalProps {
  name: string;
  label: string;
  items: RadioItem[];
}

type Props = AdditionalProps & Pick<NativeInputProps, 'value' | 'onChange' | 'className'>;

function radioId(prefix: string, value: string): string {
  return `${prefix}__${value}`;
}

export const RadioButton: FC<Props> = (props) => {
  const { name, items, label, className, value, onChange } = props;
  const gridStyles = useMemo(() => getRowGrid(), []);

  return (
    <div className={classNames(gridStyles.container, styles.container, className)}>
      <Label className={classNames(gridStyles.label)}>{label}</Label>
      <div className={classNames(gridStyles.input, styles.radioContainer)}>
        {items.map((item) => (
          <div key={item.value} className={styles.radioBtn}>
            <input
              id={radioId(name, item.value)}
              type="radio"
              name={name}
              value={item.value}
              checked={value === item.value}
              onChange={onChange}
            />
            <label htmlFor={radioId(name, item.value)}>{item.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

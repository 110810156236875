const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;

export const customJSONDataParser = (key: unknown, value: unknown): unknown => {
  if (typeof value === 'string') {
    const a = reISO.exec(value);
    if (a) return new Date(value);
    return value;
  }
  return value;
};

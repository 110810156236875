import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { useService } from '../providers/useService';
import { CustomerProfile, PatchCustomerProfilePayload } from '../dataTypes';

const customerProfileKey = ['customerProfile'] as const;

interface UseCustomerProfileOptions {
  enabled?: boolean;
}

export function useCustomerProfile(
  options?: UseCustomerProfileOptions,
): UseQueryResult<CustomerProfile> {
  const { customerProfileApiService } = useService();

  return useQuery(
    customerProfileKey,
    async () => {
      return await customerProfileApiService.getCustomerProfile();
    },
    { ...options },
  );
}

export function useCreateCustomerProfile(): UseMutationResult<
  CustomerProfile,
  unknown,
  CustomerProfile
> {
  const { customerProfileApiService } = useService();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: CustomerProfile) => {
      return await customerProfileApiService.createCustomerProfile(payload);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(customerProfileKey),
    },
  );
}

export function usePatchCustomerProfile(): UseMutationResult<
  CustomerProfile | null,
  unknown,
  PatchCustomerProfilePayload
> {
  const { customerProfileApiService } = useService();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: PatchCustomerProfilePayload) => {
      return await customerProfileApiService.patchCustomerProfile(payload);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(customerProfileKey),
    },
  );
}

import { FC } from 'react';

interface CreditCardProps {
  className?: string;
  width: number;
  height: number;
}

export const CreditCardIcon: FC<CreditCardProps> = (props: CreditCardProps) => {
  return (
    <div className={props.className} style={{ width: props.width, height: props.height }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.2803 9.09657L18.9034 0.719651C17.9438 -0.239939 16.3824 -0.239829 15.423 0.719651L4.91152 11.2312H2.46104C1.10402 11.2312 0 12.3352 0 13.6922V25.539C0 26.896 1.10402 28 2.46104 28H21.3082C22.6653 28 23.7693 26.896 23.7693 25.539V16.088L27.2803 12.577C28.2399 11.6174 28.2399 10.0561 27.2803 9.09657ZM16.5831 1.87979C16.7431 1.71987 16.9532 1.63986 17.1633 1.63986C17.3733 1.63986 17.5834 1.71987 17.7433 1.87979L22.0597 6.1962L17.0247 11.2312H7.23173L16.5831 1.87979ZM21.3082 26.3593C21.7606 26.3593 22.1286 25.9913 22.1286 25.539V13.6922C22.1286 13.2399 21.7606 12.8718 21.3082 12.8718H2.46104C2.0087 12.8718 1.64069 13.2399 1.64069 13.6922V25.539C1.64069 25.9913 2.0087 26.3593 2.46104 26.3593H21.3082ZM21.6421 11.2544C21.5328 11.2395 21.4215 11.2312 21.3082 11.2312H19.345L23.2198 7.35627L24.38 8.51646L21.6421 11.2544ZM23.7693 13.7678L26.1202 11.4169C26.44 11.097 26.44 10.5765 26.1202 10.2566L25.5401 9.6766L23.1521 12.0647C23.5357 12.4988 23.7693 13.0686 23.7693 13.6922V13.7678Z"
            fill="black"
          />
          <path d="M3.15527 22.0845H6.3102V23.6619H3.15527V22.0845Z" fill="black" />
          <path d="M7.8877 22.0845H11.0426V23.6619H7.8877V22.0845Z" fill="black" />
          <path d="M12.6201 22.0845H15.775V23.6619H12.6201V22.0845Z" fill="black" />
          <path d="M17.3516 22.0845H20.5065V23.6619H17.3516V22.0845Z" fill="black" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.15527 15.3804H8.6764V20.1128H3.15527V15.3804ZM4.79091 18.5353H7.04077V16.9578H4.79091V18.5353Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

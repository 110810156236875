/* istanbul ignore file */
import { customJSONDataParser } from './customJSONDataParser';
import { makeAutoObservable } from 'mobx';

export class LocalPersistService {
  constructor() {
    makeAutoObservable(this);
  }

  hasCache(key: string): boolean {
    return !!window.localStorage.getItem(key);
  }

  save(key: string, data: unknown): void {
    window.localStorage.setItem(key, JSON.stringify(data));
  }

  saveCache(key: string, data: unknown): void {
    window.localStorage.setItem(this.getCacheKey(key), JSON.stringify(data));
  }

  restoreCache(key: string): unknown | undefined {
    return JSON.parse(window.localStorage.getItem(this.getCacheKey(key)) ?? '{}');
  }

  getCacheKey(key: string): string {
    return 'cache' + key.toString();
  }

  restore(key: string): unknown {
    return JSON.parse(window.localStorage.getItem(key) as string, customJSONDataParser);
  }

  delete(key: string): void {
    window.localStorage.removeItem(key);
    window.localStorage.removeItem(this.getCacheKey(key));
  }

  clear(): void {
    window.localStorage.clear();
  }
}

import React, { ComponentProps, FC } from 'react';
import BaseConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';
import { PRIVACY_URL } from '../../routePaths';

import styles from './CookieConsent.module.scss';

type Props = Pick<ComponentProps<typeof BaseConsent>, 'cookieName' | 'cookieValue' | 'onAccept'>;

export const CookieConsent: FC<Props> = (props) => {
  return (
    <BaseConsent
      disableStyles
      disableButtonStyles
      buttonText="SAVE AND CLOSE"
      containerClasses={styles.container}
      contentClasses={styles.content}
      buttonClasses={styles.button}
      buttonWrapperClasses={styles.buttonWrapper}
      {...props}
    >
      <p>
        We use analytics cookies when you land on and browse on our site and app. In addition, if
        you create an account with us, additional cookies are stored. Please go to our{' '}
        <Link to={PRIVACY_URL}>Data Protection, Privacy and Cookies Policy</Link> page for more
        information.
      </p>
      <p>I understand and I am content to proceed.</p>
    </BaseConsent>
  );
};

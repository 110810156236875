import { AxiosInstance } from 'axios';
import { makePostMakeOfferUrl } from 'consts';
import { MakeOfferPayload } from 'dataTypes';

export class OfferApiService {
  constructor(private readonly axiosInstance: AxiosInstance) {}

  async makeOffer(orderId: string, payload: MakeOfferPayload): Promise<void> {
    const url = makePostMakeOfferUrl(orderId);
    await this.axiosInstance.post<void>(url, payload);
  }
}

import { FC, useMemo, useState } from 'react';
import styles from './ItemListSelector.module.scss';
import { OrderItem } from '../../../../dataTypes';
import DetailedItem from '../DetailedItem';

interface Props {
  title?: string;
  description?: string;
  items: OrderItem[];
  onItemsChanged(items: OrderItem[]): void;
}

export const ItemListSelector: FC<Props> = ({ items, title, description, onItemsChanged }) => {
  const selectedItemsMap = useMemo(() => {
    const result = new Map<string, boolean>();
    for (const item of items) {
      result.set(item.id, true);
    }
    return result;
  }, [items]);

  const [selectedItems, setSelectedItems] = useState(selectedItemsMap);

  const onItemSelect = (id: string): void => {
    const newSelectedItems = new Map<string, boolean>(selectedItems);
    newSelectedItems.set(id, !newSelectedItems.get(id));
    setSelectedItems(newSelectedItems);
    const purchasedItems = items.filter((item) => newSelectedItems.get(item.id));
    onItemsChanged(purchasedItems);
  };

  return (
    <div className={styles.items}>
      {description && <div className={styles.description}>{description}</div>}
      <div>
        {items.map((item) => (
          <DetailedItem
            category={item.websiteCategory || item.category}
            brand={item.brand}
            size={item.size}
            price={item.choixcePrice}
            key={item.id}
            isSelected={selectedItems.get(item.id)}
            onSelect={() => onItemSelect(item.id)}
            type="selectable"
          />
        ))}
      </div>
    </div>
  );
};

import { FC } from 'react';
import styles from './ErrorMessage.module.scss';
import { Button } from '../../../../components/Button';

interface Props {
  onReset(): void;
}

export const ErrorMessage: FC<Props> = ({ onReset }) => {
  return (
    <div className={styles.host}>
      <p>
        There was an error while adding a new payment method. Please try again later or contact us
        if problem persists.
      </p>
      <div>
        <Button color="black" onClick={onReset} className={styles.button}>
          Got it
        </Button>
      </div>
    </div>
  );
};

import { FC } from 'react';
import { ItemList } from '../../../../ItemList';
import { StatusViewProps } from '../../../propTypes';
import { OrderPaid } from '../../OrderPaid';
import { arrayHasItems } from '../../../../../../../utils';
import { DeliveryDetailsInfo } from '../../DeliveryDetailsInfo';
import { useCustomerProfile } from 'serverCache/customerProfile';
import { TrustedShopsDataTransfer } from 'components/TrustedShopsDataTransfer';
import TwoColumnLayout from 'components/TwoColumnLayout';

interface Props extends StatusViewProps {
  showReviewRequest: boolean;
}

export const CompletedStatusView: FC<Props> = ({ order, showReviewRequest }) => {
  const { data: customerProfile, isSuccess } = useCustomerProfile();

  const purchasedItems = order.purchase?.purchasedItems;
  const returnedItems = order.purchase?.returnedItems;

  return (
    <TwoColumnLayout
      firstColumn={<DeliveryDetailsInfo order={order} hideOnMobile />}
      secondColumn={
        <>
          <OrderPaid
            orderId={order.id}
            purchaseTotals={order.purchase?.totals}
            showReviewRequest={showReviewRequest}
          />
          {arrayHasItems(purchasedItems) && (
            <ItemList
              title="My items"
              description={`You kept ${purchasedItems.length} items`}
              showDetails
              items={purchasedItems}
            />
          )}
          {arrayHasItems(returnedItems) && (
            <ItemList
              description={`You returned ${returnedItems.length} items`}
              showDetails
              items={returnedItems}
            />
          )}
          {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            isSuccess && customerProfile && (
              <TrustedShopsDataTransfer order={order} customerProfile={customerProfile} />
            )
          }
        </>
      }
    />
  );
};

import { FC, ReactElement, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import { CustomerProfile as TCustomerProfile } from '../../../../../dataTypes';
import { TextInput } from '../../../../../components';
import { emailRegex } from '../../../../../constants';
import { NAME_MAX_LENGTH, NAME_MIN_LENGTH } from '../constants';
import { useCanAutofocus } from '../../../../../hooks/useCanAutofocus';
import { useSubscribeOnFormChanges } from '../../../../../utils/form-helpers';
import { FormState, useStoreOperations } from './useStoreOperations';

import styles from './CustomerProfile.module.scss';
import { useHistory } from 'react-router-dom';
import { useGetLoginUrl } from 'auth/useGetLoginUrl';

interface Props {
  customerProfile?: TCustomerProfile;
  showLoginLink: boolean;
  onStepForward(): void;
  renderFooter(form: UseFormReturn<FormState>): ReactElement;
}

export const CustomerProfile: FC<Props> = (props) => {
  const { customerProfile, showLoginLink, onStepForward, renderFooter } = props;
  const { defaultValues, patchStore } = useStoreOperations(customerProfile);

  const form = useForm<FormState>({
    mode: 'all',
    defaultValues,
  });

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = form;

  useSubscribeOnFormChanges(watch, patchStore);

  const canAutofocus = useCanAutofocus();

  const history = useHistory();
  const loginUrl = useGetLoginUrl();

  const handleLoginClick = useCallback(() => {
    history.replace(loginUrl);
  }, [history, loginUrl]);

  return (
    <form
      data-testid="form"
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit((formState) => {
        patchStore(formState);
        return onStepForward();
      })}
    >
      {renderFooter(form)}
      <div className={styles.container}>
        <TextInput
          label="Your First Name"
          placeholder="Forename"
          maxLength={NAME_MAX_LENGTH}
          autoFocus={canAutofocus}
          id="cd-firstName"
          hasError={!!errors.firstName}
          {...register('firstName', {
            required: true,
            minLength: NAME_MIN_LENGTH,
            maxLength: NAME_MAX_LENGTH,
          })}
        />
        <TextInput
          label="Your Last Name"
          placeholder="Surname"
          maxLength={NAME_MAX_LENGTH}
          id="cd-lastName"
          hasError={!!errors.lastName}
          {...register('lastName', {
            required: true,
            minLength: NAME_MIN_LENGTH,
            maxLength: NAME_MAX_LENGTH,
          })}
        />
        <TextInput
          label="Your E-Mail"
          placeholder="E-Mail"
          id="cd-email"
          hasError={!!errors.email}
          {...register('email', {
            required: true,
            pattern: emailRegex,
          })}
        />

        {showLoginLink && (
          <div className={styles.loginText}>
            {'Already have an account? '}
            <span className={styles.loginLink} onClick={handleLoginClick}>
              Login
            </span>
          </div>
        )}
      </div>
    </form>
  );
};

import { FC, useEffect, useRef } from 'react';
import { CustomerProfile, SubmittedOrder } from 'dataTypes';

interface TrustedShopsDataTransferProps {
  order: SubmittedOrder;
  customerProfile: CustomerProfile;
}

export const TrustedShopsDataTransfer: FC<TrustedShopsDataTransferProps> = ({
  order,
  customerProfile,
}) => {
  const trustedShopsContainerRef = useRef<HTMLDivElement>(null);
  const showTrustedShopsMessage = !localStorage.getItem(`trustedShopsMessageShown_${order.id}`);

  useEffect(() => {
    if (trustedShopsContainerRef.current && showTrustedShopsMessage) {
      const trustedShopsContainer = trustedShopsContainerRef.current;
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.innerHTML = 'trustbadge.remove(); trustbadge.reInitialize();';
      trustedShopsContainer.appendChild(s);
      localStorage.setItem(`trustedShopsMessageShown_${order.id}`, 'true');
    }
  }, [trustedShopsContainerRef, order.id, showTrustedShopsMessage]);

  return (
    <div ref={trustedShopsContainerRef} id="trustedShopsContainer">
      <div id="trustedShopsCheckout" style={{ display: 'none' }}>
        <span id="tsCheckoutOrderNr" data-testid="order-number">{`${order.createdDate.format(
          'YYYY-MM-DD',
        )}-${order.id}`}</span>
        <span id="tsCheckoutBuyerEmail">{customerProfile.email}</span>
        <span id="tsCheckoutOrderAmount">{order.purchase?.totals.sellPrice}</span>
        <span id="tsCheckoutOrderCurrency">GBP</span>
        <span id="tsCheckoutOrderPaymentType">PREPAYMENT</span>
      </div>
    </div>
  );
};

import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import { shortSiteTitle } from '../../constants';
import { PAYMENT_METHODS_URL, PAYMENT_METHODS_NEW_URL } from '../../routePaths';
import { Main } from './pages/Main';
import { New } from './pages/New';

/* istanbul ignore file */

export const PaymentMethods: FC = () => {
  return (
    <>
      <Helmet>
        <title>{shortSiteTitle} - My payment methods</title>
      </Helmet>
      <Switch>
        <Route exact path={PAYMENT_METHODS_URL} component={Main} />
        <Route path={PAYMENT_METHODS_NEW_URL} component={New} />
      </Switch>
    </>
  );
};

import { SystemOfMeasure } from 'dataTypes';

interface MetricHeight {
  readonly systemOfMeasure: 'Metric';
  readonly cm: string;
}

interface ImperialHeight {
  readonly systemOfMeasure: 'Imperial';
  readonly feet: string;
  readonly inches: string;
}

export type Height = MetricHeight | ImperialHeight;

export function imperialHeightToString(feet: string, inches: string): string {
  return `${feet ? feet + "'" : ''}${inches ? inches + '"' : ''}`; // e.g. `5'3.5"`
}

export function stringToHeight(value?: string): Height | undefined {
  if (!value) {
    return undefined;
  }

  const imperialRegexp = /^(?<feet>\d+)?'((?<inches>\d+(\.\d+)?)")?$/; // parses `5'3.1"` to { feet: '5', inches: '3.1' }
  const imperialResult = imperialRegexp.exec(value);

  if (imperialResult) {
    return {
      systemOfMeasure: 'Imperial',
      feet: imperialResult.groups?.feet ?? '',
      inches: imperialResult.groups?.inches ?? '',
    };
  }

  const metricRegexp = /^(?<cm>\d+)$/; // parses `145` to { cm: '145' }
  const metricResult = metricRegexp.exec(value);

  if (metricResult && metricResult.groups) {
    return {
      systemOfMeasure: 'Metric',
      cm: metricResult.groups.cm,
    };
  }

  return undefined;
}

export function heightToString(
  height: Height | undefined,
  systemOfMeasure: SystemOfMeasure,
): string {
  if (!height) {
    return '';
  }

  if (systemOfMeasure === 'Imperial') {
    const imperialHeight = heightAsImperial(height);
    return imperialHeight ? imperialHeightToString(imperialHeight.feet, imperialHeight.inches) : '';
  } else {
    const metricHeight = heightAsMetric(height);
    return metricHeight ? metricHeight.cm : '';
  }
}

const CM_IN_INCH = 2.54;

export function heightAsMetric(height: Height | undefined): MetricHeight | undefined {
  if (height?.systemOfMeasure === 'Metric') {
    return height;
  } else if (height?.systemOfMeasure === 'Imperial') {
    if (!height.feet && !height.inches) {
      return undefined;
    }

    const feet = height.feet ? Number.parseInt(height.feet) : 0;
    const inches = height.inches ? Number.parseFloat(height.inches) : 0;

    return {
      systemOfMeasure: 'Metric',
      cm: `${Math.round((feet * 12 + inches) * CM_IN_INCH)}`,
    };
  }
}

export function heightAsImperial(height: Height | undefined): ImperialHeight | undefined {
  if (height?.systemOfMeasure === 'Imperial') {
    return height;
  } else if (height?.systemOfMeasure === 'Metric') {
    if (!height.cm) {
      return undefined;
    }

    const totalInches = Number.parseFloat(height.cm) / CM_IN_INCH;
    let feet = Math.floor(totalInches / 12);
    let inches = Math.round(totalInches - feet * 12);
    if (inches === 12) {
      feet++;
      inches = 0;
    }

    return {
      systemOfMeasure: 'Imperial',
      feet: `${feet}`,
      inches: inches === 0 ? '' : `${inches}`,
    };
  }
}

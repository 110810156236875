import { FC, useState } from 'react';
import * as React from 'react';
import { PaymentMethod } from '../../../../../../dataTypes';
import { CardSummary } from '../../../../../../components/CardSummary';
import styles from './OrderPaymentMethod.module.scss';
import { PaymentMethodSelector } from './PaymentMethodSelector';
import editIcon from '../../../../../../assets/images/icons/edit.svg';

interface Props {
  selectedPaymentMethod: PaymentMethod;
  onSelectPaymentMethod(paymentMethod: PaymentMethod): void;
  paymentMethods: PaymentMethod[];
}

export const OrderPaymentMethod: FC<Props> = (props: Props) => {
  const { selectedPaymentMethod, paymentMethods, onSelectPaymentMethod } = props;
  const [selectMode, setSelectMode] = useState(false);

  const switchToSelectMode = (e: React.MouseEvent): void => {
    e.preventDefault();
    setSelectMode(true);
  };

  const selectPaymentMethod = (paymentMethod: PaymentMethod, e: React.SyntheticEvent): void => {
    e.preventDefault();
    onSelectPaymentMethod(paymentMethod);
    setSelectMode(false);
  };

  return (
    <div className={styles.wrapper}>
      {!selectMode && (
        <div className={styles.box}>
          <CardSummary small card={selectedPaymentMethod.card} />
          <div className={styles.edit} onClick={switchToSelectMode} data-testid="change">
            <img src={editIcon} alt="edit" />
          </div>
        </div>
      )}
      {selectMode && (
        <PaymentMethodSelector
          selectedPaymentMethod={selectedPaymentMethod}
          onSelectPaymentMethod={selectPaymentMethod}
          paymentMethods={paymentMethods}
        />
      )}
    </div>
  );
};

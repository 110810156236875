import { makeAutoObservable, runInAction } from 'mobx';
import { PaymentApiService } from '../services/PaymentApiService';
import { PaymentMethod, PaymentResult, PaymentResultStatus } from '../dataTypes';
import { OrderListStore } from './OrderListStore';

export class PaymentStore {
  get paymentResult(): PaymentResult | null {
    return this._paymentResult;
  }

  get lastPaymentStatusLoaded(): boolean {
    return this._lastPaymentStatusLoaded;
  }

  constructor(
    private readonly api: PaymentApiService,
    private readonly orderListStore: OrderListStore,
  ) {
    makeAutoObservable(this);
  }

  private _paymentResult: PaymentResult | null = null;
  private _lastPaymentStatusLoaded = false;

  loadLastPaymentStatus(orderId: string): void {
    this._lastPaymentStatusLoaded = false;
    this._paymentResult = null;

    void this.api.getPaymentStatus(orderId).then((status) => {
      runInAction(() => {
        this._paymentResult = status;
      });
    });

    this._lastPaymentStatusLoaded = true;
  }

  reset(): void {
    this._lastPaymentStatusLoaded = false;
    this._paymentResult = null;
  }

  pay(
    orderId: string,
    paymentMethod: PaymentMethod,
    sellPrice?: number,
    purchasedItemIds?: string[],
  ): void {
    void this.__pay(orderId, paymentMethod.id, sellPrice, purchasedItemIds);
  }

  async createZipCheckout(
    orderId: string,
    sellPrice?: number,
    purchasedItemIds?: string[],
  ): Promise<void> {
    this._paymentResult = { status: PaymentResultStatus.Processing };

    const payload = await this.api.getZipCheckoutLink(orderId, sellPrice, purchasedItemIds);
    payload.redirectUrl && window.location.replace(payload.redirectUrl);
  }

  async payWithZip(orderId: string, checkoutId: string): Promise<void> {
    this._paymentResult = { status: PaymentResultStatus.Processing };

    try {
      const newPaymentResult = await this.api.payWithZip(orderId, checkoutId);
      runInAction(() => {
        this._paymentResult = newPaymentResult;
      });

      if (newPaymentResult?.status === PaymentResultStatus.Completed) {
        this.orderListStore.reloadOrder(orderId);
      }
    } catch (e) {
      this._paymentResult = {
        status: PaymentResultStatus.Failed,
        errorMessage: 'Network error',
      };
    }
  }

  private async __pay(
    orderId: string,
    paymentMethodId: string,
    sellPrice?: number,
    purchasedItemIds?: string[],
  ): Promise<void> {
    this._paymentResult = { status: PaymentResultStatus.Processing };
    try {
      const newPaymentResult = await this.__payApiCall(
        orderId,
        paymentMethodId,
        sellPrice,
        purchasedItemIds,
      );
      runInAction(() => {
        this._paymentResult = newPaymentResult;
      });

      if (newPaymentResult?.status === PaymentResultStatus.Completed) {
        this.orderListStore.reloadOrder(orderId);
      }
    } catch (e) {
      this._paymentResult = {
        status: PaymentResultStatus.Failed,
        errorMessage: 'Network error',
      };
    }
  }

  private async __payApiCall(
    orderId: string,
    paymentMethodId: string,
    sellPrice?: number,
    purchasedItemIds?: string[],
  ): Promise<PaymentResult | null> {
    if (sellPrice && purchasedItemIds) {
      return await this.api.payDesiredAmount(orderId, paymentMethodId, sellPrice, purchasedItemIds);
    } else {
      return await this.api.directPayForOrder(orderId, paymentMethodId);
    }
  }
}

import React, { FC, PropsWithChildren } from 'react';
import styles from './RegistrationFlowLayout.module.scss';
import { CookieConsent } from '../CookieConsent';
import * as consentConstants from './../../consentConstants';
import { useAnalytics } from '../../analytics';
// import StylistMessage from 'components/StylistMessage/StylistMessage';
// import ProgressBar from 'pages/RegistrationFlow/components/ProgressBar';
import BoxSamplesTiles from 'components/BoxSamplesTiles';
import menSamples from 'components/BoxSamplesTiles/samples/menSamples';
import kidsSamples from 'components/BoxSamplesTiles/samples/kidsSamples';
// import RegistrationFlowContext from 'providers/RegistrationFlowContext';
import { useStylingFor } from 'pages/RegistrationFlow/hooks/useStylingFor';
import { BoxSample, StylingFor } from 'dataTypes';
import shuffleArray from 'utils/shuffleArray';
import Footer from 'components/Footer';
import Header from 'components/Header';

interface Props {
  imageUrl?: string;
  name?: string;
  stepsCount: number;
  currentStepIndex: number;
}

const RegistrationFlowLayout: FC<PropsWithChildren<Props>> = ({
  children,
  // imageUrl,
  // name,
  // stepsCount,
  // currentStepIndex,
}) => {
  const { loadScripts } = useAnalytics();
  // const { header } = useContext(RegistrationFlowContext).values;

  const stylingFor = useStylingFor();
  // const showProgressBar = currentStepIndex + 1 <= stepsCount;
  // const showStylistMessage = showProgressBar && !header.isHidden;

  const boxSamples = shuffleArray<BoxSample>(
    stylingFor === StylingFor.Men ? menSamples : kidsSamples,
  );

  return (
    <div className={styles.wrapper}>
      <main className={styles.main}>
        <Header showNavButtons={true} />
        {/* {showProgressBar && (
          <ProgressBar stepsCount={stepsCount} currentStepIndex={currentStepIndex} />
        )} */}
        {/* {showStylistMessage && (
          <StylistMessage
            imageUrl={imageUrl}
            name={name || 'Lorna'}
            title={header.title || ''}
            message={header.subTitle || ''}
          />
        )} */}
        <div className={styles.container}>{children}</div>

        <section className={styles.boxSamplesWrapper}>
          <div className={styles.boxSamplesSection} id="BoxSamplesTiles">
            <h2 className={styles.boxSamplesTitle}>Here’s what our customers recently paid</h2>
            <div className={styles.boxSamplesSubtitle}>Remember, you can pay what you want!</div>
            <BoxSamplesTiles boxSamples={boxSamples} />
          </div>
        </section>

        <Footer />
      </main>
      <CookieConsent
        cookieName={consentConstants.COOKIE_NAME}
        cookieValue={consentConstants.ACCEPTED_VALUE}
        onAccept={loadScripts}
      />
    </div>
  );
};

export default RegistrationFlowLayout;

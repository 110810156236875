import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { useService } from '../providers/useService';
import { CreateAddressPayload, SavedAddress } from '../dataTypes';
import { useAlert } from 'react-alert';

/* istanbul ignore file */

const addressListKey = ['addresses'] as const;

export function useAddressList(): UseQueryResult<SavedAddress[]> {
  const { addressApiService } = useService();

  return useQuery(addressListKey, async () => {
    return await addressApiService.getList();
  });
}

export function useCreateAddress(): UseMutationResult<SavedAddress, unknown, CreateAddressPayload> {
  const { addressApiService } = useService();
  const queryClient = useQueryClient();
  const { error } = useAlert();

  return useMutation(
    async (payload: CreateAddressPayload) => {
      return await addressApiService.create(payload);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(addressListKey),
      onError: () => {
        error(
          'Oops! We were unable to save an address. Please try again a bit later or contact us if problem persists.',
        );
      },
    },
  );
}

export function useSetDefaultAddress(id: string): UseMutationResult {
  const { addressApiService } = useService();
  const queryClient = useQueryClient();
  const { error } = useAlert();

  return useMutation(
    async () => {
      return await addressApiService.setDefault(id);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(addressListKey),
      onError: () => {
        error(
          'Oops! We were unable to set this address as default one. Please try again a bit later or contact us if problem persists.',
        );
      },
    },
  );
}

export function useDeleteAddress(id: string): UseMutationResult {
  const { addressApiService } = useService();
  const queryClient = useQueryClient();
  const { error } = useAlert();

  return useMutation(
    async () => {
      const confirmResult = window.confirm('Are you sure you want to delete this address?');
      if (!confirmResult) {
        return;
      }
      return await addressApiService.delete(id);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(addressListKey),
      onError: () => {
        error(
          'Oops! We were unable to delete this address. Please try again a bit later or contact us if problem persists.',
        );
      },
    },
  );
}

import { FC } from 'react';
import classNames from 'classnames';
import { AlertComponentPropsWithStyle } from 'react-alert';
import styles from './AlertTemplate.module.scss';
import errorIcon from './image/error.svg';

/* istanbul ignore file */

export const AlertTemplate: FC<AlertComponentPropsWithStyle> = (props) => {
  const { style, close, message, options } = props;

  const alertTypeClass = options.type && options.type in styles ? styles[options.type] : undefined;

  return (
    <div className={classNames(styles.container, alertTypeClass)} style={style} onClick={close}>
      <div className={styles.wrapper}>
        <img src={errorIcon} className={styles.errorIcon} alt="" />
        <div className={styles.message}>{message}</div>
      </div>
    </div>
  );
};

import { useCallback, useMemo } from 'react';
import { useStore } from '../../../../../providers/useStore';
import { StylingFor, StylingProfile } from '../../../../../dataTypes';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function useStoreOperations(stylingProfiles: StylingProfile[]) {
  const {
    registrationFlowStore,
    registrationFlowStore: { order },
  } = useStore();

  const defaultValue = useMemo<StylingProfile>(() => {
    return order.stylingProfile.id ? order.stylingProfile : stylingProfiles[0];
  }, [order.stylingProfile, stylingProfiles]);

  const patchStore = useCallback(
    (stylingProfile: StylingProfile): void => {
      registrationFlowStore.patch({
        stylingProfile,
      });
    },
    [registrationFlowStore],
  );

  const clearStore = (): void =>
    patchStore({
      id: undefined,
      firstName: '',
      lastName: '',
      dateOfBirth: undefined,
      gender: order.stylingFor === StylingFor.Men ? 'Male' : 'Boy',
      questionnaire: [],
      questionnaireComments: undefined,
      systemOfMeasure: 'Imperial',
    } as StylingProfile);

  return {
    defaultValue,
    patchStore,
    clearStore,
  };
}

import { OrderDraft, StylingFor } from '../../dataTypes';

export const defaultKidsRegistrationData: OrderDraft = {
  stylingFor: StylingFor.Kids,
  intention: '',
  clothingCategories: [],
  stylingProfile: {
    dateOfBirth: undefined,
    gender: 'Boy',
    firstName: '',
    lastName: '',
    questionnaire: [],
    questionnaireComments: '',
    systemOfMeasure: 'Imperial',
  },
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  communicationMethod: 'call',
  communicationDateTime: {},
};

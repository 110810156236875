import { OrderDraftApiService } from '../services/OrderDraftApiService';
import { OrderApiService } from '../services/OrderApiService';
import { PaymentApiService } from '../services/PaymentApiService';
import { CustomerProfileApiService } from '../services/CustomerProfileApiService';
import { AddressApiService } from '../services/AddressApiService';
import { OfferApiService } from 'services/OfferApiService';
import { ReturnApiService } from 'services/ReturnApiService';
import { ReviewApiService } from 'services/ReviewApiService';

export class Services {
  constructor(
    public readonly orderDraftApiService: OrderDraftApiService,
    public readonly apiService: OrderApiService,
    public readonly paymentApiService: PaymentApiService,
    public readonly customerProfileApiService: CustomerProfileApiService,
    public readonly addressApiService: AddressApiService,
    public readonly offerApiService: OfferApiService,
    public readonly returnApiService: ReturnApiService,
    public readonly reviewApiService: ReviewApiService,
  ) {}
}

/* eslint-disable @typescript-eslint/no-misused-promises */
import { FC, useState } from 'react';
import { StatusViewProps } from '../../../propTypes';
import { OrderMessage } from '../../OrderMessage';
import { DeliveryDetailsInfo } from '../../DeliveryDetailsInfo';
import { ItemList } from 'pages/Orders/components/ItemList';
import styles from './DeliveredStatusView.module.scss';
import { arrayHasItems } from 'utils';
import { useMakeOffer } from 'serverCache/offer';
import TwoColumnLayout from 'components/TwoColumnLayout';
import { Button } from 'components/Button';
import OfferSlider from './components/OfferSlider';

export const DeliveredStatusView: FC<StatusViewProps> = (props: StatusViewProps) => {
  const { order } = props;

  const [showOffer, setShowOffer] = useState(true);

  const { mutateAsync: makeOfferAsync, isLoading: isMakingOffer } = useMakeOffer(order.id);

  const handleShowOffer = async (): Promise<void> => {
    setShowOffer(false);
    await makeOfferAsync({ sellPrice: 1, purchasedItemIds: order.items.map((i) => i.id) });
  };

  return (
    <TwoColumnLayout
      firstColumn={<DeliveryDetailsInfo order={order} hideOnMobile />}
      secondColumn={
        <>
          {!showOffer && !isMakingOffer && (
            <>
              <OrderMessage messageText="Please wait to be contacted by your stylist to finalize the purchase." />
              {arrayHasItems(order.items) && (
                <ItemList title="My items" showDetails items={order.items} />
              )}
            </>
          )}
          {(showOffer || isMakingOffer) && (
            <>
              <OfferSlider order={order} />
              <div className={styles.box}>
                <div className={styles.talk} onClick={handleShowOffer}>
                  Not found your price? <strong>Speak to your stylist to pay what you want</strong>
                </div>
                <Button color="white" className={styles.button}>
                  <strong> CHAT WITH STYLIST</strong>
                </Button>
              </div>
            </>
          )}
        </>
      }
    />
  );
};

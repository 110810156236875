import { FC } from 'react';
import { generatePath, matchPath, NavLink } from 'react-router-dom';
import styles from './RightMenu.module.scss';
import { HELP_URL, PROFILE_URL, PROTECTED_URL } from '../../../../routePaths';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../../../../providers/AuthProvider';
import classnames from 'classnames';

export const RightMenu: FC = observer(() => {
  const { isAuthenticated } = useAuth();

  // TODO: in case of isAuth use component that will go to API
  const accountText = isAuthenticated ? 'My Account' : 'Login';

  return (
    <div className={styles.links}>
      <NavLink
        to={generatePath(HELP_URL)}
        activeClassName={styles.active}
        className={styles.linksItem}
      >
        <div className={classnames(styles.linkIcon, styles.faqIcon)} />
        <span className={styles.text}>faq</span>
      </NavLink>

      <NavLink
        to={generatePath(PROFILE_URL)}
        isActive={(match, location) => {
          const matchProtected = matchPath(location.pathname, {
            path: PROTECTED_URL,
          });

          return !!matchProtected;
        }}
        activeClassName={styles.active}
        className={styles.linksItem}
        data-testid="profile-link"
      >
        <div className={classnames(styles.linkIcon, styles.userIcon)} />
        <span className={styles.text}>{accountText}</span>
      </NavLink>
    </div>
  );
});

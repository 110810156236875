import { useService } from 'providers/useService';
import { useMutation, UseMutationResult } from 'react-query';

interface Params {
  itemId: string;
  returnReason: string;
}

export function useSetItemReturnReason(returnId: string): UseMutationResult<void, unknown, Params> {
  const { returnApiService } = useService();

  return useMutation(async (params: Params) => {
    await returnApiService.setReturnReasonForItem(returnId, params.itemId, params.returnReason);
  });
}

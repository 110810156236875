import { FC, useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { getChangePasswordRedirectUrl } from '../../utils/changePasswordRedirect';

export const ChangePasswordRedirect: FC = () => {
  const history = useHistory();
  useEffect(() => {
    const urlToRedirect = getChangePasswordRedirectUrl();

    history.push(generatePath(urlToRedirect));
  }, [history]);

  return null;
};

import { FC } from 'react';
import styles from './CardStep.module.scss';
import classNames from 'classnames';
import { Check } from '../Icons/Check';

export interface Props {
  number: number;
  image: JSX.Element;
  title: string;
  visited?: boolean;
}

export const CardStep: FC<Props> = ({ number, image, title, visited }) => {
  /* istanbul ignore next */
  return (
    <div
      className={classNames(styles.card, {
        [styles.cardVisited]: visited,
      })}
    >
      <div className={styles.box}>
        <div className={styles.number}>{visited ? <Check /> : number}</div>
        {image}
      </div>
      <div className={styles.title}>{title}</div>
    </div>
  );
};

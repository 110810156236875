import { FC } from 'react';
import { generatePath, Link } from 'react-router-dom';
import {
  ABOUT_URL,
  HELP_URL,
  ORDER_LIST_URL,
  PRIVACY_URL,
  PROFILE_URL,
  TERMS_AND_CONDITIONS_URL,
} from 'routePaths';
import styles from './Footer.module.scss';
import classNames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const currentYear = new Date().getFullYear();

const Footer: FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.blockFirst}>
          <div className={styles.footerLogo} />
          <div className={styles.footerText}>
            With clothing hand selected by our expert stylists for your unique size & style, you’ll
            always look and feel your best. No subscription required.
          </div>
          <div className={styles.footerIconsPayment}>
            <div className={classNames(styles.footerIconsPaymentItem, styles.visa)}></div>
            <div className={classNames(styles.footerIconsPaymentItem, styles.mastercard)}></div>
          </div>
          <div className={styles.footerIconsSocial}>
            <a href="https://www.facebook.com/choixceuk" rel="noreferrer" target="_blank">
              <div className={classNames(styles.socialLink, styles.facebook)}></div>
            </a>

            <a href="https://www.instagram.com/choixceuk/" rel="noreferrer" target="_blank">
              <div className={classNames(styles.socialLink, styles.instargam)}></div>
            </a>

            <a href="https://www.tiktok.com/@choixceuk" rel="noreferrer" target="_blank">
              <div className={classNames(styles.socialLink, styles.tiktok)}></div>
            </a>
          </div>
        </div>

        <div className={styles.blocks}>
          <div className={styles.block}>
            <div className={styles.footerTitle}>choixce limited</div>
            <ul className={styles.footerAddressList}>
              <li className={styles.footerAddressListItem}>
                <LazyLoadImage src="/images/contact/address.svg" alt="" />
                <div>
                  Choixce Limited 11 Cronin Courtyard Corby Northampton
                  <span className={styles.displayBlock}>NN18 8AG</span>
                </div>
              </li>
              <li className={styles.footerAddressListItem}>
                <LazyLoadImage src="/images/contact/phone.svg" alt="" />
                <div>07548 824060</div>
              </li>
              <li className={styles.footerAddressListItem}>
                <LazyLoadImage src="/images/contact/email.svg" alt="" />
                <div>info@choixce.com</div>
              </li>
            </ul>
          </div>

          <div className={styles.blockLast}>
            <div className={styles.block}>
              <div className={styles.footerTitle}>help</div>
              <Link to={generatePath(HELP_URL)} className={styles.footerLink}>
                FAQ
              </Link>
              <Link to={generatePath(TERMS_AND_CONDITIONS_URL)} className={styles.footerLink}>
                Terms of use
              </Link>
              <Link to={generatePath(PRIVACY_URL)} className={styles.footerLink}>
                Privacy Policy
              </Link>
              <Link to={generatePath(ABOUT_URL)} className={styles.footerLink}>
                About us
              </Link>
            </div>

            <div className={styles.block}>
              <div className={styles.footerTitle}>account</div>
              <Link to={generatePath(ORDER_LIST_URL)} className={styles.footerLink}>
                My orders
              </Link>
              <Link to={PROFILE_URL} className={styles.footerLink}>
                My profile
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.copy}>© {currentYear}. Choixce Limited.</div>
    </footer>
  );
};

export default Footer;

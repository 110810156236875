import { useAuth } from './AuthProvider';
import { useStore } from './useStore';

export const useLogout = (): ((returnTo?: string) => void) => {
  const { registrationFlowStore } = useStore();
  const { logout: authLogout } = useAuth();

  const logout = (returnTo?: string): void => {
    registrationFlowStore.clear();
    authLogout(returnTo);
  };

  return logout;
};

/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-explicit-any */

// TODO replace to some library. e.g. https://react-query.tanstack.com/

import { useEffect, useRef, useState } from 'react';

type AsyncFn<T = any> = () => Promise<T>;

export interface UseAsyncResult<T> {
  data: T | null;
  error: Error | null;
  loading: boolean;
}

export function useAsync<T = any>(func: AsyncFn<T>, params: unknown[]): UseAsyncResult<T> {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const funcRef = useRef(func);
  useEffect(() => {
    funcRef.current = func;
  }, [func]);

  useEffect(() => {
    setLoading(true);
    setData(null);
    setError(null);

    funcRef
      .current()
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        if (err instanceof Error) {
          setError(err);
        }
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...params]);

  return {
    data,
    error,
    loading,
  };
}

import { FC } from 'react';
import { StylingProfileCard } from 'components/StylingProfileCard';
import { StylingProfile } from 'dataTypes';

import styles from './RadioCard.module.scss';
interface RadioCardsProps {
  items: StylingProfile[];
  selectedItem?: StylingProfile;
  onSelect(profile: StylingProfile): void;
}

// Future refactoring: generalize the component and use generic type
export const RadioCard: FC<RadioCardsProps> = ({ items, selectedItem, onSelect }) => {
  return (
    <div className={styles.wrapperCard}>
      {items.map((item, index) => {
        return (
          <StylingProfileCard
            key={item.id || index}
            selected={selectedItem?.id === item.id}
            profile={item}
            onClick={onSelect}
          />
        );
      })}
    </div>
  );
};

import { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';

export type Color = 'black' | 'white';
export type Size = 'medium' | 'large' | 'small';
export type Width = 'wide' | 'full';

export interface StylingProps {
  color?: Color;
  size?: Size;
  width?: Width;
  className?: string;
  children?: ReactNode;
  endIcon?: ReactNode;
  startIcon?: ReactNode;
}

export function useApplyStyles<T extends StylingProps>(
  props: T,
): [string, ReactNode, Omit<T, keyof StylingProps>] {
  const {
    color = 'black',
    size = 'regular',
    width = 'regular',
    className: forwardedClassName,
    children,
    startIcon,
    endIcon,
    ...otherProps
  } = props;

  const className = classNames(
    styles.base,
    styles[`color_${color}`],
    styles[`size_${size}`],
    styles[`width_${width}`],
    forwardedClassName,
  );

  return [
    className,
    <>
      {startIcon}
      {children}
      {endIcon}
    </>,
    otherProps,
  ];
}

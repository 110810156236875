import dayjs from 'dayjs';

export function isWorkingDay(dayOfWeek: number): boolean {
  return dayOfWeek !== 6 && dayOfWeek !== 0;
}

export function getOffsetToWorkingDays(dayOfWeek: number, daysToSkip: number): number[] {
  let i = daysToSkip;
  const result = [];
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  while (result.length < 3) {
    if (isWorkingDay((dayOfWeek + i) % 7)) {
      result.push(i);
    }
    ++i;
  }

  return result;
}

export function formatDay(currentDate: dayjs.Dayjs, daysToSkip: number): string {
  if (daysToSkip === 0) {
    return 'Today';
  } else if (daysToSkip === 1) {
    return 'Tomorrow';
  }
  return currentDate.add(daysToSkip, 'day').format('dddd');
}

export function getAvailableCommunicationDays(
  endOfWorkingDay: number,
  currentDate: dayjs.Dayjs = dayjs(),
): { label: string; value: string }[] {
  const dayOfWeek = currentDate.day();
  let daysToSkip = 0;

  if (isWorkingDay(dayOfWeek) && currentDate.toDate().getUTCHours() >= endOfWorkingDay) {
    daysToSkip++;
  }

  const nearestWorkingDays = getOffsetToWorkingDays(dayOfWeek, daysToSkip);

  return nearestWorkingDays.map((day) => ({
    label: formatDay(currentDate, day),
    value: currentDate.add(day, 'day').format('DD/MM/YYYY'),
  }));
}

export function formatTime(value: number): string {
  return value <= 12 ? `${value}AM` : `${value - 12}PM`;
}

export function getAvailableCommunicationTime(
  start: number,
  end: number,
  step: number,
): { label: string; value: string }[] {
  const result: { label: string; value: string }[] = [];
  for (let i = start; i < end; i += step) {
    const timeRange = `${formatTime(i)} - ${formatTime(i + step)}`;
    result.push({
      label: timeRange,
      value: timeRange,
    });
  }

  return result;
}

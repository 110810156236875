import { FC } from 'react';
import styles from './Total.module.scss';

interface Props {
  fullPrice: number;
  discountPrice: number;
  deposit: number;
  sellPrice: number;
  discountPercent?: number;
}

const Total: FC<Props> = ({ fullPrice, discountPrice, deposit, sellPrice, discountPercent }) => {
  const sellPriceString = sellPrice >= 0 ? `£${sellPrice}` : `-£${Math.abs(sellPrice)}`;

  return (
    <div className={styles.total}>
      <div className={styles.row}>
        <div className={styles.item}>Discount %:</div>
        <div className={styles.item}>{discountPercent}%</div>
      </div>
      <div className={styles.row}>
        <div className={styles.item}>Subtotal:</div>
        <div className={styles.item}>£{fullPrice}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.item}>Discount:</div>
        <div className={styles.item}>-£{discountPrice}</div>
      </div>
      {deposit > 0 && (
        <div className={styles.row}>
          <div className={styles.item}>Deposit:</div>
          <div className={styles.item}>-£{deposit}</div>
        </div>
      )}
      <div className={styles.row}>
        <div className={styles.item}>
          <strong>Total:</strong>
        </div>
        <div className={styles.item}>
          <strong>{sellPriceString}</strong>
        </div>
      </div>
    </div>
  );
};

export default Total;

import getFbPixelScript, { getFbEventScript } from './facebookPixel';
import getYmCounterScript, { getYmCounterNoScript, ymCounterHit } from './yandexMetrikaCounter';

export interface Analytics {
  readonly gaAccount: string;
  loadScripts(): void;
  scriptsLoaded: boolean;
  gtag: Gtag.Gtag;
  fbTrack(event: FbEvent): void;
  ymHit(): void;
}

export type FbEvent = 'Lead' | 'CompleteRegistration';

export const defaultAnalytics: Analytics = {
  gaAccount: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  loadScripts() {},
  scriptsLoaded: false,
  gtag: window.gtag,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fbTrack() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ymHit() {},
};

export class AnalyticsImpl implements Analytics {
  scriptsLoaded = false;

  constructor(
    public readonly gaAccount: string,
    public readonly fbPixelId: string,
    public readonly ymCounterId: string,
  ) {}

  loadScripts = (): void => {
    if (!this.scriptsLoaded) {
      this.renderScriptInHead(true, (script) => {
        script.src = `https://www.googletagmanager.com/gtag/js?id=${this.gaAccount}`;
      });

      this.renderScriptInHead(false, (script) => {
        script.text = getFbPixelScript(this.fbPixelId);
      });

      this.renderScriptInHead(false, (script) => {
        script.text = getYmCounterScript(this.ymCounterId);
      });
      this.renderNoScript(getYmCounterNoScript(this.ymCounterId));

      this.scriptsLoaded = true;
    }
  };

  private renderScriptInHead = (
    loadAsync: boolean,
    scriptCallback: (script: HTMLScriptElement) => void,
  ): void => {
    this.renderScript(loadAsync, scriptCallback, document.getElementsByTagName('head')[0]);
  };

  private renderScriptInBody = (
    loadAsync: boolean,
    scriptCallback: (script: HTMLScriptElement) => void,
  ): void => {
    this.renderScript(loadAsync, scriptCallback, document.getElementsByTagName('body')[0]);
  };

  private renderScript = (
    loadAsync: boolean,
    scriptCallback: (script: HTMLScriptElement) => void,
    elementToAppend: HTMLHeadElement | HTMLBodyElement,
  ): void => {
    const script = document.createElement('script');
    script.async = loadAsync;
    scriptCallback(script);

    elementToAppend.appendChild(script);
  };

  private renderNoScript = (noScriptContent: Node): void => {
    const noScript = document.createElement('noscript');
    noScript.appendChild(noScriptContent);

    const elementToAppend = document.getElementsByTagName('head')[0];
    elementToAppend.appendChild(noScript);
  };

  gtag = window.gtag;

  fbTrack = (event: 'Lead' | 'CompleteRegistration'): void => {
    if (this.scriptsLoaded) {
      this.renderScriptInBody(false, (script) => {
        script.text = getFbEventScript(event);
      });
    }
  };

  ymHit = (): void => {
    if (this.scriptsLoaded) {
      ymCounterHit(this.ymCounterId);
    }
  };
}

import { useQuery, UseQueryResult } from 'react-query';
import { useService } from '../providers/useService';
import { ReviewList } from '../dataTypes';

export function useReviews(): UseQueryResult<ReviewList> {
  const { reviewApiService } = useService();

  return useQuery(['reviews'], async () => {
    return await reviewApiService.getAllReviews();
  });
}

import { FC, useState } from 'react';
import styles from './AccordionItem.module.scss';
import classNames from 'classnames';
import { ArrowIcon } from 'components/Icons';

export interface Props {
  title: string;
  content: JSX.Element;
}

const AccordionItem: FC<Props> = (props: Props) => {
  const { title, content } = props;
  const [isActive, setIsActive] = useState(false);
  return (
    <div
      className={classNames(styles.card, {
        [styles.active]: isActive,
      })}
      onClick={(e) => {
        setIsActive(!isActive);
      }}
      data-testid={'card'}
    >
      <div className={styles.arrow}>
        <ArrowIcon />
      </div>
      <div className={styles.contentBox}>
        <h3 className={styles.title}>{title}</h3>
        <div className={styles.content}>{content}</div>
      </div>
    </div>
  );
};

export default AccordionItem;

import { useEffect } from 'react';
import { useAuth } from '../providers/AuthProvider';
import { useHistory } from 'react-router-dom';
import { useGetLoginUrl } from './useGetLoginUrl';

export const useAuthRedirect = (): void => {
  const { isAuthenticated, isLoading } = useAuth();
  const history = useHistory();
  const url = useGetLoginUrl();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      history.replace(url);
    }
  }, [history, isAuthenticated, isLoading, url]);
};

import { HeightQuestion, QuestionBase, SelectQuestion, WeightQuestion } from '.';

export const noOfKidsQuestionsInTopSection = 4;

export const kidsQuestions: QuestionBase<unknown>[] = [
  new SelectQuestion(
    'Top Half',
    [
      { title: '' },
      { title: '6 months' },
      { title: '1-2 years' },
      { title: '2-3 years' },
      { title: '3-4 years' },
      { title: '5-6 years' },
      { title: '7-8 years' },
      { title: '9-10 years' },
      { title: '11-12 years' },
      { title: '13-14 years' },
      { title: '15-16 years' },
    ].map((value) => ({
      value: value.title,
      label: value.title,
    })),
  ),

  new SelectQuestion(
    'Bottom Half',
    [
      { title: '' },
      { title: '6 months' },
      { title: '1-2 years' },
      { title: '2-3 years' },
      { title: '3-4 years' },
      { title: '5-6 years' },
      { title: '7-8 years' },
      { title: '9-10 years' },
      { title: '11-12 years' },
      { title: '13-14 years' },
      { title: '15-16 years' },
    ].map((value) => ({
      value: value.title,
      label: value.title,
    })),
  ),

  new SelectQuestion(
    'UK Shoe Size',
    [
      { title: '' },
      { title: '1' },
      { title: '2' },
      { title: '3' },
      { title: '4' },
      { title: '5' },
      { title: '6' },
      { title: '6.5' },
      { title: '7' },
      { title: '7.5' },
      { title: '8' },
      { title: '8.5' },
      { title: '9' },
      { title: '9.5' },
      { title: '10' },
      { title: '10.5' },
      { title: '11' },
      { title: '11.5' },
      { title: '12' },
      { title: '12.5' },
      { title: '13' },
      { title: '13.5' },
      { title: '14' },
    ].map((value) => ({
      value: value.title,
      label: value.title,
    })),
  ),

  new HeightQuestion('Height'),

  new WeightQuestion('Weight'),
];

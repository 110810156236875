export const longSiteTitle = 'Its Your Choixce';

export const shortSiteTitle = 'Choixce';

export const personalDetailsStepPath = 'who-are-we-styling-for';

export const intentionStepPath = 'how-can-we-help-you';

export const clothingCategoriesStepPath = 'what-do-you-need';

export const sizesStepPath = 'size-and-fit';

export const registrationStepPath = 'register-your-account';

export const deliveryAddressStepPath = 'delivery-address';

export const paymentDetailsStepPath = 'payment-details';

export const contactDetailsStepPath = 'contact-details';

export const congratulationsStepPath = 'congratulations';

export const goBackFromRegistrationFlowRedirectUrl = '/';

export const postcodeRegex = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i;

export const phoneRegex = /^(\(?\+?[0-9]*\)?)?[0-9_\- ()]{1,20}$/;

// Copied from https://github.com/jquense/yup/blob/b53e5f23ced4c2df7636203e1300b9938fd33b3a/src/string.ts#L9
// eslint-disable-next-line
export const emailRegex = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

export const changePasswordRedirectKey = 'changePasswordRedirectLocation';

// zigzag requirement
export const noAngleBracketsRegex = /^[^<>]*$/;

import { useAuth } from './AuthProvider';
import { useMemo } from 'react';
import { OrderDraftApiService } from '../services/OrderDraftApiService';
import { OrderApiService } from '../services/OrderApiService';
import { OrderListStore } from '../stores/OrderListStore';
import { CustomerProfileApiService } from '../services/CustomerProfileApiService';
import { PaymentApiService } from '../services/PaymentApiService';
import { AddressApiService } from '../services/AddressApiService';
import { PaymentStore } from '../stores/PaymentStore';
import { RegistrationFlowStore } from '../stores/RegistrationFlowStore';
import { Services } from './Services';
import { AppContext } from './AppContext';
import { Stores } from './Stores';
import { LocalPersistService } from '../stores/RegistrationFlowStore/LocalPersistService';
import { OfferApiService } from 'services/OfferApiService';
import { ReturnApiService } from 'services/ReturnApiService';
import { ReviewApiService } from 'services/ReviewApiService';

export const useCreateContext = (): AppContext => {
  const { axiosInstance } = useAuth();
  return useMemo(() => {
    const orderDraftApiService = new OrderDraftApiService(axiosInstance);
    const apiService = new OrderApiService(axiosInstance);
    const paymentService = new PaymentApiService(axiosInstance);
    const orderListStore = new OrderListStore(apiService);
    const customerProfileApiService = new CustomerProfileApiService(axiosInstance);
    const addressApiService = new AddressApiService(axiosInstance);
    const offerApiService = new OfferApiService(axiosInstance);
    const returnApiService = new ReturnApiService(axiosInstance);
    const reviewApiService = new ReviewApiService(axiosInstance);

    const paymentStore = new PaymentStore(paymentService, orderListStore);
    const localPersistService = new LocalPersistService();

    const registrationFlowStore = new RegistrationFlowStore(
      orderDraftApiService,
      apiService,
      localPersistService,
    );

    const services = new Services(
      orderDraftApiService,
      apiService,
      paymentService,
      customerProfileApiService,
      addressApiService,
      offerApiService,
      returnApiService,
      reviewApiService,
    );

    const stores = new Stores(orderListStore, paymentStore, registrationFlowStore);

    return {
      stores,
      services,
    };
  }, [axiosInstance]);
};

import { FC } from 'react';
import styles from './RatingRequest.module.scss';
import { useGetReviewLink } from './useGetReviewLink';

interface Props {
  orderId: string;
  onClick?(): void;
}

const RatingRequest: FC<Props> = ({ orderId, onClick }) => {
  const { data: review } = useGetReviewLink(orderId);

  const linkProps = {
    href: review || '',
    target: '_blank',
    className: styles.box,
  };

  return (
    <a {...linkProps} onClick={onClick}>
      <div className={styles.text}>Please rate your experience</div>
      <div className={styles.starList}>
        {Array(5)
          .fill(0)
          .map((_, index) => (
            <svg
              key={index}
              className={styles.star}
              width="32"
              height="29"
              viewBox="0 0 32 29"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16 0L20.1568 10.2786L31.2169 11.0557L22.7259 18.1854L25.4046 28.9443L16 23.072L6.59544 28.9443L9.27413 18.1854L0.783095 11.0557L11.8432 10.2786L16 0Z" />
            </svg>
          ))}
      </div>
    </a>
  );
};

export default RatingRequest;

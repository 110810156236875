import { FC } from 'react';

interface DetailsIconProps {
  className?: string;
  width: number;
  height: number;
}

export const DetailsIcon: FC<DetailsIconProps> = (props: DetailsIconProps) => {
  return (
    <div className={props.className} style={{ width: props.width, height: props.height }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 22 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M21 1H1V25H21V1Z" stroke="#0D0D0D" strokeWidth="1.8" strokeLinejoin="round" />
        <path d="M7 1V8L11 5L15 8V1" stroke="black" strokeWidth="1.8" strokeLinejoin="round" />
        <path
          d="M6 13H16"
          stroke="black"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 17H11"
          stroke="black"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

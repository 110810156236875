import { FC } from 'react';
import { StatusViewProps } from '../../../propTypes';
import { ItemList } from '../../../../ItemList';
import { arrayHasItems } from '../../../../../../../utils';
import { DeliveryDetailsInfo } from '../../DeliveryDetailsInfo';
import TwoColumnLayout from 'components/TwoColumnLayout';

export const UnknownStatusView: FC<StatusViewProps> = (props: StatusViewProps) => {
  const { order } = props;

  return (
    <TwoColumnLayout
      firstColumn={<DeliveryDetailsInfo order={order} />}
      secondColumn={
        <>{arrayHasItems(order.items) && <ItemList title="Items" items={order.items} />}</>
      }
    />
  );
};

import { AnswerOption, ImageAnswerOption, TextAnswerOption } from '../../dataTypes';

export const genderMenAnswerOptions: AnswerOption[] = [{ title: 'Male', isSelected: true }];

export const genderKidsAnswerOptions: AnswerOption[] = [
  { title: 'Boy', isSelected: false },
  { title: 'Girl', isSelected: false },
];

export const intentionMenImageAnswerOptions: ImageAnswerOption[] = [
  {
    title: 'Wardrobe Basics',
    text: 'Everyday essentials to mix and match with your wardrobe',
    imageUrl: '/images/men/Wardrobe-Basics.webp',
    imageUrlHover: '/images/men/Wardrobe-Basics-hover.webp',
    isSelected: false,
  },
  {
    title: 'Special Occasion',
    text: 'A new look for a specific occasion ',
    imageUrl: '/images/men/Looking-for-Inspiration.webp',
    imageUrlHover: '/images/men/Looking-for-Inspiration-hover.webp',
    isSelected: false,
  },
  {
    title: 'Need Inspiration',
    text: 'We’ll select pieces we think you’ll love to refresh your look',
    imageUrl: '/images/men/Help-with-an-Occasion.webp',
    imageUrlHover: '/images/men/Help-with-an-Occasion-hover.webp',
    isSelected: false,
  },
];

export const intentionKidsAnswerOptions: ImageAnswerOption[] = [
  {
    title: 'Wardrobe Basics',
    text: 'Everyday essentials to mix and match with your wardrobe',
    imageUrl: '/images/kids/Wardrobe-Basics.webp',
    imageUrlHover: '/images/kids/Wardrobe-Basics-hover.webp',
    isSelected: false,
  },
  {
    title: 'Looking for Inspiration',
    text: 'A new look for a specific occasion ',
    imageUrl: '/images/kids/Looking-for-Inspiration.webp',
    imageUrlHover: '/images/kids/Looking-for-Inspiration-hover.webp',
    isSelected: false,
  },
  {
    title: 'Help with an occasion',
    text: 'We’ll select pieces we think you’ll love to refresh your look',
    imageUrl: '/images/kids/Help-with-an-Occasion.webp',
    imageUrlHover: '/images/kids/Help-with-an-Occasion-hover.webp',
    isSelected: false,
  },
];

export const answerOptionExists = (answerOptions: AnswerOption[], title: string): boolean => {
  return answerOptions.map((answer) => answer.title).includes(title);
};

export const intentionTextAnswerOption: TextAnswerOption = {
  title: 'Others',
  placeholder: 'Please specify here...',
  isSelected: false,
  value: '',
};

export const categoriesMenImageAnswerOptions: ImageAnswerOption[] = [
  {
    title: 'Coats & Jackets',
    imageUrl: '/images/categories/men/Coats-Jackets.webp',
    imageUrlHover: '/images/categories/men/Coats-Jackets-hover.webp',
    imageBadge: '/images/item-icons/jacketblazer.svg',
    isSelected: false,
  },
  {
    title: 'Blazers',
    imageUrl: '/images/categories/men/Blazers.webp',
    imageUrlHover: '/images/categories/men/Blazers-hover.webp',
    imageBadge: '/images/item-icons/coat.svg',
    isSelected: false,
  },
  {
    title: 'Suits',
    imageUrl: '/images/categories/men/Suits.webp',
    imageUrlHover: '/images/categories/men/Suits-hover.webp',
    imageBadge: '/images/item-icons/suit.svg',

    isSelected: false,
  },
  {
    title: 'Knitwear & Sweats',
    imageUrl: '/images/categories/men/Knitwear-Sweats.webp',
    imageUrlHover: '/images/categories/men/Knitwear-Sweats-hover.webp',
    imageBadge: '/images/item-icons/knitwear.svg',

    isSelected: false,
  },
  {
    title: 'Rugby Shirts',
    imageUrl: '/images/categories/men/Rugby-Shirts.webp',
    imageUrlHover: '/images/categories/men/Rugby-Shirts-hover.webp',
    imageBadge: '/images/item-icons/rugbyshirt.svg',

    isSelected: false,
  },
  {
    title: 'Shirts',
    imageUrl: '/images/categories/men/Shirts.webp',
    imageUrlHover: '/images/categories/men/Shirts-hover.webp',
    imageBadge: '/images/item-icons/shirt.svg',

    isSelected: false,
  },
  {
    title: 'Polo & T-shirts',
    imageUrl: '/images/categories/men/Polo-Shirts.webp',
    imageUrlHover: '/images/categories/men/Polo-Shirts-hover.webp',
    imageBadge: '/images/item-icons/poloshirt.svg',
    isSelected: false,
  },
  {
    title: 'Chinos & Jeans',
    imageUrl: '/images/categories/men/Chinos-Jeans.webp',
    imageUrlHover: '/images/categories/men/Chinos-Jeans-hover.webp',
    imageBadge: '/images/item-icons/jeans.svg',
    isSelected: false,
  },
  {
    title: 'Shorts',
    imageUrl: '/images/categories/men/Shorts.webp',
    imageUrlHover: '/images/categories/men/Shorts-hover.webp',
    imageBadge: '/images/item-icons/shorts.svg',
    isSelected: false,
  },
  {
    title: 'Swimwear',
    imageUrl: '/images/categories/men/Swimwear.webp',
    imageUrlHover: '/images/categories/men/Swimwear.webp',
    imageBadge: '/images/item-icons/swimwear.svg',

    isSelected: false,
  },
  {
    title: 'Sportswear',
    imageUrl: '/images/categories/men/Sportswear.webp',
    imageUrlHover: '/images/categories/men/Sportswear-hover.webp',
    imageBadge: '/images/item-icons/sportswear.svg',

    isSelected: false,
  },
  {
    title: 'Footwear',
    imageUrl: '/images/categories/men/Footwear.webp',
    imageUrlHover: '/images/categories/men/Footwear-hover.webp',
    imageBadge: '/images/item-icons/footwear.svg',

    isSelected: false,
  },
  {
    title: 'Underwear & Socks',
    imageUrl: '/images/categories/men/Underwear.webp',
    imageUrlHover: '/images/categories/men/Underwear.webp',
    imageBadge: '/images/item-icons/underwear.svg',

    isSelected: false,
  },
  {
    title: 'Accessories',
    imageUrl: '/images/categories/men/Accessories.webp',
    imageUrlHover: '/images/categories/men/Accessories-hover.webp',
    imageBadge: '/images/item-icons/sunglasses.svg',

    isSelected: false,
  },
];

export const categoriesKidsImageAnswerOptions: ImageAnswerOption[] = [
  {
    title: 'Newborn & Sleepsuits',
    imageUrl: '/images/categories/kids/Newborn-Sleepsuits.webp',
    imageUrlHover: '/images/categories/kids/Newborn-Sleepsuits-hover.webp',
    imageBadge: '/images/item-icons/baby.svg',
    isSelected: false,
  },
  {
    title: 'All in ones',
    imageUrl: '/images/categories/kids/All-in-ones.webp',
    imageUrlHover: '/images/categories/kids/All-in-ones-hover.webp',
    imageBadge: '/images/item-icons/allinone.svg',
    isSelected: false,
  },
  {
    title: 'Coats, Jackets & Blazers',
    imageUrl: '/images/categories/kids/Coats-Jackets.webp',
    imageUrlHover: '/images/categories/kids/Coats-Jackets-hover.webp',
    imageBadge: '/images/item-icons/coat.svg',
    isSelected: false,
  },
  {
    title: 'Knitwear & Sweats',
    imageUrl: '/images/categories/kids/Knitwear-Sweats.webp',
    imageUrlHover: '/images/categories/kids/Knitwear-Sweats.webp',
    imageBadge: '/images/item-icons/knitwear.svg',
    isSelected: false,
  },
  {
    title: 'Polo & Rugby Shirts',
    imageUrl: '/images/categories/kids/Polo-Rugby.webp',
    imageUrlHover: '/images/categories/kids/Polo-Rugby-hover.webp',
    imageBadge: '/images/item-icons/poloshirt.svg',
    isSelected: false,
  },
  {
    title: 'Shirts & Blouses',
    imageUrl: '/images/categories/kids/Shirts-Blouses.webp',
    imageUrlHover: '/images/categories/kids/Shirts-Blouses-hover.webp',
    imageBadge: '/images/item-icons/shirt.svg',
    isSelected: false,
  },
  {
    title: 'Dresses',
    imageUrl: '/images/categories/kids/Dresses.webp',
    imageUrlHover: '/images/categories/kids/Dresses-hover.webp',
    imageBadge: '/images/item-icons/dress.svg',
    isSelected: false,
  },
  {
    title: 'Trousers & Jeans',
    imageUrl: '/images/categories/kids/Trousers-Jeans.webp',
    imageUrlHover: '/images/categories/kids/Trousers-Jeans-hover.webp',
    imageBadge: '/images/item-icons/trousers.svg',
    isSelected: false,
  },
  {
    title: 'Leggings & Joggers',
    imageUrl: '/images/categories/kids/Leggings-Joggers.webp',
    imageUrlHover: '/images/categories/kids/Leggings-Joggers-hover.webp',
    imageBadge: '/images/item-icons/leggings.svg',
    isSelected: false,
  },
  {
    title: 'Shorts, Skirts & Dunagrees',
    imageUrl: '/images/categories/kids/Shorts-Skirts.webp',
    imageUrlHover: '/images/categories/kids/Shorts-Skirts-hover.webp',
    imageBadge: '/images/item-icons/skirt.svg',
    isSelected: false,
  },
  {
    title: 'Footwear',
    imageUrl: '/images/categories/kids/Footwear.webp',
    imageUrlHover: '/images/categories/kids/Footwear.webp',
    imageBadge: '/images/item-icons/footwear.svg',
    isSelected: false,
  },
  {
    title: 'Suits',
    imageUrl: '/images/categories/kids/Suits.webp',
    imageUrlHover: '/images/categories/kids/Suits.webp',
    imageBadge: '/images/item-icons/suit.svg',
    isSelected: false,
  },
  {
    title: 'Swimwear',
    imageUrl: '/images/categories/kids/Swimwear.webp',
    imageUrlHover: '/images/categories/kids/Swimwear-hover.webp',
    imageBadge: '/images/item-icons/swimwear.svg',
    isSelected: false,
  },
  {
    title: 'Underwear, Socks & Tights',
    imageUrl: '/images/categories/kids/Underwear-Socks.webp',
    imageUrlHover: '/images/categories/kids/Underwear-Socks-hover.webp',
    imageBadge: '/images/item-icons/underwear.svg',
    isSelected: false,
  },
  {
    title: 'Accessories',
    imageUrl: '/images/categories/kids/Accessories.webp',
    imageUrlHover: '/images/categories/kids/Accessories.webp',
    imageBadge: '/images/item-icons/bag.svg',
    isSelected: false,
  },
];

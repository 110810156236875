import { QuestionBase } from './baseTypes';

const imperialWeightRegex = /^(\d)(\d)? st(, (\d|(10)|(11)|(12)|(13)) lb)?$/;
const metricWeightRegex = /^\d+$/;

export class WeightQuestion implements QuestionBase<string> {
  constructor(public readonly label: string) {}

  validate = (value: string | undefined): string | undefined => {
    if (value && !imperialWeightRegex.test(value) && !metricWeightRegex.test(value)) {
      return 'Please enter correct weight (digits only)';
    }

    return undefined;
  };
}

import { FC } from 'react';
import { Button } from '../../../../components/Button';
import { Loading, LoadingSizes } from '../../../../components/Loading';
import GoBackArrowIcon from '../../../../assets/images/icons/GoBackArrowIcon.svg';
import NextArrowIcon from '../../../../assets/images/icons/NextArrowIcon.svg';
import styles from './StepFooter.module.scss';
// import { Link } from 'react-router-dom';
// import { LOGIN_URL } from 'routePaths';
import { useAuth } from 'providers/AuthProvider';
import { observer } from 'mobx-react-lite';
import ProgressBar from 'pages/RegistrationFlow/components/ProgressBar';
import withMediaQuery from 'components/MediaQueries/withMediaQueries';
import { IMediaQueries } from 'hooks/useMediaQueries';

export interface StepFooterProps {
  onBack(): void;
  onNext?(): void | Promise<void>;
  mediaQueries?: IMediaQueries;
  isSubmitting?: boolean;
  hideGoBackButton?: boolean;
  hideNextButton?: boolean;
  disableNext?: boolean;
  hideLogin?: boolean;
  selectMessage?: boolean;
  stepsCount: number;
  currentStepIndex: number;
}

const StepFooter: FC<StepFooterProps> = observer((props: StepFooterProps) => {
  const { isLoading: isAuthLoading /*,isAuthenticated*/ } = useAuth();
  const {
    onBack,
    onNext,
    mediaQueries,
    hideGoBackButton,
    hideNextButton,
    isSubmitting,
    // hideLogin,
    selectMessage,
    stepsCount,
    currentStepIndex,
  } = props;
  const isLoading = isSubmitting;
  const showProgressBar = currentStepIndex + 1 <= stepsCount;
  const isPhoneLayout = mediaQueries?.isPhone;
  const progressBar = <ProgressBar stepsCount={stepsCount} currentStepIndex={currentStepIndex} />;

  return (
    <>
      {isAuthLoading ? null : (
        <section className={styles.container}>
          {isPhoneLayout && showProgressBar && progressBar}

          {!hideGoBackButton && (
            <Button
              className={styles.button}
              type="button"
              size="medium"
              width="wide"
              color="white"
              onClick={onBack}
              data-testid="back-button"
              startIcon={<img src={GoBackArrowIcon} alt="" />}
            >
              back
            </Button>
          )}

          {!isPhoneLayout && showProgressBar && progressBar}

          {/* {!hideLogin && !isAuthenticated && (
            <div className={styles.login}>
              Already a member?
              <Link to={LOGIN_URL} className={styles.link}>
                Login
              </Link>
            </div>
          )} */}

          {!hideNextButton && (
            <Button
              className={styles.button}
              disabled={props.disableNext || isLoading}
              type="submit"
              size="medium"
              width="wide"
              color="black"
              onClick={onNext}
              data-testid="next-button"
              endIcon={!isLoading && <img src={NextArrowIcon} alt="" />}
            >
              {isLoading ? <Loading size={LoadingSizes.small} /> : <>next</>}
            </Button>
          )}

          {props.disableNext && selectMessage && (
            <div className={styles.selectMessage}>Please select an option to proceed</div>
          )}
        </section>
      )}
    </>
  );
});

export default withMediaQuery<StepFooterProps>(StepFooter);

import { FC, useMemo, useEffect } from 'react';
import { Loading } from 'components/Loading';
import { observer } from 'mobx-react-lite';
import { ContentContainer } from 'pages/RegistrationFlow/components/ContentContainer';
import { useStylingFor } from 'pages/RegistrationFlow/hooks/useStylingFor';
import { StepComponentProps } from 'pages/RegistrationFlow/StepComponentProps';
import { useAuth } from 'providers/AuthProvider';
import { useStore } from 'providers/useStore';
import { useCustomerProfile } from 'serverCache/customerProfile';
import { useStylingProfiles } from 'serverCache/useStylingProfiles';
import {
  checkCustomerProfileHasAllData,
  PersonalDetailsRouter,
  step,
} from './PersonalDetailsRouter';
import { useAnalytics } from 'analytics';
import styles from './PersonalDetailsStep.module.scss';
import { pick } from 'utils/pick';

export const PersonalDetailsStep: FC<StepComponentProps> = observer((props) => {
  const { scriptsLoaded, fbTrack } = useAnalytics();
  useEffect(() => {
    scriptsLoaded && fbTrack('Lead');
  }, [scriptsLoaded, fbTrack]);

  const { isLoading: isAuthLoading, isAuthenticated } = useAuth();

  const stylingFor = useStylingFor();

  const {
    registrationFlowStore: {
      order: { stylingProfile },
      patch,
    },
  } = useStore();

  const { data: customerProfileFromApi, isLoading: isCustomerProfileLoading } = useCustomerProfile({
    enabled: isAuthenticated,
  });

  const isCustomerProfileComplete = checkCustomerProfileHasAllData(customerProfileFromApi);

  useEffect(() => {
    if (isCustomerProfileComplete) {
      const customerProfileFields = pick(customerProfileFromApi, [
        'firstName',
        'lastName',
        'email',
      ]);
      patch(customerProfileFields);
    }
  }, [isCustomerProfileComplete, patch, customerProfileFromApi]);

  const { data: stylingProfiles, isLoading: isStylingProfilesLoading } = useStylingProfiles(
    isAuthenticated,
    stylingFor,
  );

  const hasStylingProfiles = !!stylingProfiles?.length;
  const isStylingProfileHasAnyData = stylingProfile.firstName || stylingProfile.lastName;
  const creatingNewStylingProfile = !stylingProfile.id && isStylingProfileHasAnyData;

  const firstStep = useMemo<step>(
    () => {
      if (!isCustomerProfileLoading && !isStylingProfilesLoading) {
        if (!isCustomerProfileComplete) {
          return 'customerProfile';
        } else if (!hasStylingProfiles || creatingNewStylingProfile) {
          return 'stylingProfile';
        }
      }
      return 'stylingProfileList';
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isCustomerProfileLoading,
      isStylingProfilesLoading,
      isCustomerProfileComplete,
      hasStylingProfiles,
    ],
  );

  return (
    <ContentContainer>
      {isAuthLoading || isCustomerProfileLoading || isStylingProfilesLoading ? (
        <div className={styles.loadingWrapper}>
          <Loading isBlack />
        </div>
      ) : (
        <>
          <PersonalDetailsRouter
            isAuthenticated={isAuthenticated}
            stylingFor={stylingFor}
            customerProfile={customerProfileFromApi}
            stylingProfiles={stylingProfiles}
            firstStep={firstStep}
            {...props}
          />
        </>
      )}
    </ContentContainer>
  );
});

import deepmerge from 'deepmerge';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import {
  DeepPartial,
  OrderDraft,
  SavedOrderDraft,
  StylingFor,
  SubmittedOrder,
} from '../../dataTypes';
import { defaultMenRegistrationData } from './defaultMenRegistrationData';
import { OrderApiService } from '../../services/OrderApiService';
import { OrderDraftApiService } from '../../services/OrderDraftApiService';
import { defaultKidsRegistrationData } from './defaultKidsRegistrationData';
import { LocalPersistService } from './LocalPersistService';
import { firstStep } from 'pages/RegistrationFlow/registrationFlowSteps';

const initialValuesMap: { [key in StylingFor]: OrderDraft } = {
  Kids: defaultKidsRegistrationData,
  Men: defaultMenRegistrationData,
};

const defaultCache = {
  Kids: { lastVisitedStep: firstStep },
  Men: { lastVisitedStep: firstStep },
};

export class RegistrationFlowStore {
  public isStarted = false;
  private stylingFor = StylingFor.Men;

  constructor(
    private readonly orderDraftService: OrderDraftApiService,
    private readonly orderService: OrderApiService,
    private readonly localPersistService: LocalPersistService,
  ) {
    this._cache[StylingFor.Men] = deepmerge(
      defaultCache[StylingFor.Men],
      this.localPersistService.restoreCache(StylingFor.Men) as Record<string, unknown>,
    );
    this._cache[StylingFor.Kids] = deepmerge(
      defaultCache[StylingFor.Kids],
      this.localPersistService.restoreCache(StylingFor.Kids) as Record<string, unknown>,
    );
    makeAutoObservable(this);
  }

  /* istanbul ignore next */
  getCache = <T>(): T => {
    /* istanbul ignore next */
    const stylingFor = this.stylingFor;
    return this._cache[stylingFor] as T;
  };

  /* istanbul ignore next */
  setCache = <T>(cache: Partial<T>): void => {
    const stylingFor = this.stylingFor;
    this._cache[stylingFor] = deepmerge(this._cache[stylingFor] as T, cache);
    this.localPersistService.saveCache(stylingFor, this._cache[stylingFor]);
  };

  get order(): OrderDraft {
    return toJS(this._order);
  }

  private _cache: Record<StylingFor, unknown> = { ...defaultCache };

  createOrUpdate = async (): Promise<SavedOrderDraft> => {
    if (!this._order.id) {
      const savedOrder = await this.orderDraftService.createOrderDraft(this._order);
      runInAction(() => {
        this._order.id = savedOrder.id;
      });
      return savedOrder;
    } else {
      return await this.orderDraftService.updateOrderDraft(this._order.id, this._order);
    }
  };

  submit = async (): Promise<SubmittedOrder> => {
    const savedOrderDraft = await this.createOrUpdate();
    const savedOrder = await this.orderService.createOrder(savedOrderDraft.id);
    this.reset();
    return savedOrder;
  };

  init = (stylingFor: StylingFor): void =>
    runInAction((): void => {
      this.isStarted = true;
      this.stylingFor = stylingFor;
      this._order = deepmerge(
        RegistrationFlowStore.getDefaultData(stylingFor),
        this.localPersistService.hasCache(stylingFor)
          ? (this.localPersistService.restore(stylingFor) as OrderDraft)
          : {},
      );
    });

  patch = (patch: DeepPartial<OrderDraft>): void => {
    this._order = deepmerge(this._order, patch as OrderDraft, {
      arrayMerge: (_destinationArray, sourceArray, _options) => sourceArray as unknown[],
    });
    this.localPersistService.save(this._order.stylingFor, this._order);
  };

  private _order: OrderDraft = { ...defaultMenRegistrationData };

  private resetOrder(): void {
    this._cache = { ...defaultCache };
    this._order = RegistrationFlowStore.getDefaultData(this.stylingFor);
  }

  reset(): void {
    this.isStarted = false;
    this.resetOrder();
    this.localPersistService.delete(this.stylingFor);
  }

  clear(): void {
    this.isStarted = false;
    this.resetOrder();
    this.localPersistService.clear();
  }

  setStarted(): void {
    this.isStarted = true;
  }

  private static getDefaultData(stylingFor: StylingFor): OrderDraft {
    return initialValuesMap[stylingFor];
  }
}

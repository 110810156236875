import React, { ComponentProps, FC, MutableRefObject, useEffect, useRef } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { FormState } from './FormState';
import { RadioButton } from '../../../../components/RadioButton';

function useSyncRef<T>(value: T): MutableRefObject<T> {
  const valueRef = useRef(value);

  if (valueRef.current !== value) {
    valueRef.current = value;
  }

  return valueRef;
}

interface Props {
  form: UseFormReturn<FormState>;
  communicationDayValues: ComponentProps<typeof RadioButton>['items'];
  communicationTimeValues: ComponentProps<typeof RadioButton>['items'];
}

export const CommunicationDateAndTimeFields: FC<Props> = (props) => {
  const { form, communicationDayValues, communicationTimeValues } = props;
  const { control, unregister, setValue, trigger: triggerValidation } = form;

  const triggerValidationRef = useSyncRef(triggerValidation);
  const unregisterRef = useSyncRef(unregister);
  const setValueRef = useSyncRef(setValue);

  useEffect(() => {
    void triggerValidationRef.current(['communicationDateTime.date', 'communicationDateTime.time']);

    return () => {
      /* eslint-disable react-hooks/exhaustive-deps */
      unregisterRef.current(['communicationDateTime.date', 'communicationDateTime.time']);
      setValueRef.current('communicationDateTime.date', '');
      setValueRef.current('communicationDateTime.time', '');
    };
  }, []);

  return (
    <>
      <Controller
        control={control}
        name="communicationDateTime.date"
        rules={{
          required: true,
        }}
        render={({ field: { ref, ...field } }) => (
          <RadioButton label="Day" items={communicationDayValues} {...field} />
        )}
      />
      <Controller
        control={control}
        name="communicationDateTime.time"
        rules={{
          required: true,
        }}
        render={({ field: { ref, ...field } }) => (
          <RadioButton label="Time" items={communicationTimeValues} {...field} />
        )}
      />
    </>
  );
};

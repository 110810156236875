import React, { FC, ComponentType } from 'react';
import { IMediaQueries } from 'hooks/useMediaQueries';
import MediaQueries from './MediaQueries';

function withMediaQuery<P>(Component: ComponentType<P>): FC<P> {
  return function ComponentWithMedia(props: P): JSX.Element {
    return (
      <MediaQueries>
        {(matches: IMediaQueries) => <Component mediaQueries={matches} {...props} />}
      </MediaQueries>
    );
  };
}

export default withMediaQuery;

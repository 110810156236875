/* istanbul ignore file */
import { useGo } from './useGo';
import { useStore } from '../../../providers/useStore';
import { useMutation } from 'react-query';
import { SUBMIT_KEY_FOR_REACT_QUERY } from '../constants';

export const useGoWithSubmit = (path: string): (() => Promise<unknown>) => {
  const { registrationFlowStore } = useStore();
  const go = useGo(path);

  const { mutateAsync } = useMutation(SUBMIT_KEY_FOR_REACT_QUERY, async () => {
    const order = await registrationFlowStore.submit();
    go({ state: { orderId: order.id } });
  });

  return mutateAsync;
};

import { FC, PropsWithChildren } from 'react';
import { LoadingScreen } from '../LoadingScreen/LoadingScreen';
import styles from './ShowLoadingBeforeAuth.module.scss';
import { useAuth } from '../../providers/AuthProvider';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

export const ShowLoadingBeforeAuth: FC<PropsWithChildren<unknown>> = observer(({ children }) => {
  const { isLoading } = useAuth();

  return (
    <>
      <div
        className={classNames({
          [styles.loaded]: !isLoading,
        })}
      >
        <LoadingScreen />
      </div>
      {children}
    </>
  );
});

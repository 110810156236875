import { FC } from 'react';
import {
  clothingCategoriesStepPath,
  congratulationsStepPath,
  contactDetailsStepPath,
  deliveryAddressStepPath,
  intentionStepPath,
  paymentDetailsStepPath,
  personalDetailsStepPath,
  registrationStepPath,
  sizesStepPath,
} from '../../constants';
import { PersonalDetailsStep } from './steps/PersonalDetails';
import { IntentionStep } from './steps/Intention';
import { ClothingCategoriesStep } from './steps/ClothingCategories';
import { SizesStep } from './steps/Sizes';
import { RegistrationStep } from './steps/Registration';
import { DeliveryAddressStep } from './steps/DeliveryAddress';
import { PaymentDetailsStep } from './steps/PaymentDetails';
import { ContactDetailsStep } from './steps/ContactDetails';
import { CongratulationsStep } from './steps/Congratulations';
import { StepComponentProps } from './StepComponentProps';
import { intentionStepTitle } from './steps/Intention';
import { clothingCategoriesStepTitle } from './steps/ClothingCategories';
import { deliveryAddressStepTitle } from './steps/DeliveryAddress';
import { contactDetailsStepTitle } from './steps/ContactDetails';
import { registrationStepTitle } from './steps/Registration';
import { menSizesStepTitle } from './steps/Sizes';
import { paymentDetailsStepTitle } from './steps/PaymentDetails';
import { congratulationsStepTitle } from './steps/Congratulations';
import { personalDetailsStepDefaultTitle } from './steps/PersonalDetails/PersonalDetailsRouter';

export type AdditionalAction = 'save' | 'submit';

export interface Step {
  path: string;
  Component: FC<StepComponentProps>;
  title: string;
  stepBackUrl?: string;
  additionalAction?: AdditionalAction;
  authenticationRequired?: boolean;
}

const registrationFlowSteps: Step[] = [
  {
    path: intentionStepPath,
    Component: IntentionStep,
    title: intentionStepTitle,
  },
  {
    path: clothingCategoriesStepPath,
    title: clothingCategoriesStepTitle,
    Component: ClothingCategoriesStep,
  },
  {
    path: personalDetailsStepPath,
    Component: PersonalDetailsStep,
    title: personalDetailsStepDefaultTitle,
    additionalAction: 'save',
  },
  {
    path: sizesStepPath,
    Component: SizesStep,
    title: menSizesStepTitle,
  },
  {
    path: registrationStepPath,
    Component: RegistrationStep,
    title: registrationStepTitle,
  },
  {
    path: deliveryAddressStepPath,
    Component: DeliveryAddressStep,
    title: deliveryAddressStepTitle,
    stepBackUrl: sizesStepPath,
    authenticationRequired: true,
  },
  {
    path: contactDetailsStepPath,
    Component: ContactDetailsStep,
    title: contactDetailsStepTitle,
    authenticationRequired: true,
  },
  {
    path: paymentDetailsStepPath,
    Component: PaymentDetailsStep,
    title: paymentDetailsStepTitle,
    additionalAction: 'submit',
    authenticationRequired: true,
  },
  {
    path: congratulationsStepPath,
    Component: CongratulationsStep,
    title: congratulationsStepTitle,
  },
];

export const firstStep = registrationFlowSteps[0].path;
export const firstStepUrl = `/styling/:stylingFor/${firstStep}`;
export const stepsCount = registrationFlowSteps.length - 1;

export const getStepIndex = (step: string): number =>
  registrationFlowSteps.findIndex((el) => el.path === step);

export default registrationFlowSteps;

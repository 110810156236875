export interface ProfileIconProps {
  className?: string;
  width: number;
  height: number;
}

export const ProfileIcon: (props: ProfileIconProps) => JSX.Element = (props: ProfileIconProps) => {
  return (
    <div className={props.className} style={{ width: props.width, height: props.height }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Final" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            id="1"
            transform="translate(-1264.000000, -30.000000)"
            fill="#FFFFFF"
            fillRule="nonzero"
          >
            <g id="Nav">
              <g id="logIn" transform="translate(1264.000000, 30.000000)">
                <path
                  d="M17.0710937,12.9289063 C15.9819141,11.8397656 14.6855078,11.0334766 13.2710547,10.5453906 C14.7859766,9.50199219 15.78125,7.75578125 15.78125,5.78125 C15.78125,2.59347656 13.1877734,0 10,0 C6.81222656,0 4.21875,2.59347656 4.21875,5.78125 C4.21875,7.75578125 5.21402344,9.50199219 6.72898437,10.5453906 C5.31453125,11.0334766 4.018125,11.8397656 2.92894531,12.9289063 C1.04019531,14.8176953 0,17.3289062 0,20 L1.5625,20 C1.5625,15.3475391 5.34753906,11.5625 10,11.5625 C14.6524609,11.5625 18.4375,15.3475391 18.4375,20 L20,20 C20,17.3289062 18.9598047,14.8176953 17.0710937,12.9289063 Z M10,10 C7.67378906,10 5.78125,8.1075 5.78125,5.78125 C5.78125,3.455 7.67378906,1.5625 10,1.5625 C12.3262109,1.5625 14.21875,3.455 14.21875,5.78125 C14.21875,8.1075 12.3262109,10 10,10 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

import { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { PurchaseTotals } from '../../../../../../dataTypes';
import { getCurrencyString, getPercentageString } from '../../../../../../utils';
import { OrderMessage } from '../OrderMessage';
import RatingRequest from 'components/RatingRequest';
import styles from './OrderPaid.module.scss';

interface Props {
  orderId: string;
  purchaseTotals: PurchaseTotals | null | undefined;
  showReviewRequest: boolean;
}

export const OrderPaid: FC<Props> = observer((props: Props) => {
  const { purchaseTotals, orderId, showReviewRequest } = props;
  const [showRating, setShowRating] = useState(showReviewRequest);

  const formattedDiscount = getPercentageString(purchaseTotals?.discount ?? 0);
  const formattedSavings = getCurrencyString(purchaseTotals?.savings ?? 0);
  const formattedSellPrice = getCurrencyString(purchaseTotals?.sellPrice ?? 0);

  return (
    <OrderMessage
      messageText={
        <>
          Great choixce! Your offer has been accepted at the price you wanted to pay. <br />
          You paid {formattedSellPrice} for a {formattedDiscount} discount and saved{' '}
          {formattedSavings}.
        </>
      }
    >
      {showRating && (
        <div className={styles.reviewLinkWrapper}>
          <RatingRequest orderId={orderId} onClick={() => setShowRating(false)} />
        </div>
      )}
    </OrderMessage>
  );
});

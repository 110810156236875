import { FC } from 'react';
import { ItemList } from '../../../../ItemList';
import { StatusViewProps } from '../../../propTypes';
import { ShipmentInfo } from './components/ShipmentInfo';
import { arrayHasItems } from '../../../../../../../utils';
import { DeliveryDetailsInfo } from '../../DeliveryDetailsInfo';
import TwoColumnLayout from 'components/TwoColumnLayout';

export const DispatchedStatusView: FC<StatusViewProps> = (props: StatusViewProps) => {
  const { order } = props;

  return (
    <TwoColumnLayout
      firstColumn={<DeliveryDetailsInfo order={order} hideOnMobile />}
      secondColumn={
        <>
          <ShipmentInfo orderId={order.id} />
          {arrayHasItems(order.items) && <ItemList title="My items" items={order.items} />}
        </>
      }
    />
  );
};

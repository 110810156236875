import { FC } from 'react';
import { Link } from 'react-router-dom';
import { TitleMain } from '../../components/TitleMain';
import styles from '../Privacy/Privacy.module.scss';

/* istanbul ignore file */

export const TermsAndConditions: FC = () => {
  return (
    <div className={styles.content}>
      <TitleMain title="Terms &amp; Conditions" />
      <div>
        <p>
          Please read these terms and conditions carefully (Terms). They set out the basis on which
          Choixce Limited, trading as “Its Your Choixce” will deal with you as a customer, including
          the set up of your account, use of our application and the provision of goods and services
          to you.{' '}
        </p>

        <h2>Update</h2>
        <p>This supersedes any old Terms and conditions you may have seen prior.</p>

        <h2>1.Introduction</h2>
        <h3>Who we are</h3>
        <p>
          CHOIXCE LMITED, is a company registered in England under registration number 10994969
          (“CHOIXCE LIMITED ”, Its Your Choixce”,“we”, “us”, or “our”). Our registered address is at
          The Old Mill, 9 Soar Lane, Leicester, LE3, 5DE and our registered VAT number 293406395.
        </p>
        <h3>How it works</h3>
        <p>
          Choixce is a membership-only online service offering personal styling for men and
          children. Members can pay up to 70% off for any items they keep. Choixce aims to provide a
          fun, friendly and efficient shopping experience with knowledgeable stylists offering a
          range of established and up-and-coming quality menswear and kidswear brands. All returns
          are free and there is no obligation to purchase.{' '}
        </p>
        <h3>What to you if you have any queries</h3>
        <p>
          If you have any questions or complaints about the Services, Products or these Terms, you
          can contact us at info@choixce.com
        </p>

        <h2>2. Requirements for the use of our Services </h2>
        <h3>Your obligations to us</h3>
        <p>
          Any use of our Services (as defined below) by you, including your use of our website at
          www.choixce.com (the “Site”) and our mobile application(s) entitled “Its Your Choixce”
          (the “App”) shall be governed by our Terms of Use which can be found{' '}
          <Link to="#">here</Link> and these Terms.{' '}
        </p>
        <p>
          In addition, by using the Site or the App, creating an Account, or submitting an order for
          a shipment of clothing and/or accessories <strong>(Products)</strong> in a consignment (a
          “Package”) (collectively the “Services”) or otherwise indicating your assent to these
          Terms, you confirm that:
        </p>
        <ul>
          <li>you are aged 18 or over;</li>
          <li>you are a UK consumer;</li>
          <li>you have read, understand, and agree to these Terms;</li>
          <li>you have the authority to enter into the Terms.</li>
        </ul>
        <p>
          If you do not wish to be bound by these Terms, you may not access or use the Services and
          please navigate away from our Site and cease using the App.
        </p>
        <p>
          The Site and the App are intended for use only by those who can access it from within the
          UK. If you choose to access the Site or the App from locations outside the UK, you are
          responsible for compliance with local laws where they are applicable.
        </p>
        <p>
          These Terms refer to the following additional terms, which also apply to your use of our
          Services:
        </p>
        <ul>
          <li>
            our <Link to="#">Returns Policy</Link>;
          </li>
          <li>
            our <Link to="#">Data Protection, Privacy and Cookies Policy</Link>
          </li>
        </ul>

        <h2>3. Changes to these Terms</h2>
        <p>
          We may need to amend these Terms from time to time to reflect changes to our Products,
          Services, users needs, or business priorities. For existing users of the Services, we will
          will give you thirty (30) calendar days notice of any changes to our Terms by posting the
          new Terms here: www.choixce.com/terms and updating the “Last Updated” date below. At our
          discretion, we may also notify you of certain changes by sending you an email to the email
          address associated with your Account (as defined below). You are responsible for providing
          us with your current email address, which you may do when you create an Account, and you
          may update it by modifying the information when logged into your Account.
        </p>
        <p>
          If you use the Services during the notice period the changes will not apply. After the
          notice period has expired, if you do not agree to the changes you may not access the Site
          or the App or use the Services. Continued use of the Site, the App, or the Services,
          including purchasing Products from us, will indicate your acknowledgement of such changes
          and agreement to be bound by the terms and conditions of such changes.
        </p>
        <p>
          For new users of the Services, changes will be effective immediately. Please regularly
          check www.choixce.com/terms to view the then-current Terms.
        </p>

        <h2>4. Types of Users</h2>
        <p>
          You may simply browse the Site or App as a visitor, or you may create an account
          (“Account”) to become a member (“Member”). You must be a Member to make an order for a
          Package.
        </p>

        <h2>5. How to become a Member and creating an account</h2>
        <p>
          To become a Member and to create an Account, you must provide your name, email address,
          other registration information, and select a password (“Account Information”).{' '}
        </p>
        <p>
          You can also create an Account and become a Member using your valid social networking
          service (“SNS”) account, by entering your SNS credentials and connecting to the Services.
        </p>
        <p>If you create an Account you agree that you are solely responsible for:</p>
        <ul>
          <li>the accuracy of the Account Information;</li>
          <li>all costs and expenses you may incur in relation to your use of the Services; and</li>
          <li>keeping your Account Information confidential.</li>
        </ul>
        <p>
          We will also ask you for additional information about your size, fit, style preferences,
          and other information to establish your style profile (“Style Profile”) and require you to
          have an introductory call with your stylist. Book your call via the sign-up process and
          select a time which is convenient for you. Please keep your Style Profile up to date so
          that we can best serve you. While we make every effort to follow the Style Profile and
          price preferences you express in your Account and during your phone consultation to create
          your Package, we do not guarantee that all of the Products will comply with those
          preferences and it is ultimately a matter for you as to what garments you feel suit your
          needs.
        </p>
        <p>
          Choixce is a club membership with limited spaces, based on the capacity of our staff and
          infrastructure as a business. We reserve the right to place you on a waiting list if we
          are at capacity and feel we cannot provide you with the high level of care and attention
          that we are known for. You will be contacted when a space has become available in your
          stylists’ diary for a consultation and dispatch of an order.
        </p>
        <p>
          We ask all of our members to pay fairly for the products and service that they receive, up
          to a maximum discount of 70% off RRP. This ‘fair price’ is based on what other members are
          currently paying for their boxes of the same RRP. You can see this average spend on the
          barometer on our homepage.
        </p>
        <p>
          If you do not pay fairly for the items and service, we reserve the right to revoke your
          membership and you will no longer be able to shop with us.
        </p>
        <h3>How we use your information</h3>
        <p>
          Any personal information that you provide to us will be dealt with in line with our data
          protection, privacy and cookies policy (see here: www.choixce.com/privacy), which explains
          what personal information we collect from you, how and why we collect, store, use and
          share such information, your rights in relation to your personal information and how to
          contact us and supervisory authorities in the event you have a query or complaint about
          the use of your personal information.
        </p>
        <h3>Use of your account</h3>
        <p>
          You should not share your Account Information with any third parties. You are always
          solely responsible for the use of your Account Information and all activities (including
          orders placed through the Services) that occur under or in connection with your Account.
          You may not use anyone else‘s Account at any time without the permission of the Account
          holder. You agree not to register for more than one Account, register for an Account on
          behalf of someone else without that individual‘s authorisation, or register for an Account
          on behalf of any group or entity. We have the right to preserve the security of the
          Services and your Account, including changing your password, requesting additional
          information to authorise transactions on your Account or disabling your Account at any
          time, if in our reasonable opinion you have failed to comply with any of the provisions of
          these Terms. If you know or suspect that anyone other than you knows your Account
          Information, you must promptly notify us at info@choixce.com
        </p>
        <h3>Use of social media and third-party services</h3>
        <p>
          We have created interfaces that allow you to connect with SNS accounts, such as Facebook
          or Google, through the Services. By granting us access to your SNS account, you understand
          that we may, subject to your privacy settings, access, make available, and store any
          information that you have made available to us by your SNS account (“SNS Content”)
          accessible through the Services so that it is available on your Account. Unless otherwise
          specified in the Terms, all SNS Content will be deemed Your Content (as defined below) for
          all purposes of the Terms.
        </p>
        <p>
          Please note that if an SNS account or associated service becomes unavailable or the
          Services‘ access to such SNS account is terminated by the third-party service provider,
          then SNS Content will no longer be available on and through the Services and you may have
          difficulty accessing the Services. You can revoke our access to any SNS at any time by
          disconnecting your SNS account via amending the settings in your Account. Please note that
          your relationship with each SNS is governed solely by your agreement(s) with such SNS, and
          we do not accept any responsibility or liability for personal data that may be provided to
          us by an SNS in violation of the privacy settings that you have set in the SNS. We shall
          not review any SNS Content for any purpose, including but not limited to, for accuracy,
          legality or non-infringement of third-party rights, and we are not responsible for any SNS
          Content. For further information on data collection and processing, please see our Data
          Protection, Privacy and Cookies Policy at www.choixce.com/privacy.
        </p>

        <h2>6. Placing Orders, Scheduling and Delivery</h2>
        <p>
          As part of our ongoing commitment to reducing waste, all plastic packaging is carefully
          removed and recycled at our warehouse and all products are shipped in fully recyclable
          bags and boxes. If you would prefer your items to be delivered in a box, please select
          “gift box” option, otherwise we will deliver your items in our recyclable bags.{' '}
        </p>
        <p>
          Ordering. We offer styling services for clothing and accessories that are delivered to you
          by way of a Package. Once you have verified your chosen payment method, all credit and
          security checks have been approved and you have spoken to your stylist, you can then
          confirm a request for a Package with your stylist. We will confirm receipt of this request
          via email.{' '}
        </p>
        <h3>If we cannot accept your Order. </h3>
        <p>
          We are not obliged to accept your order for goods. If for any reason we decline your
          request, we will attempt to notify you at the email address on your Account. We may not be
          able to accept your request because of unexpected limits on our resources which we could
          not reasonably plan for, because we are unable to meet a delivery deadline you have
          specified, your billing information is not correct or not verified, you are under 18 years
          of age, you are not a consumer, or your request is flagged by our security systems as
          unusual or susceptible to fraud.
        </p>
        <h3>Verifying your information</h3>
        <p>
          When you send us a request for a Package, our payment processing provider or a third-party
          service provider will run some eligibility checks on it before we accept your request.{' '}
        </p>
        <p>
          These checks may include verifying your address and checking for potential fraud. A fully
          refundable £1 credit card validation check may show up in your bank account for 30 days.{' '}
        </p>
        <p>
          Please go to our{' '}
          <strong>
            <a href="https://apexx.global/terms-and-conditions/">
              payment provider’s terms and conditions
            </a>
          </strong>{' '}
          for further information.{' '}
          <strong>
            Please note that you use and upload your personal information to our payment provider in
            accordance with their terms and conditions.
          </strong>
        </p>
        <h3>Sending you your Package</h3>
        <p>
          Once we have accepted your order, your Package will usually be dispatched within 24 hours
          on a standard 2 x day courier service. Please note that whilst we endeavour to ensure
          delivery dates are met, these are approximate only.
        </p>
        <p>
          Please note that shipping dates may vary based on a variety of factors including, but not
          limited to, inventory, geography, and automatic shipping preference.
        </p>
        <p>
          You may change the scheduled date for your Package if you give us notice via the options
          presented when you log in to your Account, otherwise we will aim to dispatch your Package
          within 24 hours.
        </p>
        <p>
          You should receive an alert when your Package is on its way by email from us and from the
          courier company, together with the relevant tracking details. Your stylist will be
          notified when your Package is delivered and may call to ensure you have received your
          items ok. Unless otherwise expressly agreed by us, we cannot dispatch any further Packages
          until your current order has been completed. You can request any future Packages by
          speaking with your stylist.{' '}
        </p>
        <p>
          If you wish to receive your Package on an express overnight service you can do so by
          selecting this service via the website. Please note you must speak to your stylist before
          12noon on the day of dispatch to ensure stylist can aim to meet dispatch deadlines. If we
          are unable to dispatch on the same day, we will aim to send items will be sent on the next
          working day. Any express deliveries must be paid for by the Member. Standard service is
          free unless otherwise advised at the time of placing your order.{' '}
        </p>
        <h3>Multiple Packages</h3>
        <p>
          You can order multiple Packages for different children within a household under one
          registered account, for up to a maximum of 5 children. Please note, we can only dispatch
          one package per household at a time until the original order is completed by either
          payment and/or returns. We cannot dispatch an additional Package for a child who already
          has an active order.{' '}
        </p>
        <h3>Additional Products</h3>
        <p>
          You may be able to select Products to be delivered separately from a Package. Such
          purchases shall be referred to herein as “Direct Purchases.” To place an order for a
          Direct Purchase, you will be required to follow the purchase process on the Services to
          submit the order, which we may at our discretion accept by shipping the additional
          products to you. You will be required to purchase the additional Products at the time of
          order for Direct Purchases unless we have agreed otherwise.{' '}
        </p>
        <h3>Delivery</h3>
        <p>
          The Products will be delivered to the address you have specified in your Order. It may not
          be possible to ship to an address if, for example, it is outside of the United Kingdom. If
          no one is available at your address to take delivery, our shipping provider will either
          contact you informing you how to rearrange delivery or will leave you a note with this
          information. If you do not rearrange delivery we will contact you for further instructions
          and may charge you for any further delivery costs. If, despite our reasonable efforts, we
          are unable to contact you or rearrange delivery we may end the contract.
        </p>
        <p>
          Upon delivery, please inspect the packaging for damage. If it appears that the Products
          are damaged, please notify us at info@choixce.com
        </p>
        <h3>Responsibility for the Products and ownership. </h3>
        <p>
          You will be responsible for the Products including Direct Purchases from the time that you
          receive them. We remain the owners of the Products until we have received full payment in
          cleared funds for the Products, at which point you will own those Products.
        </p>

        <h2>7. Free Home Trial Process for Packages</h2>
        <p>
          If your order a Package, we will send you the Products that we have chosen based upon your
          Style Profile. We suggest you try them on as soon as possible. We offer a free 7-day home
          trial of the Products, commencing on the date on which you receive the Package (the
          “Try-on Period”). You can decide to keep all, some, or none of the Products. This does not
          affect your statutory rights.{' '}
        </p>
        <h3>If you decide that you do not want to keep the Products</h3>
        <p>
          If you wish to send back any of the Products in your Package, you can choose the shipping
          method convenient for you and return them for free.{' '}
        </p>
        <p>
          Any Products returned must be postmarked before the expiration of the Try-on Period. If we
          have not heard from you by the end of the Try-on Period, and our stylist is unable to
          contact you, we will assume that you have chosen to keep all the Products in the Package
          and we will charge your Account the recommended retail price <strong>(RRP)</strong> of
          those Products. You may ask for an extension of the Try-on Period through your Account. We
          may extend the Try-on Period entirely at our own discretion.{' '}
        </p>
        <p>
          If we dispatch the goods to you, receive proof of delivery, and do not have any further
          communication with you despite all reasonable attempts to contact you, we reserve the
          right to charge you for the RRP of all the Products contained within the box and terminate
          your membership and/or refuse to supply further Products to you.{' '}
        </p>
        <p>
          All returned Products must be unworn and in the original condition with labels attached.
          If a returned Product is damaged or shows signs of wear, without tags the intact, we may
          charge you the full Your Choixce RRP for the Product.
        </p>

        <h2>8. Pricing</h2>
        <p>
          We do not show the prices of the Products in a Package until you receive a Package. Prices
          of the Products are indicated on the swing tickets attached to each item and online in
          your Account on the check-out page. Prices include VAT (where applicable) mark-up,
          consultancy and handling fees. Its Your Choixce reserve the right to set the price at its
          own discretion.
        </p>
        <p>
          It is always possible that, despite our reasonable efforts, some of the Products may be
          incorrectly priced. If the Product‘s correct price is higher than the price stated to you
          in the Package, we will contact you for your instructions before we take payment. If we
          take payment where a pricing error is obvious and unmistakeable and could reasonably have
          been recognised by you as a mispricing, we may end our agreement with you in respect of
          the Products, refund you any payments made and require you to return the relevant
          Products.
        </p>
        <h3>Price discussions </h3>
        <p>
          If you confirm with your stylist that you are keeping some or all your Products during the
          Try-on Period, you will then be required to pay up to 70% off the RRP for the products
          kept. This is a discussion between yourself, the stylist or sales team whereby you have
          the chance to decide what you want to pay up to 70% off. You have 7 days to decide on a
          price with your stylist. If you do not confirm what you’d like to pay within 7 days from
          receiving your box we reserve the right to charge full RRP after 30 days.
        </p>
        <p>
          If you have not paid for your order within 30 days from delivery, you will be invoiced for
          the full RRP of the box along with the fee for the debt recovery fee. You will have 7 days
          to pay the invoice, if we don’t hear from you, your account will be passed onto our debt
          recovery team who will recover the full RRP. Once your account has been handed over to
          this department, you will no longer be eligible to return your items.
        </p>
        <p>
          When you confirm a price with your stylist we will confirm via an email and proceed to
          complete to check-out. You will then be obliged to pay for the Products.
        </p>
        <h3>Price discussions after 4 boxes are complete (Applicable to ‘Gold members’ only)</h3>
        <p>
          If you confirm with your stylist that you are keeping some or all of your Products during
          the Try-on Period, you will then be required to discuss a price for what you would be
          prepared to pay. By entering this process, we expect you to negotiate in a fair and
          reasonable manner. This is a discussion between yourself, the stylist or sales team
          whereby you have the chance to decide what you want to pay. You have 7 days to decide on a
          price with your stylist. If you do not confirm what you’d like to pay within 7 days from
          receiving your box we reserve the right to charge full RRP after 30 days.
        </p>
        <h3>Qualifying for ‘Gold membership’ Pay what you want</h3>
        <p>
          To gain access to this exclusive gold members only payment method, all you have to do is
          order and complete 4 boxes with us paying up to 70% off. This will then unlock the full
          sliding payment scale on your account, where you can select the any price you want pay.
        </p>
        <h3>How to pay for your Products</h3>
        <p>
          Members who are approved by us and our payment provider may pay online, new Members can
          pay with their stylist over the phone. We accept major debit and credit cards, such as
          Visa, Mastercard and American Express. All methods of payment are expressly specified on
          the checkout page. If the rate of VAT changes between when you place your order and when
          you pay, the rate of VAT applicable at the date of payment shall apply.
        </p>
        <h3>Payment authorisation.</h3>
        <p>
          By submitting your payment details to your Account, you authorise us to charge your card
          or other payment method in accordance with these Terms.
        </p>
        <p>
          You must have all necessary authorisations to make a payment. We will not be liable in the
          event your children or someone acting with or without your permission makes an order using
          your Account; however, you may report any unauthorised use to us, and we will use
          reasonable measures within our control to help prevent future unauthorised use of your
          Account If you are an approved Member and you have arranged for Packages to be
          automatically dispatched to you without speaking to your stylist with our prior agreement,
          then you understand and agree that a discretionary Styling Fee may be held against the
          payment details registered with your Account on each occasion you select to have a Package
          dispatched to you.
        </p>
        <h2>9. Cancellation of Packages, refunds and exchanges</h2>
        <p>
          You may cancel Packages by either: (i) clicking the ”Manage automatic Packages” link on
          your Account homepage when you log in, choosing to stop receiving automatic Packages, and
          following the prompts and options that follow; or (ii) by emailing us at info@choixce.com
          stating that you want to stop receiving automatic Packages from the email address then
          currently associated with your Account.
        </p>
        <p>
          Where you have requested scheduling of Packages, you may amend the delivery date or cancel
          an upcoming Package by accessing your Account and amending such date or cancelling the
          Package at any time at least eleven (11) days before the Package is due to be dispatched.
        </p>
        <p>
          You may cancel your request for a package (without a reason) within 24 hours unless we
          have already begun the styling services. If you wish to cancel before the Package has been
          dispatched, please notify us within 24 hours by calling your stylist or emailing
          info@choixce.com. Unfortunately, we are not able to recall any boxes after dispatch and
          you must return the Package to us following the free returns process as follows:
        </p>
        <p>
          Log onto your profile and follow the returns instructions or click to the returns section
          via the link in your email. Place any unwanted items back inside the bag or gift box and
          ensure all tags or protective strips are attached, otherwise we may have to charge you the
          full Your Choixce RRP for them. Check the package is sealed properly and remove any old
          delivery labels. Fix the returns label onto the outside ensuring the barcode can be easily
          read. Take to your nearest drop off location as shown in the returns portal online.
        </p>
        <p>
          Returning Products after purchase. If you have decided to keep any Products or we have
          charged you for Products as you have kept them for longer than the Try-on Period and have
          not heard from you, you can return them to us for a full refund as long as you return them
          in their original condition, within 30 days of the checkout date, or in the event of
          Direct Purchases, within 30 days of receipt of the Products (“Return Date”). More details
          on returns are available <Link to="#">here</Link>.
        </p>
        <p>
          If you need any assistance please email : info@choixce.com or give your stylist a call and
          we will be happy to help.
        </p>
        <p>
          All returned Products must be unworn and in the original condition with labels attached,
          and, in the case of items such as swimwear with any protective cover or backing still on
          the Product. If a returned Product is damaged or shows signs of wear, we may charge you
          for the Product.
        </p>
        <p>If you wish to return the Products, please notify us at returns@choixce.com</p>
        <h3>Exchange of Products</h3>
        <p>
          If you would like to exchange any of the Products received in a Package (e.g., for a
          different size), you can request an exchange by contacting your stylists, or an approved
          member can do so by accessing your Account and proceeding through the check-out flow for
          your order. We will let you know if an exchange is available. To make an exchange, you
          must first return the original first. Unfortunately, we cannot guarantee that you will be
          able to exchange or replace any item. If you want to exchange any item not received in a
          Package please contact us and we will investigate the availability of a new product.
        </p>
        <p>
          We will then deliver the exchanged item, either separately or, if possible, with your next
          Package.
        </p>
        <p>
          If you fail to send us the original Product by the Return Date or if the original Product
          is damaged or shows signs of wear, we may charge you for the original Product.
        </p>

        <h2>10. Referrals reward scheme</h2>
        <p>
          Members who invite their friends and family to Its Your Choixce may participate in the Its
          Your Choixce referral program (the “Program”). Program participants can receive credit for
          referrals to use towards future purchases (a “Referral Credit”). The amount of Referral
          Credit awarded is specified on our Referral Program FAQ page available{' '}
          <Link to="#">here</Link> and may vary from time to time, at Its Your Choixce Ltd
          discretion
        </p>
        <h3>Reward Criteria</h3>
        <p>
          To qualify for a Referral Credit, the referral must be a “Qualifying Referral” by
          complying with the following requirements:
        </p>
        <ul>
          <li>you must be a Member of the Services;</li>
          <li>
            you must share a unique referral link generated through your Account with your contact
            who has not previously used the Services;
          </li>
          <li>
            you must only invite people you know and who have consented to receive your message;
          </li>
          <li>
            you must disclose your relationship with Its Your Choixce to your contact (e.g., ”if you
            place an order, I‘ll get a credit”);
          </li>
          <li>
            the referred person must register for the Services by directly clicking the unique link;
          </li>
          <li>
            the referred person must, within 30 days of the date you send the unique link, order a
            Package and meet the minimum spending requirements advised to you at the time the
            referred person orders a Package (e.g., spending at least £15 with Its Your Choixce
            above the discretionary stylist fee); and
          </li>
          <li>
            such minimum spend must be met without contribution from a Its Your Choixce Gift Card,
            together the “Reward Criteria”.
          </li>
        </ul>
        <p>
          Referral Credits will be logged in your Account promptly after the Qualifying Referral has
          been completed. You are responsible for notifying Its Your Choixce if you believe the
          number of Referral Credits in your Account is incorrect.
        </p>
        <h3>Restrictions</h3>
        <p>
          Referral Credits can be accrued solely by you and you may not earn Referral Credits by:
        </p>
        <ul>
          <li>permitting another individual to use your Account;</li>
          <li>creating multiple Accounts;</li>
          <li>
            promoting your referral code through paid marketing, such as search engine or social
            media marketing (e.g., AdWords, Yahoo, Facebook);
          </li>
          <li>
            posting your unique referral link on any public forum or coupon site that where you are
            not the primary content owner (e.g., you may post your referral link on your personal
            Facebook page or tweet it from your personal Twitter account, but you may not post it on
            a site like RetailMeNot or Wikipedia); or
          </li>
          <li>
            upon prior notice of an amendment to these Terms, any other restriction we impose on
            participants in the Program.
          </li>
        </ul>
        <p>
          The maximum amount of Referral Credits that a Member can receive in any calendar year is
          £1000. You are not able to redeem Referral Credits for cash.
        </p>
        <p>
          If you are a paid Its Your Choixce influencer you may not earn Referral Credits under the
          Program in the same calendar year that you receive payments from a Its Your Choixce or a
          Its Your Choixce affiliate marketing partner.
        </p>
        <p>
          You agree and acknowledge that Referral Credits are not transferable. You may not obtain
          any cash or money in exchange for Referral Credits. You are responsible for notifying Its
          Your Choixce if you believe the number of Referral Credits in your Account is incorrect.
        </p>
        <p>
          Unless extended by Its Your Choixce at its sole discretion, Referral Credits will
          automatically expire ninety (90) days from issuance.
        </p>
        <p>
          We reserve the right to modify, suspend or terminate the Program and to void any Referral
          Credits upon 30 days’ notice to you by email and amending these Terms. All Referral
          Credits are voided immediately upon termination of this contract or of your Account.
        </p>
        <p>
          At our discretion we may allow you to use all your credits against one purchase, or more
          than the standard allowance.
        </p>

        <h2>11.Our Liability to you</h2>
        <p>
          We are only responsible to you if we fail to comply with these Terms. If we fail to comply
          with these Terms, we shall be responsible for any loss or damage you suffer that is a
          foreseeable result of our breach of these Terms or our failure to use reasonable skill and
          care, but we are not responsible for any loss or damage that is not foreseeable. Loss or
          damage is foreseeable if it is obvious that it will happen, or if, at the time the
          contract was made, both we and you knew it might happen.
        </p>
        <p>
          We do not exclude or limit our liability to you in any way where it would be unlawful to
          do so. This includes liability for death or personal injury caused by our negligence or
          the negligence of our employees, agents or subcontractors; for fraud or fraudulent
          misrepresentation; for breach of your legal rights in relation to the Products including
          the right to receive Products which are: (i) as described and match information we
          provided to you; (ii) of satisfactory quality; (iii) fit for any particular purpose made
          known to us; (iv) and supplied with reasonable skill and care; and for defective products
          under the UK Consumer Protection Act 1987.
        </p>
        <p>
          We only supply the Products for domestic and private use. If you use the Products for any
          commercial, business or re-sale purpose we will have no liability to you for any loss of
          profit (direct or indirect), loss of business, business interruption, or loss of business
          opportunity.
        </p>

        <h2>12.Other important terms</h2>
        <p>
          <strong>Transfer</strong> of rights. We may transfer our rights and obligations under
          Terms to another organisation. We will tell you in writing if this happens.{' '}
        </p>
        <p>
          <strong>You need our consent to transfer your rights to someone else.</strong> You may
          only transfer your rights or your obligations under these Terms if we agree to this in
          writing.{' '}
        </p>
        <p>
          <strong>Modifications to the Services.</strong> We reserve the right to modify or
          discontinue the Services (including Packages) with or without notice to you. We will not
          be liable to you or any third party should we exercise our right to modify or discontinue
          the Services. If you object to any such changes, your sole recourse will be to cease
          access to the Services. Continued access to the Services following notice of any such
          changes will indicate your acknowledgement of such changes and satisfaction with the
          Services as so modified. You agree that we, in our sole discretion, may immediately
          terminate your access to the Services at any time, for any reason, in our sole discretion.
          You agree that we will not be liable to you or any other party for any termination of your
          access to the Services.
        </p>
        <p>
          <strong>Events beyond our control.</strong> Due to circumstances beyond our control, we
          may not be able to perform some or all of our agreement with you for the supply of
          Products in accordance with these Terms. This is known as “Force Majeure”. Such
          circumstances may include fire, riot, flood or other natural disaster, epidemic, pandemic,
          governmental laws or restrictions, failure of utilities, failure of the Vehicle’s onboard
          systems, strike or lock-out. In such circumstances we may be able to bring our agreement
          with you to and end without any liability.{' '}
        </p>
        <p>
          <strong>
            If a court finds part of our agreement with you illegal, the rest will continue in
            force.
          </strong>
          Each of the paragraphs of these terms operates separately. If any court or relevant
          authority decides that any of them are unlawful, the remaining paragraphs will remain in
          full force and effect.
        </p>
        <p>
          <strong>
            Even if we delay in enforcing our agreement with you, we can still enforce it later.
          </strong>{' '}
          If we do not insist immediately that you do anything you are required to do under these
          terms, or if we delay in taking steps against you in respect of your breaking our
          agreement, that will not mean that you do not have to do those things and it will not
          prevent us taking steps against you at a later date.{' '}
        </p>
        <p>
          <strong>
            Which laws apply to this contract and where you may bring legal proceedings.
          </strong>{' '}
          These Terms are governed by English law and you can bring legal proceedings in respect of
          any matter relating to these Terms only in the English courts.{' '}
        </p>

        <h2>TERMS OF USE</h2>
        <h3>Who we are</h3>
        <p>
          CHOIXCE LMITED, is a company registered in England under registration number 10994969
          (“CHOIXCE LIMITED ”, Its Your Choixce”,“we”, “us”, or “our”). Our registered address is at
          The Old Mill, 9 Soar Lane, Leicester, LE3, 5DE and our registered VAT number 293406395.
        </p>
        <p>
          These terms of use (Terms of Use) govern the use by you of the use of our website at
          www.choixce.com (the “Site”) and our mobile application(s) (App) to enable you to access
          and use our services which includes personal styling services and the provision of
          products (Services). In addition, our Terms of Sale (Terms), our
          <Link to="#">Returns Policy</Link> may apply if you order products from us. Please also
          refer to our <Link to="#">Data Protection, Privacy and Cookies Policy</Link>
          which applies as to how we collect and store your personal data.{' '}
        </p>
        <h3>We may make changes to these terms</h3>
        <p>
          We amend these terms from time to time. Every time you wish to use our Site and/or our
          App, please check these terms to ensure you understand the terms that apply at that time.
          These Terms of Use were prepared on 7 October 2022.
        </p>
        <p>
          <strong>We may make changes to our Site and our App.</strong> We may update and change our
          Site and our App from time to time.{' '}
        </p>
        <h3>We may suspend or withdraw our Services </h3>
        <p>
          Our Site and App are made available free of charge. We do not guarantee that our Site or
          App, or any content on it, will always be available or be uninterrupted. We may suspend or
          withdraw or restrict the availability of all or any part of our Site and/or App for
          business and operational reasons. We will try to give you reasonable notice of any
          suspension or withdrawal.
        </p>
        <p>
          You are also responsible for ensuring that all persons who access our Site through your
          internet connection are aware of these terms of use and other applicable terms and
          conditions, and that they comply with them.
        </p>
        <h3>We may transfer this agreement to someone else</h3>
        <p>
          We may transfer our rights and obligations under these terms to another organisation. We
          will always tell you in writing if this happens.
        </p>
        <h3>Our site is only for users in the UK</h3>
        <p>
          Our Site is directed to people residing in the United Kingdom. We do not represent that
          content available on or through our site is appropriate for use or available in other
          locations.
        </p>
        <h3>How you may use material on our Site and App</h3>
        <p>
          We are the owner or the licensee of all intellectual property rights in and to our Site
          and our App unless stated otherwise. Nothing in these Terms of Use grant you any legal
          rights in our Site and App, nor any underlying technology, other than as necessary to
          enable you to access our Services. You agree not to adjust to try to circumvent or delete
          any notices contained on the Site and the App (including any intellectual property
          notices) and in particular in any digital rights or other security technology embedded or
          contained within our Site, App and technology.
        </p>
        <h3>Content</h3>
        <p>
          <strong>Open-Source Software.</strong> Certain software code incorporated into or
          distributed with the Site and App Services may be licensed by third parties under various
          ”open-source” or ”public-source” software licences (such as the GNU General Public
          Licence, the GNU Lesser General Public Licence, the Apache Licence, the Berkeley Software
          Distribution Licence, and the MIT Licence) (collectively, the “Open Source Software”).
          Notwithstanding anything to the contrary in these Terms of Use, the Open Source Software
          is not licensed under these Terms of Use and instead is separately licensed pursuant to
          the terms and conditions of their respective open-source software licences. You agree to
          comply with the terms and conditions of such open-source software licence agreements
        </p>
        <p>
          <strong>App Stores.</strong> With respect to any App accessed through or downloaded from
          the Apple App Store, Google Play Store or any similar store or marketplace (each an “App
          Store” and references to the App Store include the corporate entity and its subsidiaries
          making the App Store available to you), you agree to comply with all applicable
          third-party terms of the App Store (the “Usage Rules”) when using the App. To the extent
          these Terms of Use provide for usage rules that are less restrictive than or otherwise in
          conflict with the Usage Rules, the more restrictive term applies.
        </p>
        <p>
          You acknowledge and agree that the availability of the App and the delivery of the
          Services, is dependent on the third party from whom you received the App licence, e.g.,
          the App Store. You acknowledge that these Terms of Use are between you and us and not with
          the App Store. We, not the App Store, are solely responsible for the App and Services, the
          contents, maintenance, support services, and warranty, and addressing any claims relating
          thereto (e.g., product liability, legal compliance, or intellectual property rights
          infringement).
        </p>
        <p>
          <strong>Use of the App.</strong> To use the App, you must have access to a wireless
          network, and you agree to pay all fees associated with such access. You also agree to pay
          all fees (if any) charged by the App Store in connection with the App or Services. You
          agree to comply with, and your license to use the App is conditioned upon your compliance
          with, all applicable third-party terms of agreement (e.g., the App Store‘s terms and
          policies) when using the App or Services. You acknowledge that the App Store (and its
          subsidiaries) are third-party beneficiaries of these Terms of Use and will have the right
          to enforce them.
        </p>
        <p>
          <strong>Third Party Content and links.</strong> In addition to the above, content from and
          links to other users, suppliers, advertisers, and other third parties may be made
          available to you through the Services. Because we do not control such content, you agree
          that we are not responsible for any such content. We do not make any guarantees about the
          accuracy, currency, suitability, or quality of the information in such content, and we
          assume no responsibility for unintended, objectionable, inaccurate, misleading, or
          unlawful content made available by other users, advertisers, and other third parties or
          violation of any third-party rights related to such content. The Services may contain
          links to websites not operated by us, including without limitation the websites of the
          brands we include in Packages. We are not responsible for the content, products,
          materials, or practices (including privacy practices) of such websites. We provide these
          links for your convenience only and we do not control such websites. Our inclusion of
          links to such websites does not imply any endorsement of the materials on such third-party
          websites or any association with their operators. The Services may also contain links to
          websites that are operated by us but which operate under different terms of use. It is
          your responsibility to review the privacy policies and terms of use of any other website
          you visit. You agree that in no event will we be liable to you in connection with any
          websites, content, products, materials, or practices of any third party.
        </p>
        <h3>Use of content</h3>
        <p>
          You may print off one copy, and may download extracts of any page(s) from the Site and the
          App only to the extent this is necessary for the purpose of placing an order with us, or
          for using the Site or the App as a shopping resource. You must not modify the paper or
          digital copies of any materials you have printed off or downloaded in any way, and you
          must not use any illustrations, photographs, video or audio sequences or any graphics
          separately from any accompanying text.
        </p>
        <p>
          Its Your Choixce status as the owner, or licensee, of content and must always be
          acknowledged. You must not use any part of the content on the Site or the App for
          commercial purposes without obtaining a licence to do so from Its Your Choixce or (if
          applicable) our licensors. If you print off, copy or download any part of the Site or the
          App in breach of these general conditions, your right to use the Services will cease
          immediately and you must, at our option, return or destroy any copies of the materials you
          have made.
        </p>
        <p>
          The content on our Site and the App is provided for general information only. It is not
          intended to amount to advice on which you should rely. You must obtain professional or
          specialist advice before taking, or refraining from, any action on the basis of the
          content on our Site or the App.
        </p>
        <h3>Content Guidelines</h3>
        <p>
          Please use good taste all content, including text, links, images, and videos, you post
          (“Your Content”) to our Services, in our forums, comments to our blog, or on any other
          page or website related to Its Your Choixce. We reserve the right, but do not undertake an
          obligation, to review Your Content, and to investigate and/or take appropriate action
          against you or Your Content in our sole discretion if you violate the guidelines below or
          any other provision of these Terms or otherwise create liability for us or any other
          person. Such action may include removing or modifying Your Content, terminating your
          Account, and/or reporting you to law enforcement authorities.
        </p>
        <p>
          You are solely responsible for Your Content. You assume all risks associated with use of
          Your Content. You understand that we do not guarantee any confidentiality with respect to
          Your Content. You may not represent or imply to others that Your Content is in any way
          provided, sponsored or endorsed by us. We are not obligated to back up Your Content, and
          Your Content may be deleted at any time without prior notice. You are solely responsible
          for creating and maintaining your own backup copies of Your Content if you desire. We will
          not be liable for the deletion or accuracy of Your Content; the failure to store,
          transmit, or receive transmission of Your Content; or the security, privacy, storage, or
          transmission of other communications originating with or involving use of the Services. We
          reserve the right in our sole discretion to pre-screen, refuse, or remove Your Content at
          any time for any reason. Please make sure that you only provide information to the
          Services that you are allowed to share without violating any obligations you may have to a
          third party.
        </p>
        <p>
          We do not claim any ownership of Your Content. By making available Your Content on or in
          the Services, you represent that you own or have all rights necessary to make available
          Your Content to us. You hereby grant, and you represent and warrant that you have the
          right to grant, to us a fully paid, royalty-free, perpetual, irrevocable, worldwide,
          non-exclusive, and fully sub-licensable licence to licence, reproduce, distribute, modify,
          adapt, publicly display and perform, prepare derivative works of, incorporate into other
          works, and otherwise use Your Content (in whole or in part) for the purposes of including
          Your Content in the Services and operating, providing and promoting the Services. You
          agree to irrevocably waive (and cause to be waived) any claims and assertions of moral
          rights or attribution with respect to Your Content.
        </p>
        <h3>General Rules of User Conduct</h3>
        <p>
          We do not guarantee that our Site or the App will be secure or free from software bugs or
          viruses. You are responsible for configuring your information technology, computer
          programmes and platform to access our Site and the App. You should use your own virus
          protection software. You must not misuse our Site or the App by knowingly introducing
          viruses, trojans, worms, logic bombs or other material that is malicious or
          technologically harmful. You must not attempt to gain unauthorised access to our Site or
          the App, the server on which our Site or the App is stored or any server, computer or
          database connected to our Site or our App. You must not attack our Site or our App via a
          denial-of-service attack or a distributed denial-of-service attack. By breaching this
          provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will
          report any such breach to the relevant law enforcement authorities and we will co-operate
          with those authorities by disclosing your identity to them. In the event of such a breach,
          your right to use our Services will cease immediately.
        </p>
        <p>
          You agree not to: (i) take any action or (ii) make available any content on or through the
          Services that: (A) violates any third-party right, including any intellectual property or
          proprietary right; (B) is unlawful, threatening, abusive, harassing, defamatory,
          libellous, deceptive, fraudulent, invasive of another‘s privacy, tortious, obscene,
          offensive, or profane; (C) constitutes unauthorised or unsolicited advertising, junk or
          bulk e-mail; (D) involves commercial activities and/or sales without our prior written
          consent; (E) impersonates any person or entity; (F) interferes with or attempts to
          interfere with the proper functioning of our technology in any way not expressly permitted
          by the Terms; or (G) attempts to engage in or engage in, any potentially harmful acts that
          are directed against our technology.
        </p>
        <h3>Right to Suspend</h3>
        <p>
          We do not guarantee that our Services, or any content on our Site or App, will always be
          available or be uninterrupted error free. Its Your Choixce reserve the right, in its
          discretion, to suspend, withdraw or restrict access to our Services, at any time at our
          discretion including, as necessary to protect the security or operation of the Services.
          We will, where possible give you reasonable notice of any suspension or withdrawal.
        </p>
        <h3>Do not rely on information </h3>
        <p>
          The content on our Site and App is not intended to amount to advice on which you should
          rely. You must obtain professional or specialist advice before taking, or refraining from,
          any action based on the content on our Site or App.
        </p>
        <p>
          Although we make reasonable efforts to update the information on our Site and App, we make
          no representations, warranties or guarantees, whether express or implied, that the content
          on our site is accurate, complete or up to date.
        </p>
        <h3>Our responsibility for loss or damage suffered by you</h3>
        <ul>
          <li>
            We do not exclude or limit in any way our liability to you where it would be unlawful to
            do so. This includes liability for death or personal injury caused by our negligence or
            the negligence of our employees, agents or subcontractors and for fraud or fraudulent
            misrepresentation
          </li>
          <li>
            Different limitations and exclusions of liability will apply to liability arising as a
            result of the supply of any products to you, which will be set out in our{' '}
            <Link to="#">Terms</Link>.{' '}
          </li>
          <li>
            Please note that we only provide our Site and App for domestic and private use. You
            agree not to use our site for any commercial or business purposes, and we have no
            liability to you for any loss of profit, loss of business, business interruption, or
            loss of business opportunity.
          </li>
          <li>
            If defective digital content that we have supplied, damages a device or digital content
            belonging to you and this is caused by our failure to use reasonable care and skill, we
            will either repair the damage or pay you compensation.{' '}
          </li>
        </ul>
        <h3>We are not responsible for viruses and you must not introduce them</h3>
        <p>
          We do not guarantee that our Site and App will be secure or free from bugs or viruses.
        </p>
        <p>
          You are responsible for configuring your information technology, computer programmes and
          platform to access our site. You should use your own virus protection software.
        </p>
        <p>
          You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs
          or other material that is malicious or technologically harmful. You must not attempt to
          gain unauthorised access to our Site or App, the server on which our site is stored or any
          server, computer or database connected to our site. You must not attack our Site or App
          via a denial-of-service attack or a distributed denial-of service attack. By breaching
          this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We
          will report any such breach to the relevant law enforcement authorities and we will
          co-operate with those authorities by disclosing your identity to them. In the event of
          such a breach, your right to use our site will cease immediately.
        </p>
        <p>
          Trademarks. “Its Your Choixce” and “Package” are EU registered trademarks of Your Choix
          Ltd. Your Choix Ltd,. is the holding company of Its Your Choixce UK Ltd / Your Choix UK
          Ltd. You are not permitted to use our trademarks without our approval.
        </p>
        <h3>Which countrys laws apply to any disputes?</h3>
        <p>
          Please note that these terms of use, their subject matter and their formation, are
          governed by English law. You and we both agree that the courts of England and Wales will
          have exclusive jurisdiction except that if you are a resident of Northern Ireland you may
          also bring proceedings in Northern Ireland, and if you are resident of Scotland, you may
          also bring proceedings in Scotland.
        </p>
      </div>
    </div>
  );
};

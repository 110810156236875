import AccordionContent from 'components/AccordionContent';
import AccordionItem from 'components/AccordionItem';
import { FC } from 'react';
import { TitleMain } from '../../components/TitleMain';
import styles from './Help.module.scss';

export const Help: FC = () => {
  return (
    <div className={styles.wrapper}>
      <TitleMain title="FAQ's" />

      <AccordionContent>
        <AccordionItem
          title="What is Choixce?"
          content={
            <p>
              Choixce is an up to 70% Off RRP, online styling service specialising in designer men’s
              and kidswear. After completing a style profile online, your stylist dispatches a box
              of designer items within 24 hours directly to you. On proof of delivery, you have
              seven days to try everything on at home. Once you have tried everything on, login to
              your account, select what you would like to keep and pay up to 70% off RRP. All
              standard UK shipping is free.
            </p>
          }
        />
        <AccordionItem
          title="How does Choixce work?"
          content={
            <div>
              <div className={styles.subtitle}>1. Complete your style profile</div>
              <p>So we understand your fit, style and need.</p>

              <div className={styles.subtitle}>2. Book your box</div>
              <p>
                Your stylist will handpick items for your box – we take a £20 for men, £10 for
                kidswear styling deposit, which will be credited against anything you decide to keep
              </p>

              <div className={styles.subtitle}>
                3. Try everything on in the comfort of your own home
              </div>
              <p>Free UK shipping, with delivery within 3 days.</p>

              <div className={styles.subtitle}>4. Payment</div>
              <p>Decide what you want to keep and pay up to 70% Off RRP.</p>

              <div className={styles.subtitle}>5. Return for free</div>
              <p>Paperless returns from your home or drop off location</p>

              <div className={styles.subtitle}>6. Reorder in one click</div>
              <p>To be one step closer to &quot;pay what you want&quot; Gold Membership</p>
            </div>
          }
        />
        <AccordionItem
          title="How are you different from shopping online, or in a shop?"
          content={
            <div>
              <p>Choixce.com is the worlds first discount online personal shopping experience.</p>
              <p>
                We specialise in designer men’s and kidswear and do all the hard work for you, so
                sit back and relax whilst we hand select what you’ll love based on what you’ve told
                us.
              </p>
              <p>
                Try everything on in the peace and comfort of your own home – no waiting in line.
                Mix and match your new items with what’s already in your wardrobe and let your home
                audience help you decide what to keep….
              </p>
              <p>
                We offer one point of contact throughout the whole journey; your personal stylist is
                only a quick message away anytime you have a question or would like another box.
              </p>
            </div>
          }
        />
        <AccordionItem
          title="Do you have a mobile app?"
          content={<p>We are working on it, keep your eyes peeled!</p>}
        />
        <AccordionItem
          title="Is Choixce available for everybody?"
          content={<p>We currently offer personal styling for men and children up to 18 years.</p>}
        />
        <AccordionItem
          title="What sizes can you style?"
          content={
            <p>We stock all ranges from XS to big and tall brands. Kids is New-born to 18 years</p>
          }
        />
        <AccordionItem
          title="Can I really pay what I want?"
          content={
            <div>
              <p>
                We know it sounds too good to be true, but you really can pay what you want. Once
                you have completed 4 boxes from us you will become part of our exclusive gold
                membership, then you unlock the full payment slider which allows you to pay what you
                want.
              </p>
            </div>
          }
        />
        <AccordionItem
          title="Why do you need my card details when registering?"
          content={
            <div>
              <p>
                We require either £20 for a men’s box or £10 for a kids box styling deposit before
                we send your box out.
              </p>
              <p>
                This is to protect us against fraud, along with covering the stylist time for
                picking, packing, and dispatching your box.
              </p>
              <p>
                The deposit will be deducted from anything your purchase, or full refund is you
                return the whole box to us.
              </p>
            </div>
          }
        />
        <AccordionItem
          title="What if I don’t return my items after 7 days?"
          content={
            <div>
              <p>
                You will be reminded that you are over your 7-day home trial by your stylist, they
                will work with you to either proceed with payment or help with returning the items.
              </p>
              <p>
                If we don’t hear from you between the end of the home trial and 30 days from the box
                delivery date, we have the right to keep your deposit and process payment for 50%
                off the full RRP of the box.
              </p>
            </div>
          }
        />
        <AccordionItem
          title="Which brands should I expect to see?"
          content={
            <p>
              We carry brands for every budget, from high street brands to designer labels. Fill out
              your style profile, book your box and your stylist will discuss sending out the right
              brands for you.
            </p>
          }
        />
        <AccordionItem
          title="How much does it cost?"
          content={
            <div>
              <p>
                Using Choixce is free, all we ask for is a styling deposit upfront which will be
                deducted from anything your purchase, or full refund if you return the whole box to
                us.
              </p>
              <p>Delivery is free.</p>
              <p>Free returns.</p>
              <p>If you decide to keep items in the box, you pay 70% 0ff the RRP.</p>
            </div>
          }
        />
        <AccordionItem
          title="How do I pay for my items?"
          content={
            <div>
              <p>
                Simply log into your account here -
                <a
                  href="https://choixce.com/my/profile"
                  rel="noreferrer"
                  target="_blank"
                  className={styles.link}
                >
                  https://choixce.com/my/profile
                </a>{' '}
                and use the payment slider to confirm what you would like to pay.
              </p>
            </div>
          }
        />
        <AccordionItem
          title="How do I arrange another delivery?"
          content={
            <p>
              Arranging another delivery is quick and easy. Simply log into your account here -
              <a
                href="https://choixce.com/my/profile"
                rel="noreferrer"
                target="_blank"
                className={styles.link}
              >
                https://choixce.com/my/profile
              </a>{' '}
              and select to re-order a new box. We can only send one order per person at a time, so
              please ensure all active orders are completed before requesting another box.
            </p>
          }
        />
        <AccordionItem
          title="How do I order for more than one child?"
          content={
            <div>
              <p>
                Ordering items for more than one child is quick and easy. Log into your account &
                click “style for kids”, you’ll need to fill out the details for each child including
                size and information about what they need. We can only send one box per household at
                a time, so your stylist will discuss with you which order you’d like us to start
                with.
              </p>
            </div>
          }
        />
        <h3 className={styles.sectionName}>Shipping</h3>
        <AccordionItem
          title="Where is Choixce available?"
          content={<p>We currently only ship to mainland UK.</p>}
        />
        <AccordionItem
          title="Do you ship internationally?"
          content={<p>Only UK shipments currently.</p>}
        />
        <AccordionItem
          title="Reschedule your box"
          content={
            <div>
              <p>
                If you need to reschedule your box, you must tell us within 12 hours of your deposit
                being taken. As we aim to dispatch your box within 24 hours.
              </p>
              <p>
                If you need to manage the delivery time, you can do this via the tracking email.
              </p>
              <p>
                Please note we may be unable to reschedule your box due to the short lead time to
                dispatch, if it is not within the first 12 hours please speak to your stylist.
              </p>
            </div>
          }
        />
        <AccordionItem
          title="Cancelling your box"
          content={
            <p>
              If you decide to cancel your box, please do so as soon as possible. Note we may be
              unable to reschedule your box due to the short lead time to dispatch, if it is not
              within the first 12 hours.
            </p>
          }
        />
        <AccordionItem
          title="Exchanging your items"
          content={
            <p>
              We can exchange items for you no problem, just contact your stylist to arrange. Please
              note, on occasion we may not have the item available in stock, however we can let you
              know when it is available for dispatch.
            </p>
          }
        />
        <AccordionItem
          title="Returns"
          content={
            <div>
              <p>All returns are free.</p>
              <p>
                Please place unwanted items back inside the packaging and ensure it is properly
                sealed.
              </p>
              <p>Ensure all items are unused and still with labels provided.</p>
              <p>Remove any old address labels.</p>
              <p>
                Visit -{' '}
                <a
                  href="https://www.royalmail.com/track-my-return/create/5726"
                  rel="noreferrer"
                  target="_blank"
                  className={styles.link}
                >
                  https://www.royalmail.com/track-my-return/create/5726
                </a>{' '}
                to process your free return.
              </p>
              <p>
                Attach label to the box or for paperless show your QR code at the Post Office so
                they can attach a label.
              </p>
              <p>We will let you know once we receive all items back to our warehouse.</p>
            </div>
          }
        />
      </AccordionContent>
    </div>
  );
};

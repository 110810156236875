export const ROOT_URL = '/';
export const ORDER_LIST_URL = `${ROOT_URL}orders`;
export const PAYMENT_METHODS_URL = `${ROOT_URL}payment-methods`;
export const PAYMENT_METHODS_DELETE_URL = `${PAYMENT_METHODS_URL}/:id`;
export const PAYMENT_METHODS_SET_DEFAULT_URL = `${PAYMENT_METHODS_URL}/:id/default`;

export const makePostOrderDraftUrl = (): string => `${ROOT_URL}order-drafts`;
export const makePutOrderDraftUrl = (orderId: string): string =>
  `${makePostOrderDraftUrl()}/${orderId}`;

export const makeGetOrderListUrl = (): string => `${ROOT_URL}orders`;
export const makePostOrderUrl = makeGetOrderListUrl;

export const makeGetPaginatedOrderListUrl = (): string => `${ROOT_URL}order-pages`;

export const makeGetOrderUrl = (orderId: string): string => `${makeGetOrderListUrl()}/${orderId}`;

export const makeGetOrderShipmentUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/shipment`;

export const makeGetOrderReturnUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/return`;

export const makePostOrderReturnLinkUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/return/link`;

export const makePostOrderCommentUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/comments`;

export const makeGetReviewLinkUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/reviews/link`;

export const makePostLastCompletedOrderUrl = (): string => `${makePostOrderUrl()}/reorder`;

export const makePostMysteryOrder = (): string => `${makePostOrderUrl()}/mystery`;

export const makePostBookChatForOrder = (): string => `${makePostOrderUrl()}/book-chat`;

export const makeGetOrderPaymentStatusUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/payment/status`;

export const makePostDirectPaymentUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/payment/direct`;

export const makePostPayDesiredAmount = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/pay/desired-amount`;

export const makeGetCustomerProfileUrl = (): string => `${ROOT_URL}profiles/me`;
export const makePostCustomerProfileUrl = makeGetCustomerProfileUrl;
export const makePatchCustomerProfileUrl = makeGetCustomerProfileUrl;

export const makeGetAddressListUrl = (): string => `${ROOT_URL}addresses`;

export const makePostAddressUrl = makeGetAddressListUrl;

export const makeSetDefaultAddressUrl = (id: string): string =>
  `${makeGetAddressListUrl()}/${id}/default`;

export const makeDeleteAddressUrl = (id: string): string => `${makeGetAddressListUrl()}/${id}`;

export const makeGetStylingProfilesUrl = (stylingFor?: string): string =>
  `${ROOT_URL}styling-profiles${stylingFor ? `?stylingFor=${stylingFor}` : ''}`;

export const makePostMakeOfferUrl = (id: string): string => `${ROOT_URL}orders/${id}/offer`;

export const makePutSetReturnReasonUrl = (id: string, itemId: string): string =>
  `${ROOT_URL}returns/${id}/items/${itemId}/reason`;

export const makeGetZipLinkDesiredAmount = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/zip/checkouts/desired-amount`;

export const makeGetZipLink = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/zip/checkouts`;

export const makePayWithZipLink = (orderId: string, checkoutId: string): string =>
  `${makeGetOrderUrl(orderId)}/zip/checkouts/${checkoutId}/payment`;

export const makeGetReviewListUrl = (): string => `${ROOT_URL}reviews`;

export const makeGetCreditListUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/available-credits`;

export const makeGenerateBraintreeTokenUrl = (): string =>
  `${PAYMENT_METHODS_URL}/braintree/clienttoken`;

export const makeBraintreeCheckouUrl = (): string => `${PAYMENT_METHODS_URL}/braintree`;

import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { Button, RouterLinkButton } from '../../../../components/Button';
import { Loading, LoadingSizes } from '../../../../components/Loading';
import { Title } from '../../../../components/Title';
import { CUSTOMER_DETAILS_URL } from '../../../../routePaths';
import {
  useCustomerProfile,
  usePatchCustomerProfile,
} from '../../../../serverCache/customerProfile';
import { CustomerDetailsForm } from '../../components/CustomerDetailsForm';

import styles from './Edit.module.scss';
import { ProfileBack } from '../../../../components/ProfileBack';

export const Edit: FC = () => {
  const { mutateAsync: patchCustomerProfile } = usePatchCustomerProfile();
  const { data, isLoading } = useCustomerProfile();
  const history = useHistory();

  return (
    <>
      <ProfileBack to={CUSTOMER_DETAILS_URL} name="details" />
      <Title backgroundTitle="My Details" title="Edit my details" align="center" />
      {!isLoading && (
        <CustomerDetailsForm
          className={styles.form}
          defaultValues={data}
          onSubmit={async (formState) => {
            const result = await patchCustomerProfile(formState);
            history.push(CUSTOMER_DETAILS_URL);
            return result;
          }}
          renderFooter={({ formState: { isValid, isSubmitting } }) => (
            <div className={styles.buttonContainer}>
              <RouterLinkButton color="white" to={CUSTOMER_DETAILS_URL}>
                Cancel
              </RouterLinkButton>
              <Button type="submit" disabled={!isValid || isSubmitting}>
                {isSubmitting ? <Loading size={LoadingSizes.small} /> : <>Save</>}
              </Button>
            </div>
          )}
        />
      )}
    </>
  );
};

import { FC, PropsWithChildren } from 'react';
import styles from './ProfileLayout.module.scss';
import classNames from 'classnames';
import { CookieConsent } from '../CookieConsent';
import * as consentConstants from './../../consentConstants';
import { useAnalytics } from '../../analytics';
import Footer from 'components/Footer';
import Header from 'components/Header';

interface Props {
  subMenu?: JSX.Element;
  addCol?: boolean;
}

export const ProfileLayout: FC<PropsWithChildren<Props>> = ({ children, subMenu }) => {
  const { loadScripts } = useAnalytics();

  return (
    <>
      <div>
        <Header showNavButtons={true} />
        <div className={styles.wrapper}>
          {subMenu}
          <div className={classNames(styles.content, 'ym-hide-content')}>{children}</div>
        </div>
        <Footer />
      </div>
      <CookieConsent
        cookieName={consentConstants.COOKIE_NAME}
        cookieValue={consentConstants.ACCEPTED_VALUE}
        onAccept={loadScripts}
      />
    </>
  );
};

import dayjs from 'dayjs';
import { StylingFor } from '../../../../dataTypes';

type DobValidator = (value: string | Date | undefined) => string | undefined;

export const isMaleDobValid: DobValidator = (value) => {
  if (value === undefined || !dayjs(value).isValid()) {
    return 'Fail';
  }

  const nowDate = dayjs();
  const maxDate = nowDate.add(-17, 'year');
  const minDate = nowDate.add(-120, 'year');

  if (!dayjs(value).isBetween(minDate, maxDate)) {
    return 'Fail';
  }
};

export const isKidDobValid: DobValidator = (value) => {
  if (value === undefined || !dayjs(value).isValid()) {
    return 'Fail';
  }
  const nowDate = dayjs();
  const minDate = nowDate.add(-17, 'year');

  if (!dayjs(value).isBetween(minDate, nowDate)) {
    return 'Fail';
  }
};

export function getDobValidator(stylingFor: StylingFor): DobValidator {
  if (stylingFor === StylingFor.Men) {
    return isMaleDobValid;
  }

  return isKidDobValid;
}

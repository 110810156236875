import React, { FC, ReactElement, useEffect } from 'react';
import { Controller, DefaultValues, useForm, UseFormReturn } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { useSubscribeOnFormChanges } from '../../../../utils/form-helpers';
import { noAngleBracketsRegex, phoneRegex } from '../../../../constants';
import { TextInput } from '../../../../components';
import { Button } from '../../../../components/Button';
import { Loading, LoadingSizes } from '../../../../components/Loading';
import { CommunicationMethod } from '../../../../dataTypes';
import { CommunicationMethodSwitch } from '../../../../components/CommunicationMethodSwitch';

import styles from './CustomerDetailsForm.module.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/explicit-function-return-type
function noop() {}

const NAME_MIN_LENGTH = 1;
const NAME_MAX_LENGTH = 50;
const PHONE_MAX_LENGTH = 35;

export interface FormState {
  firstName: string;
  lastName: string;
  phone: string;
  communicationMethod: CommunicationMethod;
}

interface Props {
  defaultValues?: DefaultValues<FormState>;
  autoFocusOnFirst?: boolean;
  onSubmit?: SubmitHandler<FormState>;
  onChange?(state: FormState): void;
  renderFooter?(form: UseFormReturn<FormState>): ReactElement;
  className?: string;
}

export const CustomerDetailsForm: FC<Props> = (props) => {
  const {
    defaultValues,
    onSubmit = noop,
    onChange = noop,
    autoFocusOnFirst,
    renderFooter,
    className,
  } = props;

  const form = useForm<FormState>({
    mode: 'all',
    defaultValues,
  });

  const {
    handleSubmit,
    register,
    control,
    watch,
    reset,
    formState,
    formState: { isValid, errors, isSubmitting },
  } = form;

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset(defaultValues);
    }
  }, [formState, reset, defaultValues]);

  useSubscribeOnFormChanges(watch, onChange);

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        className={styles.textInput}
        label="First Name"
        placeholder="Forename"
        maxLength={NAME_MAX_LENGTH}
        autoFocus={autoFocusOnFirst}
        id="cd-firstName"
        hasError={!!errors.firstName}
        {...register('firstName', {
          required: true,
          minLength: NAME_MIN_LENGTH,
          maxLength: NAME_MAX_LENGTH,
          pattern: noAngleBracketsRegex,
        })}
      />
      <TextInput
        className={styles.textInput}
        label="Last Name"
        placeholder="Surname"
        maxLength={NAME_MAX_LENGTH}
        id="cd-lastName"
        hasError={!!errors.lastName}
        {...register('lastName', {
          required: true,
          minLength: NAME_MIN_LENGTH,
          maxLength: NAME_MAX_LENGTH,
          pattern: noAngleBracketsRegex,
        })}
      />
      <TextInput
        label="Phone"
        autoComplete="tel"
        placeholder="International or local"
        id="address-phone"
        className={styles.textInput}
        maxLength={PHONE_MAX_LENGTH}
        hasError={!!errors.phone}
        {...register('phone', {
          required: true,
          pattern: phoneRegex,
        })}
      />
      <Controller
        control={control}
        name="communicationMethod"
        rules={{
          required: true,
        }}
        render={({ field: { ref, ...field } }) => (
          <CommunicationMethodSwitch
            label="Preferred contact method"
            {...field}
            className={styles.marginBottom}
          />
        )}
      />
      {renderFooter && renderFooter(form)}
      {!renderFooter && (
        <div className={styles.buttonContainer}>
          <Button type="submit" disabled={!isValid || isSubmitting}>
            {isSubmitting ? <Loading size={LoadingSizes.small} /> : <>Save</>}
          </Button>
        </div>
      )}
    </form>
  );
};

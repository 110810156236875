import { useAsync, UseAsyncResult } from 'hooks/useAsync';
import { useService } from 'providers/useService';

export function useGetReviewLink(orderId: string): UseAsyncResult<string> {
  const { apiService } = useService();

  return useAsync(async () => {
    const link = await apiService.getReviewLink(orderId);

    return link;
  }, [orderId]);
}

import { removeSpecialSymbols } from '.';

export const itemIconsFileExtension = 'png';
export const itemIconsPath = '/images/item-icons/';

export const getIconPath = (websiteCategory: string): string => {
  return `${itemIconsPath}${removeSpecialSymbols(
    websiteCategory,
  ).toLowerCase()}.${itemIconsFileExtension}`;
};

import { FC, useEffect, useState } from 'react';
import { NewBraintreeCardForm } from './NewBraintreeCardForm';
import { useService } from 'providers/useService';
import { ErrorMessage } from 'pages/PaymentMethods/components/ErrorMessage';
import { Loading } from 'components/Loading';
import styles from './NewBraintreePaymentMethod.module.scss';

interface Props {
  buttonText?: string;
  onSuccess(): void;
}

export const NewBraintreePaymentMethod: FC<Props> = ({ buttonText, onSuccess }: Props) => {
  const { paymentApiService } = useService();

  const [clientToken, setClientToken] = useState('');
  const [isError, setIsError] = useState(false);
  const [showBraintreeDropIn, setShowBraintreeDropIn] = useState(false);

  useEffect(
    () => {
      paymentApiService
        .generateBraintreeToken()
        .then((token: string) => {
          setClientToken(token);
          setShowBraintreeDropIn(true);
        })
        .catch(() => {
          setIsError(true);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const initiatePayment = (paymentMethodNonce: string): void => {
    setShowBraintreeDropIn(false);

    paymentApiService
      .checkoutWithBraintree(paymentMethodNonce)
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        setIsError(true);
      });
  };

  const resetFail = (): void => {
    setShowBraintreeDropIn(true);
    setIsError(false);
  };

  return (
    <>
      {isError ? (
        <ErrorMessage onReset={resetFail} />
      ) : !showBraintreeDropIn ? (
        <div className={styles.loadingBox}>
          <Loading isBlack />
        </div>
      ) : (
        <NewBraintreeCardForm
          clientToken={clientToken}
          buttonText={buttonText}
          show={showBraintreeDropIn}
          checkout={initiatePayment}
        />
      )}
    </>
  );
};

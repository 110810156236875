import React, { FC, useMemo } from 'react';
import styles from './RadioInput.module.scss';
import classNames from 'classnames';
import { Label } from '../Label';
import { getRowGrid } from '../InputGrid/styles';

type NativeInputProps = React.InputHTMLAttributes<HTMLInputElement>;

interface RadioItem {
  value: string;
  label: string;
}

interface AdditionalProps {
  name: string;
  label?: string;
  outlined?: boolean;
  layout?: 'horizontal' | 'vertical';
  items: RadioItem[];
}

type Props = AdditionalProps & Pick<NativeInputProps, 'value' | 'onChange' | 'className'>;

function radioId(prefix: string, value: string): string {
  return `${prefix}__${value}`;
}

export const RadioInput: FC<Props> = (props) => {
  const { name, items, label, layout = 'horizontal', className, value, onChange } = props;
  const gridStyles = useMemo(() => getRowGrid(), []);

  return (
    <div className={classNames(gridStyles.container, styles.container, className)}>
      {label !== undefined && (
        <Label className={classNames(gridStyles.label, styles.controlTitle)}>{label}</Label>
      )}
      <div className={classNames(gridStyles.input, styles.radioGroup, styles[layout])}>
        {items.map((item) => (
          <div
            key={item.value}
            className={classNames(styles.radioBtn, {
              [styles.outlined]: props.outlined,
            })}
          >
            <input
              id={radioId(name, item.value)}
              type="radio"
              name={name}
              value={item.value}
              checked={value === item.value}
              onChange={onChange}
            />
            <label htmlFor={radioId(name, item.value)}>{item.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

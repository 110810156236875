import { FC, useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Button } from '../../../../components/Button';
import { ORDER_FLOW_START_PATH } from '../../../../routePaths';
import styles from './Empty.module.scss';

const menUrl = generatePath(ORDER_FLOW_START_PATH, { stylingFor: 'men' });
const kidsUrl = generatePath(ORDER_FLOW_START_PATH, { stylingFor: 'kids' });

export const EmptyComponent: FC = () => {
  const history = useHistory();

  const onClickMen = useCallback(() => history.push(menUrl), [history]);
  const onClickKids = useCallback(() => history.push(kidsUrl), [history]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.host}>
        <h2 className={styles.header}>You have no orders yet</h2>
        <p className={styles.subtitle}>
          You currently don&rsquo;t have any orders logged with&nbsp;us. Please tell&nbsp;us what
          you&rsquo;re looking for by&nbsp;filling out a&nbsp;short questionnaire here.
        </p>
        <p className={styles.buttons}>
          <Button data-testid="men" color="black" width="full" onClick={onClickMen}>
            Men
          </Button>
          <Button data-testid="kid" color="black" width="full" onClick={onClickKids}>
            Kids
          </Button>
        </p>
      </div>
    </div>
  );
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, ReactElement, useCallback, useState } from 'react';
import { Button } from '../../../../components/Button';
import styles from './ReorderTile.module.scss';
import { LoadingBox } from '../../../../components/LoadingBox';
import { UseMutationResult } from 'react-query';
import { WhiteLogo } from '../../../../components/Icons';
import classNames from 'classnames';
import { useStylingProfiles } from 'serverCache/useStylingProfiles';
import { Select } from 'components/Select';

type Mutation = Pick<UseMutationResult<void, unknown, string>, 'status' | 'mutate'>;

interface Props {
  image?: string;
  icon?: JSX.Element;
  title: string;
  buttonTitle: string;
  idleContent: ReactElement;
  successContent: ReactElement;
  errorContent: ReactElement;
}

export const ReorderTile: FC<Props & Mutation> = (props) => {
  const { mutate, status } = props;
  const [showProfilesSelect, setShowProfilesSelect] = useState(false);
  const [selectedProfileId, setSelectedProfileId] = useState<string | undefined>(undefined);

  const { data: stylingProfiles } = useStylingProfiles();

  const isLoading = status === 'loading';
  const isIdle = status === 'idle';
  const isSuccess = status === 'success';
  const isError = status === 'error';

  const handleButtonClick = useCallback((): void => {
    if (stylingProfiles) {
      const moreThanOneProfile = stylingProfiles.length > 1;
      const firstProfileId = stylingProfiles[0].id!;
      if (moreThanOneProfile) {
        setShowProfilesSelect(true);
        setSelectedProfileId(firstProfileId);
      } else {
        mutate(firstProfileId);
      }
    }
  }, [mutate, stylingProfiles]);

  const handleOkClick = (): void => {
    mutate(selectedProfileId!);
    setShowProfilesSelect(false);
  };

  const handleCancelClick = (): void => {
    setShowProfilesSelect(false);
  };

  return (
    <LoadingBox className={styles.loadingBox} isLoading={isLoading}>
      {isIdle && (
        <>
          <div className={styles.image}>
            <img
              src={props.image}
              alt={props.title}
              className={classNames({
                [styles.hidden]: props.icon,
              })}
            />
            {props.icon}
          </div>
          <h3 className={styles.title}>{props.title}</h3>
          <div className={styles.text}>{props.idleContent}</div>
          {!showProfilesSelect && (
            <Button
              color="black"
              onClick={handleButtonClick}
              className={styles.button}
              data-testid="button"
            >
              <WhiteLogo />
              <span>{props.buttonTitle}</span>
            </Button>
          )}
        </>
      )}
      {isSuccess && <>{props.successContent}</>}
      {isError && <>{props.errorContent}</>}
      {showProfilesSelect && stylingProfiles && (
        <>
          <Select
            name="select"
            label="For:"
            value={selectedProfileId}
            items={stylingProfiles.map((el) => ({
              value: el.id!,
              label: `${el.firstName} ${el.lastName}`,
            }))}
            onChange={(e) => setSelectedProfileId(e.target.value)}
            className={styles.box}
          />
          <div className={styles.buttonWrapper}>
            <Button onClick={handleOkClick}>OK</Button>
            <Button onClick={handleCancelClick}>Cancel</Button>
          </div>
        </>
      )}
    </LoadingBox>
  );
};

import { useQuery, UseQueryResult } from 'react-query';
import { useService } from '../providers/useService';
import { SubmittedOrder } from '../dataTypes';

export function useOrder(orderId: string): UseQueryResult<SubmittedOrder> {
  const { apiService } = useService();

  return useQuery(['orders', orderId], async () => {
    return await apiService.getOrder(orderId);
  });
}

import { useQuery, UseQueryResult } from 'react-query';
import { StylingProfile } from 'dataTypes';
import { useAuth } from 'providers/AuthProvider';
import { makeGetStylingProfilesUrl } from 'consts';

export const useStylingProfiles = (
  enabled?: boolean,
  stylingFor?: string,
): UseQueryResult<StylingProfile[]> => {
  const { axiosInstance } = useAuth();

  return useQuery(
    ['stylingProfiles', stylingFor],
    async () => {
      const url = makeGetStylingProfilesUrl(stylingFor);
      const response = await axiosInstance.get<StylingProfile[]>(url);
      return response.data;
    },
    { enabled },
  );
};

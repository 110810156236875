import styles from './InputGrid.module.scss';

export interface GridOptions {
  container?: string;
  label?: string;
  input?: string;
}

export function getRowGrid(): GridOptions {
  return {
    container: styles.row,
    label: styles.label,
    input: styles.input,
  };
}

export function getColumnGrid(): GridOptions {
  return {
    container: styles.column,
    label: styles.label,
    input: styles.input,
  };
}

export function moveFirstToStart<T>(items: T[], predicate: (item: T) => boolean): T[] {
  const indexOfFirstItem = items.findIndex(predicate);

  if (indexOfFirstItem < 0) {
    return items;
  }

  const otherItems = [...items];
  otherItems.splice(indexOfFirstItem, 1);

  return [items[indexOfFirstItem], ...otherItems];
}

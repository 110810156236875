import { useService } from '../../providers/useService';
import { useQuery, UseQueryResult } from 'react-query';
import { OrderStatus } from '../../dataTypes';

/* istanbul ignore file */

export function useCanRequestAnotherBox(): UseQueryResult<boolean> {
  const { apiService } = useService();

  return useQuery(['user', 'canRequestAnotherBox'], async () => {
    const orders = await apiService.getOrderList();
    const firstCompletedOrder = orders.find((order) => {
      const status = OrderStatus[order.status as keyof typeof OrderStatus];
      return status === OrderStatus.Completed || status === OrderStatus.FullyReturned;
    });
    return !!firstCompletedOrder;
  });
}

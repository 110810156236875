import { FC, useEffect } from 'react';
import { useAuth } from '../providers/AuthProvider';
import { useHistory } from 'react-router-dom';

/* istanbul ignore file */

export const HistorySetter: FC = () => {
  const { setHistory } = useAuth();
  const history = useHistory();
  useEffect(() => {
    setHistory(history);
  }, [history, setHistory]);
  return <></>;
};

import { FC } from 'react';
import { MainLayout } from '../../components/MainLayout';
import { TitleMain } from '../../components/TitleMain';
import styles from './Privacy.module.scss';

export const Privacy: FC = /* istanbul ignore next */ () => {
  return (
    <MainLayout>
      <div className={styles.content}>
        <TitleMain title="Privacy" />
        <div>
          <h2>Choixce Data Protection Privacy and Cookies Policy</h2>
          <h3>Overview</h3>
          <p>
            Choixce Limited <strong>(CHOIXCE)</strong> is committed to protecting and respecting
            your privacy. This policy together with our Terms and Conditions, Terms of Use and
            Returns Policy set out the basis on which any Personal Data (as defined below) we
            collect from you or which you provide to us will be processed by us as a result of using
            or interacting with our website or app, as set out below. We aim at all times to process
            and collect your data lawfully, transparently, fairly and without adversely affecting
            your rights as a Data Subject (as defined below). Please read the following carefully to
            understand our practices and procedures. The website we operate is: www.choixce.com its
            related domains and sub domains <strong>(CHOIXCE Site)</strong> which is hosted by
            Microsoft Azure on servers in the United Kingdom. This policy also applies to our mobile
            application <strong>(App)</strong>.
          </p>
          <h3>Please note:</h3>
          <p>
            By giving your consent, you are accepting and consenting to the practices described in
            this policy. If you do not give your consent, please navigate away from the CHOIXCE Site
            or the App.
          </p>
          <p>
            You may withdraw your consent at any time. An explanation of your rights is set out
            below.{' '}
          </p>
          <p>
            For the purpose of the Data Protection Legislation (as defined below), the data
            controller is CHOIXCE LMITED, a company registered in England under registration number
            10994969. Our registered address is at The Old Mill, 9 Soar Lane, Leicester, LE3, 5DE
            and our registered VAT number 293406395.
          </p>
          <p>
            If you are not satisfied with any action taken by us or response given, you have the
            right to complain to Information Commissioner at{' '}
            <a href="http://www.ico.gov.uk/" target="_blank" rel="noopener noreferrer">
              www.ico.gov.uk
            </a>
            , or help line telephone number: 0303 1231113.
          </p>
          <h3>Definitions</h3>
          <p>In this policy the following terms shall have the following meanings:</p>
          <p>
            <strong>Cookie:</strong> a small file placed on your computer or device by the CHOIXCE
            Site or the App when you visit certain parts of the CHOIXCE Site and/or App and/or when
            you use certain features of the CHOIXCE Site and/or App.
          </p>
          <p>
            <strong>Cookie Law:</strong> means the relevant parts of the Privacy and Electronic
            Communications (EC Directive) Regulations 2003.
          </p>
          <p>
            <strong>Data Protection Legislation:</strong> means the Data Protection Act 2018, the UK
            GDPR and to the extent applicable, the General Data Protection Regulation ((EU)
            2016/679) (GDPR).
          </p>
          <p>
            <strong>Data Subject:</strong> a natural identifiable person.{' '}
          </p>
          <p>
            <strong>Personal Data:</strong> has the meaning set out in the Data Protection
            Legislation, being as at the data of this policy, any data which identifies a natural
            person (by way of example, name, address, phone number and so on).{' '}
          </p>
          <h3>What this policy also covers</h3>
          <p>This policy also explains:</p>
          <ul>
            <li>
              What personally identifiable information of yours is collected:
              <br /> — on the CHOIXCE Site or App, when you interact with it;
              <br /> — when you download from the CHOIXCE Site or App; <br />— when you interact
              CHOIXCE advertising and applications on third party
              <br />
              websites and services.
            </li>
            <li>What organisation is collecting the information;</li>
            <li>How the information is used;</li>
            <li>With whom the information may be shared;</li>
            <li>
              What choices are available to you regarding collection, use and distribution of the
              information;
            </li>
            <li>
              What kind of security procedures are in place to protect the loss, misuse or
              alteration of information under CHOIXCE s control;{' '}
            </li>
            <li>How you can correct any inaccuracies in the information; and </li>
            <li>How you can request the information we process and store to be removed.</li>
          </ul>
          <h3>We may make changes to these terms and our website</h3>
          <p>
            We may update and change these terms the Choixce Site and/or our App from time to time
            for a variety of reasons. By way of examples: to reflect changes to the services we
            offer, users needs, regulatory or legal requirements. Every time you wish to use the
            CHOIXCE Site and/or our App, please check these terms to ensure you understand the terms
            that apply at that time. These terms were updated on 5 December 2022.
          </p>
          <h3>Persons under the age of 18. </h3>
          <p>
            Persons under the age of 18 are not eligible to use our services and we ask that minors
            do not submit any personal information to us. We will not knowingly accept any data from
            a person who is under the age of 18 years.{' '}
          </p>
          <h3>Information we collect from you</h3>
          <p>We will collect and process the following information: </p>
          <ul>
            <li>
              technical information, including the Internet protocol (IP) address used to connect
              your computer to the Internet, your login information, browser type and version, time
              zone setting, browser plug-in types and versions, operating system and platform;{' '}
            </li>
            <li>
              information about your visit, including the full Uniform Resource Locators (URL),
              clickstream to, through and from our site (including date and time), download errors,
              length of visits to certain pages, page interaction information (such as scrolling,
              clicks, and mouse-overs), methods used to browse away from the page, and any phone
              number used to call us.
            </li>
            <li>
              if you: (a) create an account with us; (b) become a member; (c) purchase our goods and
              take up the offer of our styling services; (d) post material or request further
              services; (e) report a problem with the CHOIXCE Site and/or our App; or (f) otherwise
              correspond with us, we may collect your name, address (including e-mail address),
              phone numbers, clothing and footwear sizes, clothing, accessories and footwear
              preferences.{' '}
            </li>
          </ul>
          <p>Please Note:</p>
          <ul>
            <li>
              When purchasing goods from us you will be subject to the data protection and privacy
              policies of the following parties with whom we share your personal in the performance
              of our contract with you:
              <br />— our payment providers who are APEXX Global. Their policies can be found at{' '}
              <a
                href="https://apexx.global/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://apexx.global/privacy-policy/
              </a>
              . Our payment providers will collect your payment information to process your
              purchases;
              <br /> — Auth0 (authentication and authorisation) Auth0s policies can be found at:{' '}
              <a href="https://auth0.com/privacy" target="_blank" rel="noopener noreferrer">
                https://auth0.com/privacy
              </a>
              .
              <br /> — Our couriers, Norsk (for deliveries) and ZigZag (for collections). Their
              policies can be found at: Norsk:{' '}
              <a
                href="https://norsk.global/gdpr-data-protection/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://norsk.global/gdpr-data-protection/
              </a>{' '}
              <a
                href="https://gfsdeliver.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://gfsdeliver.com/privacy-policy/
              </a>
            </li>
          </ul>
          <h3>Use of your information</h3>
          <p>We may use the data collected about you in the following ways:</p>
          <ul>
            <li>to provide goods and styling services to you;</li>
            <li>
              to provide information about our businesses or services which may be of interest to
              you;
            </li>
            <li>improve the efficiency of the CHOIXCE Site and /or App;</li>
            <li>to develop our services;</li>
            <li>to notify you of changes to our goods and/or services; </li>
            <li>
              to understand our customer requirements and preferences, perform analysis and
              comparisons;
            </li>
            <li>
              to administer and update the Choixce Site and our App to ensure it runs smoothly on
              your hardware; and
            </li>
            <li>
              to offer you support in the event that you have problem or an issue with the CHOIXCE
              Site and/or the App.
            </li>
          </ul>
          <p>Please also see below under “Cookies”.</p>
          <h3>Where we store your personal data</h3>
          <ul>
            <li>
              Although the data we collect from you is stored in the European Economic Area (EEA),
              data transferred to third parties to perform our contract with you may be transferred
              to third parties outside the EEA, e.g. data passed to our payment providers, APEXX
              Global and information sent and received on email. By submitting Personal Data, you
              agree to this transfer, storing or processing. We will take all steps reasonably
              necessary to ensure that your data is treated securely and in accordance with this
              policy.
            </li>
            <li>
              As referred to above, all information you provide to us is stored on our secure
              servers and those of our payment providers when you submit Personal Data in the course
              of utilising your account, ordering and paying for goods and using our styling
              services. We use Microsoft Azure by way of back up.{' '}
            </li>
            <li>
              In addition, we use SSL (Secure Sockets Layer) technology to encrypt the data on the
              CHOIXCE Site. The parties with whom we share your data to perform our contract with
              you may have different arrangements in place.{' '}
            </li>
            <li>
              Where we have given you (or where you have chosen) a password which enables you to
              access certain parts of the CHOIXCE Site and our App, you are responsible for keeping
              this password confidential. We ask you not to share a password with anyone.
            </li>
            <li>
              We take all reasonable technical, organisation and security steps to ensure that the
              information you supply to us over the internet is secure.{' '}
            </li>
            <li>
              Please note that we use Salesforce as our CRM system. Any data stored by Salesforce is
              hosted in London. Salesforces privacy policy can be found at:{' '}
              <a
                href="www.salesforce.com/uk/company/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.salesforce.com/uk/company/privacy
              </a>
            </li>
          </ul>
          <h3>Your rights</h3>
          <p>
            As a Data Subject under the Data Protection Legislation, you have the following rights:
          </p>
          <ul>
            <li>
              You have the right to ask us not to process your Personal Data for marketing purposes
              unless you give us your express consent to do so. When you create an account with us
              and become a member, we will ask you if you are happy for us to contact you for
              marketing purposes and you will be asked to select the methods by which you consent to
              us contacting you. In addition, we will not share your information with third party
              marketing agencies without your consent. You can also exercise the right at any time
              to ask us to stop contacting you for marketing purposes where you have given your
              consent by contacting us at: email:{' '}
              <a href="mailto:data@choixce.com">data@choixce.com</a>.
            </li>
            <li>
              The CHOIXCE Site and our App may, from time to time, contain links to and from other
              websites including but not limited to our payment providers. If you follow a link to
              any of these websites, please note that these websites have their own privacy policies
              and that we do not accept any responsibility or liability for these policies. Please
              check these policies before you submit any Personal Data to these websites.
            </li>
            <li>
              You have the right to ask us to supply to you the information we hold, this is known
              as a data subject access request. For further details please contact us on email:{' '}
              <a href="mailto:data@choixce.com">data@choixce.com</a>. or by writing to us at Choixce
              Limited, Corby Innovation Hub, Bangrave Road South Weldon, Corby NN17 1NN for the
              attention of: The Data Protection Officer.{' '}
            </li>
            <li>
              You have the right to ask us to rectify the data we hold if it contains inaccuracies
              or is incomplete. To discuss this please contact us on email:{' '}
              <a href="mailto:data@choixce.com">data@choixce.com</a>. or by writing to us at Choixce
              Limited, Corby Innovation Hub, Bangrave Road South Weldon, Corby NN17 1NN for the
              attention of: The Data Protection Officer.{' '}
            </li>
            <li>
              You have the right to ask us to erase the Personal Data we hold if: (i) it is no
              longer necessary for us to hold the data; (ii) you wish to withdraw your consent to us
              holding the data; (iii) you object to us holding or processing the data; (iv) you
              believe that we have processed the data unlawfully; or (v) the data needs to be erased
              for us to comply with a particular legal obligation. To make a request please contact
              us on email: <a href="mailto:data@choixce.com">data@choixce.com</a>. or by writing to
              us at Choixce Limited, Corby Innovation Hub, Bangrave Road South Weldon, Corby NN17
              1NN for the attention of: The Data Protection Officer.{' '}
            </li>
          </ul>
          <h3>Disclosure of your information</h3>
          <p>You agree that we have the right to share your Personal Data with: </p>
          <ul>
            <li>
              Any member of our group, which means our subsidiaries, our ultimate holding company
              and its subsidiaries, as defined in section 1159 of the UK Companies Act 2006.
            </li>
            <li>
              Selected third parties including:
              <ul>
                <li>
                  Our payment providers, our couriers, our business partners, suppliers and
                  sub-contractors for the performance of any contract we enter into with them or
                  you;
                </li>
                <li>
                  analytics and search engine providers that assist us in the improvement and
                  optimisation of the CHOIXCE Site and our App.
                </li>
              </ul>
            </li>
            <li>To a prospective buyer or seller if we sell or buy any business or assets. </li>
            <li>
              If any of the shares in CHOIXCE are sold or substantially all its assets are acquired
              by a third party, in which case Personal Data held by it about its customers will be
              one of the transferred assets.
            </li>
            <li>
              If we are under a duty to disclose or share your Personal Data to comply with any
              legal obligation, or to enforce or apply our terms of business or terms of use, and
              other agreements; or to protect the rights, property, or safety of CHOIXCE , our
              customers, or others. This includes exchanging information with other companies and
              organisations for the purposes of fraud detection and credit risk reduction.
            </li>
          </ul>
          <h3>Cookies</h3>
          <p>
            All Cookies used by CHOIXCE on the CHOIXCE Site and App are used in accordance with
            current Cookie Law.{' '}
          </p>
          <h4>
            Please note: when you land on the CHOIXCE Site or App, the only cookies created at that
            point are analytics cookies. However, when you create an account with us, we may use
            some or all of the following types of Cookie:
          </h4>
          <ul>
            <li>
              Strictly Necessary Cookies (SNC) - a Cookie falls into this category if it is
              essential to the operation of the CHOIXCE Site, supporting functions such as logging
              in.{' '}
            </li>
            <li>
              Analytics and Flash Cookies (AFC) - it is important for CHOIXCE to understand how you
              use the CHOIXCE Site and our App. For example, how efficiently you are able to
              navigate around it, and what features you use. Analytics Cookies enable us to gather
              this information, helping CHOIXCE to improve the CHOIXCE Site, our App and your
              experience of them.{' '}
            </li>
            <li>
              Functionality Cookies (FC) - enable us to provide additional functions to you on the
              CHOIXCE Site and our App, such as personalisation and remembering your saved
              preferences. Some functionality Cookies may also be strictly necessary Cookies, but
              not all necessarily fall into that category.
            </li>
            <li>
              Persistent Cookies (PC) - any of the above types of Cookie may be a persistent Cookie.
              Persistent Cookies are those which remain on your computer or device for a
              predetermined period and are activated each time you visit the CHOIXCE Site or our
              App.{' '}
            </li>
            <li>
              Session Cookies (SC) - any of the above types of Cookie may be a session Cookie.
              Session Cookies are temporary and only remain on your computer or device from the
              point at which you visit the CHOIXCE Site or our App until you close your browser/ the
              App. Session Cookies are not deleted when you close your browser, to delete these
              cookies you will need to clear your browser history.{' '}
            </li>
            <li>
              Web beacons (WB) - certain pages on the CHOIXCE Site or our App and e-mails may
              contain small electronic files known as web beacons. Web beacons permit CHOIXCE to
              monitor the volume of users of the CHOIXCE Site and our App and the popularity of
              certain pages. Before Cookies are placed on your computer or device you will be shown
              a cookie compliance statement, requesting your consent to set those Cookies. By giving
              your consent to the placing of Cookies you are enabling us to provide you with the
              best possible experiences and services to you. You may wish to deny consent to the
              placing of the Cookies at which point we request you cease using the CHOIXCE Site or
              our App as the case may be. In addition to the controls that CHOIXCE provide, you can
              choose to enable or disable Cookies and the web beacons referred to above in your
              internet browser. Most internet browsers also enable you to choose whether you wish to
              disable all Cookies or only third-party Cookies. By default, most internet browsers
              accept Cookies, but this can be changed. For further details, please access the help
              menu in your internet browser. Although we do not collect and store cookies, our
              partner Auth0 will store cookies on our behalf. Their data protection compliance
              information can be found at: Auth0 General Data Protection Regulation Compliance. In
              the table below, you can see what cookies will be collected by Auth0 on CHOIXCEs
              behalf and their purpose:
            </li>
          </ul>
          <table>
            <tbody>
              <tr>
                <td>_ga</td>
                <td>
                  <a href="choixce.com" target="_blank" rel="noopener noreferrer">
                    .choixce.com
                  </a>
                </td>
                <td>Used to distinguish users.</td>
              </tr>
              <tr>
                <td>_gid</td>
                <td>
                  <a href="choixce.com" target="_blank" rel="noopener noreferrer">
                    .choixce.com
                  </a>
                </td>
                <td>Used to distinguish users.</td>
              </tr>
              <tr>
                <td>{'_ga_<container-id>'}</td>
                <td>
                  <a href="choixce.com" target="_blank" rel="noopener noreferrer">
                    .choixce.com
                  </a>
                </td>
                <td>Used to distinguish users.</td>
              </tr>
              <tr>
                <td>{'_ga_<container-id>'}</td>
                <td>
                  <a href="choixce.com" target="_blank" rel="noopener noreferrer">
                    .choixce.com
                  </a>
                </td>
                <td>Used to distinguish users.</td>
              </tr>
              <tr>
                <td>{'_gac_gb_<container-id>'}</td>
                <td>
                  <a href="choixce.com" target="_blank" rel="noopener noreferrer">
                    .choixce.com
                  </a>
                </td>
                <td>{`Contains campaign related information. If you have linked your Google Analytics and Google Ads accounts,
                  Google Ads website conversion tags will read this cookie unless you opt-out.`}</td>
              </tr>
              <tr>
                <td>{'_fbp, fr, tr'}</td>
                <td>
                  <a href="choixce.com" target="_blank" rel="noopener noreferrer">
                    .choixce.com
                  </a>
                </td>
                <td>
                  It is used by Facebook to measure and improve the relevance of ads and to deliver
                  advertisement products such as real time bidding from third-party advertisers.
                </td>
              </tr>
              <tr>
                <td>notice_behavior</td>
                <td>
                  <a href="https://auth0.com/" target="_blank" rel="noopener noreferrer">
                    .auth0.com
                  </a>
                </td>
                <td>This is a functionality cookie used to store a visitors cookie preferences</td>
              </tr>
              <tr>
                <td>optimizelyEndUserId</td>
                <td>
                  <a href="https://auth0.com/" target="_blank" rel="noopener noreferrer">
                    .auth0.com
                  </a>
                </td>
                <td>
                  Stores a visitors unique Optimizely identifier. Its a combination of a timestamp
                  and and random number. No other information about you or your visitors is stored
                  inside.
                </td>
              </tr>
              <tr>
                <td>OptanonConsent</td>
                <td>
                  <a href="https://auth0.com/" target="_blank" rel="noopener noreferrer">
                    .auth0.com
                  </a>
                </td>
                <td>The consent status of a visitor is indicated using the first party</td>
              </tr>
              <tr>
                <td>ajs_anonymous_id</td>
                <td>
                  <a href="https://auth0.com/" target="_blank" rel="noopener noreferrer">
                    .auth0.com
                  </a>
                </td>
                <td>
                  Functional cookies give the user information that enriches the browsing
                  experience, such as displaying the weather forecast based on your location.
                </td>
              </tr>
              <tr>
                <td>auth0</td>
                <td>
                  <a href="choixce.com" target="_blank" rel="noopener noreferrer">
                    .choixce.com
                  </a>
                </td>
                <td>Used to implement the Auth0 Session Layer.</td>
              </tr>
              <tr>
                <td>auth0_compat</td>
                <td>
                  <a href="choixce.com" target="_blank" rel="noopener noreferrer">
                    .choixce.com
                  </a>
                </td>
                <td>
                  Fallback cookie for Single Sign On on browsers that don’t support the
                  sameSite=None attribute.
                </td>
              </tr>
              <tr>
                <td>auth0-mf</td>
                <td>
                  <a href="choixce.com" target="_blank" rel="noopener noreferrer">
                    .choixce.com
                  </a>
                </td>
                <td>Used to establish the trust level for a given device.</td>
              </tr>
              <tr>
                <td>auth0-mf_compat</td>
                <td>
                  <a href="choixce.com" target="_blank" rel="noopener noreferrer">
                    .choixce.com
                  </a>
                </td>
                <td>
                  Fallback cookie for Multi-factor Authentication on browsers that don’t support the
                  sameSite=None attribute.
                </td>
              </tr>
              <tr>
                <td>did</td>
                <td>
                  <a href="choixce.com" target="_blank" rel="noopener noreferrer">
                    .choixce.com
                  </a>
                </td>
                <td>Device identification for the purposes of Attack Protection.</td>
              </tr>
              <tr>
                <td>did_compat</td>
                <td>
                  <a href="choixce.com" target="_blank" rel="noopener noreferrer">
                    .choixce.com
                  </a>
                </td>
                <td>
                  Fallback cookie for Anomaly Detection on browsers that don’t support the
                  sameSite=None attribute.
                </td>
              </tr>
              <tr>
                <td>auth0_compat</td>
                <td>
                  <a href="choixce.com" target="_blank" rel="noopener noreferrer">
                    .choixce.com
                  </a>
                </td>
                <td>
                  We use this cookie as a unique random key to prevent replay attacks on request
                  with a nonce (one-time) token held and stored locally.
                </td>
              </tr>
              <tr>
                <td>auth0.ssodata</td>
                <td>
                  <a href="/" target="_blank">
                    .choixce.com
                  </a>
                </td>
                <td>
                  This cookie allows users to re-authenticate last used log in credentials with User
                  ID and connection ID of the last user logged in stored locally to enhance user
                  performance and experience of the website.
                </td>
              </tr>
              <tr>
                <td>_ym_metrika_enabled</td>
                <td>
                  <a href="/" target="_blank">
                    .choixce.com
                  </a>
                </td>
                <td>Checks whether other Yandex.Metrica cookies are installed correctly.</td>
              </tr>
              <tr>
                <td>_ym_isad</td>
                <td>
                  <a href="/" target="_blank">
                    .choixce.com
                  </a>
                </td>
                <td>Determines whether a user has ad blockers.</td>
              </tr>
              <tr>
                <td>_ym_uid</td>
                <td>
                  <a href="/" target="_blank">
                    .choixce.com
                  </a>
                </td>
                <td>Used for identifying site users.</td>
              </tr>
              <tr>
                <td>_ym_d</td>
                <td>
                  <a href="/" target="_blank">
                    .choixce.com
                  </a>
                </td>
                <td>Saves the date of the user&apos;s first site session.</td>
              </tr>
              <tr>
                <td>yabs-sid</td>
                <td>
                  <a href="https://metrica.yandex.com/" target="_blank" rel="noopener noreferrer">
                    .yandex.com
                  </a>
                </td>
                <td>Yandex.Metrica - Session ID.</td>
              </tr>
              <tr>
                <td>_ym_debug</td>
                <td>
                  <a href="https://metrica.yandex.com/" target="_blank" rel="noopener noreferrer">
                    .yandex.com
                  </a>
                </td>
                <td>Yandex.Metrica - Indicates that debug mode is active.</td>
              </tr>
              <tr>
                <td>_ym_mp2_substs</td>
                <td>
                  <a href="https://metrica.yandex.com/" target="_blank" rel="noopener noreferrer">
                    .yandex.com
                  </a>
                </td>
                <td>Yandex.Metrica - Used for Target Call.</td>
              </tr>
              <tr>
                <td>_ym_visorc_*</td>
                <td>
                  <a href="https://metrica.yandex.com/" target="_blank" rel="noopener noreferrer">
                    .yandex.com
                  </a>
                </td>
                <td>Yandex.Metrica - Allows Session Replay to function correctly.</td>
              </tr>
              <tr>
                <td>_ym_hostIndex</td>
                <td>
                  <a href="https://metrica.yandex.com/" target="_blank" rel="noopener noreferrer">
                    .yandex.com
                  </a>
                </td>
                <td>Yandex.Metrica - Limits the number of requests.</td>
              </tr>
              <tr>
                <td>_ym_mp2_track</td>
                <td>
                  <a href="https://metrica.yandex.com/" target="_blank" rel="noopener noreferrer">
                    .yandex.com
                  </a>
                </td>
                <td>Yandex.Metrica - Used for Target Call.</td>
              </tr>
              <tr>
                <td>i, yandexuid, yuidss</td>
                <td>
                  <a href="https://metrica.yandex.com/" target="_blank" rel="noopener noreferrer">
                    .yandex.com
                  </a>
                </td>
                <td>Yandex.Metrica - Used for identifying site users.</td>
              </tr>
              <tr>
                <td>yp, mda</td>
                <td>
                  <a href="https://metrica.yandex.com/" target="_blank" rel="noopener noreferrer">
                    .yandex.com
                  </a>
                </td>
                <td>
                  Yandex.Metrica - These cookies are used to measure and analyze the traffic of this
                  website. It collects information about how visitors use our website.
                </td>
              </tr>
              <tr>
                <td>gdpr, is_gdpr, is_gdpr_b</td>
                <td>
                  <a href="https://metrica.yandex.com/" target="_blank" rel="noopener noreferrer">
                    .yandex.com
                  </a>
                </td>
                <td>
                  Used by Yandex Metrica to collect information about the visitor&apos;s behaviors
                  on our website..
                </td>
              </tr>
              <tr>
                <td>ymex</td>
                <td>
                  <a href="https://metrica.yandex.com/" target="_blank" rel="noopener noreferrer">
                    .yandex.com
                  </a>
                </td>
                <td>
                  Stores auxiliary information for Yandex.Metrica performance: ID creation time and
                  their alternative values.
                </td>
              </tr>
              <tr>
                <td>usst</td>
                <td>
                  <a href="https://metrica.yandex.com/" target="_blank" rel="noopener noreferrer">
                    .yandex.com
                  </a>
                </td>
                <td>
                  Stores auxiliary information for syncing site user IDs between different Yandex
                  domains.
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            You can also prevent the use of any cookies by the settings of your browser. However,
            this could affect the service functions and block also technically necessary cookies.
          </p>
          <h3>Email tracking</h3>
          <p>
            Some emails that we send may have tracking on so that we can tell how much traffic those
            emails send to the CHOIXCE Site and our App.
          </p>
          <h3>Suppliers and Other Service Providers</h3>
          <p>
            From time to time, we use third party suppliers and service providers to facilitate our
            services. Where any service provider collects information directly from you (for
            example, in the course of making a purchase via one of our payment providers or
            processing a return via one of our couriers) you will be notified of the involvement of
            the supplier or service provider, and all information disclosures you make will be
            strictly optional.
          </p>
          <h3>Control of your password on your account</h3>
          <p>
            You must not disclose your CHOIXCE password to any third parties nor share it with any
            third parties. If you lose control of your password, you may lose control over your
            Personal Data which in turn may commit you to legally binding obligations without your
            consent. Therefore, if your password has been compromised for any reason, you should
            immediately contact us at: email: <a href="mailto:data@choixce.com">data@choixce.com</a>
            . or by writing to us at Choixce Limited, Corby Innovation Hub, Bangrave Road South
            Weldon, Corby NN17 1NN for the attention of: The Data Protection Officer.
          </p>
        </div>
      </div>
    </MainLayout>
  );
};

import { ComponentProps, forwardRef } from 'react';
import { combineStyles, StylingProps } from './styling';

type NativeProps = ComponentProps<'input'>;

type Props = NativeProps & StylingProps;

export const Input = forwardRef<HTMLInputElement, Props>(function Input(props, ref) {
  const [className, inputProps] = combineStyles(props);

  return <input {...inputProps} className={className} ref={ref} />;
});

import { FC } from 'react';
import { Card } from '../../dataTypes';
import { CardBrandIcon } from '../CardBrandIcon';
import styles from './CardSummary.module.scss';
import { useCardBrand } from '../../hooks/useCardBrand';
import { useCardLastDigits } from '../../hooks/useCardLastDigits';

interface Props {
  small?: boolean;
  card: Card;
}

export const CardSummary: FC<Props> = (props: Props) => {
  const { card, small } = props;
  const { cardNumber, expiryMonth, expiryYear } = card;

  const lastCardNumberDigits = useCardLastDigits(cardNumber);
  const cardBrand = useCardBrand(cardNumber);

  return (
    <div>
      <div className={styles.title}>
        <CardBrandIcon cardBrand={cardBrand} />
        {small ? (
          <div>
            <div className={styles.smallTitle}>Payment method</div>
            <div className={styles.smallText}>**** **** **** {lastCardNumberDigits}</div>
          </div>
        ) : (
          <>
            <span>{cardBrand}</span>&nbsp;ending in {lastCardNumberDigits}
          </>
        )}
      </div>
      {!small && (
        <div className={styles.subtitle}>
          Valid until {expiryMonth} / {expiryYear}
        </div>
      )}
    </div>
  );
};

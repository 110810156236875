/* istanbul ignore file */
import { useGo } from './useGo';
import { useStore } from '../../../providers/useStore';
import { useMutation } from 'react-query';

export const useGoWithSave = (path: string): (() => Promise<unknown>) => {
  const { registrationFlowStore } = useStore();
  const go = useGo(path);

  const { mutateAsync } = useMutation('createOrder', async () => {
    await registrationFlowStore.createOrUpdate();
    go();
  });

  return mutateAsync;
};

import { FC } from 'react';
import { PaymentMethod, PurchaseTotals } from '../../../../../../dataTypes';
import { NewCardPayment } from './NewCard';
import { DirectPayment } from './Direct';
import { Loading } from '../../../../../../components/Loading';
import styles from './Payment.module.scss';
import { usePaymentMethods } from '../../../../../../serverCache/paymentMethods';
import { arrayHasItems, calculateCredit } from '../../../../../../utils';
import { useAvailableCredits } from 'serverCache/credits';

interface Props {
  orderId: string;
  totals: PurchaseTotals;
  onPay(type: string, paymentMethod?: PaymentMethod): void;
}

export const Payment: FC<Props> = ({ orderId, totals, onPay }) => {
  const { data: paymentMethods, isLoading: isPaymentMethodsLoading } = usePaymentMethods();
  const { data: credits, isLoading: isCreditsLoading } = useAvailableCredits(orderId);

  const creditAmount = calculateCredit(credits);

  return (
    <>
      {isPaymentMethodsLoading || isCreditsLoading ? (
        <div className={styles.loadingWrapper}>
          <Loading isBlack />
        </div>
      ) : (
        <>
          {!arrayHasItems(paymentMethods) && <NewCardPayment />}
          {arrayHasItems(paymentMethods) && (
            <DirectPayment
              paymentMethods={paymentMethods}
              purchaseTotals={totals}
              creditAmount={creditAmount}
              onPay={onPay}
            />
          )}
        </>
      )}
    </>
  );
};

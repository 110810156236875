import { AxiosInstance } from 'axios';
import { makePutSetReturnReasonUrl } from '../consts';

export class ReturnApiService {
  constructor(private readonly axiosInstance: AxiosInstance) {}

  async setReturnReasonForItem(id: string, itemId: string, returnReason: string): Promise<void> {
    const url = makePutSetReturnReasonUrl(id, itemId);
    await this.axiosInstance.put(url, { returnReason });
  }
}

/* istanbul ignore file */

import React, { FC, Fragment, useState } from 'react';
import { Address, SubmittedOrder } from '../../../../../../dataTypes';
import styles from './DeliveryDetailsInfo.module.scss';
import { OrderStatusBadge } from '../../../OrderStatusBadge';
import { dateToLongString } from '../../../../../../utils';
import classNames from 'classnames';
import ChevronLeftIcon from 'components/Icons/ChevronLeftIcon/ChevronLeftIcon';

interface Props {
  order: SubmittedOrder;
  hideOnMobile?: boolean;
}

function notUndefined<T>(item: T | undefined): item is T {
  return typeof item !== 'undefined';
}

const generateAddressLines = (address: Address): React.ReactElement => (
  <>
    {[address.house, address.street, address.city, address.county, address.postcode]
      .filter(notUndefined)
      .map((value, index, arr) => (
        <Fragment key={index}>
          {value}
          {index + 1 < arr.length && (
            <>
              <br />
            </>
          )}
        </Fragment>
      ))}
  </>
);

export const DeliveryDetailsInfo: FC<Props> = (props) => {
  const { order, hideOnMobile } = props;

  const name = order.stylingProfile.firstName
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `${order.stylingFor} - ${order.stylingProfile.firstName} ${order.stylingProfile.lastName!}`
    : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `${order.stylingFor} - ${order.stylingProfile.name!}`;

  const [showDetailsInfo, setShowDetailsInfo] = useState(false);

  const toggleOrderDetails = (): void => {
    setShowDetailsInfo((current) => {
      if (!current) {
        document.querySelector('body')?.classList.add('isModalOpened');
        window.scrollTo(0, 0);
      } else {
        document.querySelector('body')?.classList.remove('isModalOpened');
      }
      return !current;
    });
  };

  return (
    <div className={styles.wrapper}>
      {hideOnMobile && !showDetailsInfo && (
        <div className={styles.detailsNavContainer}>
          <div className={styles.text}>{<OrderStatusBadge status={order.status} />}</div>
          <div className={styles.showOrderDetailsLink} onClick={toggleOrderDetails}>
            Show order details
          </div>
        </div>
      )}

      <div
        className={classNames(styles.container, {
          [styles.isOpened]: showDetailsInfo,
          [styles.hiddenContainer]: hideOnMobile,
        })}
      >
        {hideOnMobile && showDetailsInfo && (
          <div className={styles.backToOrderLinkWrapper}>
            <ChevronLeftIcon />
            <div className={styles.backToOrderLink} onClick={toggleOrderDetails}>
              Back to order
            </div>
          </div>
        )}
        <div className={styles.orderInfo}>
          <div className={styles.infoBox}>
            <div className={styles.title}>Order</div>
            <div className={styles.text}>{name}</div>
          </div>

          <div className={styles.infoBox}>
            <div className={styles.title}>Date</div>
            <div className={styles.text}>{dateToLongString(order.createdDate)}</div>
          </div>

          <div className={styles.infoBox}>
            <div className={styles.title}>Status</div>
            <div className={styles.text}>{<OrderStatusBadge status={order.status} />}</div>
          </div>
        </div>

        <div className={styles.orderInfo}>
          <div className={styles.infoBox}>
            <div className={styles.title}>Delivery address</div>
            <div className={styles.text}>{order.address.phone}</div>
            <div className={styles.address}>{generateAddressLines(order.address)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

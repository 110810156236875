import { FC } from 'react';
import { Button } from '../../../../components/Button';
import styles from './Logout.module.scss';
import { useLogout } from 'providers/useLogout';

interface LogoutProps {
  className?: string;
  returnTo?: string;
}

export const Logout: FC<LogoutProps> = (props: LogoutProps) => {
  const { returnTo } = props;
  const logout = useLogout();

  return (
    <div className={props.className}>
      <Button onClick={() => logout(returnTo)} color="white" className={styles.button}>
        Logout
      </Button>
    </div>
  );
};

import { FC } from 'react';
import { useAuth } from '../providers/AuthProvider';
import { observer } from 'mobx-react-lite';
import { useAuthRedirect } from './useAuthRedirect';

export const withAuthenticationRequired = <P extends Record<string, unknown>>(
  Component: FC<P>,
): FC<P> => {
  return observer(function WithAuthenticationRequired(props: P): JSX.Element {
    const { isAuthenticated } = useAuth();

    useAuthRedirect();

    if (!isAuthenticated) {
      return <></>;
    }

    return <Component {...props} />;
  });
};

import { FC, useEffect, useRef, useContext } from 'react';
import { useAuth } from '../../../../providers/AuthProvider';
import { observer } from 'mobx-react-lite';
import styles from './RegistrationStep.module.scss';
import { CreateAuth0Lock } from '../../../../services/CreateAuth0Lock';
import { StepComponentProps } from '../../StepComponentProps';
import { useSetHeader } from 'pages/RegistrationFlow/hooks/useSetHeader';
import StepFooter from 'pages/RegistrationFlow/components/StepFooter';
import {
  useCreateCustomerProfile,
  useCustomerProfile,
  usePatchCustomerProfile,
} from 'serverCache/customerProfile';
import { CommunicationMethod } from 'dataTypes';
import StylistMessage from 'components/StylistMessage/StylistMessage';
import RegistrationFlowContext from 'providers/RegistrationFlowContext';

export const registrationStepTitle = 'Register your account';

export const RegistrationStep: FC<StepComponentProps> = observer(
  ({
    orderDraft,
    imageUrl,
    name,
    stepsCount,
    currentStepIndex,
    onChange,
    onStepBack,
    onStepForward,
  }) => {
    useSetHeader({ title: registrationStepTitle });

    const { setAuthResult, onForgotPassword, isAuthenticated } = useAuth();
    const emailRef = useRef(orderDraft.email); // Hack to use in `useEffect` without adding to deps list

    const { refetch: getCustomerProfile } = useCustomerProfile({ enabled: false });
    const { mutateAsync: createCustomerProfile } = useCreateCustomerProfile();
    const { mutateAsync: patchCustomerProfile } = usePatchCustomerProfile();

    const { header } = useContext(RegistrationFlowContext).values;
    const showProgressBar = currentStepIndex + 1 <= stepsCount;
    const showStylistMessage = showProgressBar && !header.isHidden;

    useEffect(() => {
      if (isAuthenticated) {
        onStepForward();
      }

      const auth0Lock = CreateAuth0Lock({
        container: 'signup',
        initialScreen: 'signUp',
        prefill: {
          email: emailRef.current,
        },
        theme: {
          logo: '',
          primaryColor: 'black',
          hideMainScreenTitle: true,
        },
      });

      if (!isAuthenticated) {
        auth0Lock.show();
      }

      const authenticate = async (result: AuthResult): Promise<void> => {
        setAuthResult(result);

        const { data: customerProfile } = await getCustomerProfile();

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const tokenPayload = result.idTokenPayload || {};

        if (!customerProfile) {
          const payload = {
            email: tokenPayload.email || orderDraft.email,
            firstName: orderDraft.firstName,
            lastName: orderDraft.lastName,
            phone: '',
            communicationMethod: 'call' as CommunicationMethod,
          };

          await createCustomerProfile(payload);
        } else if (!(customerProfile.firstName && customerProfile.lastName)) {
          await patchCustomerProfile({
            firstName: orderDraft.firstName,
            lastName: orderDraft.lastName,
          });
        }

        onStepForward();
      };

      auth0Lock.on('authenticated', (result) => void authenticate(result));
      auth0Lock.on('forgot_password submit', onForgotPassword);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <StepFooter
          onBack={onStepBack}
          hideNextButton
          hideLogin
          stepsCount={stepsCount}
          currentStepIndex={currentStepIndex}
        />

        {showStylistMessage && (
          <StylistMessage
            imageUrl={imageUrl}
            name={name || 'Lorna'}
            title={registrationStepTitle}
            message={''}
          />
        )}

        <div id="signup" className={styles.signup} />
        {/* <StepFooter onBack={onStepBack} hideNextButton hideLogin /> */}
      </>
    );
  },
);

/* eslint-disable react/no-unknown-property */
import { FC, useRef, useState } from 'react';
import styles from './VideoBanner.module.scss';
import poster from '../../../../assets/images/video/poster.webp';
import { Play } from 'components/Icons/Play';

const VideoBanner: FC = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const vidRef = useRef<HTMLVideoElement>(null);

  const handleVideoClick = (): void => {
    if (!vidRef.current) return;

    if (isPlaying) {
      vidRef.current.pause();
      setIsPlaying(false);
      return;
    }

    void vidRef.current.play();
    setIsPlaying(true);
  };

  return (
    <div className={styles.section}>
      <video
        className={styles.video}
        ref={vidRef}
        onClick={handleVideoClick}
        preload="none"
        loop
        playsInline
        muted
        poster={poster}
        webkit-playsinline="webkit-playsinline"
        x5-playsinline="x5-playsinline"
      >
        <source src="https://choixce.azureedge.net/video/iyc.webm" type="video/webm" />
        <source src="https://choixce.azureedge.net/video/iyc.mp4" type="video/mp4" />
      </video>
      {!isPlaying && (
        <button className={styles.button} onClick={handleVideoClick}>
          <Play className={styles.playIcon} />
          Watch video
        </button>
      )}
    </div>
  );
};

export default VideoBanner;

import { ComponentProps, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { useApplyStyles, StylingProps } from './styling';

type NativeButtonProps = ComponentProps<Link>;

type Props = StylingProps & NativeButtonProps;

export const RouterLinkButton = forwardRef<HTMLAnchorElement, Props>(function RouterLinkButton(
  props,
  ref,
) {
  const [className, children, buttonProps] = useApplyStyles(props);

  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return (
    <Link {...buttonProps} className={className} ref={ref}>
      {children}
    </Link>
  );
});

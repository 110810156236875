import { Auth0Lock } from 'auth0-lock';
import { makeAutoObservable } from 'mobx';
import { AxiosInstance } from 'axios';
import { AxiosProvider } from '../providers/AxiosProvider';
import { setChangePasswordRedirectUrl } from '../utils/changePasswordRedirect';
import { useHistory } from 'react-router-dom';
import { Auth0Error } from 'auth0-js';

export class AuthStore {
  isLoading = false;

  get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  invalidateAuth(): void {
    this.isLoading = false;
    this._isAuthenticated = false;
  }

  setAuthResult = (result: AuthResult): void => {
    this._isAuthenticated = true;
    this.isLoading = false;
    this.axiosProvider.setAuthResult(result);
  };

  private _isAuthenticated = false;

  public get axiosInstance(): AxiosInstance {
    /* istanbul ignore next */
    return this.axiosProvider.axiosInstance;
  }

  private axiosProvider!: AxiosProvider;

  setHistory = (history: ReturnType<typeof useHistory>): void => {
    /* istanbul ignore next */
    this.axiosProvider.history = history;
  };

  constructor(private lock: typeof Auth0Lock) {
    makeAutoObservable(this);
    this.axiosProvider = new AxiosProvider(this);
    this.isLoading = true;
    this.lock.checkSession({}, this.onCheckSessionDone);
    this.subscribeOnLockEvents();
  }

  private onCheckSessionDone = (
    error: Auth0Error | undefined,
    authResult: AuthResult | undefined,
  ): void => {
    if (error || !authResult) {
      this.invalidateAuth();
      return;
    }
    this.setAuthResult(authResult);
  };

  public onForgotPassword = (): void => {
    setChangePasswordRedirectUrl();
  };

  private subscribeOnLockEvents(): void {
    this.lock.on('forgot_password submit', this.onForgotPassword);
  }

  logout = (returnTo?: string): void => {
    this.lock.logout({
      returnTo,
    });
  };
}

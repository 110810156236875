import { FC, useMemo } from 'react';
import * as React from 'react';
import { PaymentMethod } from '../../../../../../dataTypes';
import { CardSummary } from '../../../../../../components/CardSummary';
import styles from './PaymentMethodSelector.module.scss';
import { moveFirstToStart } from './utils';

interface Props {
  selectedPaymentMethod: PaymentMethod;
  onSelectPaymentMethod(paymentMethod: PaymentMethod, e: React.SyntheticEvent): void;
  paymentMethods: PaymentMethod[];
}

export const PaymentMethodSelector: FC<Props> = (props: Props) => {
  const { selectedPaymentMethod, paymentMethods, onSelectPaymentMethod } = props;

  const sortedPaymentMethods = useMemo(
    () => moveFirstToStart(paymentMethods, (pm) => pm.id === selectedPaymentMethod.id),
    [paymentMethods, selectedPaymentMethod],
  );

  return (
    <div className={styles.boxSelect}>
      {sortedPaymentMethods.map((paymentMethod) => (
        <div key={paymentMethod.id} className={styles.card}>
          <input
            type="radio"
            className={styles.radio}
            key={paymentMethod.id}
            name="payment-method"
            id={`pm-${paymentMethod.id}`}
            value={paymentMethod.id}
            checked={selectedPaymentMethod.id === paymentMethod.id}
            onChange={(e) => onSelectPaymentMethod(paymentMethod, e)}
            onClick={(e) => onSelectPaymentMethod(paymentMethod, e)}
          />
          <label className={styles.formRadio} htmlFor={`pm-${paymentMethod.id}`}>
            <CardSummary card={paymentMethod.card} />
          </label>
        </div>
      ))}
    </div>
  );
};

export const languageDictionary = {
  title: '',
  loginLabel: 'Members Log In',
  loginSubmitLabel: 'Log In',
  signUpLabel: 'Register Account',
  signUpSubmitLabel: 'Register',
  signUpTerms:
    'By setting up an account with us and becoming one of our members or sending us an e-mail via our website or app, you are allowing Choixce Limited to access and store your personal data (including cookies) for the purpose of offering you our styling services and goods to buy in accordance with our Data Protection, Privacy and Cookies Policy which can be found at https://choixce.com/privacy. </br>I understand the above statement and I am happy to be contacted by Email.',
  error: {
    forgotPassword: {
      too_many_requests:
        'You have reached the limit on password change attempts. Please wait before trying again.',
      'lock.fallback': "We're sorry, something went wrong when requesting the password change.",
      enterprise_email:
        "Your email's domain is part of an Enterprise identity provider. To reset your password, please see your security administrator.",
    },
    login: {
      blocked_user: 'The user is blocked.',
      invalid_user_password: 'Wrong credentials.',
      invalid_captcha: 'Solve the challenge question to verify you are not a robot.',
      invalid_recaptcha: 'Select the checkbox to verify you are not a robot.',
      'lock.fallback': "We're sorry, something went wrong when attempting to sign in.",
      'lock.invalid_code': 'Wrong code.',
      'lock.invalid_email_password': 'Wrong email or password.',
      'lock.invalid_username_password': 'Wrong username or password.',
      'lock.network': 'We could not reach the server. Please check your connection and try again.',
      'lock.popup_closed': 'Popup window closed. Try again.',
      'lock.unauthorized': 'Permissions were not granted. Try again.',
      'lock.mfa_registration_required':
        'Multifactor authentication is required but your device is not enrolled. Please enroll it before moving on.',
      'lock.mfa_invalid_code': 'Wrong code. Please try again.',
      password_change_required:
        'You need to update your password because this is the first time you are logging in, or because your password has expired.',
      password_leaked:
        'We have detected a potential security issue with this account. To protect your account, we have blocked this login. An email was sent with instruction on how to unblock your account.',
      too_many_attempts: 'Your account has been blocked after multiple consecutive login attempts.',
      too_many_requests:
        "We're sorry. There are too many requests right now. Please reload the page and try again. If this persists, please try again later.",
      session_missing:
        "Couldn't complete your authentication request. Please try again after closing all open dialogs",
      'hrd.not_matching_email': 'Please use your corporate email to login.',
    },
    signUp: {
      invalid_password: 'Password is invalid.',
      'lock.fallback': 'The email is already registered.',
      password_dictionary_error: 'Password is too common.',
      password_no_user_info_error: 'Password is based on user information.',
      password_strength_error: 'Password is too weak.',
      user_exists: 'The user already exists.',
      username_exists: 'The email is already registered.',
      social_signup_needs_terms_acception:
        'Please agree to the Terms of Service below to continue.',
    },
  },
};

import React from 'react';
import { withAuthenticationRequired } from 'auth/withAuthenticationRequired';
import { ShowLoadingBeforeAuth } from 'components/ShowLoadingBeforeAuth';

const ProtectedArea: React.FC = withAuthenticationRequired(({ children }) => {
  return <>{children}</>;
});

const ProtectedAreaWithLoading: React.FC = ({ children }) => {
  return (
    <ShowLoadingBeforeAuth>
      <ProtectedArea>{children}</ProtectedArea>
    </ShowLoadingBeforeAuth>
  );
};

export default ProtectedAreaWithLoading;

import { FC } from 'react';
import { StatusViewProps } from '../../../propTypes';
import { OrderMessage } from '../../OrderMessage';
import { DeliveryDetailsInfo } from '../../DeliveryDetailsInfo';
import TwoColumnLayout from 'components/TwoColumnLayout';

export const PaymentOverdueStatusView: FC<StatusViewProps> = ({ order }: StatusViewProps) => {
  return (
    <TwoColumnLayout
      firstColumn={<DeliveryDetailsInfo order={order} hideOnMobile />}
      secondColumn={
        <OrderMessage messageText="Urgent! Payment for your order is overdue. Please contact your stylist for assistance." />
      }
    />
  );
};

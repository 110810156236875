import { FC } from 'react';
import styles from './BriefItem.module.scss';
import { OrderItem } from '../../../../dataTypes';
import { getIconPath } from '../../../../utils/itemIcons';

interface Props {
  item: OrderItem;
}

export const BriefItem: FC<Props> = ({ item }) => {
  const category = item.websiteCategory ?? item.category;

  return (
    <div className={styles.item}>
      <div className={styles.image}>
        <img src={getIconPath(category)} alt={category} />
      </div>
      <div className={styles.category}>
        <span className={styles.name}>{category}</span>
        {item.quantity > 1 && <span className={styles.quantity}>({item.quantity} pieces)</span>}
      </div>
      <div className={styles.size}>{item.size}</div>
    </div>
  );
};

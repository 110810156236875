import { DeepPartial } from 'dataTypes';
import deepmerge from 'deepmerge';
import React, { useState } from 'react';

export interface HeaderSetup {
  title?: string;
  subTitle?: string | JSX.Element;
  isHidden?: boolean;
}

export interface RegistrationFlowContextValues {
  header: HeaderSetup;
}

const defaultValues: RegistrationFlowContextValues = {
  header: {
    title: '',
    subTitle: '',
    isHidden: false,
  },
};

export interface RegistrationFlowContextInterface {
  values: RegistrationFlowContextValues;
  setValues(values: DeepPartial<RegistrationFlowContextValues>): void;
}

const RegistrationFlowContext = React.createContext<RegistrationFlowContextInterface>({
  values: defaultValues,
  setValues: () => null,
});

const RegistrationFlowContextProvider: React.FC = ({ children }) => {
  const [contextValue, setContextValue] = useState<RegistrationFlowContextValues>(defaultValues);

  const setValues = (value: DeepPartial<RegistrationFlowContextValues>): void => {
    const newContextValue = deepmerge(contextValue, value as RegistrationFlowContextValues);
    setContextValue(newContextValue);
  };

  return (
    <RegistrationFlowContext.Provider value={{ values: contextValue, setValues }}>
      {children}
    </RegistrationFlowContext.Provider>
  );
};

export { RegistrationFlowContextProvider };
export default RegistrationFlowContext;

/* istanbul ignore file */

import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { shortSiteTitle } from '../../constants';
import { ADDRESS_LIST_URL, ADDRESS_NEW_URL } from '../../routePaths';
import { Main } from './pages/Main';
import { New } from './pages/New';

export const Addresses: FC = () => {
  return (
    <>
      <Helmet>
        <title>{shortSiteTitle} - My addresses</title>
      </Helmet>
      <Switch>
        <Route exact path={ADDRESS_LIST_URL} component={Main} />
        <Route path={ADDRESS_NEW_URL} component={New} />
      </Switch>
    </>
  );
};

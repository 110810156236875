/* istanbul ignore file */
import { FC } from 'react';
import { StatusViewProps } from '../../../propTypes';
import { OrderMessage } from '../../OrderMessage';
import { ItemList } from '../../../../ItemList';
import { arrayHasItems } from '../../../../../../../utils';
import { DeliveryDetailsInfo } from '../../DeliveryDetailsInfo';
import TwoColumnLayout from 'components/TwoColumnLayout';

const message =
  'Oops! It seems like your delivery didnt happen within expected timeframe or we were not informed about it. ' +
  'Please contact your stylist to clarify the details.';

export const DeliveryOverdueStatusView: FC<StatusViewProps> = (props: StatusViewProps) => {
  const { order } = props;

  return (
    <TwoColumnLayout
      firstColumn={<DeliveryDetailsInfo order={order} hideOnMobile />}
      secondColumn={
        <>
          <OrderMessage messageText={message} />
          {arrayHasItems(order.items) && <ItemList title="My items" items={order.items} />}
        </>
      }
    />
  );
};

import * as React from 'react';
import 'rc-tooltip/assets/bootstrap.css';
import type { SliderProps } from 'rc-slider';
import Tooltip from 'rc-tooltip';

interface Props {
  value: number;
  children: React.ReactElement;
  visible: boolean;
  tipFormatter?(value: number): React.ReactNode;
}

const HandleTooltip: React.FC<Props> = (props: Props) => {
  const { value, children, visible, tipFormatter = (val) => `${val} %`, ...restProps } = props;

  const tooltipRef = React.useRef<unknown>();

  return (
    <Tooltip
      placement="bottom"
      overlay={tipFormatter(value)}
      overlayInnerStyle={{ minHeight: 'auto' }}
      ref={tooltipRef}
      visible={visible}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};

export const handleRender: SliderProps['handleRender'] = (node, props) => {
  return (
    <HandleTooltip value={props.value} visible={true}>
      {node}
    </HandleTooltip>
  );
};

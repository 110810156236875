import { FC } from 'react';
import classNames from 'classnames';
import { TickButtonIcon } from '../../../../components/Icons';
import styles from './SelectableCard.module.scss';

export declare type ImageSize = 'Medium' | 'Large';

export interface Props {
  imageUrl: string;
  imageUrlHover: string;
  title: string;
  text: string | undefined;
  isSelected: boolean;
  imageClassName?: string;
  buttonClassName?: string;

  onSelect(title: string, isSelected: boolean): void;
}

const SelectableCard: FC<Props> = (props: Props) => {
  const {
    imageUrl,
    imageUrlHover,
    title,
    text,
    isSelected,
    imageClassName,
    buttonClassName,
    onSelect,
  } = props;

  return (
    <div
      className={classNames(styles.button, buttonClassName, {
        [styles.boxActive]: isSelected,
      })}
    >
      <input
        id={title}
        type="checkbox"
        checked={isSelected || false}
        className={styles.checkboxInput}
        onChange={() => onSelect(title, !isSelected)}
      />
      <label htmlFor={title} className={styles.container} data-testid="label">
        <div className={styles.imageContainer}>
          <img
            className={classNames(styles.image, imageClassName)}
            src={imageUrl}
            alt={title}
            draggable={false}
          />
          <img
            src={imageUrlHover}
            alt={title}
            className={classNames(styles.imageHover, imageClassName)}
          />
          <div
            className={classNames(styles.overlay, {
              [styles.overlayActive]: isSelected,
            })}
          >
            <TickButtonIcon />
          </div>
        </div>

        <div
          className={classNames(styles.box, {
            [styles.boxActive]: isSelected,
          })}
        >
          <div className={styles.title}>{title}</div>
          <div className={styles.text}>{text}</div>
        </div>
      </label>
    </div>
  );
};

export default SelectableCard;

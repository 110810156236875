import { FC } from 'react';
import { useReorder } from './useReorder';
import { ReorderTile } from '../ReorderTile';
import { FavouritesBoxIcon } from '../../../../components/Icons';

export const PastFavouritesBox: FC = () => {
  const { status, mutate } = useReorder();

  return (
    <ReorderTile
      icon={<FavouritesBoxIcon />}
      title="Re-order"
      buttonTitle="Re-order"
      idleContent={<p>Order a new box based on your past favourites with one click.</p>}
      successContent={<p>Thank you. Your order will be dispatched shortly.</p>}
      errorContent={
        <p>
          Oops! We were unable to file an order for you. Please try again a bit later or contact
          your stylist if problem persists.
        </p>
      }
      status={status}
      mutate={mutate}
    />
  );
};

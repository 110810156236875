import { FC } from 'react';
import { useCreateMysteryOrder } from './useCreateMysteryOrder';
import { ReorderTile } from '../ReorderTile';
import { MysteryBoxIcon } from '../../../../components/Icons';

export const MysterySurpriseBox: FC = () => {
  const { status, mutate } = useCreateMysteryOrder();

  return (
    <ReorderTile
      icon={<MysteryBoxIcon />}
      title="Mystery Surprise Box"
      buttonTitle="order mystery box"
      idleContent={<p>Based on past purchases, we’ll send items we know you’ll love.</p>}
      successContent={<p>Thank you. Your order will be dispatched shortly.</p>}
      errorContent={
        <p>
          Oops! We were unable to file an order for you. Please try again a bit later or contact
          your stylist if problem persists.
        </p>
      }
      status={status}
      mutate={mutate}
    />
  );
};

import { Credit } from 'dataTypes';
import { useQuery, UseQueryResult } from 'react-query';
import { useService } from '../providers/useService';

export const CREDITS_KEY = 'credits';

export function useAvailableCredits(orderId: string): UseQueryResult<Credit[]> {
  const { apiService } = useService();

  return useQuery(CREDITS_KEY, async () => {
    return await apiService.getCreditList(orderId);
  });
}

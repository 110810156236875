export function initLocalGtag(gaAccount: string): void {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window.dataLayer = window.dataLayer || [];

  const gtag: Gtag.Gtag = function () {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  };

  window.gtag = gtag;

  gtag('js', new Date());
  gtag('config', gaAccount);
}

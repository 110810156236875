import { BoxSample } from 'dataTypes';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FC, useRef } from 'react';

import styles from './BoxSamplesTiles.module.scss';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import withMediaQuery from 'components/MediaQueries/withMediaQueries';
import { IMediaQueries } from 'hooks/useMediaQueries';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

interface BoxSamplesTilesProps {
  boxSamples: BoxSample[];
  mediaQueries?: IMediaQueries;
  showBadges?: boolean;
  autoplay?: boolean;
}

SwiperCore.use([Navigation]);
SwiperCore.use([Autoplay]);

const BoxSamplesTiles: FC<BoxSamplesTilesProps> = ({
  boxSamples,
  mediaQueries,
  showBadges,
  autoplay,
}: BoxSamplesTilesProps) => {
  const prevRef: React.RefObject<HTMLDivElement> = useRef(null);
  const nextRef: React.RefObject<HTMLDivElement> = useRef(null);

  return (
    <div className={styles.container}>
      <div className={styles.sliderButtonPrev} ref={prevRef} />
      <div className={styles.sliderButtonNext} ref={nextRef} />

      <Swiper
        spaceBetween={mediaQueries?.isPhone ? 10 : 20}
        slidesPerView="auto"
        centeredSlides
        loop={true}
        autoplay={
          autoplay
            ? {
                delay: 2000,
                disableOnInteraction: false,
              }
            : false
        }
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
      >
        {boxSamples.map((sample, index) => (
          <SwiperSlide key={index} className={styles.swiperSlide}>
            <div className={styles.item} key={index}>
              <div className={styles.imageUrl}>
                <LazyLoadImage
                  src={sample.imageUrl}
                  alt="Box Sample"
                  data-testid="sample-image"
                  effect="blur"
                />
                {showBadges && <div className={styles.typeBadge}>{sample.type}</div>}
              </div>
              {/* <div className={styles.info}>
                <div className={styles.box}>
                  <div className={styles.text}>RRP</div>
                  <div className={styles.originalPrice}>£{sample.originalPrice}</div>
                </div>
                <div className={styles.box}>
                  <div className={styles.customerPrice}>
                    {sample.name} paid <strong>£{sample.customerPrice}</strong>
                  </div>
                </div>
              </div> */}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default withMediaQuery<BoxSamplesTilesProps>(BoxSamplesTiles);

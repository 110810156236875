type Func<TIn, TOut> = (input: TIn) => TOut;

export function toDictionary<T, TKey extends string, TValue>(
  items: T[],
  keySelector: Func<T, TKey>,
  valueSelector: Func<T, TValue>,
): Record<TKey, TValue> {
  const result = {} as Record<TKey, TValue>;

  for (const item of items) {
    const key = keySelector(item);
    result[key] = valueSelector(item);
  }

  return result;
}

import { FC } from 'react';

export const FavouritesBoxIcon: FC = () => {
  return (
    <svg width="40" height="62" viewBox="0 0 40 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8">
        <path
          d="M39.8791 28.167C39.8519 28.13 39.8295 28.0898 39.8124 28.0473C39.7339 27.9132 39.6349 27.7921 39.5191 27.6882C39.3764 27.5516 39.209 27.4433 39.0258 27.369L20.36 22.0494C20.1245 21.9835 19.8755 21.9835 19.64 22.0494L0.9742 27.369C0.822678 27.4136 0.678822 27.4809 0.547553 27.5685C0.431764 27.6724 0.332816 27.7935 0.254233 27.9276C0.258636 27.9674 0.258636 28.0075 0.254233 28.0473C0.118713 28.2412 0.031838 28.4646 0.000910888 28.6989V55.2972C-0.0101535 55.5957 0.0798448 55.8892 0.256406 56.1304C0.432967 56.3717 0.685811 56.5466 0.9742 56.6271L19.64 61.9467C19.8747 62.0178 20.1253 62.0178 20.36 61.9467L39.0258 56.6271C39.3142 56.5466 39.567 56.3717 39.7436 56.1304C39.9202 55.8892 40.0102 55.5957 39.9991 55.2972V28.6989C39.9926 28.5157 39.9519 28.3353 39.8791 28.167ZM20 24.7092L33.8127 28.6989L20 32.6887L6.1873 28.6989L20 24.7092ZM2.66746 30.4677L5.334 31.2258V40.6681C5.32513 40.9719 5.42084 41.2696 5.60521 41.5116C5.78957 41.7536 6.05147 41.9252 6.34729 41.9981L11.6804 43.328C11.7867 43.3408 11.8941 43.3408 12.0004 43.328C12.354 43.328 12.6931 43.1879 12.9431 42.9385C13.1932 42.689 13.3336 42.3508 13.3336 41.9981V33.4999L18.6667 35.016V58.848L2.66746 54.2997V30.4677ZM10.6671 32.6887V40.2426L8.00055 39.5776V31.9838L10.6671 32.6887ZM37.3325 54.2997L21.3333 58.848V35.016L37.3325 30.4677V54.2997Z"
          fill="black"
        />
        <path
          d="M19.1358 2.54679C20.1475 2.01783 21.28 1.95778 22.2778 2.28917C23.2756 2.62057 24.1409 3.34409 24.6352 4.37323L25.1694 5.49632L26.2673 4.92199C28.2908 3.86401 30.7782 4.69013 31.7667 6.74843C32.7552 8.80672 31.9284 11.2963 29.905 12.3541C27.2073 13.7646 24.0428 15.2658 21.3451 16.6761L17.276 8.14645C16.2896 6.08765 17.1123 3.60466 19.1358 2.54679Z"
          stroke="#0D0D0D"
          strokeWidth="2.5"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

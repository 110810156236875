import { useParams } from 'react-router-dom';
import { StylingFor } from '../../../dataTypes';

enum UrlStylingFor {
  Men = 'men',
  Kids = 'kids',
}

export const useStylingFor = (): StylingFor => {
  const params = useParams<{ stylingFor: UrlStylingFor }>();
  /* istanbul ignore next */
  return params.stylingFor === UrlStylingFor.Kids ? StylingFor.Kids : StylingFor.Men;
};

import { HeightQuestion, QuestionBase, SelectQuestion } from '.';

export const noOfMenQuestionsInTopSection = 6;

export const menQuestions: QuestionBase<unknown>[] = [
  new HeightQuestion('Height'),

  new SelectQuestion(
    'Tops',
    [
      { title: '' },
      { title: 'XS' },
      { title: 'S' },
      { title: 'M' },
      { title: 'L' },
      { title: 'XL' },
      { title: 'XXL' },
      { title: 'XXXL' },
    ].map((value) => ({
      value: value.title,
      label: value.title,
    })),
  ),

  new SelectQuestion(
    'Waist Size',
    [
      { title: '' },
      { title: '28"' },
      { title: '29"' },
      { title: '30"' },
      { title: '31"' },
      { title: '32"' },
      { title: '33"' },
      { title: '34"' },
      { title: '35"' },
      { title: '36"' },
      { title: '37"' },
      { title: '38"' },
      { title: '39"' },
      { title: '40"' },
      { title: '41"' },
      { title: '42"' },
    ].map((value) => ({
      value: value.title,
      label: value.title,
    })),
  ),

  new SelectQuestion(
    'Leg Length',
    [
      { title: '' },
      { title: '28"' },
      { title: '30"' },
      { title: '32"' },
      { title: '34"' },
      { title: '36"' },
      { title: 'I don’t know' },
    ].map((value) => ({
      value: value.title,
      label: value.title,
    })),
  ),

  new SelectQuestion(
    'UK Shoe Size',
    [
      { title: '' },
      { title: '6' },
      { title: '6.5' },
      { title: '7' },
      { title: '7.5' },
      { title: '8' },
      { title: '8.5' },
      { title: '9' },
      { title: '9.5' },
      { title: '10' },
      { title: '10.5' },
      { title: '11' },
      { title: '11.5' },
      { title: '12' },
      { title: '12.5' },
      { title: '13' },
      { title: '13.5' },
      { title: '14' },
    ].map((value) => ({
      value: value.title,
      label: value.title,
    })),
  ),

  new SelectQuestion(
    'Neck Size',
    [
      { title: '' },
      { title: '14.5"' },
      { title: '15"' },
      { title: '15.5"' },
      { title: '16"' },
      { title: '16.5"' },
      { title: '17"' },
      { title: '17.5"' },
      { title: 'I don’t know' },
    ].map((value) => ({
      value: value.title,
      label: value.title,
    })),
  ),

  new SelectQuestion(
    'Blazer',
    [
      { title: '' },
      { title: '36"' },
      { title: '37"' },
      { title: '38"' },
      { title: '39"' },
      { title: '40"' },
      { title: '41"' },
      { title: '42"' },
      { title: '43"' },
      { title: '44"' },
      { title: '45"' },
    ].map((value) => ({
      value: value.title,
      label: value.title,
    })),
  ),
];

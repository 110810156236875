import React, { FC, Fragment, useMemo, useState, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { StylingFor, SystemOfMeasure } from '../../../../dataTypes';
import styles from './SizesStep.module.scss';
import { StepComponentProps } from '../../StepComponentProps';
import {
  HeightQuestion,
  SelectQuestion,
  TextQuestion,
  WeightQuestion,
} from '../../../../utils/questions';
import { SystemOfMeasureSwitch } from './SystemOfMeasureSwitch';
import {
  menQuestions,
  noOfMenQuestionsInTopSection,
} from '../../../../utils/questions/menQuestions';
import {
  kidsQuestions,
  noOfKidsQuestionsInTopSection,
} from '../../../../utils/questions/kidsQuestions';
import { getDobValidator } from '../PersonalDetails/ulils';
import { MultilineTextInput, TextInput } from '../../../../components';
import { DatePicker, dateOfBirthFormat } from '../../../../components/DatePicker';
import { WeightInput } from '../../../../components/WeightInput';
import { HeightInput } from '../../../../components/HeightInput';
import { useSubscribeOnFormChanges } from '../../../../utils/form-helpers';
import { omit } from '../../../../utils/omit';
import { dictToQuestionnaire } from './helpers';
import { ContentContainer } from '../../components/ContentContainer';
import { Select } from '../../../../components/Select';
import { useCanAutofocus } from 'hooks/useCanAutofocus';
import { useDefaultValues } from './useDefaultValues';
import { useSetHeader } from 'pages/RegistrationFlow/hooks/useSetHeader';
import StepFooter from 'pages/RegistrationFlow/components/StepFooter';
import StylistMessage from 'components/StylistMessage/StylistMessage';
import RegistrationFlowContext from 'providers/RegistrationFlowContext';

function isOf<TOut extends T, T>(Cls: ClassType<TOut>, instance: T): instance is TOut {
  return instance instanceof Cls;
}

export const kidsSizesStepTitle = "Kid's size & fit";
export const menSizesStepTitle = 'Tell us about your size and fit';
export const subTitle =
  'We’re almost there.. we just need a few more details to create your perfect box..';

export const SizesStep: FC<StepComponentProps> = ({
  orderDraft,
  imageUrl,
  name,
  stepsCount,
  currentStepIndex,
  onChange,
  onStepBack,
  onStepForward,
}: StepComponentProps) => {
  const { stylingFor, stylingProfile } = orderDraft;

  const { title, questions, noOfQuestionsInTopSection } = useMemo(() => {
    return stylingFor === StylingFor.Kids
      ? {
          title: kidsSizesStepTitle,
          questions: kidsQuestions,
          noOfQuestionsInTopSection: noOfKidsQuestionsInTopSection,
        }
      : {
          title: menSizesStepTitle,
          questions: menQuestions,
          noOfQuestionsInTopSection: noOfMenQuestionsInTopSection,
        };
  }, [stylingFor]);

  useSetHeader({ title, subTitle });

  const [showAllFields, setShowAllFields] = useState(false);
  const [systemOfMeasure, setSystemOfMeasure] = useState(stylingProfile.systemOfMeasure);

  const onSystemOfMeasureChange = (value: SystemOfMeasure): void => {
    onChange({
      stylingProfile: { systemOfMeasure: value },
    });
    setSystemOfMeasure(value);
  };

  const {
    register,
    control,
    watch,
    formState: { isValid, errors },
  } = useForm({
    defaultValues: useDefaultValues(orderDraft.stylingProfile),
    mode: 'onChange',
  });

  useSubscribeOnFormChanges(watch, ({ questionnaire, ...stylingProfile }) => {
    onChange({
      stylingProfile: {
        ...stylingProfile,
        questionnaire: dictToQuestionnaire(questionnaire),
      },
    });
  });

  const canAutoFocus = useCanAutofocus();

  const { header } = useContext(RegistrationFlowContext).values;
  const showProgressBar = currentStepIndex + 1 <= stepsCount;
  const showStylistMessage = showProgressBar && !header.isHidden;

  return (
    <>
      <StepFooter
        onBack={onStepBack}
        onNext={onStepForward}
        disableNext={!isValid}
        stepsCount={stepsCount}
        currentStepIndex={currentStepIndex}
      />

      {showStylistMessage && (
        <StylistMessage
          imageUrl={imageUrl}
          name={name || 'Lorna'}
          title={title}
          message={subTitle}
        />
      )}

      <ContentContainer>
        <form>
          <section className={styles.questionsContainer}>
            <SystemOfMeasureSwitch value={systemOfMeasure} onChange={onSystemOfMeasureChange} />
            <Controller
              name="dateOfBirth"
              control={control}
              rules={{
                validate: getDobValidator(stylingFor),
              }}
              render={({ field, fieldState }) => (
                <DatePicker
                  label="Date of Birth"
                  placeholder={dateOfBirthFormat}
                  hasError={!!fieldState.error}
                  autoFocus={canAutoFocus}
                  {...omit(field, ['ref'])}
                />
              )}
            />
            {questions
              .slice(0, showAllFields ? questions.length : noOfQuestionsInTopSection - 1)
              .map((question) => (
                <Fragment key={question.label}>
                  {isOf(HeightQuestion, question) && (
                    <Controller
                      control={control}
                      name={`questionnaire.${question.label}` as const}
                      rules={{
                        validate: question.validate,
                      }}
                      render={({ field, fieldState }) => (
                        <HeightInput
                          label={question.label}
                          systemOfMeasure={systemOfMeasure}
                          errorMessage={fieldState.error?.message}
                          {...omit(field, ['ref'])}
                        />
                      )}
                    />
                  )}
                  {isOf(WeightQuestion, question) && (
                    <Controller
                      control={control}
                      name={`questionnaire.${question.label}` as const}
                      rules={{
                        validate: question.validate,
                      }}
                      render={({ field, fieldState }) => (
                        <WeightInput
                          label={question.label}
                          systemOfMeasure={systemOfMeasure}
                          errorMessage={fieldState.error?.message}
                          {...omit(field, ['ref'])}
                        />
                      )}
                    />
                  )}
                  {isOf(SelectQuestion, question) && (
                    <Controller
                      control={control}
                      name={`questionnaire.${question.label}` as const}
                      rules={{
                        validate: question.validate,
                      }}
                      render={({ field }) => (
                        <Select
                          className={styles.optionSelectContainer}
                          label={question.label}
                          items={question.options}
                          {...omit(field, ['ref'])}
                        />
                      )}
                    />
                  )}
                  {isOf(TextQuestion, question) && (
                    <TextInput
                      key={question.label}
                      label={question.label}
                      placeholder={question.placeholder}
                      maxLength={question.maxLength}
                      hasError={!!errors.questionnaire?.[question.label]}
                      {...register(`questionnaire.${question.label}` as const, {
                        validate: question.validate,
                      })}
                    />
                  )}
                </Fragment>
              ))}
            {showAllFields && (
              <MultilineTextInput
                title="What else would you like us to know?"
                placeholder="Please specify here"
                {...register('questionnaireComments')}
              />
            )}
            <div className={styles.specifyMore} onClick={() => setShowAllFields(!showAllFields)}>
              {showAllFields ? 'Specify less' : 'Specify more'}
            </div>
          </section>
        </form>
      </ContentContainer>
      {/* <StepFooter onBack={onStepBack} onNext={onStepForward} disableNext={!isValid} /> */}
    </>
  );
};

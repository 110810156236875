import { FC } from 'react';

interface HomeIconProps {
  className?: string;
  width: number;
  height: number;
}

export const HomeIcon: FC<HomeIconProps> = (props: HomeIconProps) => {
  return (
    <div className={props.className} style={{ width: props.width, height: props.height }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 23 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.8">
          <path
            d="M1 10.3947V25.3158H7.63158V18.1316H15.3684V25.3158H22V10.3947L11.5 1L1 10.3947Z"
            stroke="black"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.5 11H5.5"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.5 11H10.5"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.5 11H15.5"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  );
};

import { useEffect } from 'react';
import { useStore } from 'providers/useStore';
import { useHistory } from 'react-router-dom';

const paymentResultParamName = 'result';
const checkoutIdParamName = 'checkoutId';

export const useProcessZipPaymentAfterRedirect = (orderId: string): void => {
  const { paymentStore } = useStore();
  const history = useHistory();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const isCheckoutSucceded = query.get(paymentResultParamName) === 'approved';
    const checkoutId = query.get(checkoutIdParamName);

    if (isCheckoutSucceded) {
      void paymentStore.payWithZip(orderId, checkoutId || '');
    }

    if (checkoutId) {
      history.replace({
        search: undefined,
      });
    }
  }, [history, orderId, paymentStore]);
};

import { DependencyList, useRef } from 'react';

type EqualDeps = (newValues: DependencyList, prevValues: DependencyList | null) => boolean;

function defaultEq(newValues: DependencyList, prevValues: DependencyList | null): boolean {
  if (prevValues === null) {
    return false;
  }

  if (newValues === prevValues) {
    return false;
  }

  if (newValues.length !== prevValues.length) {
    return false;
  }

  for (let i = 0; i < newValues.length; i++) {
    if (newValues[i] !== prevValues[i]) {
      return false;
    }
  }

  return true;
}

export function useSyncEffect(
  action: () => void,
  deps: DependencyList,
  eq: EqualDeps = defaultEq,
): void {
  const prevValuesRef = useRef<DependencyList | null>(null);

  const prevValues = prevValuesRef.current;
  if (!eq(deps, prevValues)) {
    action();
    prevValuesRef.current = deps;
  }
}

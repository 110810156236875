import { createContext, FC, useContext, useEffect, useRef, useState } from 'react';
import { Analytics, AnalyticsImpl, defaultAnalytics } from './Analytics';

const AnalyticsCtx = createContext<Analytics>(defaultAnalytics);

interface Props {
  gaAccount: string;
  fbPixelId: string;
  ymCounterId: string;
  loadScriptOnInit?: boolean;
}

export const AnalyticsContext: FC<Props> = (props) => {
  const { gaAccount, fbPixelId, ymCounterId, loadScriptOnInit } = props;
  const shouldBeLoadedRef = useRef(loadScriptOnInit);
  const [analytics] = useState(() => new AnalyticsImpl(gaAccount, fbPixelId, ymCounterId)); // Ensures that is executed synchronously and once

  useEffect(() => {
    if (shouldBeLoadedRef.current) {
      analytics.loadScripts();
    }
  }, [analytics]);

  return <AnalyticsCtx.Provider value={analytics}>{props.children}</AnalyticsCtx.Provider>;
};

export function useAnalytics(): Analytics {
  return useContext(AnalyticsCtx);
}

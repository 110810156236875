import { FC, PropsWithChildren } from 'react';
import { BorderBox } from '../BorderBox';
import { Loading } from '../Loading';

interface Props {
  isLoading?: boolean;
  className?: string;
}

export const LoadingBox: FC<PropsWithChildren<Props>> = ({ children, isLoading, className }) => {
  return isLoading ? (
    <BorderBox className={className}>
      <Loading isBlack />
    </BorderBox>
  ) : (
    <BorderBox>{children}</BorderBox>
  );
};
